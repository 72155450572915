import {Profile, ProfileData} from 'Common/pages/artist/Profile';
import {Style} from 'Shared/artists/Style';
import {Location} from 'Shared/artists/Location';
import {PressShot} from 'Shared/artists/PressShot';
import {SocialSites} from 'Shared/artists/SocialSites';
import {Videos} from 'Shared/artists/Videos';
import {Biography} from 'Shared/artists/Biography';
import {MusicLinks} from 'Shared/artists/MusicLinks';
import {Text} from 'Shared/forms/Inputs';
import {labelStyle, Wrap} from 'Shared/forms/Wrap';
import {ArtistDoc} from 'Shared/model/Artist';
import {createStore} from 'solid-js/store';
import {mergeProps} from 'solid-js';
import {PageProvider} from 'Shared/frontend/PageProvider';
import {steps} from 'Shared/artists/Steps';
import {css, injectGlobal} from '@emotion/css';
import {EditComponent} from 'Common/components/EditComponent';
import {Page} from 'Common/pages/Page';
import { Id } from 'Common/Id';
import { DeleteMessage2 } from 'Common/Messages';
import { palette } from 'Shared/backend/Theme';
import { DeleteButton } from 'Shared/forms/DeleteButton';
import { theme, ThemeProvider } from 'Shared/backend/ThemeProvider';
import { Theme } from 'Shared/artists/Theme';
import { standardRules } from 'Shared/common/Sanitise';

export const linkIconStyle = () => css({
	width: '2em'
});

export const searchLinkStyle = () => css(labelStyle(), {
	display: 'flex',
	alignItems: 'center',
	gap: '0.5em',
	color: 'rgb(102, 102, 102)',
	textDecoration: 'none'
});

export const activeStyle = () => css({
	color: palette.active
});

export const inactiveStyle = () => css({
	color: palette.inactive
});

export const sectionStyle = () => css({
	margin: '1em 0 2em 0'
});

export const sectionHeadingStyle = () => css({
	textTransform: 'uppercase',
	color: 'rgb(0, 0, 0)',
	fontSize: '1em',
	margin: '1em 0px',
});

export const sectionNoteStyle = () => css({
	fontSize: 10,
	lineHeight: '1.2em',
	margin: '2px 0px',
	color: 'rgb(102, 102, 102)',
	textTransform: 'initial'
});

export const verticalStyle = () => css({
	display: 'flex',
	flexDirection: 'column',
	gap: '1.5em'
});


export type ArtistProps = ArtistDoc & { 
	setStore: (...args: any[]) => void,
	editArtist: EditComponent<ProfileData,ArtistDoc>,
};

export function ProfilePage(props:ProfileData & {page:Page<ProfileData>})
{
	injectGlobal([standardRules,{body:Theme.body}]);

	const [artistStore,setArtistStore] = createStore(props.artist);

//XXX or am I better passing through all props?
	const artist = mergeProps(artistStore,{
		page: props.page,
		docId: artistStore._id,
		permission: 'edit',
		location: [],
		store: artistStore,
		setStore: setArtistStore,
	});

//<ErrorBoundary fallback={err => <div>GOT ERROR: {err}</div>}>

	return (
		<ThemeProvider theme={Theme}>
			<PageProvider page={props.page}>
				<div class={css(theme().content)}>
					<nav class={css(theme().nav)}>
						<a class={css(theme().a)} href='/manager'>Top</a>
						<a class={css(theme().a)} href='/user'>User details</a>  
					</nav>		

					<main class={steps()}>
						<ArtistName 	{...artist}  />

						<Style			{...artist} />
						<Location		{...artist} />
						<SocialSites	{...artist} />  
						<PressShot		{...artist} />
						<Videos			{...artist} />
						<MusicLinks		{...artist} />
						<Biography		{...artist} />

						<br/>
						<br/>
						<DeleteButton label='Delete artist profile'
							delete={() => deleteProfile(props.page,artistStore._id,location)}
						/>
					</main>
				</div>
			</PageProvider>		
		</ThemeProvider>
	);
}

async function deleteProfile(page,docId:Id,location:Location)
{
	if (!confirm('Delete this artist profile?'))
		return;

	await page.server.sendOperation(new DeleteMessage2(Profile.pageName,'edit',docId));

//TODO need to use Urls() or links.ts to help standardise...
	window.pageJs('/manager');
}


function ArtistName(artist:ArtistProps)
{
//XXX BUG when the artist name is deleted the various search options should change

	return (
		<Wrap label='Artist name' required={true}>
			<Text {...artist} field='name' />
		</Wrap>
	);
}


