import { css } from '@emotion/css';
import { theme } from 'Shared/backend/ThemeProvider';
import { Show } from 'solid-js';
import { For } from 'solid-js';

const breadcrumbsStyle = () => css({
	userSelect: 'none'
});


export function Breadcrumbs(props)
{
	return (
		<div class={breadcrumbsStyle()}>
			<For each={Object.entries(props.items)}>{(entry,i) => <>
				<Show when={entry[1]} fallback={entry[0]}>
					<a class={css(theme().a)} href={entry[1]}>{entry[0]}</a> 
				</Show>

				<Show when={ i() < Object.keys(props.items).length - 1 }>
					{' > '}
				</Show>
			</>}</For>
		</div>
	);
}

