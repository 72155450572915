import { z } from 'zod';
import {staffResource as staffResourceModel} from 'Shared/model/StaffResource';
import {DocumentQueries } from 'Common/config/PageConfigTypes';
import * as t from 'Shared/model/basic';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {FrontendPageConfig, frontendData} from 'Common/FrontendPageConfig';
import { StaffResourcePage } from 'Shared/frontend/staffResources/StaffResourcePage';

export const staffResourceParams = z.object({
	id: t.id
}).strict();	
export type StaffResourceParams = z.infer<typeof staffResourceParams>;

export const staffResourceData = frontendData.extend({
	pageDisplayName: z.string(),
	staffResource: staffResourceModel,
}).strict();
export type StaffResourceData = z.infer<typeof staffResourceData>;


export class StaffResource extends FrontendPageConfig<StaffResourceData,StaffResourceParams>
{
	static readonly pageName = 'frontend/staffResource';
	name() { return StaffResource.pageName; }

	settingsName() { return 'staffResource'; }

//TODO cf 'venueStaff' permission
	access() { return 'venueFrontend' as AccessAreaName; }

	title() { return 'Resource'; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			template: StaffResourcePage,
			pageDisplayName: 'Staff Resources'
		};
	}

	documents(params:StaffResourceParams):DocumentQueries
	{
		return ({
			...super.documents(params),
//XXX why are we putting all the docs on one page?		
			staffResources: {
				type: 'array',
				collection: 'staffResource',
				aggregate: () => [
					{'$sort': {'position':1}},
				],
			},
		});
	}
}

