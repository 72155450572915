import { css } from '@emotion/css';
import { onCleanup } from 'solid-js';
import { onMount } from 'solid-js';
import { Portal } from 'solid-js/web';

const overlayStyle = () => css({
	width: '100vw',
	height: '100vh',
	position: 'fixed',
	top: 0,
	left: 0,
	zIndex: 10
});

const backgroundStyle = () => css({
	width: '100%',
	height: '100%',
	backgroundColor: 'rgba(0, 0, 0, 0.8)'
});

const foregroundStyle = () => css({
	position: 'absolute',
	width: '100%',
	top: 0,
	overflow: 'auto',
	maxHeight: '100vh',
	animationName: 'slideIn',
	animationDuration: '0.5s'
,label:'foreground'	
});

const closeStyle = () => css({
	display: 'inline-block',
	position: 'fixed',
	top: 0,
	right: 0,

//TODO fluid type
	fontSize: 60,

	lineHeight: '1em',
	width: '1.3em',
	borderRadius: 0,
	padding: 0,
	border: 'none',
	fontWeight: 'bold',
	zIndex: 20,
	height: 80,
	color: 'black',
	backgroundColor: 'white',

/* General button settings: */	
	textAlign: 'center',
	minWidth: 'fit-content',
	maxWidth: '100%',
	userSelect: 'none',
	cursor: 'pointer'
});

const bodyStyle = () => css({
	maxWidth: 1100,
	margin: '0 auto'
});

const contentStyle = () => css({
	paddingBottom: 1
});


export function Overlay(props)
{
	/* Prevent underlay from scrolling */
	onMount(() => {
		document.body.style.overflowY = 'hidden';

		onCleanup(() => {
			document.body.style.overflowY = '';
		});
	});


	/* Note if needed: Portal can take a node other than <body> as an argument */
	return (
		<Portal>
			<div class={overlayStyle()}> 
				<div class={backgroundStyle()} />
				<div class={foregroundStyle()}>
					<div class={bodyStyle()}>
						<button class={closeStyle()} onClick={props.close}>×</button>
						<div class={contentStyle()}> 
							{props.children}
						</div>
					</div>
				</div>
			</div>
		</Portal>
	);
}
