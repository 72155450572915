import Assert from 'Common/Assert';
/* 
	NOTE:
	html2canvas is not being maintained. 
	html2canvas-pro has a problem with Emotion - maps class names to lowercase which breaks things intermittently.
*/
import * as htmlToImage from 'html-to-image';

export class HtmlCapturer 
{
	constructor()
	{
		this.saveFromLink = this.saveFromLink.bind(this);
		this.upload = this.upload.bind(this);
	}

	async saveFromLink(source:HTMLElement,scale:number)
	{
		const canvas = await this.getCanvas(source,scale);

		/*
			Could possibly use this library instead: https://github.com/rndme/download (but cf upload).
			Note htmlToImage can deliver pngs directly if need be.
		*/
		const dataURL = canvas.toDataURL();
		
		const downloadLink = document.createElement('a');
		downloadLink.download = Math.floor(Math.random() * 1000000).toString(36).toUpperCase() + '.png';

		const url = dataURL.replace(/^data:image\/png/,'data:application/octet-stream');
		downloadLink.setAttribute('href', url);
		downloadLink.click();
	}

	async upload(source:HTMLElement,scale:number,uploadUrl:string)
	{
		const canvas = await this.getCanvas(source,scale);
		const me = this;

		return await new Promise((resolve,reject) => {
			canvas.toBlob(async blob => {
				resolve(await me.uploadFile(Assert.have(blob),uploadUrl));
			});
		});
	}		

	private async getCanvas(source:HTMLElement,scale:number)
	{
		const poster = Assert.htmlElement(source);

		const canvas = await htmlToImage.toCanvas(poster,{
			pixelRatio: scale,
			filter: node => !node.classList?.contains('detailsButton')
		});

		return canvas;
	}

	private async uploadFile(file:File|Blob,uploadUrl:string)
	{
		const formData = new FormData();

		formData.append('file',file);

		const ret = await fetch(uploadUrl,{method:'POST',body:formData});
		const body = await ret.json();
		if (body?.errorType!=null)
			throw new Error('Error on server:'+JSON.stringify(body,undefined,4));   //TODO use better error

		return body;
	}
}

