import {ArtistProps, sectionNoteStyle, sectionStyle,sectionHeadingStyle, searchLinkStyle, activeStyle} from 'Shared/artists/ProfilePage';
import {IconColour} from 'Shared/backend/IconColour';
import {LinkOpen} from 'Shared/backend/icons/LinkOpen';
import {PeopleCircle} from 'Shared/backend/icons/PeopleCircle';
import {PersonCircle} from 'Shared/backend/icons/PersonCircle';
import {ImageUpload} from 'Shared/forms/ImageUpload';
import {Show} from 'solid-js';
import { css, cx } from '@emotion/css'
import { ArtistUrls } from 'Common/ArtistUrls';
import { usePage } from 'Shared/frontend/PageProvider';
import { IconPane } from 'Shared/forms/IconPane';


const imageUploadStyle = () => css({
	img: {
		maxWidth: 450,
		maxHeight: 300
	}
});

export const innerSearchLinkStyle = () => css(searchLinkStyle(), {
	gap: '0.3em'
});

export const linkIcon2Style = () => css({
	width: '1.5em'
});


export function PressShot(props:ArtistProps)
{
	const imageExists = () => props.image != undefined;
	const page = usePage();

	const setImage = value => props.setStore(...props.location,'image',value);

//TODO PeopleCircle (and PersonCircle) has a 'stroke' but shouldnt
	return (
		<section class={sectionStyle()}>
			<h4 class={sectionHeadingStyle()}>Press shot
				<div class={sectionNoteStyle()}>VOS will crop image to 3:2 landscape ratio</div>
			</h4>

			<IconPane>
				{ props.performanceType=='originalSolo' || props.performanceType=='coversSolo' || props.performanceType=='dj' ?
					<PersonCircle fill={imageExists() ? IconColour.active : IconColour.inactive} />
				:
					<PeopleCircle fill={imageExists() ? IconColour.active : IconColour.inactive} />
				}

				<div>
					<Show when={props.facebook || props.website}>
						<label class={css(searchLinkStyle(),{gap:'0.4em'})}>
							Search for photos on your 

							<Show when={props.facebook}>
								<a href={`https://www.facebook.com/${props.facebook}/photos`} target='_blank' class={cx(innerSearchLinkStyle(),activeStyle())}>
									<span>Facebook page</span> 
									<LinkOpen class={linkIcon2Style()} fill={IconColour.active} />
								</a>
							</Show>

							<Show when={props.facebook && props.website}>
								or 
							</Show>

							<Show when={props.website}>
								<a href={props.website} target='_blank' class={cx(innerSearchLinkStyle(),activeStyle())}>
									<span>website</span>
									<LinkOpen class={linkIcon2Style()} fill={IconColour.active} />
								</a>
							</Show>
						</label>
					</Show>

					<div class={imageUploadStyle()}>
						<ImageUpload {...props} 
							location={[...props.location,'image']}
							image={props.image} 
							setImage={setImage}
							urls={new ArtistUrls(usePage().build)}
							dimensions='landscape'
							permission='image'
							fallbackUrl='defaults/profile3x2.png'
							previewSize='medium'
							page={page}
						/>
					</div>
				</div>
			</IconPane>
		</section>
	);
}

