import { css } from '@emotion/css';
import { fluidType } from 'Shared/common/FluidType';
import { FullWidthStyle } from 'Shared/frontend/FullWidthStyle';
import { theme } from 'Shared/frontend/ThemeProvider';


const iframeStyle = () => css(theme().iframe, {
	width: '100%',
	height: '100%',

	paddingLeft: fluidType('px',theme().sizes.flipToMobileNav,theme().sizes.startMarginShrink,
		theme().sizes.narrowPageSideMargin,theme().sizes.widePageSideMargin),
	paddingRight: fluidType('px',theme().sizes.flipToMobileNav,theme().sizes.startMarginShrink,
		theme().sizes.narrowPageSideMargin,theme().sizes.widePageSideMargin),
});


/*
	Use this when embedding busy forms (single column only).
	On small pages the margins collapse to show more content.
*/

export function WideIframeForm(props)
{
	return (
		<FullWidthStyle>
			<iframe class={iframeStyle()}
					style={{'min-height':`${props.minHeight}px`}}
					src={props.url} 
					onload={window.parent.scrollTo(0,0)} 
					frameborder={0} marginheight={0} marginwidth={0} >
				Loading…
			</iframe>
		</FullWidthStyle>
	);
}


