import {AccessAreaName} from 'Common/permissions/AccessArea';
import {FrontendPageConfig, frontendData} from 'Common/FrontendPageConfig';
import { z } from 'zod';
import { ReservationPage } from 'Shared/frontend/reservationPage/ReservationPage';

export const reservationsParams = z.object({ }).strict();	
export type ReservationsParams = z.infer<typeof reservationsParams>;


export const reservationsData = frontendData.extend({
	pageDisplayName: z.string(),
}).strict();
export type ReservationsData = z.infer<typeof reservationsData>;



export class Reservations extends FrontendPageConfig<ReservationsData,ReservationsParams>
{
	static readonly pageName = 'frontend/reservations';
	name() { return Reservations.pageName; }

	settingsName() { return 'reservations'; }

	access() { return 'venueFrontend' as AccessAreaName; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			template: ReservationPage,
			pageDisplayName: 'Reservations'
		};
	}
}

