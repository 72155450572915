import { css, cx } from '@emotion/css';
import { ArtistImageAndSummary } from 'Shared/frontend/eventPage/ArtistImageAndSummary';
import { InstagramEmbed } from 'Shared/frontend/eventPage/InstagramEmbed';
import { BandcampButton } from 'Shared/frontend/icons/BandcampButton';
import { ListenOnApple } from 'Shared/frontend/icons/ListenOnApple';
import { ListenOnSpotify } from 'Shared/frontend/icons/ListenOnSpotify';
import { SoundcloudButton } from 'Shared/frontend/icons/SoundcloudButton';
import { palette, sizes, theme } from 'Shared/frontend/ThemeProvider';
import { LineupSet } from 'Shared/model/lineup';
import { Match, onCleanup, onMount, Show, Switch } from 'solid-js';
import { FacebookCircle } from 'Shared/frontend/icons/FacebookCircle';
import { InstagramCircle } from 'Shared/frontend/icons/InstagramCircle';
import { Twitter } from 'Shared/frontend/icons/Twitter';
import { WebsiteCircle } from 'Shared/frontend/icons/WebsiteCircle';
import { fluidType } from 'Shared/common/FluidType';
import { createSignal } from 'solid-js';
import { isServer } from 'solid-js/web';
import { inspect } from 'util';


/* Artist info is busy so using a custom point */
export const singleColumn = 800;

const artistStyle = () => css({
	width: '100%',
	maxWidth: '100%',
	overflowX: 'hidden',
	display: 'flex',
	flexDirection: 'column',
	gap: '1em',
	margin: '2em 0 4em 0'
});

const videoWrapStyle = () => css({
	float: 'left',
	width: '50%',
	marginRight: '1em'
});

const iframeStyle = () => css(theme().iframe,{
	width: '100%',
	/* 
		Taller aspect ratios may work better on phones... 
		The variability of the videos themselves also causes difficulty, particularly if we want
		to put things next to them in desktop mode. Youtube is probably more standard that Facebook, so 
		maybe best to go with that.
		Example of a square FB video:
			https://www.facebook.com/watch/?v=1182933525211403&t=16
		Example of a portrait FB video:
			https://www.facebook.com/aljamia.art/videos/782430049857325%2F&amp;show_text=false&amp;width=100&amp;height=60&amp;t=0
	*/
	aspectRatio: '16 / 9',
});

const instagramEmbedStyle = () => css({
	width: '100%',
	maxWidth: 410
});

const playerLinksStyle = (isWide:boolean,haveVideo:boolean) => css({
	flex: 1,
	width: isWide && haveVideo ? 'unset' : '100%',
	height: 'fit-content',
	display: 'flex',
	flexFlow: 'wrap',
	gap: '1em 1em',
	marginBottom: isWide ? '2em' : '1em'
});

/*
	These large white buttons are somewhat dominating, so tweaking with fluidType()
	here seems important.
*/
const playerStyle = (isWide:boolean,isPreview:boolean) => css({
	width: isWide ? 
		fluidType('em',singleColumn/16, sizes().maxPageWidth/16,9,12) : 
		fluidType('em',sizes().endFontShrink/16, singleColumn/16,7.5,11.5),

	...(isPreview ? {
		width: '7.5em'
	} : {}),

	svg: {
		width: '100%',
		verticalAlign: 'bottom',
		fill: palette().text
	}
});


const biographyStyle = () => css({
	overflowX: 'scroll',
	scrollbarWidth: 'none',

	a: {
		color: palette().feature,
		textDecoration: 'none'
	},

	/* Remove the outer margins. The HTML editor automatically includes <p> */
	'p:first-child' : {
		marginTop: 0
	},

	'p:last-child' : {
		marginBottom: 0
	},
});

const socialIconsStyle = () => css({
	display: 'flex',
	flexGrow: 1,
	justifyContent: 'flex-start'
});

const socialIconStyle = () => css({
	display: 'inline-block',
	width: '3.1em',
	padding: '0 0.3em',
});

function videoExists(props)
{
	return props.facebookVideoUrl || props.instagramVideoUrl || props.youtubeVideoUrl || props.videoEmbedId;
}

function playerExists(props)
{
	return props.spotify || props.appleMusic || props.bandcamp || props.soundcloud;
}

export function artistAnchorId(name:string)
{
	return name?.toLowerCase()?.replaceAll(' ','');
}


export function Artist(props:LineupSet)
{
	const [wide,setWide] = createSignal(isServer ? true : undefined);

	onMount(() => {
		setWide(window.innerWidth >= singleColumn || props.isPreview);

		const widthHandler = () => setWide(window.innerWidth >= singleColumn || props.isPreview);
		window.addEventListener('resize', widthHandler);
		onCleanup(() => window.removeEventListener('resize', widthHandler));
	});

	return (<>
		<div id={artistAnchorId(props.name)} class={artistStyle()}>
			<ArtistImageAndSummary {...props} wide={wide()} />

			{wide() && videoExists(props) ? 
				<WrappedVideo {...props} /> :
				<VerticalVideoAndPlayersAndBio {...props} /> 
			}

			<SocialIcons {...props} />
		</div>
	</>);
}

function VerticalVideoAndPlayersAndBio(props)
{
	return (<>
		<Show when={videoExists(props)}>
			<Video {...props} />
		</Show>

		<PlayerLinks {...props} wide={false} />

		<Show when={props.biography}>
			<div class={biographyStyle()} innerHTML={props.biography} />
		</Show>
	</>);
}

function WrappedVideo(props)
{
	return (
		<div>
			<div class={videoWrapStyle()}>
				<Video {...props} />
			</div>

			<PlayerLinks {...props} wide={true} />

			<span class={biographyStyle()} innerHTML={props.biography} />

			<div style='clear:both' />
		</div>
	);
}

function Video(props:LineupSet)
{
	return (
		<Switch>
			<Match when={props.youtubeVideoUrl}>
				<iframe class={iframeStyle()} src={`https://www.youtube.com/embed/${props.youtubeVideoUrl}?modestbranding=1`}
					title='YouTube video player' allowfullscreen
					allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share' 
				/>
			</Match>
			<Match when={props.facebookVideoUrl}>
				<iframe class={iframeStyle()} allowfullscreen
					src={`https://www.facebook.com/plugins/video.php?show_text=false&width=100&height=60&t=0&href=${props.facebookVideoUrl}/`}
					allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'
				/>
			</Match>
			<Match when={props.instagramVideoUrl}>
				<div class={instagramEmbedStyle()}>
					<InstagramEmbed url={props.instagramVideoUrl} />
				</div>
			</Match>
		</Switch>
	);
}		

function PlayerLinks(props:LineupSet)
{
	return (
		<Show when={playerExists(props)}>
			<div class={playerLinksStyle(props.wide,videoExists(props))}>
				<Show when={props.spotify}>
					<a class={playerStyle(props.wide,props.isPreview)} href={props.spotify} target='_blank'>
						<ListenOnSpotify />
					</a>
				</Show>
				<Show when={props.appleMusic}>
					<a class={playerStyle(props.wide,props.isPreview)} href={props.appleMusic} target='_blank'>
						<ListenOnApple />
					</a>
				</Show>
				<Show when={props.bandcamp}>
					<a class={playerStyle(props.wide,props.isPreview)} href={props.bandcamp} target='_blank'>
						<BandcampButton />
					</a>
				</Show>
				<Show when={props.soundcloud}>
					<a class={playerStyle(props.wide,props.isPreview)} href={props.soundcloud} target='_blank'>
						<SoundcloudButton />
					</a>
				</Show>
			</div>
		</Show>
	);
}


function SocialIcons(props:LineupSet)
{
	const trimAt = value => value[0]=='@' ? value.slice(1) : value;

	return (
		<Show when={props.facebook || props.instagram || props.website || props.twitter}>
			<div class={socialIconsStyle()}>
				<Show when={props.facebook}>
					<a href={`https://www.facebook.com/${trimAt(props.facebook)}`} target='_blank'>
						<div class={socialIconStyle()}>
							<FacebookCircle fill={palette().text} />
						</div>
					</a>
					{' '}
				</Show>
				<Show when={props.instagram}>
					<a href={`https://www.instagram.com/${trimAt(props.instagram)}`} target='_blank'>
						<div class={socialIconStyle()}>
							<InstagramCircle fill={palette().text} />
						</div>
					</a>
					{' '}
				</Show>
				<Show when={props.website}>
					<a href={props.website} target='_blank'>
						<div class={socialIconStyle()}>
							<WebsiteCircle fill={palette().text} />
						</div>
					</a>
					{' '}
				</Show>
				<Show when={props.twitter}>
					<a href={`https://www.twitter.com/${trimAt(props.twitter)}`} target='_blank'>
						<div class={socialIconStyle()}>
							<Twitter fill={palette().text} />
						</div>
					</a>
					{' '}
				</Show>
			</div>
		</Show>
	);
}

