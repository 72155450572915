import {AccessAreaName} from 'Common/permissions/AccessArea';
import {FrontendPageConfig, frontendData} from 'Common/FrontendPageConfig';
import { z } from 'zod';
import { MembersPage } from 'Shared/frontend/membersPage/MembersPage';

export const membersParams = z.object({ }).strict();	
export type MembersParams = z.infer<typeof membersParams>;


export const membersData = frontendData.extend({
	pageDisplayName: z.string(),
}).strict();
export type MembersData = z.infer<typeof membersData>;



export class Members extends FrontendPageConfig<MembersData,MembersParams>
{
	static readonly pageName = 'frontend/members';
	name() { return Members.pageName; }

	settingsName() { return 'members'; }

	access() { return 'venueFrontend' as AccessAreaName; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			template: MembersPage,
			pageDisplayName: 'Members'
		};
	}
}

