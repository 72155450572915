import { css, cx, injectGlobal } from '@emotion/css';
import { PageFunctions } from 'Browser/PageFunctions';
import { VenueUrls } from 'Common/VenueUrls';
import { BusinessHours } from 'Shared/frontend/contactPage/BusinessHours';
import { Emails } from 'Shared/frontend/contactPage/Emails';
import { PhoneAndAddress } from 'Shared/frontend/contactPage/PhoneAndAddress';
import { ThirdPartyServices } from 'Shared/frontend/contactPage/ThirdPartyServices';
import { StandardDefaultLayout } from 'Shared/frontend/layouts/StandardDefaultLayout';
import { PageProvider, usePage } from 'Shared/frontend/PageProvider';
import { standardRules } from 'Shared/common/Sanitise';
import { pageTheme, sizes, theme, ThemeProvider } from 'Shared/frontend/ThemeProvider';
import { ContactData } from 'Shared/view/frontend/Contact';
import { For, Show } from 'solid-js';
import { createStore } from 'solid-js/store';

const headingStyle = () => css(theme().contact.heading);

const imageStyle = () => css({
	width: '100%',
	marginBottom: '1em',

	[`@media (min-width: ${sizes().desktopWidth}px)`]: {
		width: '50%',
		float: 'left',
		paddingRight: '2em'
	}
});

//XXX could share much of this with businessHoursStyle
const contactDetailsStyle = () => css({
	width: '100%',

	[`@media (min-width: ${sizes().desktopWidth}px)`]: { 
		textAlign: 'left'
	},

	[`@media (min-width: ${sizes().flipToMobileNav}px)`]: { 
		width: '50%',
		float: 'right'
	},

	margin: '1em 0',
	display: 'flex',
	flexDirection: 'column',
	gap: '1em'
});

/*
	TODO CJ's hacky stuff but need no margins so 50% is enough to place 2 times on the same row, and padding-right to align sections
	like fullWidth sections overriden by importants
*/

const sectionStyle = () => css({
	margin: '64px 0'
});

const halfWidthStyle = () => css({
	width: '100%',

	[`@media (min-width:${sizes().flipToOneColumn}px)`]: {
		display: 'inline-block',
		width: '50%',

		//XXX the margin here conflicts with the sectionStyle above. This is used by Different Drummer, and the other by TheTownie
		margin: '2em 0',

		//TODO better to make this flex and use gap
		paddingRight: '2em'  
	}
});

const fullWidthStyle = () => css({
	width: '100%'
});

/*
FIXME The Different Drummer Contact Us form iframe has internal margins. In the past the left margin was cancelled
    out with the page <main> left margin. Once I've implemented some customisation of panes I should line this up again. 
    NB wrapping the iframe in a div and using absolute positioning might work best here.
*/

const iframeStyle = () => css({
	display: 'block',
	width: '100%',
	height: '100%'
});

const mapResponsiveStyle = () =>css({
	width: '100%',
	display: 'inline-block',
	overflow: 'hidden',
	paddingBottom: '56.25%',
	position: 'relative',
	marginTop: '1em',
	height: 0,

	iframe: {
		left: 0,
		top: 0,
		height: '100%',
		width: '100%',
		position: 'absolute'
	}
});

const businessNumbersStyle = () => css(theme().definitions);


export function ContactPage(props:ContactData)
{
	injectGlobal([standardRules,{body:props.theme.body}]);

	const [theme] = createStore(props.theme);

	return (
		<ThemeProvider theme={theme}>
			<PageProvider page={props.page}>
				<StandardDefaultLayout {...props} expandDesktopNav={false}>
					<PageDesign {...props} />
				</StandardDefaultLayout>
			</PageProvider>
		</ThemeProvider>
	);
}

function PageDesign(props)
{
//XXX could create a helper function for this... use elsewhere too. NB image vs photo vs poster fields...
	const url = () => {
		if (!props.photo?.hash) return '';
		return (new PageFunctions()).createImageUrl2(new VenueUrls(usePage().build,usePage().site.key),props.photo.hash,props.photo,'Venue-photo','full');
	};

	return (<>
		<h2 class={headingStyle()}>Contact Us</h2>

		<img class={imageStyle()} src={props.venue.photo.url} />

		<BusinessHours {...props} />

		<div class={contactDetailsStyle()}>

{/* FIXME HACK excluding TheDock - could add an enable/disable reservations setting in the DB settings */}
			<Show when={props.venue._id != '65e56e13e5fd50550305d461'}>
				<a class={css(theme().a)} href='/reservation'>
					<button class={css(pageTheme().button)}>
						RESERVATIONS
					</button>
				</a>
			</Show>

			<PhoneAndAddress {...props} />
			<Emails {...props} />

			<Show when={props.venue.thirdPartyServices?.length > 0}>
				<ThirdPartyServices {...props} />
			</Show>

			<Show when={props.venue.abn || props.venue.acn}>
				<dl class={businessNumbersStyle()}>
					<Show when={props.venue.abn}>
						<dt>ABN:</dt>
						<dd>{props.venue.abn}</dd>
					</Show>
					<Show when={props.venue.acn}>
						<dt>ACN:</dt>
						<dd>{props.venue.acn}</dd>
					</Show>
				</dl>
			</Show>
		</div>

		<div style='clear:both' />

		<Show when={props.venue.aboutList?.length > 0}>
			<DescriptionSections {...props} />
		</Show>

		<Show when={props.venue.mapEmbed}>
			<section class={cx(sectionStyle(),fullWidthStyle())}>
				<div class={mapResponsiveStyle()} innerHTML={props.venue.mapEmbed} />
			</section>
		</Show>
	</>);
}	

function DescriptionSections(props)
{
//TODO url() here?	
//XXX fold hideTitle into title?	

//XXX do we need <x-description>	

	return (
		<For each={props.venue.aboutList}>{ about => 
			<Show when={about.display.includes('contact')}>
				<section class={cx(sectionStyle(),about.halfWidth ? halfWidthStyle() : fullWidthStyle())}>
					<div class='anchor' id={about.title.toLowerCase().replace(' ','')} />

					<Show when={about.photo?.hash}>
						<div class='imageWrap'>
							{url()}
						</div>
					</Show>
					
					<Show when={about.description || !about.hideTitle}>
						<x-description>
							<Show when={!about.hideTitle}>
								<h3 class={css(pageTheme().section.heading)}>{about.title}</h3>
							</Show>

							<p class={css(pageTheme().embeddedHtml)}  innerHTML={about.description} />
						</x-description>
					</Show>

					<Show when={about.embedUrl}>
						<iframe class={iframeStyle()}
							src={about.embedUrl}
							onload='window.parent.scrollTo(0,0)' 
							style={`min-height:${about.embedHeight}px`}
							frameborder={0} marginheight={0} marginwidth={0}>
								Loading…
						</iframe>
					</Show>
				</section>
			</Show>
		}</For>
	);
}

