
import { Match, Show, Switch } from 'solid-js';
import { ErrorBoundary } from 'solid-js';
import { capitalise } from 'Shared/common/Capitalise';
import { formatDateTime, TrimmedTime } from 'Shared/common/Macros';
import { css } from '@emotion/css';

const notesStyle = () => css({
	color: '#666',
	fontSize: '0.8em'
});


export function EventTimeSummary(props)
{
	return (
		<ErrorBoundary fallback={(error,reset) => <div>{error.message}</div>}>
			{props.frequency == 'once' ? 
				<SingleEvent {...props} />  : 
				<RecurringEvent {...props} />
			}
		</ErrorBoundary>
	);
}

function SingleEvent(props)
{
	return (<>
		<div>
			{ formatDateTime(props.startSingle,'ccc, d LLL yyyy') }

			{/* Function is to not display endSingle Date if it matches starSingle or endSingle is not set and then display start time with end time */}

			<Switch>
				<Match when={formatDateTime(props.startSingle,'ccc, d LLL yyyy') == formatDateTime(props.endSingle,'ccc, d LLL yyyy') 
					|| formatDateTime(props.endSingle,'ccc, d LLL yyyy')==null}
				>
					<div class='clock'>	
						<TrimmedTime time={props.startSingle} />

						<Show when={props.endSingle}>
							- <TrimmedTime time={props.endSingle} />
						</Show>
					</div>
				</Match>
				<Match when={true}>
					{/* display startTime in same calendar div as startDate */}
					<TrimmedTime time={props.startSingle} />

					<Show when={props.endSingle}>
						- Close
					</Show>
				</Match>
			</Switch>
		</div>

		<div class='calendar'>
			<Show when={props.endSingle}>
				to { formatDateTime(props.endSingle,'ccc, d LLL yyyy') }
			</Show>

			<Show when={props.endSingle}>
				<TrimmedTime time={props.endSingle} />
			</Show>
		</div>
	</>);
}

function RecurringEvent(props)
{
	return (<>
		<Switch>
			<Match when={props.frequency == 'daily'}>
				<div class='calendar'>Every day</div>
			</Match>
			<Match when={props.frequency == 'weekly'}>
				<div class='calendar'>
					{props.weekdays.map(i => capitalise(i)).join(',')}
				</div>
			</Match>
			<Match when={props.frequency == 'monthly'}>
				<div class='calendar'>
					{capitalise(props.week)}
					{capitalise(props.weekday)} of month,
				</div>
			</Match>
		</Switch>

		<div class='clock'>
			<Switch>
				<Match when={! props.allDay && props.startTime}>
					<TrimmedTime time={props.startTime} />
					-
					<Show when={props.endTime} fallback='Close'>
						<TrimmedTime time={props.endTime} />
					</Show>
				</Match>
				<Match when={props.allDay}>
					All Day
				</Match>
			</Switch>
		</div>
	</>);
}

export function EventTimeNotes(props) 
{
	return (
		<Show when={props.frequency != 'once'}>
			<div class={notesStyle()}>
				<Show when={props.startDate}>
					<Switch>
						<Match when={props.endDate}>
							From {formatDateTime(props.startDate,'d/LL/yy')}
							to {formatDateTime(props.endDate,'d/LL/yy')}
						</Match>
						<Match when={true}>
							Ongoing from {formatDateTime(props.startDate,'d/LL/yy')}
						</Match>
					</Switch>
				</Show>

				<Show when={!props.startDate && props.endDate}>
					until {formatDateTime(props.endDate,'d/LL/yy')}
				</Show>
			</div>
		</Show>
	);
}

