import { css, cx, injectGlobal } from '@emotion/css';
import { CreateMessage2, ReorderDocumentsMessage2 } from 'Common/Messages';
import { BackendWrap } from 'Shared/backend/BackendWrap';
import { LinkOpen } from 'Shared/backend/icons/LinkOpen';
import { palette, Theme } from 'Shared/backend/Theme';
import { PageProvider } from 'Shared/frontend/PageProvider';
import { mergeProps } from 'solid-js';
import { For } from 'solid-js';
import { createStore } from 'solid-js/store';
import { closestCenter, createSortable, DragDropProvider, DragDropSensors, DragEventHandler, DragOverlay, SortableProvider, transformStyle } from '@thisbeyond/solid-dnd';
import { createSignal } from 'solid-js';
import { createMemo } from 'solid-js';
import AjaxConnectionToServer from 'Browser/AjaxConnectionToServer';
import { StaffResources } from 'Shared/view/backend/StaffResources';
import { Id } from 'Common/Id';
import { theme, ThemeProvider } from 'Shared/backend/ThemeProvider';
import { standardRules } from 'Shared/common/Sanitise';


const headingBarStyle = () =>css({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	flexWrap: 'wrap'
});

const stickyHeaderStyle = () => css({
	h4: {
		marginTop: 12,
		marginBottom: 12,
		textTransform: 'uppercase'
	},

	background: '#1b2126',
	color: 'white',
	position: 'sticky',
	top: 70,
	zIndex: 1
});

const gridStyle = () => css({
	display: 'grid',
	gridTemplateColumns: '1em 1fr 3fr 10ch',
	padding: '0.5em',
	boxShadow: 'none',
	borderTop: '#fafafa',
	gridGap: '0 1em',
	width: '100%',
	alignItems: 'center'
});


const rowStyle = () => css({
	minHeight: '4em',

	a: {
		color: 'black'
	},

	'a:visited': {
		color: 'black',
	},

	'.fa-ellipsis-v': {
		cursor: 'grab',
		color: 'gray'
	}
});	

const activeUnderlayStyle = () => css({
	opacity: 0.25,

	'.fa-ellipsis-v': {
		cursor: 'grabbing'
	}
});

const viewLinkStyle = () => css({
	display: 'flex',
	gap: '0.5em',
	alignItems: 'center',
	color: palette.active
});


export function StaffResourcesPage(props)
{
	injectGlobal([standardRules,{body:Theme.body}]);

	const [store,setStore] = createStore(props.staffResources);

	const itemProps = mergeProps({page: props.page,
		store: store,
		setStore: setStore,
		location: [],
		permission: 'editStaffResource',
	});

	return (
		<ThemeProvider theme={Theme}>
			<PageProvider page={props.page}>
				<BackendWrap>
					<Content {...itemProps} siteId={props.venue.siteId} />
				</BackendWrap>
			</PageProvider>
		</ThemeProvider>
	);
}


function Content(props)
{
	const ids = () => [...Array(props.store.length).keys()].map(v => v+1);

	const indexed = createMemo(() => props.store.map((e:XXX,i:number) => ({id:i+1,data:e})));
	const [activeItem, setActiveItem] = createSignal(null);

	const onDragEnd:DragEventHandler = ({draggable,droppable})  => {
		if (!draggable || !droppable) return;

		const fromIndex = indexed().findIndex( (item:{id:number}) => item.id == draggable.id);
		const toIndex = indexed().findIndex( (item:{id:number}) => item.id == droppable.id);
		if (fromIndex === toIndex) return;

		const newItems = props.store.slice();
		newItems.splice(toIndex, 0, ...newItems.splice(fromIndex, 1));
		props.setStore(newItems);

		const ids = newItems.map(e => e._id);
		reorder(props.page.server,ids);
	};

	return (
		<div>
			<div class={headingBarStyle()}>
				<h2>Staff Resources</h2>

				<a href='/staffResources' class={viewLinkStyle()} target='_blank'>
					 <span>view on staff hub</span>
					<LinkOpen class={css(theme().linkIcon)} />
				</a>
				
				<button class={css(theme().button)} onClick={() => createResourceAndRedirect(props.page,props.store.length)}>Create Staff Resource</button>
			</div>

			<div class={cx(stickyHeaderStyle(),gridStyle())}>
				<div />
				<h4>Title</h4>
				<h4>sections</h4>
			</div>

			<DragDropProvider onDragEnd={onDragEnd} collisionDetector={closestCenter} >
				<DragDropSensors />
				<SortableProvider ids={ids()}>
					<For each={indexed()}>{
						(item,index) => <Resource item={item.data} reorderingId={item.id} /> 
					}</For>
				</SortableProvider>

				<DragOverlay>
					<div class='row' innerHTML={activeItem()} />
				</DragOverlay>
			</DragDropProvider>
		</div>
	);
}

export function Resource(props: IResource)
{
	const sortable = createSortable(props.reorderingId,props.item as any);

	return (
		<div ref={sortable.ref}
			classList={{
				[rowStyle()]: true,
				[activeUnderlayStyle()]: sortable.isActiveDraggable,
				[props.itemStyle]: true,
				[gridStyle()]: true,
			}}
      		style={transformStyle(sortable.transform)}
		>
			<i class='fas fa-ellipsis-v' {...sortable.dragActivators} />
			
			<a href={`/admin/staffResource/${props.item._id}`}>{props.item.name}&nbsp;</a>

			<a href={`/admin/staffResource/${props.item._id}`}>
				{ rowTitle(props.item.section ?? []) }
			</a>
		</div>
	);
}

function rowTitle(sections)
{
	let ret = '';
	for (const section of sections)
		ret += ' • ' + section.name;
	return ret;
}

function reorder(server:AjaxConnectionToServer,positions:Id[])
{
	const msg = new ReorderDocumentsMessage2(StaffResources.pageName,'editStaffResource',positions);
	server.sendOperationOptimistically(msg);
}

async function createResourceAndRedirect(page,numResources:number)
{
	const msg = new CreateMessage2(page.name(),'editStaffResource',{position:numResources}); 
	const ret = await page.server.sendOperation(msg);

	window.pageJs(`/admin/staffResource/${ret.id}`);
}

