import {DocumentQueries} from 'Common/config/PageConfigTypes';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {FrontendPageConfig, frontendData} from 'Common/FrontendPageConfig';
import { z } from 'zod';
import { ContactPage } from 'Shared/frontend/contactPage/ContactPage';

export const contactParams = z.object({}).strict();	
export type ContactParams = z.infer<typeof contactParams>;

export const contactData = frontendData.extend({
	params: contactParams,
	pageDisplayName: z.string(),
}).strict();
export type ContactData = z.infer<typeof contactData>;


export class Contact extends FrontendPageConfig<ContactData,ContactParams>
{
	static readonly pageName = 'frontend/contact';
	name() { return Contact.pageName; }

	settingsName() { return 'contact'; }

	access() { return 'venueFrontend' as AccessAreaName; }

	title() { return 'Contact'; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			pageDisplayName: 'Contact',
			template: ContactPage
		};
	}

	documents(params:ContactParams):DocumentQueries
	{
		return ({
			...super.documents(params),
			/* venue is included in all venue page requests */
		});
	}
}

