
/*
	These are used in inline svgs, so CSS is not good enough.
*/
export enum IconColour {
	text= '#333',
	red = '#FF5D5D',
	orange = '#fda857',
	green = '#44C4A1',
	white = '#ffffff',
	alert = 'red',
	active = '#44C4A1',
	inactive = 'lightgrey'
}

export interface ISvgColours 
{
	fill?: string,
	stroke?: string
};			
