import { VenueUrls } from 'Common/VenueUrls';
import { usePage } from 'Shared/frontend/PageProvider';
import { IEventProps } from 'Shared/backend/eventPage/EventPage'
import { Text, Html } from 'Shared/forms/Inputs'
import { Wrap } from 'Shared/forms/Wrap';

export function TaglineAndMoreInfo(eventProps:IEventProps)
{
	const page = usePage();
	const urls = new VenueUrls(page.build,page.site.key);

	return (<>
		<Wrap label={eventProps.store.imageType=='photo' ? 
			'Short description on Poster and Event Page' : 
			'Short description on Event Page'}
		>
			<Text {...eventProps} field='tagline' />
		</Wrap>

		<Wrap label='More Info on Event Page'>
			<Html {...eventProps} field='moreInfo' urls={urls} options={{
				height: 200,
				menubar: false,
				plugins: ['link'],
				toolbar: 'undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent | link ',
				statusbar: true,
				resize: true 
			}} />
		</Wrap>
	</>);
}

