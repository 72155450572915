import {id as createId} from 'Common/components/NjkEditComponent';
import {Location} from 'Common/config/PageConfigTypes';
import {InvalidFieldValueError} from 'Browser/InvalidFieldValueError';
import {palette} from 'Shared/artists/Palette';
import {getInput} from 'Browser/Input';
import {IPermissions} from 'Common/PageConfig';
import {Id} from 'Common/Id';
import TomSelect from 'tom-select';
import {TomInput} from 'tom-select/dist/types/types';
import { reconcile, SetStoreFunction} from 'solid-js/store';
import {Setter,onMount,Show,For,createSignal,createMemo, onCleanup} from 'solid-js';
import { UpdateMessage2 } from 'Common/Messages';
import { SolidHtmlEditorWidget } from 'Browser/widgets/SolidHtmlEditorWidget';
import { css } from '@emotion/css';

//select, input, textarea {
export const fieldStyle = () => css({
	font: '15px Arial, Helvetica, sans-serif',
	backgroundColor: 'inherit',
	borderTop: 'none',
	borderRight: 'none',
	borderLeft: 'none',
	borderImage: 'initial',
	borderBottom: '1px solid rgb(239, 239, 239)',
	padding: '5px 10px 5px 6px',
	width: '100%',
	color: 'rgb(85, 85, 85)',

	':focus': {
		borderColor: 'rgb(102, 175, 233)',
		outline: 0,
		boxShadow: 'rgba(0, 0, 0, 0.075) 0px 1px 1px inset, rgba(102, 175, 233, 0.6) 0px 0px 8px'
	}
});

export const inputStyle = () => css(fieldStyle(), {
	transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',

	'::placeholder': {
		fontSize: 14,
		fontStyle: 'italic'
	}
});

//XXX not currently used
export const textAreaStyle = () => css(fieldStyle(), {
	transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out'
});

export const selectStyle = () => css(fieldStyle(), { });

const checkboxStyle = () => css({
	width: 'initial'  //TODO remove after Less gone?
});

const priceStyle = () => css({
	display: 'flex',
	gap: '0.1em',
	alignItems: 'center'
});


export interface IField<D> {
	page: XXX;
	permission: string;
	docId: Id,
	location: Location,
	field:string;
	ref?: HTMLDivElement;
	processInput?: (value:string) => string;
	store: D;
	setStore: SetStoreFunction<D>;
}

export interface IText extends IField<string> {
	maxLength?: number;
	placeholder?: string;
	extraAttributes?: any;  //TODO remove or refine if possible
	onInput?: (value:string) => void;
}

export function Text(props:IText)  
{
	//TODO if there is an error probably should re-evaluate it every key press...
	//     separate the onChange code? NB annoying when fields complain too soon though.

	const value = () => props.store ? valueFromStore(props as Required<IText>) : props.value ;
	const [error,setError] = createSignal(undefined as undefined|string);

	return (<>
		<input type='text'
			id={id(props)}
			class={inputStyle()} name='' autocomplete='off'
			maxlength={props.maxLength ?? 255}
			value={value() ?? ''}
			placeholder={props.placeholder}
			ref={props.ref}
			onChange={e => updateField(e.target,props,setError)}
			onInput={e => props.onInput?.(e.target.value)  } 
			style={error() ? 'background-color:'+palette.errorBackground : ''}
			data-error={error() ?? ''}
			{...(props.extraAttributes ?? {})}
		/>
	</>);
}

interface IMultiLineText extends IField<string[]> {
	numRows:number;
	maxLength?:number;
	extraAttributes?:any;
}

/* XXX if I keep using this, probably want to support paragraphs */
export function MultiLineText(props:IMultiLineText)
{
	const value = () => props.store ? valueFromStore(props as Required<IMultiLineText>) : props.value ;
	const [error,setError] = createSignal(undefined as undefined|string);

	return (
		<textarea id={id(props)}
			class='bf-multiLineText' name='' autocomplete='off'
			rows={props.numRows}
			maxlength={props.maxLength ?? 2000}
			{...(props.extraAttributes ?? {})}
			onChange={e => updateField(e.target,props,setError)}
			style={error() ? 'background-color:'+palette.errorBackground : ''}
			data-error={error() ?? ''}
		>{value()}</textarea>
	);
}

interface ISingleSelect extends IField<string> {
	options:object;
	required?:boolean;
	value?:string;
	disable?:boolean;
	placeholder?:string;
	class?:string;
	extraAttributes?:any;
}

export function SingleSelect(props:ISingleSelect)
{
	const value = () => props.store ? valueFromStore(props as Required<ISingleSelect>) : props.value ;
	const [error,setError] = createSignal(undefined as undefined|string);

	return (
		<select class={selectStyle()} name='' autocomplete='off' 
			id={id(props)}
			disabled={props.disable}
			onChange={e => updateField(e.target,props,setError)}
			style={error() ? 'background-color:'+palette.errorBackground : ''}
			data-error={error() ?? ''}
			{...(props.extraAttributes ?? {})}
		>
			{/*
				Showing '(none)' when 'required' is safer when there is no value than defaulting to 
				the first option as the DB won't be updated with the first value unless the user 
				changes the selected value. 
					In time maybe use JS to remove (none) on first select.
					There is a default option in DbTables which can/should always/sometimes be used			*/} 
			<Show when={!props.required || !value()}>
				<option value='' selected={value()==undefined} >
					{props.placeholder ?? '(none)'}
				</option>
			</Show>

			<For each={Object.entries(props.options)}>{ item => 
				<option value={item[0]} selected={item[0]==value()}>
					{item[1]}
				</option>
			}</For>
		</select>
	);
}

interface ICheckbox extends IField<boolean> {
	extraAttributes?:any;
}

export function Checkbox(props:ICheckbox)
{
	const value = () => props.store ? valueFromStore(props as Required<ICheckbox>) : props.value;
	const [error,setError] = createSignal(undefined as undefined|string);

//	if (node.classList.contains('bf-checkbox')) 
//		return (<any>node)?.checked ?? false;

//export function updateFieldWithValue<D>(value:any,props:IField<D>,setError:Setter<string|undefined>)

	return (
		<input type='checkbox' class={checkboxStyle()} name='' 
			id={id(props)}
			checked={value()}
			onChange={e => updateFieldWithValue(e.target.checked ?? false,props,setError)}
			{...(props.extraAttributes ?? {})}
		/>
	);
}

interface IInteger extends IField<number> {
	min:number;
	max:number;
	step:number;
	extraInputAttributes:any;
}

export function Integer(props:IInteger)
{
	const value = () => props.store ? valueFromStore(props as Required<IInteger>) : props.value ;
	const [error,setError] = createSignal(undefined as undefined|string);

	return (
		<input type='number'
			id={id(props)}
			class='bf-integer' name='' value={value()}
			max={props.max} min={props.min} step={props.step}
			{...(props.extraInputAttributes ?? {})}
			onChange={e => updateField(e.target,props,setError)}
		/>
	);
}

interface IMultiSelect extends IField<string[]> {
	options:any;
}

export function MultiSelect(props:IMultiSelect)
{
	let anchor!: HTMLSelectElement;
	const value = () => props.store ? (valueFromStore(props as Required<IMultiSelect>) ?? []) : props.value ;
	const [error,setError] = createSignal(undefined as undefined|string);

	const selectedTagKeys = createMemo(() => {
		const keys:any = {};
		for (const key of value())
			keys[key] = true;
		return keys;
	});

	onMount(() => {
		new TomSelect(anchor as TomInput, {
			plugins: {
				remove_button:{ title:'Remove' }
			},
			onChange: (args:string[]) => {
				updateArrayInStoreAndDb(args,props,setError);
			}
		});
	});

	return (
		<div class='bf-multiSelect' id={id(props)}>
			<select multiple name='' ref={anchor} data-junk='true' >
				<For each={Object.entries(props.options)}>{ item => 
					<option value={item[0]} selected={item[0] in selectedTagKeys()}>
						{item[1]}
					</option>
				}</For>
			</select>
		</div>
	);
}

interface IPrice extends IField<string> {
	maxLength?:number;
	placeholder?:string;
	extraInputAttributes?:any;
}

//			class={inputStyle()} name='' autocomplete='off'

export function Price(props:IPrice)
{
	const value = () => props.store ? valueFromStore(props as Required<IPrice>) : props.value ;
	const [error,setError] = createSignal(undefined as undefined|string);

	return (
		<div class={priceStyle()} id={id(props)} >
			$ <input type='text' class={inputStyle()}
				name='' autocomplete='off'
				maxlength={props.maxLength ?? 255}
				value={value() ?? ''}
				placeholder={props.placeholder ?? 'X.XX'}
				{...(props.extraInputAttributes ?? {})}
				onChange={e => updateField(e.target,props,setError)}
			/>
		</div>
	);
}


interface IHtml extends IField<string> { 
	options;
	urls;
}

//TODO merge this function with SolidHtmlEditorWidget
export function Html(props:IHtml)
{
	let htmlEditor;
	let htmlNode!: HTMLDivElement;

	onMount(() => {
		htmlEditor = new SolidHtmlEditorWidget(props.urls,props.options);
		htmlEditor.init(htmlNode)
	});
	onCleanup(() => htmlEditor?.destroy(htmlNode));

	const value = () => props.store ? valueFromStore(props as Required<IHtml>) : props.value ;
	const [error,setError] = createSignal(undefined as undefined|string);

	return (
		<div class='bf-html' id={id(props)}
			onChange={e => updateField(e.target,props,setError)}
			ref={htmlNode}
		>
			<div id={`tinymce-${id(props)}`} innerHTML={value()} placeholder={props.placeholder}></div>
		</div>
	);
}

/*
{% macro password(errors,mode,label,id) %}
    {% if mode=='edit' or mode=='create' %}
		{{ instructionsAndLabel(label,id,false,null) }}
        <div>
            <input id={id(props)} type="password" name="{{id}}" autocomplete="off" placeholder="********"/>
            {{ error(errors,id) }}
        </div>
    {% else %}
        <label>{{ label }}</label><div>********</div>
    {% endif %}
{% endmacro %}
*/


/* --- Private functions: --- */

interface IId {
	permission: IPermissions;
	location?: Location;
	field: string;
}

export function id(props:IId)
{
	return createId(props.permission, props.location ?? [], props.field);
}

export function valueFromStore(props:{store:any,location?:Location,field:string})
{
	const parentPath = [...subdocument(props), ...(props.location ?? [])];

	let pos = props.store;
	for (const part of parentPath) 
		pos = pos[part];

	return pos==undefined ? undefined : pos[props.field];
}

function subdocument(props:IField<any>)
{
	if (props.permission) 
		return props.page.config.permissions()[props.permission].subdocument ?? [];
	return [];
}

export function updateField<D>(node:HTMLElement,props:IField<D>,setError:Setter<string|undefined>)
{
	try {
		const val = getInput(node);
		const value = val!=undefined && props.processInput ? props.processInput(val) : val;
		updateStoreAndDb(value,props,setError);
	}
	catch(err) {
		if (err instanceof InvalidFieldValueError)
			setError(err.message);
		else
			throw err;
	}
}

export function updateFieldWithValue<D>(value:any,props:IField<D>,setError:Setter<string|undefined>)
{
	try {
		updateStoreAndDb(value,props,setError);
	}
	catch(err) {
		if (err instanceof InvalidFieldValueError)
			setError(err.message);
		else
			throw err;
	}
}

export function updateStoreAndDb<D>(value:any,props:IField<D>,setError:Setter<string|undefined>)
{
	props.setSignal?.(value);

	const permissionObj = props.page.config.permissions()[props.permission];
	const subdocument = permissionObj?.subdocument ?? [];

	const path = [...subdocument,...(props.location ?? []),props.field];
	props.setStore?.(...path,value);

	const docId = props.docId ?? props.page.data[permissionObj.collection]._id; //XXX do we need both when using permissions?

	try {
		const msg = new UpdateMessage2(props.page.name(),props.permission,docId,props.location,{[props.field]:value});
		props.page.server.sendOperationOptimistically(msg);
		setError(undefined);
	}
	catch(err) {
		if (err instanceof InvalidFieldValueError)
			alert(err.message);
		else
			throw err;
	}
}

function updateArrayInStoreAndDb<D>(array:any[],props:IField<D>,setError:Setter<string|undefined>)
{
//FIXME haven't addressed signals...
	props.setSignal?.(array);

	const path = [...subdocument(props),...(props.location ?? []),props.field];

	props.setStore?.(...path, reconcile(array));

	try {
		const msg = new UpdateMessage2(props.page.name(),props.permission,props.docId,props.location,{[props.field]:array});

		props.page.server.sendOperationOptimistically(msg);
		setError(undefined);
	}
	catch(err) {
		if (err instanceof InvalidFieldValueError)
			alert(err.message);
		else
			throw err;
	}
}
