import {IPageWrapper} from 'Browser/pages/PageWrapper';
import {IPageData} from 'Common/PageConfig';
import {IPage} from 'Common/pages/IPage';
import {Dynamic, hydrate} from 'solid-js/web';

export function hydrateEventHandlers(pageWrapper:IPageWrapper,page:IPage<IPageData>,template:any,theme)		//XXX any
{
	const data = {
		...window.initComponentData,
		...pageReferences(pageWrapper,page),
		theme: theme
	};

	hydrate(() => <Dynamic component={template} {...data}  />, document.getElementById('root') as HTMLElement,{renderId:'root',owner:{}})
}

export function pageReferences(pageWrapper:IPageWrapper,page:IPage<IPageData>)
{
	return {
		pageWrapper: pageWrapper, 	
		page: page,
		pageConfig: page.config
	};
}
