import { css } from '@emotion/css';
import { palette } from 'Shared/frontend/ThemeProvider';

//XXX used in mobile nav too. Maybe separate a file for the common bits.
export const leadingIconStyle = () => css({
	display: 'inline-block',
	verticalAlign: 'middle',
	width: '1.9em',
	height: 'auto',
	marginRight: '0.35em',

	svg: {
		fill: palette().text
	}
});

