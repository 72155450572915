import { css } from '@emotion/css';
import { IEventProps } from 'Shared/backend/eventPage/EventPage';
import { ClockCircle } from 'Shared/backend/icons/ClockCircle';
import { ExclamationCircle } from 'Shared/backend/icons/ExclamationCircle';
import { StatusArchived } from 'Shared/backend/icons/StatusArchived';
import { StatusDraft } from 'Shared/backend/icons/StatusDraft';
import { StatusPublished } from 'Shared/backend/icons/StatusPublished';
import { Match, Switch } from 'solid-js';
import { theme } from 'Shared/backend/ThemeProvider';


const linkStyle = () => css(theme().linkIcon);

export function StatusIcons(props:IEventProps) 
{
//XXX low priority feature add status text to icons on hover

	return (
		<Switch>
			<Match when={props.status=='draft'}>
				<StatusDraft class={linkStyle()} />
			</Match>
			<Match when={props.status=='published'}>
				<StatusPublished class={linkStyle()} />
			</Match>
			<Match when={props.status=='unpublished'}>
				<StatusArchived class={linkStyle()} />
			</Match>
			<Match when={props.status=='scheduled'}>
				<Switch>
					<Match when={props.scheduleDate}>
						<ClockCircle class={linkStyle()} />
					</Match>
					<Match when={true}>
						<ExclamationCircle class={linkStyle()} />
					</Match>
				</Switch>
			</Match>
			<Match when={true}>
				<ExclamationCircle class={linkStyle()} />
			</Match>
		</Switch>
	);
}

