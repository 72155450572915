/*
	Can set a #hex color or the name of color if it's defined in iconsAsMacros.njk
	'default' colors should be set to the most logical and commonly used color for the icon 
	(eg 'red' warning icons),
	or write textColour with no brackets '' if you want to match the theme's textColour.
*/

import {ISvgColours, IconColour} from 'Shared/backend/IconColour'

export function VideoCircle(props:ISvgColours)
{
	return (
		<svg id="video_circle" class="icon" style={`fill:${props.fill ?? IconColour.text}; stroke:${props.stroke ?? '#fff0'};`} 
				viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
			<path d="M 32 2 C 15.431 2 2 15.431 2 32 C 2 48.569 15.431 62 32 62 C 48.569 62 62 48.569 62 32 C 61.982 15.439 48.561 2.018 32 2 Z M 44.634 32.956 C 44.426 33.372 44.089 33.71 43.672 33.918 L 43.672 33.929 L 26.529 42.5 C 25.471 43.029 24.184 42.6 23.655 41.541 C 23.504 41.24 23.427 40.908 23.429 40.571 L 23.429 23.429 C 23.428 22.245 24.387 21.285 25.57 21.285 C 25.903 21.285 26.232 21.362 26.529 21.511 L 43.672 30.082 C 44.731 30.61 45.162 31.897 44.634 32.956 Z" data-original="#000000" bx:origin="-0.033333 -0.033333"></path>
		</svg>
	);
}	
