import {usePage} from 'Shared/frontend/PageProvider';
import {itemStyle} from 'Shared/backend/menu/SectionItem';
import {Show} from 'solid-js';
import {navHeight, phone, tabletOrDesktop} from 'Shared/backend/Theme';
import { css } from '@emotion/css';


const stickySubHeaderStyle = () => css({
	backgroundColor: 'black',
	color: 'white',
	textTransform: 'uppercase',
	fontWeight: 600,
	zIndex: 1,

	[phone]: {
		position: 'relative',
	},
	[tabletOrDesktop]: {
		position: 'sticky',
		top: navHeight
	}
});

export function MenuItemsHeader()
{
	const imagesEnabled = () => usePage().data.venue.settings.pages?.menu?.itemType != 'imageless';
	
	return (
		<div class={`${itemStyle(imagesEnabled())} ${stickySubHeaderStyle()}`}>
			<div class='grabber'></div>
			<div class='name'>Items</div>
			<div class='prices'>Prices</div>
			<div class='tags'>Tags</div>
			<Show when={imagesEnabled()}>
				<div class='gridImage'>Image</div>
			</Show>
			<div class='status'></div>
		</div>
	);
}

