
import { css } from '@emotion/css';
import {Links, Nav} from 'Shared/frontend/layouts/StandardDefaultLayout';
import {NavWidgetContents} from 'Shared/frontend/navWidget/NavWidgetContents';
import {ShrinkingLogo} from 'Shared/frontend/navWidget/ShrinkingLogo';
import {ShrinkingNavBar} from 'Shared/frontend/navWidget/ShrinkingNavBar';
import { sanitise } from 'Shared/common/Sanitise';
import { sizes } from 'Shared/frontend/ThemeProvider';
import {createSignal, Show} from 'solid-js';


const buttonsStyle = () => css({
	...sanitise.button,
	display: 'flex',
	flex: '1 1',
	justifyContent: 'space-between',
	maxWidth: sizes().navButtonsMaxWidth,  
	flexWrap: 'nowrap',
	alignItems: 'center',
});


export interface IShrinkingNavWithLogo {
	expand: boolean;
	logoSide: 'left'|'right';
	logo;
	nav: Nav;
	links: Links;
	currentPage: string;
};

export function ShrinkingNavWithLogo(props:IShrinkingNavWithLogo)
{
	const [fullHeight,setFullHeight] = createSignal(0);

	return (
		<ShrinkingNavBar expand={props.expand} setFullHeight={setFullHeight}>
			<Show when={props.logoSide=='left'}>
				<ShrinkingLogo shrinkDirection='left' logo={props.logo} fullHeight={fullHeight()} />
			</Show>

			<div class={buttonsStyle()}>
				<NavWidgetContents nav={props.nav} links={props.links} currentPage={props.currentPage} />
			</div>
			<Show when={props.logoSide=='right'}>
				<ShrinkingLogo shrinkDirection='right' logo={props.logo} fullHeight={fullHeight()} />
			</Show>
		</ShrinkingNavBar>
	);
}

