import { css } from '@emotion/css';
import { genres, performanceType} from 'Shared/ArtistTypes';
import { ISet, sectionStyle, verticalStyle } from 'Shared/backend/eventPage/artist/ArtistForm';
import { IconColour } from 'Shared/backend/IconColour';
import { InfoCircle } from 'Shared/backend/icons/InfoCircle';
import { theme } from 'Shared/backend/ThemeProvider';
import { IconPane } from 'Shared/forms/IconPane';
import { SingleSelect, Text } from 'Shared/forms/Inputs';
import { Wrap } from 'Shared/forms/Wrap';

export function Style(props:ISet)
{
	return (
		<section class={sectionStyle()}>
			<h4 class={css(theme().section.heading)}>Style</h4>

			<IconPane>
				<InfoCircle fill={props.mainGenre || props.extraGenre ? IconColour.active : IconColour.alert} />

				<div class={verticalStyle()}>
					<Wrap label='Performance type'>
						<SingleSelect {...props} field='performanceType' options={performanceType} placeholder='Original Band' />
					</Wrap>

					<Wrap label='Main genre'>
						<SingleSelect {...props} field='mainGenre' options={genres} />
					</Wrap>

					<Wrap label='2nd genre'>
						<SingleSelect {...props} field='otherGenre1' options={genres} />
					</Wrap>

					 {/* using class hide because removing field breaks artist import but we dont want to display */}
					<div class='hide'>
						<Wrap label='Other genre 2'>
							<SingleSelect {...props} field='otherGenre2' options={genres} />
						</Wrap>
					</div>

					<Wrap label='Custom genre' instructionsAfterLabel='Type if not in the above genre list'>
						<Text {...props} field='extraGenre' />
					</Wrap>
				</div>
			</IconPane>
		</section>
	);
}

