
export function Music(props)
{
	return (
		<svg class={props.class} style={`fill:${props.fill ?? IconColour.text}; stroke:${props.stroke ?? 'inherit'};`} viewBox="0 0 64 64" enable-background="new 0 0 64 64" xmlns="http://www.w3.org/2000/svg">
		  <defs></defs>
		  <path id="path0" d="M 42.781 6.859 C 37.038 8.403 29.008 10.557 24.935 11.647 C 20.862 12.737 17.016 14.032 16.388 14.526 L 15.246 15.424 L 15.365 29.768 L 15.483 44.111 L 13.627 44.428 C 8.031 45.384 3.107 50.839 4.137 54.943 C 5.66 61.008 14.135 61.175 20.35 55.263 L 22.127 53.572 L 22.127 39.727 C 22.127 27.106 22.197 25.822 22.922 25.197 C 24.44 23.886 49.142 17.49 50.427 18.076 C 51.928 18.76 51.939 18.843 51.72 28.125 L 51.529 36.182 L 50.201 36.182 C 42.19 36.182 37.619 49.564 44.998 51.416 C 48.093 52.193 54.381 49.343 57.926 45.556 L 59 44.409 L 58.663 24.848 C 58.239 0.257 59.985 2.235 42.781 6.859" style="paint-order: fill;"></path>
		</svg>
	);
}

