import { cx } from '@emotion/css';

export function ListenOnApple(props)
{
	return (
<svg id="apple" class={cx(props.class)} version="1.0" xmlns="http://www.w3.org/2000/svg"
 viewBox="0 0 900.000000 327.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,327.000000) scale(0.100000,-0.100000)">
<path d="M0 1635 l0 -1635 4500 0 4500 0 0 1635 0 1635 -4500 0 -4500 0 0
-1635z m2929 1379 c2 -2 4 -18 4 -35 0 -32 -15 -49 -45 -49 -45 0 -66 73 -25
90 19 7 54 4 66 -6z m-447 -251 l3 -258 110 -3 c87 -2 112 -6 122 -19 9 -11 9
-21 2 -34 -9 -17 -24 -19 -154 -19 -132 0 -145 2 -155 19 -15 29 -14 543 2
559 7 7 25 12 40 12 l28 0 2 -257z m1086 150 c4 -40 17 -51 67 -55 33 -2 41
-7 43 -27 4 -28 -14 -42 -46 -34 -14 3 -30 -1 -42 -12 -17 -16 -20 -31 -20
-120 0 -56 5 -116 11 -134 10 -30 20 -35 74 -43 26 -4 32 -38 9 -55 -38 -28
-116 -7 -148 39 -13 18 -16 52 -16 166 l0 143 -31 11 c-24 8 -30 15 -27 36 2
18 10 28 28 32 19 4 26 13 28 35 6 59 65 74 70 18z m-283 -57 c23 -10 35 -22
35 -35 0 -28 -15 -34 -54 -21 -64 21 -115 -12 -102 -66 4 -16 25 -33 64 -54
76 -40 89 -51 107 -94 14 -34 14 -40 -4 -81 -15 -35 -29 -48 -65 -64 -55 -26
-110 -27 -155 -3 -40 20 -46 31 -28 55 12 17 15 17 42 3 39 -20 85 -20 113 0
18 12 22 24 20 52 -3 34 -8 39 -58 64 -30 16 -70 42 -87 58 -27 25 -33 38 -33
73 1 93 109 153 205 113z m758 -5 c54 -25 82 -63 97 -135 17 -83 14 -85 -143
-88 l-132 -3 -3 -27 c-3 -29 29 -82 57 -94 43 -17 89 -22 130 -13 66 15 101
-24 44 -50 -75 -35 -194 -19 -250 32 -36 32 -63 101 -63 159 0 109 42 182 125
219 51 24 86 24 138 0z m512 -4 c26 -14 48 -37 63 -67 20 -40 22 -59 22 -185
0 -149 -4 -165 -47 -165 -36 0 -43 30 -43 179 0 127 -2 143 -20 161 -29 29
-67 35 -107 16 -52 -25 -63 -63 -63 -215 0 -137 -2 -141 -51 -141 -27 0 -30
24 -27 229 3 164 5 185 21 195 15 9 21 7 36 -13 l18 -24 54 26 c64 33 92 33
144 4z m724 13 c55 -15 107 -58 127 -102 29 -63 25 -182 -6 -234 -81 -132
-242 -140 -337 -16 -32 42 -33 47 -33 130 0 72 4 94 23 126 26 46 58 72 113
91 47 17 69 18 113 5z m576 -25 c55 -41 67 -82 73 -231 5 -149 -2 -174 -47
-174 -17 0 -31 6 -35 16 -3 9 -6 79 -6 156 l0 140 -29 29 c-39 39 -80 39 -123
0 l-33 -29 -3 -144 c-2 -83 -8 -148 -14 -156 -6 -7 -23 -12 -37 -10 l-26 3 0
210 0 210 22 3 c17 3 26 -3 33 -22 9 -26 25 -34 32 -17 2 4 21 18 43 30 51 27
103 22 150 -14z m-2936 11 c8 -9 11 -74 9 -212 l-3 -199 -23 -3 c-49 -7 -52 6
-52 214 0 173 2 194 18 203 24 14 38 13 51 -3z m-1299 -361 c12 -15 10 -52 -7
-130 -30 -130 -196 -285 -305 -285 -42 0 -58 15 -58 54 0 112 119 282 234 335
91 41 119 47 136 26z m-458 -476 c108 -37 110 -37 156 -22 26 8 60 21 75 29
16 8 35 14 43 14 9 0 38 7 65 16 56 19 202 15 222 -5 6 -6 19 -11 28 -11 42 0
189 -112 189 -144 0 -8 -26 -41 -57 -73 -64 -65 -74 -79 -103 -155 -36 -94
-23 -220 32 -323 18 -34 141 -155 157 -155 6 0 15 -9 21 -21 9 -16 8 -26 -4
-44 -9 -14 -16 -30 -16 -38 0 -8 -11 -34 -25 -58 -14 -24 -28 -51 -32 -59 -19
-40 -101 -150 -142 -191 -95 -95 -168 -109 -311 -59 -120 41 -215 41 -330 0
-103 -37 -176 -40 -229 -8 -87 53 -192 185 -261 328 -12 25 -26 53 -31 62 -10
20 -40 108 -64 193 -13 48 -16 93 -13 230 3 161 4 174 32 240 70 167 199 273
366 298 49 8 123 -6 232 -44z m4823 40 c28 -5 63 -13 78 -16 16 -3 47 -16 70
-29 23 -13 51 -28 63 -33 28 -12 110 -105 130 -146 19 -40 42 -136 36 -153 -3
-9 -41 -12 -132 -12 l-129 0 -23 47 c-33 65 -82 102 -158 117 -121 25 -244
-25 -263 -108 -29 -121 43 -172 323 -226 76 -15 203 -60 244 -87 62 -41 104
-88 131 -149 34 -76 36 -226 4 -306 -54 -139 -181 -232 -354 -263 -22 -4 -101
-7 -175 -6 -142 0 -207 13 -300 61 -109 56 -204 193 -198 287 l3 48 130 0 129
0 23 -40 c47 -84 76 -103 182 -124 51 -10 77 -10 130 0 99 19 133 45 159 123
15 41 5 81 -27 120 -28 33 -137 74 -256 96 -231 43 -369 122 -425 246 -30 64
-32 225 -4 294 51 128 149 207 307 249 80 22 219 26 302 10z m2025 -25 c41
-14 82 -32 91 -40 8 -8 20 -14 26 -14 14 0 143 -137 143 -152 0 -6 6 -19 13
-27 23 -28 42 -104 42 -171 l0 -65 -130 0 -130 0 -20 64 c-35 114 -137 194
-245 194 -88 0 -190 -64 -229 -146 -25 -52 -51 -211 -51 -312 0 -98 26 -247
52 -297 47 -89 132 -138 241 -138 111 0 195 67 231 183 l17 58 132 -3 132 -3
3 -43 c4 -59 -28 -159 -74 -227 -38 -58 -120 -126 -189 -159 -22 -10 -52 -25
-67 -32 -40 -21 -344 -20 -395 2 -162 68 -247 140 -299 251 -14 32 -31 67 -36
77 -33 63 -48 449 -23 575 41 200 123 320 278 401 31 16 68 30 80 30 13 0 27
4 33 9 15 15 70 19 189 16 87 -3 126 -10 185 -31z m-5286 -59 c16 -33 35 -73
42 -90 7 -16 27 -61 44 -100 18 -38 38 -83 45 -100 17 -40 59 -132 89 -197 14
-29 36 -80 51 -113 50 -115 60 -134 70 -135 6 0 19 21 29 48 11 26 39 89 63
140 23 51 43 99 43 106 0 8 7 19 15 26 8 7 15 18 15 25 0 7 14 40 30 73 17 34
30 67 30 75 0 8 7 20 15 27 8 7 15 19 15 27 0 8 6 27 14 41 24 46 73 156 80
179 10 31 30 34 176 31 l125 -3 3 -657 2 -658 -22 -5 c-13 -3 -68 -5 -123 -3
l-100 3 -6 390 c-3 215 -7 397 -8 405 -1 10 -9 6 -26 -14 -14 -16 -25 -33 -25
-37 0 -5 -20 -52 -45 -105 -25 -52 -45 -97 -45 -100 0 -2 -14 -34 -31 -71 -17
-38 -43 -95 -57 -128 -15 -32 -38 -82 -52 -110 l-25 -50 -92 -3 -92 -3 -47 98
c-25 54 -54 116 -64 138 -10 22 -29 65 -43 95 -13 30 -35 78 -47 105 -12 28
-33 73 -46 102 -13 29 -29 64 -34 79 -7 18 -14 24 -20 18 -6 -6 -12 -172 -15
-403 -3 -216 -8 -397 -11 -402 -8 -14 -218 -11 -232 3 -18 18 -17 1304 1 1312
6 2 73 4 147 3 l135 -2 29 -60z m1557 -380 c6 -497 9 -522 70 -585 51 -53 92
-72 166 -78 116 -9 208 40 254 136 21 42 22 62 28 505 l6 462 140 0 140 0 3
-469 c2 -447 1 -473 -18 -545 -32 -117 -126 -231 -230 -277 -111 -49 -138 -54
-300 -54 -145 -1 -210 13 -316 65 -60 31 -142 112 -179 179 -47 87 -50 133
-46 631 2 253 6 464 8 468 3 4 64 6 136 5 l132 -3 6 -440z m2664 -220 l0 -660
-124 -5 c-92 -4 -129 -2 -142 8 -18 13 -19 41 -19 662 0 509 3 649 13 654 6 2
71 4 142 3 l130 -2 0 -660z"/>
<path d="M3919 2793 c-29 -18 -59 -62 -59 -87 0 -15 13 -16 103 -14 95 3 102
4 105 24 2 14 -10 35 -31 58 -39 40 -74 46 -118 19z"/>
<path d="M5180 2792 c-66 -35 -95 -133 -63 -217 20 -55 68 -95 112 -95 58 0
120 86 121 167 0 50 -35 120 -72 143 -41 25 -54 25 -98 2z"/>
</g>
</svg>
	);
}
