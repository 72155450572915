import { css } from '@emotion/css';
import { Checkbox, id } from 'Shared/forms/Inputs';
import { labelStyle as stdLabelStyle } from 'Shared/forms/Wrap';

const style = () => css({
	display: 'flex',
	alignItems: 'center',
	gap: '0.2em'
});

const labelStyle = () => css(stdLabelStyle(), {
	userSelect: 'none'
});

export function DoneCheckbox(props)
{
	return (
		<div class={style()}>
			<Checkbox {...props} field={props.field} />
			{' '}
			<label class={labelStyle()} for={id(props)}>Done</label>
		</div>
	)
};

