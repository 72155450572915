import {AccessAreaName} from 'Common/permissions/AccessArea';
import {FrontendPageConfig, frontendData} from 'Common/FrontendPageConfig';
import { z } from 'zod';
import { HireEnquirePage } from 'Shared/frontend/hireEnquiryPage/HireEnquiryPage';

export const hireEnquiryParams = z.object({ }).strict();	
export type HireEnquiryParams = z.infer<typeof hireEnquiryParams>;


export const hireEnquiryData = frontendData.extend({
	pageDisplayName: z.string(),
}).strict();
export type HireEnquiryData = z.infer<typeof hireEnquiryData>;



export class HireEnquiry extends FrontendPageConfig<HireEnquiryData,HireEnquiryParams>
{
	static readonly pageName = 'frontend/hireEnquiry';
	name() { return HireEnquiry.pageName; }

	settingsName() { return 'hireEnquiry'; }

	access() { return 'venueFrontend' as AccessAreaName; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			template: HireEnquirePage,
			pageDisplayName: 'Hire Enquiry'
		};
	}
}

