import { BaseTheme, BaseThemeType } from 'Shared/frontend/BaseTheme';
import { fluidType } from 'Shared/common/FluidType';
import { merge } from 'Shared/frontend/merge';
import { DeepPartial } from 'Shared/frontend/ThemeProvider';


export class DifferentDrummerTheme extends BaseTheme
{
	palette()
	{
		return {
			background: 'black',
			text: 'white',
			feature: '#be1e2d', /* a red */
			feature2: '#f2eb67', /* yellow */
			dimBackground: '#2b2929' /* a gray */
		};
	}

	fonts() {
		return {
			text: '"Open Sans", sans-serif',
			heading: 'Oswald, sans-serif',
			buttons: '"Open Sans", sans-serif',
			desktopNav: 'Oswald, sans-serif',
			mobileNav: '"Open Sans", sans-serif',
			subNav: '"Open Sans", sans-serif',
			menuItem: '"Alatsi", sans-serif'
		};
	}

	page()
	{
		return merge(super.page(),{
			widgets: {
				subNav: {
					button: {
						backgroundColor: this.palette().text
					}
				},
				slideshow: {
					teaser: {
						margin: '1em 0 2em 0'
					}
				},
			},
			button: {
				border: 'solid 1px',
				backgroundColor: this.palette().background,

				':hover': {
					backgroundColor: this.palette().text,
					color: this.palette().background
				}
			},
			embeddedHtml: {
				a: {
					color: this.palette().feature2
				}
			}
		});
	}

	settings()
	{
		const base = super.settings();
		const palette = this.palette();
		const fonts = this.fonts();
		const sizes = this.sizes();

		return merge(base,<DeepPartial<BaseThemeType>>{
			nav: {
				desktop: {
					active: {
						color: palette.text
					},
					hover: {
						color: palette.text
					}
				},
				mobile: {
					button: {
						backgroundColor: palette.background
					},
					active: {
						color: palette.text
					},
					hover: {
						color: palette.text
					}
				}
			},

			event: {
				subNav: {
					button: {
						backgroundColor: palette.text,
						color: palette.background,
						fontSize: '0.8em',
						padding: '0.5em'
					}
				},
			},

			a: {
				color: palette.feature2
			},

			phone: {
				color:  palette.feature2
			},

			address: {
				color:  palette.feature2
			},

			menu: {
				main: {
					paddingLeft: fluidType('px',sizes.flipToMobileNav,sizes.startMarginShrink,22,48),
					paddingRight: fluidType('px',sizes.flipToMobileNav,sizes.startMarginShrink,22,48),
				},
				section: {
					marginBottom: '3em'
				},
				item: {
					title: { 
						fontFamily: fonts.menuItem,
						fontSize: '1.3em',
						fontWeight: 'bold'
					},
				},
			},
		});
	}
};

