
import { css } from '@emotion/css';
import { appleMusic, bandcamp, soundcloud, spotify } from 'Browser/InputTypes';
import { ISet, sectionStyle, verticalStyle } from 'Shared/backend/eventPage/artist/ArtistForm';
import { labelLinkStyle } from 'Shared/backend/eventPage/artist/ArtistDetails';
import { BandcampButton } from 'Shared/backend/icons/BandcampButton';
import { IconColour } from 'Shared/backend/IconColour';
import { LinkOpen } from 'Shared/backend/icons/LinkOpen';
import { ListenOnApple } from 'Shared/backend/icons/ListenOnApple';
import { ListenOnSpotify } from 'Shared/backend/icons/ListenOnSpotify';
import { MusicCircle } from 'Shared/backend/icons/MusicCircle';
import { SoundcloudButton } from 'Shared/backend/icons/SoundcloudButton';
import { Text } from 'Shared/forms/Inputs';
import { Wrap } from 'Shared/forms/Wrap';
import { Show } from 'solid-js';
import { palette } from 'Shared/backend/Theme';
import { IconPane } from 'Shared/forms/IconPane';
import { HeadingWithNote } from 'Shared/backend/eventPage/artist/HeadingWithNote';

const activeLabelStyle = () => css(labelLinkStyle, {
	color: palette.active
});

const inactiveLabelStyle = () => css(labelLinkStyle,{
	color: palette.label
});

const playerClass = () => css({
	width: 100
});


export function MusicLinks(props:ISet)
{
	return (
		<section class={sectionStyle()}>
			<HeadingWithNote
				heading='Music' 
				note='VOS displays links on venue website & drafts social media posts so fans can listen on their platform of choice' 
			/>

			<IconPane>
				<MusicCircle fill={props.spotify || props.appleMusic || props.musicEmbedId ? IconColour.active : IconColour.inactive} />

				<div class={verticalStyle()}>
					<Spotify {...props} />
					<AppleMusic {...props} />
					<Bandcamp {...props} />
					<Soundcloud {...props} />
				</div>
			</IconPane>
		</section>
	);
}

function Spotify(props:ISet)
{
	const label = () => props.spotify ?
		<a class={activeLabelStyle()}>Spotify song link </a>
	:
		<a class={inactiveLabelStyle()} href={`https://open.spotify.com/search/${props.name}/artists`} target='_blank'>
			Search Spotify for a song &nbsp;
			<LinkOpen fill={IconColour.inactive} />
		</a>;

	return (<>
		<Wrap label={label()}>
			<Text {...props} field='spotify' processInput={spotify} placeholder='Paste song share link' />
		</Wrap>
			
		<Show when={props.spotify}>
			<a href={props.spotify} target='_blank'>
				<ListenOnSpotify class={playerClass()} />
			</a>
		</Show>
	</>);
}

function AppleMusic(props:ISet)
{
	const label = () => props.appleMusic ?
		<a class={activeLabelStyle()}>Apple song link </a>
	:
		<a class={inactiveLabelStyle()} href={`https://music.apple.com/us/search?term=${props.name}`} target='_blank'>
			Search Apple Music for a song &nbsp;
			<LinkOpen fill={IconColour.inactive} />
		</a>;

	return (<>
		<Wrap label={label()}>
			<Text {...props} field='appleMusic' processInput={appleMusic} placeholder='Paste song share link' />
		</Wrap>

		<Show when={props.appleMusic}>
			<a href={props.appleMusic} target='_blank'>
				<ListenOnApple class={playerClass()} />
			</a>
		</Show>
	</>);
}

function Bandcamp(props:ISet)
{
	const label = () => props.bandcamp ?
		<a class={activeLabelStyle()}>Bandcamp page</a>
	:
		<a class={inactiveLabelStyle()} href={`https://bandcamp.com/search?q=${props.name}&item_type`} target='_blank'>
			Search Bandcamp &nbsp;
			<LinkOpen fill={IconColour.inactive} />
		</a>;

	return (<>
		<Wrap label={label()}>
			<Text {...props} field='bandcamp' processInput={bandcamp} placeholder='Paste track link' />
		</Wrap>

		<Show when={props.bandcamp}>
			<a href={props.bandcamp} target='_blank'>
				<BandcampButton class={playerClass()} />
			</a>
		</Show>
	</>);
}

function Soundcloud(props:ISet)
{
	const label = () => props.soundcloud ?
		<a class={activeLabelStyle()}>Soundcloud page</a>
	:
		<a class={inactiveLabelStyle()} href={`https://soundcloud.com/search?q=${props.name}`} target='_blank'>
			Search Soundcloud &nbsp;
			<LinkOpen fill={IconColour.inactive} />
		</a>;

	return (<>
		<Wrap label={label()}>
			<Text {...props} field='soundcloud' processInput={soundcloud} placeholder='Link to artist/track/album' />
		</Wrap>

		<Show when={props.soundcloud}>
			<a href={props.soundcloud} target='_blank'>
				<SoundcloudButton class={playerClass()} />
			</a>
		</Show>
	</>);
}

