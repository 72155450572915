import { css } from '@emotion/css'
import { EventPoster } from 'Shared/frontend/eventPosters/EventPoster';
import { Overlay } from 'Shared/frontend/Overlay';
import { SpecialsPoster } from 'Shared/frontend/specialsPosters/SpecialsPoster';
import { createMemo,createSignal, For,Show, Switch } from 'solid-js';
import { SpecialsOverlayContent } from 'Shared/frontend/specialsPosters/SpecialsOverlayContent';
import { specialItemOverlayStyle } from 'Shared/frontend/menuPage/MenuPage';
import { palette, theme } from 'Shared/frontend/ThemeProvider';
import { Info } from 'Shared/frontend/showcaseWidgets/Info';
import { Match } from 'solid-js';
import { posterGridStyle } from 'Shared/frontend/posterGridStyle';


//XXX MAYBE remove event-specific look from subNavButton
//TODO hover

const scrollStyle = () => css({
	padding: '0 10px',
	maxWidth: '100%',
	display: 'flex',
	flexWrap: 'nowrap',
	overflowX: 'auto',
	scrollbarColor: 'black #666363',
	justifyContent: 'flex-start'
});	

const specialOverlayBodyStyle = () => css({
	maxWidth: 396,					//XXX set anywhere?
	minHeight: 680,
	backgroundColor: palette().dimBackground,
	marginBottom: '3em',
});

const aStyle = () => css(theme().a);


export function Posters(props)
{
//TODO rename 'carouselMusic' etc to remove carousel 

	return (
		<Switch>
			<Match when={props.layout == 'carouselMusic'}>
				<MusicPosters {...props} />
			</Match>
			<Match when={props.layout == 'carouselNonMusic'}>
				<NonMusicPosters {...props} />
			</Match>
			<Match when={props.layout == 'carouselAllEvents'}>
				<AllEventPosters {...props} />
			</Match>
			<Match when={props.layout?.includes('carouselSpecials')}>
				<SpecialsPosters {...props} />
			</Match>
		</Switch>
	);
}

function MusicPosters(props)
{
	const displaySection = createMemo(() => props.musicEvents.findIndex(e => e.status == 'published') >= 0);

	return (<>
		<Show when={displaySection}>
			<Info {...props} />
		</Show>
		<Show when={props.musicEvents.length > 0}>
			<div class={props.musicEvents.length >= props.carouselToScroll ? scrollStyle() : posterGridStyle()}>
				<For each={props.musicEvents}>{event =>
					<a class={aStyle()} href={`/event/${event.slug}`}>
						<EventPoster {...event} times={event.times} lineup={event.lineup} />
					</a>
				}</For>
			</div>
		</Show>
	</>);
}

function NonMusicPosters(props)
{
	const displaySection = createMemo(() => props.nonMusicEvents.findIndex(e => e.status == 'published') >= 0);

	return (<>
		<Show when={displaySection}>
			<Info {...props} />
		</Show>
		<Show when={props.nonMusicEvents.length > 0}>
			<div class={props.nonMusicEvents.length >= props.carouselToScroll ? scrollStyle() : posterGridStyle()}>
				<For each={props.nonMusicEvents}>{event =>
					<a class={aStyle()} href={`/event/${event.slug}`}>
						<EventPoster {...event} times={event.times} lineup={event.lineup} />
					</a>
				}</For>
			</div>
		</Show>
	</>);
}

function AllEventPosters(props)
{
	const displaySection = createMemo(() => props.events.findIndex(e => e.status == 'published') >= 0);

	return (<>
		{/* If any record in the loop has a particular field or status display {{sameInfo}} only only */}
		<Show when={displaySection}>
			<Info {...props} />
		</Show>

		<div class={props.events.length >= props.carouselToScroll ? scrollStyle() : posterGridStyle()}>
			<For each={props.events}>{event =>
				<a class={aStyle()} href={`/event/${event.slug}`}>
					<EventPoster {...event} times={event.times} lineup={event.lineup} />
				</a>
			}</For>
		</div>
	</>);
}

function SpecialsPosters(props)
{
	return (<>
		<CarouselSpecialGroup {...props} group='group0' />
		<CarouselSpecialGroup {...props} group='group1' />
		<CarouselSpecialGroup {...props} group='group2' />
		<CarouselSpecialGroup {...props} group='group3' />
	</>);
}

function CarouselSpecialGroup(props)
{
	const [current,setCurrent] = createSignal();

	const layout = () => props.group=='group0' ? 'carouselSpecials' : `carouselSpecials_${props.group}`;

//XXX group0, group1... either replace with 0,1,... or give proper name. Or else put specials directly into carousels.	

	return (<>
		<Show when={props.specials.length > 0 && props.layout == layout()}>
			<Info {...props} />

			<section id={layout()}>
				<div class={posterGridStyle()}>
					<For each={props.specials}>{special => 
						<Show when={(special.specialsGroup ?? 'group0') == props.group}>
							<a class={aStyle()} href='' onClick={() => setCurrent(special)} >
								<SpecialsPoster {...special} times={special.times} />
							</a>
						</Show>
					}</For>
				</div>
			</section>
		</Show>

		<Show when={current()}>
			<Overlay iconOverImage={true} animation='slideIn' close={() => setCurrent(undefined)}>
				<div class={specialItemOverlayStyle()}>
					<div class={specialOverlayBodyStyle()}>
						<SpecialsOverlayContent special={current()} times={current().times} />
					</div>
				</div>
			</Overlay>
		</Show>
	</>);
}

