import { css } from '@emotion/css';
import { ISet, verticalStyle, sectionStyle } from 'Shared/backend/eventPage/artist/ArtistForm';
import { IconColour } from 'Shared/backend/IconColour';
import { PinCircle } from 'Shared/backend/icons/PinCircle'
import { theme } from 'Shared/backend/ThemeProvider';
import { IconPane } from 'Shared/forms/IconPane';
import { SingleSelect, Text } from 'Shared/forms/Inputs'
import { Wrap } from 'Shared/forms/Wrap';
import { Show } from 'solid-js';

export function LocationDetails(props:ISet)
{
	return (
		<section class={sectionStyle()}>
			<h4 class={css(theme().section.heading)}>Location</h4>

			<IconPane>
				<PinCircle fill={props.state || props.city || props.country ? IconColour.active : IconColour.alert} />

				<div class={verticalStyle()}>
					{/*TODO ben, CJ tried adding international:'International', as state option so Country isnt displayed if venue is adding artist
									and selects Austraian State. but vm wasnt storing international in database, please add */}

					<Wrap label='City/Town'>
						<Text {...props} field='city' />
					</Wrap>

					<Wrap label='State'>
						<SingleSelect {...props} field='state' options={{
							act: 'ACT',
							nsw: 'NSW',
							nt: 'NT',
							qld: 'QLD',
							sa: 'SA',
							tas: 'TAS',
							vic: 'VIC',
							wa: 'WA'
						}}/>
					</Wrap>

					{/* todo ben, pl note: add or 'international' to if statement when international option added to State */}
					<Show when={!props.state}>
						<Wrap label='Country'>
							<Text {...props} field='country' placeholder='Australia' />
						</Wrap>
					</Show>
				</div>
			</IconPane>
		</section>
	);
}

