import {PageFunctions} from 'Browser/PageFunctions';
import {VenueUrls} from 'Common/VenueUrls';
import {usePage} from 'Shared/frontend/PageProvider';
import {createMemo, For, Show} from 'solid-js';
import {unwrap} from 'solid-js/store';
import { css } from '@emotion/css';
import { palette, theme } from 'Shared/frontend/ThemeProvider';
import { fluidType } from 'Shared/common/FluidType';

//TODO replace constants

const itemStyle = (isPreview:boolean) => css({
	...narrowItemStyle(isPreview),
	...wideItemStyle(isPreview),
	...previewItemStyle(isPreview),

	height: '100%',
    backgroundColor: palette().dimBackground,
    borderRadius: 2,
    boxShadow: '#000000 0px 26px 30px -10px, #000000 0px 16px 10px -10px',
    letterSpacing: '0.1em',
    lineHeight: 1.2,
    color: '#e2e2e2',
    display: 'flex',
    overflow: 'hidden',
	/* 
		Used when there is no image. Gives these items a bit more substance and makes
		them a bit more card-like.  Ideally would be more adaptive. 
		NB can have variable number of columns 
	*/
	minHeight: 100,
});

const imageStyle = (isPreview:boolean) => css({
	...narrowImageStyle(isPreview),
	...wideImageStyle(isPreview),
	...previewImageStyle(isPreview),

	objectFit: 'cover',
	objectPosition: 'center',
});

const detailsStyle = (isPreview:boolean,imageExists:boolean) => css({
	...narrowDetailsStyle(isPreview),
	...wideDetailsStyle(isPreview),
	...previewDetailsStyle(isPreview),

	width: '100%',
	display: 'flex',
	flexDirection: 'column',
	/*
		space-around fills in some space when there is no image, by the positioning
		of the title and price are less consistent when there are descriptions and other 
		variable stuff.
	*/
	justifyContent: imageExists ? 'space-between' : 'space-around'
});

const titleAndQuickDetailsStyle = () => css({
	textAlign: 'left'
});

const titleStyle = (section) => css(
	theme().menu.item.title,
	theme().menu.item.titleType(section)
);

const priceInnerStyle = () => css({
	textAlign: 'right'
});

const priceWrapStyle = (isPreview:boolean) => css({
	...theme().menu.item.price,
	...widePriceWrapStyle(isPreview),
	alignSelf: 'flex-end',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-end',
});

const descriptionStyle = (isPreview:boolean) => css({
	display: '-webkit-box',
	WebkitBoxOrient: 'vertical',
	overflow: 'hidden',
	whiteSpace: 'normal',
	lineHeight: '1.2em',
	textOverflow: 'ellipsis',
	textAlign: 'left',
	fontSize: '0.8em',

	/* Single column: */

	[`@media (max-width: 600px)`]: {
		WebkitLineClamp: 3
	},

	[`@media (min-width: 600px)`]: {
		WebkitLineClamp: 4
	},

	/* Multiple column: */

	[`@media (min-width: 725px)`]: {
		WebkitLineClamp: 2
	},

	[`@media (min-width: 820px)`]: {
		WebkitLineClamp: 3,
	},

	[`@media (min-width: 925px)`]: {
		WebkitLineClamp: 4,
	},

	...(isPreview ? {
		WebkitLineClamp: '4 !important',
	} : {})
});	

const smallStyle = () => css({
	fontSize: '0.7em'
});

const unitStyle = () => css({
	fontSize: '0.7em'
});


/* Narrow styling modifications */

const narrowItemStyle = (isPreview:boolean) => narrow(isPreview,{
	width: '100%',
	maxHeight: '200px',
});

const narrowImageStyle = (isPreview:boolean) => narrow(isPreview,{
	minWidth: '200px',
	maxWidth: '200px',
	minHeight: '200px',
	maxHeight: '200px',
});

const narrowDetailsStyle = (isPreview:boolean) => narrow(isPreview,{
	padding: fluidType('px',600,745,13,16),
});

/* Wide styling modifications */

const wideItemStyle = (isPreview:boolean) => wide(isPreview,{
	width: fluidType('px',745,1040,300,420),
	maxHeight: fluidType('px',745,1040,150,200),
});

const wideImageStyle = (isPreview:boolean) => wide(isPreview,{
	minWidth: fluidType('px',745,1040,150,200),
	maxWidth: fluidType('px',745,1040,150,200),
	minHeight: fluidType('px',745,1040,150,200),
	maxHeight: fluidType('px',745,1040,150,200)
});

const wideDetailsStyle = (isPreview:boolean) => wide(isPreview,{
	fontSize: fluidType('px',745,1040,14,16),
	padding: 10  //TODO check
});

const widePriceWrapStyle = (isPreview:boolean) => wide(isPreview,{
	fontSize: '0.9em'
});

/* Preview styling modifications */

const previewItemStyle = (isPreview:boolean) => !isPreview ? {} : {
	maxWidth: '400px',
	maxHeight: '200px',
};

const previewImageStyle = (isPreview:boolean) => !isPreview ? {} : {
	minWidth: '200px',
	maxWidth: '200px',
	minHeight: '200px',
	maxHeight: '200px',
};

const previewDetailsStyle = (isPreview:boolean) => !isPreview ? {} : {
	padding: fluidType('px',600,745,13,16),
};


/* Helper functions: */

function narrow(isPreview:boolean,properties:any)
{
	return isPreview ? {} : { ['@media (max-width: 745px)']: properties };
}

function wide(isPreview:boolean,properties:any)
{
	return isPreview ? {} : { ['@media (min-width: 745px)']: properties };
}



export function CardItem(props) 
{
/*
	{# todo ben: The id "item{{item.id}} below is used as a html #anchor to jump to the item on the menu, however we need to compensate
	for the sticky menu nav which covers about 50px of the top of the item.
	Test it by going to the menu item GUI and on your 'Summary' preview click the 'view on website menu' button. 
	That will open the menu in a new tab and drop you the items anchor #}
*/	

//TODO remove url generation higher up frontend caller stack...

	const imageUrl = createMemo(() => {
		if (!props.item.image?.hash)
			return null;

		return (new PageFunctions()).createImageUrl2(
			new VenueUrls(usePage().build,usePage().site.key),
			props.item.image.hash,
			props.item.image,
			'MenuItem-image',
			'medium'
		);
	});

	return (<>
		<div class={itemStyle(props.isPreview)}>
			<Show when={imageUrl()}>
				<img class={imageStyle(props.isPreview)} src={imageUrl()} />
			</Show>

			<div class={detailsStyle(props.isPreview,imageUrl() != null)}>
				<div class={titleAndQuickDetailsStyle()}>
					<TitleAndQuickDetails {...props} />
				</div>

				<Show when={(props.item.tagline ?? '').trim() != ''}>
					<div class={descriptionStyle(props.isPreview)}>
						{props.item.tagline}
					</div>
				</Show>

{/* TODO if no prices should hide this... */}
				<Prices item={props.item}/>
			</div>
		</div>
	</>);
}

export function TitleAndQuickDetails(props)
{
	const tags = () => unwrap(props.item.tags) ?? [];

	//FIXME townie specific
	const tagsStr = () => tags().filter(i => i!='0%' && i!='☺').join(' ');

	return (<>
		<span class={titleStyle(props.section)}>
			{props.item.brandName} {props.item.name}
			<Show when={tags().includes('0%')}>
				{' '}0%
			</Show>
			<Show when={tags().includes('☺')}>
				{' '}☺
			</Show>
		</span>
		<Show when={props.item.variety}>
			{' '}
			<span class={smallStyle()}>{props.item.variety} {props.item.varietyOther}</span>
		</Show>
		<Show when={tagsStr().length > 0 }>
			{' '}
			<span class={smallStyle()}> 
				({tagsStr()})
			</span>
		</Show>

		<Show when={props.item.year || props.item.region || props.item.state || props.item.country }>
			{' '}
			<span class={smallStyle()}>
				{props.item.year} {props.item.region} {props.item.state?.toUpperCase()}
				<Show when={props.item.country != 'Australia'}>
					{' '}
					{props.item.country}
				</Show>
			</span>
		</Show>
	</>);
}

function Prices(props)
{
	return (
		<div class={priceWrapStyle(props.isPreview)}>
			<For each={props.item.prices}>{ unit =>
				<Show when={unit.price !== null }>
				{/* XXX probably shouldnt be required but currently these items contain nulls */}
					<div class={priceInnerStyle()}>
						{/* Only 1 of these unit options will appear */}
						<span class={unitStyle()}>{capitalise(unit.size)}{capitalise(unit.description)}{' '}</span>
						<span>${stripZeros(unit.price)}</span>
					</div>
				</Show>
			}</For>
		</div>
	);
}

export function capitalise(s:string) 
{
	const str = s ?? '';
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function stripZeros(amount)
{
	const a = amount ?? '';

	if (a.match(/.*\.00$/))
		return a.substring(0,amount.length - 3);
	else if (a.match(/.*\.[0-9]0$/))
		return a.substring(0,amount.length - 1);
	else
		return a;
}
