import { css, injectGlobal } from '@emotion/css';
import { StandardDefaultLayout } from 'Shared/frontend/layouts/StandardDefaultLayout';
import { PageProvider } from 'Shared/frontend/PageProvider';
import { standardRules } from 'Shared/common/Sanitise';
import { pageTheme, sizes, ThemeProvider } from 'Shared/frontend/ThemeProvider';
import { WideIframeForm } from 'Shared/frontend/WideIframeForm';
import { MembersData } from 'Shared/view/frontend/Members';
import { For, Show } from 'solid-js';
import { createStore } from 'solid-js/store';

const headingStyle = () => css(pageTheme().heading);

const outerDescriptionStyle = () => css({
	width: '100%',
	display: 'inline-block',
	marginTop: '1em',
	marginBottom: '1em'
});

const descriptionStyle = () => css(pageTheme().embeddedHtml);

const imageStyle = () => css({
	width: '100%',
	marginBottom: '1em',

	[`@media (min-width: ${sizes().desktopWidth}px)`]: {
		width: '50%',
		float: 'left',
		paddingRight: '2em'
	}
});


export function MembersPage(props:MembersData)
{
	injectGlobal([standardRules,{body:props.theme.body}]);

	const [theme] = createStore(props.theme);

	return (
		<ThemeProvider theme={theme}>
			<PageProvider page={props.page}>
				<StandardDefaultLayout {...props} expandDesktopNav={false}>
					<Content {...props} />
				</StandardDefaultLayout>
			</PageProvider>
		</ThemeProvider>
	);
}

function Content(props)
{
	return (
		<For each={props.venue.aboutList}>{about => <>
			<Show when={about.display.includes('members')}>
				<section>
					<h2 class={headingStyle()}>{about.title}</h2>

					<div class={outerDescriptionStyle()}>
						<div id={about.title.toLowerCase().replace(' ','')} />

						{/* XXX image not used currently. Dont think there is backend support for this at the moment. */}
						<Show when={props.photo?.hash}>
							<img class={imageStyle()} src={about.photo?.url} />
						</Show>
						<div class={descriptionStyle()} innerHTML={about.description} />
					</div>
				</section>

				<WideIframeForm url={about.embedUrl} minHeight={about.embedHeight} />
			</Show>
		</>}</For>
	);
}

