import {createMemo} from 'solid-js';
import {Match, Show, Switch} from 'solid-js';
import {MenuItemPreview} from 'Shared/frontend/menuPage/MenuPage';
import { StatusKey } from 'Shared/backend/menu/StatusIcons';
import { usePage } from 'Shared/frontend/PageProvider';
import { IItemProps } from 'Shared/backend/menu/MenuItemPage';
import { ThemeProvider } from 'Shared/frontend/ThemeProvider';
import { locateSubDocument } from 'Common/ViewUtils';
import { OverlayItem } from 'Shared/frontend/menuPage/OverlayItem';
import { css } from '@emotion/css';
import { TabPanes } from 'Shared/backend/Tabs';
import { theme } from 'Shared/backend/ThemeProvider';


const paneStyle = () => css({
	width: '100%',
	padding: '1em',
	background: '#f5f5f5',
	minHeight: 315
});

const previewButtonStyle = () => css(theme().button,{
	marginBottom: 7 
});

const previewStyle = (theme) => css(theme.body,{
	backgroundColor: 'inherit',
	overflowX: 'visible'
});


interface IPreviewTab extends IItemProps {
	status: StatusKey,
	link: string
}

export function PreviewTab(props:IPreviewTab)
{
	const tabs = {
		'summary': {label:'Summary',component:SummaryTab},
		'full': {label:'Full',component:FullTab}
	};

	return (
		<TabPanes tabs={tabs} nested={true} tabProps={props} />
	);
}

function SummaryTab(props)
{
	return (
		<div class={paneStyle()}>
			<Show when={props.item.status=='published'}>
				<a target='_blank' href={props.link}>
					<button class={previewButtonStyle()}>View on website menu</button>
				</a>
				<br/>
			</Show>

			<Preview {...props} selected='summary' />
		</div>
	);
}

function FullTab(props)
{
	return (
		<div class={paneStyle()}>
			<Preview {...props} selected='full' />
		</div>
	);
}

function Preview(props:IItemProps)
{
	const page = usePage();
	const settings = page.data.venue.settings;
	const section = createMemo(() => locateSubDocument(props.store,props.location.slice(0,-2)));

	return (
		<ThemeProvider theme={page.theme}>
			<div class={previewStyle(page.theme)}>
				<Switch>
					<Match when={props.selected == 'summary'}>
						<MenuItemPreview {...props} isPreview={true}
							menu={props.store}
							section={section()} 
							settings={settings} 
						/>
					</Match>
					<Match when={props.selected == 'full'}>
						<OverlayItem {...props} isPreview={true}
							menu={props.store}
							section={section()} 
							settings={settings} 
							productType={props.section.productType} 
						/>
					</Match>
				</Switch>
			</div>
		</ThemeProvider>
	);
}

