import { unwrap } from 'solid-js/store';

export async function copyToClipboard(node:HTMLElement)
{
	const selection = window.getSelection()!;
	selection.removeAllRanges();

	const range = document.createRange();
	range.selectNode(node);
	selection.addRange(range);

	if (!navigator?.clipboard) {
		alert('Copy to clipboard is only available on https sites');	
		return;
	}

	await navigator.clipboard.writeText(selection!.toString());

	alert('Copied! Ready for you to paste to social media.')
}

