// TODO create a product search to insert products like we do with artist search

import {ImageSelector} from 'Shared/backend/menu/ImageSelector';
import {productTypes, sectionProductTypes} from 'Shared/backend/menu/MenuDesignerPage';
import {PreviewTab} from 'Shared/backend/menu/PreviewTab';
import {BeerDescription} from 'Shared/backend/menu/BeerDescription';
import {WineDescription} from 'Shared/backend/menu/WineDescription';
import {MultiSelect, Price, SingleSelect, Text} from 'Shared/forms/Inputs';
import { labelStyle, Wrap } from 'Shared/forms/Wrap';
import {Switch,Match} from 'solid-js';
import {StoreRepeater, addItemAndOpen} from 'Shared/forms/StoreRepeater';
import {Show} from 'solid-js';
import { mergeProps } from 'solid-js';
import { usePage } from 'Shared/frontend/PageProvider';
import { IItemProps } from 'Shared/backend/menu/MenuItemPage';
import { DeleteArrayItemMessage2 } from 'Common/Messages';
import { MenuItem } from 'Shared/view/backend/MenuItem';
import { Id } from 'Common/Id';
import { DeleteButton } from 'Shared/forms/DeleteButton';
import { ContentAndPreviewPanes } from 'Shared/backend/ContentAndPreviewPanes';
import { css } from '@emotion/css';
import { theme } from 'Shared/backend/ThemeProvider';

const productTypeLabelStyle = () => css(labelStyle(), {
	marginRight: '1em'
});

const productTypeValueStyle = () => css({
	color: 'grey'
});

const verticalStyle = () => css({
	display: 'flex',
	flexDirection: 'column',
	gap: '1.5em'
});

const unitPriceLayoutStyle = () => css({
	display: 'flex',
	flexDirection: 'column',
	gap: '1em',
	paddingLeft: '1em'
});

const brandAndNameStyle = () => css({
	display: 'flex',
	flexDirection: 'column',
	gap: '1em'
});


//XXX cf injecting product type mini-templates

interface IDetailsTab extends IItemProps {
	productType: keyof typeof productTypes
	parentProductType: keyof typeof sectionProductTypes
}

export function DetailsTab(props:IDetailsTab)
{
	return (
		<div class={css(theme().detailsPane)}>
			<ContentAndPreviewPanes>
				<ContentPane {...props} />

				<PreviewTab {...props} />
			</ContentAndPreviewPanes>
		</div>
	);
}

function ContentPane(props)
{
	let itemsNode!:HTMLElement;

	const tags = {
		GF: 'Gluten Free (GF)', 
		GFo: 'Gluten Free Option (GFO)', 
		VG: 'Vegan (VG)', 
		V: 'Vegetarian (V)', 
		DF: 'Dairy Free (DF)',
		DFO: 'Dairy Free Option (DFO)',
		'0%': 'Zero Alcohol',
		'☺': '☺'
	};

	const priceProps = (index:number) => mergeProps(props,{location:[...props.location,'prices',index]});
	const page = usePage();

	return (
		<div>
			<Switch>
				<Match when={props.parentProductType == 'mixed'}>
					<Wrap label='Product type' required={true}>
						<SingleSelect {...props} field='productType' options={productTypes} required={true} />
					</Wrap>
				</Match>
				<Match when={true}>
					<label class={productTypeLabelStyle()}>Product type</label>
					<span class={productTypeValueStyle()}>{productTypes[props.productType]}</span>
				</Match>
			</Switch>

			<h4 class={css(theme().menu.heading)}>Description</h4>

			<div class={verticalStyle()}>
				<Switch>
					<Match when={props.productType=='beers' || props.productType=='wines'}>
						<div class={brandAndNameStyle()}> 
							<Wrap label='Brand Name'>
								<Text {...props} field='brandName' />
							</Wrap>
							<Wrap label='Product Name' required={true}>
								<Text {...props} field='name' />
							</Wrap>
						</div>
					</Match>
					<Match when={true}>
						<Wrap label='Product Name'>
							<Text {...props} field='name' />
						</Wrap>
					</Match>
				</Switch>

				<Wrap label='Tags'>
					<MultiSelect {...props} field='tags' options={tags} />
				</Wrap>

				<Switch>
					<Match when={props.productType=='beers'}>
						<BeerDescription {...props} />
					</Match>
					<Match when={props.productType=='wines'}>
						<WineDescription {...props} />
					</Match>
					<Match when={true}>
						<Wrap label='Short description'>
							<Text {...props} field='tagline' />
						</Wrap>
					</Match>
				</Switch>
			</div>

			<h4 class={css(theme().menu.heading)}>Prices and add ons</h4>

			<x-field>
				<repeater-pane>
					<StoreRepeater ref={itemsNode} {...props} page={page} field='prices'
						renderTitle={i => <>{i.size} {i.description} {i.price!=undefined ? '$' : ''}{i.price}</> }
					>
						{(item,index) => <UnitPrice unitPriceProps={priceProps(index)} productType={props.productType} />}
					</StoreRepeater>

					<button class={css(theme().button)}
						onClick={() => addItemAndOpen({...props,permission:'editMenu',field:'prices'},itemsNode,{})}
					>
						<i class='fas fa-plus'/> Add unit price
					</button>
				</repeater-pane>
			</x-field>

			<Show when={page.data.venue.settings.pages.menu.itemType!='imageless'}>
				<ImageSelector {...props} />
			</Show>

			<DeleteButton label='Delete item' delete={() => deleteItem(page,props.docId,props.location)} />
		</div>
	);
}


interface IUnitPrice {
	unitPriceProps;
	productType;
}

function UnitPrice(props:IUnitPrice)
{

//TODO test wines. Shouldnt be an add on should it?
//			{unitOptions: {glass:'Glass',bottle:'Bottle'} } :
	const beerUnits = {can:'Can',bottle:'Bottle',stubby:'Stubby',midi:'Midi',schooner:'Schooner',pint:'Pint',jug:'Jug'};

	return (
		<div class={unitPriceLayoutStyle()}>
			<Switch>
				<Match when={props.productType == 'beers'}>
					<Wrap label='Size' required={true}>
						<SingleSelect {...props.unitPriceProps} field='size' options={beerUnits} />
					</Wrap>
					 
					<Wrap label='Price' required={true}>
						<Price {...props.unitPriceProps} field='price' />
					</Wrap>
				</Match>
				<Match when={props.productType == 'wines'}>
					TODO wines 
				</Match>
				<Match when={true}>
					<Wrap label='Price' required={true}>
						<Price {...props.unitPriceProps} field='price' />
					</Wrap>

					<Wrap label='Description (optional, but useful for add ons)'>
						<Text {...props.unitPriceProps} field='description' />
					</Wrap>
				</Match>
			</Switch>
		</div>
	);
}

async function deleteItem(page,docId:Id,location:Location)
{
	if (!confirm('Delete this menu item?'))
		return;

//XXX if no items left cf deleting out the array entirely	
	const msg = new DeleteArrayItemMessage2(MenuItem.pageName,'editMenu',docId,location.slice(0,-1),location[location.length-1]);
	await page.server.sendOperation(msg)

//TODO need to use Urls() or links.ts to help standardise...
	window.pageJs('/admin/menus/'+page.params.slug+'/'+page.params.tail.split('/').slice(0,-1).join('/'));
}

