import { z } from 'zod';
import {eventDoc as eventModel} from 'Shared/model/Event';
import {DocumentQueries } from 'Common/config/PageConfigTypes';
import {FutureEventsQueryClause} from 'Shared/view/FutureEventsQueryClause';
import {PastEventsQueryClause} from 'Shared/view/PastEventsQueryClause';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {BackendPageConfig, backendData} from 'Common/BackendPageConfig';
import {TimesFunctions} from 'Common/TimesFunctions';
import { EventsPage } from 'Shared/backend/eventsPage/EventsPage';


export const eventsParams = z.object({ }).strict();	
export type EventsParams = z.infer<typeof eventsParams>;

export const eventsData = backendData.extend({
	events: z.array(eventModel),
	pastEvents: z.array(eventModel),

	/* Component data types: */
	createEvent: z.object({
		slug: z.string()
	})
}).strict();
export type EventsData = z.infer<typeof eventsData>;


export class Events extends BackendPageConfig<EventsData,EventsParams>
{
	static readonly pageName = 'backend/events';
	name() { return Events.pageName; }

	access() { return 'venueEventEditor' as AccessAreaName; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			template: EventsPage
		};
	}

	permissions()
	{
		return ({
			edit: { collection: 'event' }
		});
	}

	documents(params:EventsParams): DocumentQueries 
	{
		const times = new TimesFunctions(this.venue.document.settings.general.timezone,this.venue.document.settings.general.testNow);
		//todo ben urgent we need a list of events if someone forgets to set any date, below we're only listing current events and past events, not ones that have no date set 
		return ({
			...super.documents(params),
			events: {
				type: 'array',
				collection: 'event',
				aggregate: () => [
					{'$match': (new FutureEventsQueryClause(times,'times',{years:100})).create() },
					{'$limit': 100},
					{'$sort': {'times.startSingle':1,'times.startDate':1}},
				],
			},
			pastEvents: {
				type: 'array',
				collection: 'event',
				aggregate: () => [
					{'$match': (new PastEventsQueryClause(times,'times',{years:100})).create() },
					{'$sort': {'times.startSingle':-1,'times.startDate':-1}},
					{'$limit': 1000},
				],
			},
		});
	}
}
