/* Included for pollyfills. See rollup.app.js - the useBuiltIns options */
import 'core-js';

import {Browser} from 'Browser/Browser';
import pageJs from 'page'; 


window.useVite = false;
fixInitComponentData();

const browser = new Browser(pageJs);
browser.run().catch(err => log.error(err));


function fixInitComponentData()
{
//TODO FIX site OR MAYBE BETTER REMOVE site.document + other unnecessary embeds!!!

	/*
		Note that when </script> tags are embedded in JSON AND included in HTML they break the page.
		The settings include embeds so they being encoded.
	*/

	window.initComponentData = JSON.parse(
			(new TextDecoder()).decode(
			Uint8Array.from(
				atob(window.initComponentData),
				m => m.codePointAt(0)
			)
		)
	);

	/* Add venue info to the site object */
	if (window.site.type == 'venue')
		window.site.document = window.initComponentData.venue;
}

