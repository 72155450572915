import { css } from '@emotion/css';
import { noteStyle as stdNoteStyle } from 'Shared/forms/Wrap';
import { theme } from 'Shared/backend/ThemeProvider';

const headingStyle = () => css(theme().section.heading, {
	marginBottom: 0
});

const noteStyle = () => css(stdNoteStyle(), {
	marginBottom: 12
});


export function HeadingWithNote(props)
{
	return (<>
		<h4 class={headingStyle()}>{props.heading}</h4>

		<div class={noteStyle()}>{props.note}</div>
	</>);
}

