import { css } from '@emotion/css';
import { actImageUrl, commonGenresStyle} from 'Shared/frontend/eventPosters/MusicPoster';
import { genreList } from 'Shared/common/Macros';
import { palette } from 'Shared/frontend/ThemeProvider';


const posterStyle = () => css({
	margin: '1.5em 32px 0 32px',
	display: 'flex',
	flexDirection: 'column'
});

const artistStyle = () => css({
	position: 'relative'
});

const pressShotStyle = () => css({
	display: 'block',
	maxWidth: '100%',
	height: 'auto'
});

const artistDetailsOverlayStyle = () => css({
	position: 'absolute',
	bottom: 0,
	width: '100%',
	background: '#00000060',
	padding: 5
});

const artistNameStyle = () => css({
	width: '100%',
	fontSize: '0.8em',
	color: palette().feature,
});

const genresStyle = () => css(commonGenresStyle(), {
	fontSize: '0.7em',
	lineHeight: '1.5em'  //XXX MOSTLY JUST SPACING. THERE ARE BETTER WAYS....
});


export function TwoActs(props)
{
	return (
		<div class={posterStyle()}>
			<Artist {...props.lineup[0]} />
			<Artist {...props.lineup[1]} />
		</div>
	);
}

function Artist(props)
{
	return (
		<div class={artistStyle()}>
			<img class={pressShotStyle()} src={actImageUrl(props.image,props.proxyProductionAssets ?? false)} />

			<div class={artistDetailsOverlayStyle()}>
				<div class={artistNameStyle()}>{props.name}</div>
				<div class={genresStyle()}>{ genreList(props) }</div>
			</div> 
		</div>
	);
}

