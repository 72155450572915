import { css } from '@emotion/css';
import { commonGenresStyle } from 'Shared/frontend/eventPosters/MusicPoster';
import { genreList } from 'Shared/common/Macros';
import { palette } from 'Shared/frontend/ThemeProvider';
import { For } from 'solid-js';


const posterStyle = () => css({
	margin: '24px 0.2em 0 0.2em',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'stretch',
	gap: 14
});

const artistNameStyle = () => css({
	width: '100%',
	fontSize: '1em',
	color: palette().feature,
});

const artistStyle = () => css({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'stretch'
});

const genresStyle = () => css(commonGenresStyle(), {
	fontSize: '1em',
	lineHeight: '1.5em'  //XXX MOSTLY JUST SPACING. THERE ARE BETTER WAYS....
});


export function FiveActs(props)
{
	return (
		<div class={posterStyle()}>
			<For each={props.lineup}>{item =>
				<Artist {...item} />
			}</For>
		</div>
	);
}

function Artist(props)
{
	return (
		<div class={artistStyle()}>
			<div class={artistNameStyle()}>{props.name}</div>
			<div class={genresStyle()}>{ genreList(props) }</div>
		</div>
	);
}

