import { BaseTheme, BaseThemeType } from 'Shared/frontend/BaseTheme';
import { merge } from 'Shared/frontend/merge';
import { DeepPartial } from 'Shared/frontend/ThemeProvider';


export class TheTownieTheme extends BaseTheme
{
	palette()
	{
		return {
			background: '#1B2126',
			text: '#E2E2E2',
			feature: '#DAB670',
			/* Eg the mobile nav colour */
			dimBackground: '#2E3234',

			food: '#B3462B',
			drinks: '#DAB670',
			cactus: '#5C905C'
		};
	}

	fonts()
	{
		return {
			text: 'Questrial, sans-serif',
//FIXME Garamond doesn't appear to be actually imported 
			heading: '"EB Garamond", sans-serif',
			buttons: 'Questrial, sans-serif',
			desktopNav: '"EB Garamond", sans-serif',
			mobileNav: 'Questrial, sans-serif',
			subNav: 'Questrial, sans-serif',
		};
	}

	page()
	{
		return merge(super.page(), {
			heading: {
				color: this.palette().feature
			},
			widgets: {
				subNav: {
					button: {
						borderRadius: '0.4em'
					}
				},
			},
			section: {
				heading: {
					color: this.palette().feature
				}
			},
			button: {
				borderRadius: '0.4em',
				backgroundColor: this.palette().dimBackground
			},
			embeddedHtml: {
				strong: {
					fontFamily: 'REM, sans-serif'
				}
			}
		});
	}

	settings()
	{
		const base = super.settings();
		const palette = this.palette();

		const settings = merge(base, <DeepPartial<BaseThemeType>> {

			footer: {
				divider: {
					borderColor: palette.feature
				},
				title: {
					color: palette.feature
				}
			},

			poster: {
				dateBar: {
					color: palette.dimBackground
				},

				photoPoster: {
					inlineName: {
						color: palette.feature
					}
				},

				detailsButton: {
					textColor: palette.feature,
					backgroundColor: 'rgba(27, 33, 38, 0.88)'
				}
			},

			event: {
				subNav: {
					button: {
						backgroundColor: palette.feature,
						color: palette.background,
						fontSize: '0.8em',
						borderRadius: '0.4em',
						padding: '0.5em'
					}
				},
				artist: {
					heading: {
						color: palette.feature
					},
					wide: {
						nameAndTime: {
							color: palette.feature
						}
					},
					narrow: {
						nameAndTime: {
							backgroundColor: palette.feature
						}
					}, 
					genresAndLocation: {
						color: palette.feature
					} 
				}
			},

			menu: {
				subNav: {
					button: {
						borderRadius: '0.4em',
						color: palette.background
					},
					buttonType: type => ({ backgroundColor: menuNavButtonColour(palette,type) })
				},
				section: {
					headingType: (level:number,section) => ({ color: menuItemTitleColour(palette,section)}),
				},
	//XXX important! used to override Less. TODO delete these in time
				item: {
					title: {
						fontWeight: 'bold !important',
					},
					titleType: section => ({color: `${menuItemTitleColour(palette,section)} !important`}),
				}
			},
		});

		// XXX if we want to entirely remove or overwrite a base setting or two then do so here 
		return settings;
	}
};

function menuItemTitleColour(palette,section)
{
	if (section.title?.startsWith('Cocktails @ Bar Cactus'))
		return palette.cactus;
	if (section.title?.startsWith('Cocktails @ Street Bar'))
		return palette.food;
	else if (section.productType == 'food')
		return palette.food;
	else
		return palette.feature;
}

function menuNavButtonColour(palette,type:string)
{
	switch(type) {
		case 'food': return palette.food;
		case 'drinks': return palette.drinks;
	}
	return 'inherit';
}

