//import {CSSInterpolation} from '@emotion/css';

import { sanitise } from 'Shared/common/Sanitise';

export const Theme:CSSInterpolation = {

	body: {
		...sanitise.body,

		margin: 0,
		width: '100%',
		minWidth: '100%',
		font: '15px "Open Sans",Helvetica,Arial,sans-serif',
		backgroundColor: 'white',
		color: 'black'
	},

	content: {
		margin: '1em auto',
		maxWidth: 700,
		border: '1px solid #ddd',
		padding: '2em 2em',
	},

	nav: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		marginBottom: '3em',
	},

	h3: {
		fontSize: '1.2em',
		color: '#42627b',
		lineHeight: '1.5em',
		textTransform: 'uppercase',
		textAlign: 'left',
		fontWeight: 'initial'
	},

	h4: {
		textTransform: 'uppercase',
		color: '#000000',
		fontSize: '1em',
		margin: '12px 0 6px 0',
		padding: 6
	},

	section: {
		margin: '0 0 36px 0'
	},

	button: {
		textAlign: 'center',
		verticalAlign: 'middle',
		backgroundColor: '#7e94a5',
		borderRadius: 3,
		border: '1px solid #728a9d',
		color: '#FFF',
		padding: '8px 12px',
		fontSize: 13,
		fontWeight: 400,
		lineHeight: '16px',
		cursor: 'pointer',
	},

	a: {
		textDecoration: 'none',
		color: '#005394',
		cursor: 'pointer',

		'&:visited': {
			color: '#005394'
		}
	},

	linkIcon: {
		width: '2em'
	}

};
