import { BaseTheme, BaseThemeType } from 'Shared/frontend/BaseTheme';
import { merge } from 'Shared/frontend/merge';
import { DeepPartial } from 'Shared/frontend/ThemeProvider';


export class TheDockTheme extends BaseTheme
{

	palette()
	{
		return {
			background: 'black',
			text: '#E2E2E2',
			feature: '#FCB03B',

			/* Eg the mobile nav colour */
			dimBackground: '#1C1513',

			food: '#B42111',
			drinks: '#FCB03B',
		};
	}

	fonts()
	{
		return {
			text: '"Noto Serif", serif',
			heading: '"Kiwi Maru", serif',
			buttons: '"Kiwi Maru", serif',
			desktopNav: '"Kiwi Maru", serif',
			mobileNav: '"Kiwi Maru", serif',
			subNav: '"Noto Serif", serif',
		};
	}

	page()
	{
		return merge(super.page(), {
			heading: {
				color: this.palette().feature
			},
			section: {
				heading: {
					color: this.palette().feature
				}
			},
			button: {
				backgroundColor: this.palette().dimBackground,
				borderRadius: '0.4em'
			},
		});
	}

	settings()
	{
		const base = super.settings();
		const palette = this.palette();

		const settings = merge(base, <DeepPartial<BaseThemeType>>{

	//XXX NB same as The Townie...
			footer: {
				divider: {
					borderColor: palette.feature
				},

				title: {
					color: palette.feature
				},
			},

			poster: {
				dateBar: {
					color: palette.dimBackground
				},

				photoPoster: {
					inlineName: {
						color: palette.feature
					}
				},

				detailsButton: {
					textColor: palette.feature
				}
			},

			event: {
				artist: {
					heading: {
						color: palette.feature,
						fontFamily: 'sans-serif'
					},
					wide: {
						nameAndTime: {
							color: palette.feature
						}
					},
					narrow: {
						nameAndTime: {
							backgroundColor: palette.feature
						}
					}, 
					genresAndLocation: {
						color: palette.feature
					} 
				}
			},

	//TODO share most of this with the townie. eg 'ColouredDarkTheme' parent
			menu: {
				subNav: {
					button: {
						borderRadius: '0.4em',
						color: palette.background,
						lineHeight: '16px'
					},
					buttonType: type => ({ backgroundColor: `${menuNavButtonColour(palette,type)} !important` })
				},
				section: {
					heading: {
						textAlign: 'center',
					},
					headingType: (level:number,section) => ({ color: `${menuItemTitleColour(palette,section)} !important`}),
				},
				item: {
					title: {
						fontWeight: 'bold !important',
					},
					titleType: section => ({color: `${menuItemTitleColour(palette,section)} !important`}),
				}
			},

			subNavButton: {
				borderRadius: '0.4em'
			},
		});

		// XXX if we want to entirely remove or overwrite a base setting or two then do so here 
		return settings;
	}
};

function menuItemTitleColour(palette,props)
{
	if (props.productType == 'food')
		return palette.food;
	else
		return palette.feature;
}

function menuNavButtonColour(palette,type:string)
{
	switch(type) {
		case 'food': return palette.food;
		case 'drinks': return palette.drinks;
	}
	return 'inherit';
}

