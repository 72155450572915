import { css } from '@emotion/css';
import { ExclamationCircle } from 'Shared/backend/icons/ExclamationCircle';
import { TickCircle } from 'Shared/backend/icons/TickCircle';
import { palette } from 'Shared/backend/Theme';
import { theme } from 'Shared/backend/ThemeProvider';
import { IconPane } from 'Shared/forms/IconPane';
import { ArtistAgreement } from 'Shared/frontend/eventPage/ArtistAgreementPage';
import { usePage } from 'Shared/frontend/PageProvider';
import { createSignal, Match, Show, Switch } from 'solid-js';

const greenLinkStyle = () => css(theme().a, {
	color: palette.active
});

const draftEmailStyle = () => css({
	width: 'fit-content',
	border: '2px dashed #728a9d',
	padding: '1em',
	fontStyle: 'italic'
});

const offerHeadingStyle = () => css({
    fontSize: '1.1em',
	fontWeight: 'normal',
    padding: 6,
    margin: '1.5em 0 1em 0',
    background: '#1b2126',
    color: 'white',
    width: '100%',
});


export function Offer(props)
{
	const [show,setShow] = createSignal(false);

//		<div class='statusAndSection shaded'>

	return (<>
		<h4 class={offerHeadingStyle()}>OFFER BOOKING TO ARTIST</h4>

		<IconPane>
			<Switch>
				<Match when={props.artist.bookingStatus == 'draft'}>
					<ExclamationCircle />
				</Match>
				<Match when={true}>
					<TickCircle />
				</Match>
			</Switch>
			
			<div>
				<a class={css(theme().a)} onClick={() => setShow(!show())}>
					1. Review agreement here
				</a>

				<Show when={show()}>
					<ArtistAgreement {...props} />
				</Show>

				<br/>
				<br/>

				<div>2. Copy the the <i>booking offer</i> message below</div>

				<br />

{/* 
	TODO ben pl get copyToClipboard working to collect the specific artists performance agreement page 
<button type="button" onclick="page.widgets.get('copyToClipboard').copy('emailText')">1. Click here to copy the booking offer</button>
*/}

{/* XXX temp hack until copy button is working */}

				<div class={draftEmailStyle()}>
{/*
TODO benadd venueName and Artibelow
*/}

					The venue.name is pleased to offer {props.artist.name} a gig.
					To confirm your booking please 

{/*
TODO ben url below needs to start with venue's frontend domain, CJ hacked in townhall domain to work for Sally testing 
*/}

					<a href={`${usePage().site.hostUrl}/event/${props.event.slug}/artist`} class={greenLinkStyle()} target='_blank'>
						follow instructions on this link 
					</a> 
				</div>
				<br />

				<div>
					3. Paste message into your email
					<br/>
					<br/>
					4. Send to Artist Contact
					<br/>
					<br/>
					5. Change booking status below from 'draft' to 'offered'
				</div>
			</div>
		</IconPane>
	</>);
}
