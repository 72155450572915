import { DeleteMessage2 } from 'Common/Messages';
import { ArtistForm, verticalStyle } from 'Shared/backend/eventPage/artist/ArtistForm';
import { IEventProps } from 'Shared/backend/eventPage/EventPage'
import { ImageSelector } from 'Shared/backend/eventPage/ImageSelector';
import { Lineup } from 'Shared/backend/eventPage/Lineup';
import { Name } from 'Shared/backend/eventPage/Name';
import { PreviewTab } from 'Shared/backend/eventPage/PreviewTab';
import { PriceOptions } from 'Shared/backend/eventPage/PriceOptions';
import { TaglineAndMoreInfo } from 'Shared/backend/eventPage/TaglineAndMoreInfo';
import { Type } from 'Shared/backend/eventPage/Type';
import { showTimesStatusIcon } from 'Shared/backend/eventPage/Utils';
import { IconColour } from 'Shared/backend/IconColour'
import { TickCircle } from 'Shared/backend/icons/TickCircle'
import { CalendarEntry } from 'Shared/forms/CalendarEntry';
import { createSignal, Show } from 'solid-js';
import { Id } from 'Common/Id';
import { DeleteButton } from 'Shared/forms/DeleteButton';
import { Event } from 'Shared/view/backend/Event';
import { StatusBar } from 'Shared/backend/eventPage/StatusBar';
import { ContentAndPreviewPanes } from 'Shared/backend/ContentAndPreviewPanes';
import { Overlay } from 'Shared/forms/Overlay';
import { IconPane } from 'Shared/forms/IconPane';
import { css } from '@emotion/css';
import { theme } from 'Shared/backend/ThemeProvider';

//FIXME not actually sticking. Do we really event want it for specials?

/* TODO cf implementing a <EntryPreviewAndStatus> to share with event pages and menu items.
    Event uses a single shared page scrollbar and sticky poster (but non-sticky page preview).
    Menu page is a bit different. Maybe discuss with Chris about desirability of 
    settling on a single approach (or not). 
*/

export function DetailsTab(props: IEventProps)
{
	const event = () => props.store;

	return (
		<div class={css(theme().detailsPane)}>
			<ContentAndPreviewPanes {...props}>
				<div>
					<Type {...props} />

					<Details {...props} />	

					<DeleteButton label='Delete event' delete={() => deleteItem(props.page,event()._id)} />
				</div>

				<PreviewTab {...props} />
			</ContentAndPreviewPanes>

			<div style='clear:both' />

			<StatusBar {...props} />
		</div>
	);
}

function Details(props)
{
	const [currentAct,setCurrentAct] = createSignal(undefined as number|undefined);
	const event = () => props.store;

	return (<>
		<h4>Date</h4>
		<IconPane>
			{showTimesStatusIcon(event().times)}
			<CalendarEntry {...props} location={['times']} />
		</IconPane>	
				
		<Show when={event().eventType == 'music'}>
			<h4>Lineup</h4>

			<Lineup {...props} lineup={event().lineup} currentAct={currentAct} setCurrentAct={setCurrentAct} />	

			<ActOverlay {...props} 
				event={props.store}
				{...(currentAct()==undefined ? {} : event().lineup[currentAct()])} 
				location={['lineup',currentAct()]} 
				currentAct={currentAct()} 
				setCurrentAct={setCurrentAct} 
			/>
		</Show>

		<h4>Poster</h4>

		<IconPane>
			<TickCircle fill={event().imageType=='auto' || event().image?.hash ? IconColour.active : IconColour.inactive} />
			<ImageSelector {...props} />
		</IconPane>
		
		<h4>Event info</h4>

		<div class={verticalStyle()}>
			<Name {...props} />
			<TaglineAndMoreInfo {...props} />
			<PriceOptions {...props} />
		</div>
	</>);
}

function ActOverlay(props)
{
	return (
		<Show when={props.currentAct != undefined}>
			<Overlay close={() => props.setCurrentAct(undefined)}>
				<ArtistForm {...props} />
			</Overlay>
		</Show>
	);
}

async function deleteItem(page,docId:Id)
{
	if (!confirm('Delete this event?'))
		return;

	const msg = new DeleteMessage2(Event.pageName,'edit',docId);
    await page.server.sendOperation(msg);

	window.pageJs('/admin/events');
}

