
import {css} from '@emotion/css';
import AjaxConnectionToServer from 'Browser/AjaxConnectionToServer';
import Assert from 'Common/Assert';
import {LoginMessage, RegisterMessage} from 'Common/Messages';
import {FacebookAuthButton} from 'Shared/artists/FacebookAuthButton';
import {GoogleAuthButton} from 'Shared/artists/GoogleAuthButton';
import {Show, createSignal} from 'solid-js';
import {renderPage} from 'Browser/pages/SolidPageWrapper';
import {Banner, utilityPageStyle} from 'Shared/users/UtilityPageTheme';
import { entryUrl } from 'Common/Urls';

const style = () => css({
	maxWidth: 450,
	margin: '0 auto',
	padding: '2em',

	form: {	
		gridTemplateColumns: '100%'
	},

	button: {
		width: '100%',
		marginBottom: '1em'
	},

	input: {
		width: '100%'
	},

	'.forgotPasswordLink': {
		textAlign: 'right',
		color: '#888'
	},

	h2: {
		color: '#222'
	}
});

const labelStyle = () => css({
	margin: '6px 0',
	fontWeight: 'bold',
	fontSize: 12,
	whiteSpace: 'nowrap',
	color: '#666',
	fontFamily: 'inherit',
	cursor: 'pointer',

	padding: 0  //TODO remove/look at again after removing Less
});

const submitButtonStyle = () => css({
	borderRadius: '0.4em',
	padding: '0.44em 2.25em',
	backgroundColor: 'green',
	color: 'white',
	borderWidth: 0,
	boxShadow: 'none',
	marginTop: '1em',
	whiteSpace: 'nowrap',
	fontSize: 'inherit',

	'&:focus': {
		outline: `1px dotted white`,
		outlineOffset: -4
	},

	'&:hover': {
		filter: 'brightness(95%)'
	}
});

const orLineStyle = () => css({
	position: 'relative',
	height: 21,
	color: 'black',

	'div:first-child': {
		position: 'absolute', 
		width: '100%',
		top: 0, 
		left: 0, 
	},

	hr: {
		border: 0,
		color: '#9fa6ad',
		backgroundColor: '#9fa6ad',
		height: 1,
		marginBottom: 20,
		marginTop: 10
	},

	'div:last-child': {
		position: 'absolute', 
		top: 0, 
		left: 0, 
		right: 0,

		div: {
			width: '2.5em',
			margin: '0 auto', 
			textAlign: 'center',
			zIndex: 1,
			backgroundColor: 'white'
		}
	}
});

const inputStyle = () => css({
	width: '100%',
	font: '15px Arial, Helvetica, sans-serif',
	borderTop: 'none',
	borderRight: 'none',
	borderLeft: 'none',
	borderImage: 'initial',
	borderBottom: '1px solid rgb(239, 239, 239)',
	padding: '5px 10px 5px 6px',
	transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
	backgroundColor: 'rgb(232, 240, 254)'
});

const verticalStyle = () => css({
	display: 'flex',
	flexDirection: 'column',
	gap: '1.2em'
});

const aStyle = () => css({
	textDecoration: 'none',
	color: '#005394'
});

const forgotStyle = () => css({
	display: 'flex',
	flexDirection: 'row-reverse'
});

const errorStyle = () => css({
	color: 'red'
});


export function AuthenticationForm(props)
{
	const [errors,setErrors] = createSignal({});

	return (
		<section class={style()}>
			<h2>Log in using</h2>

			<GoogleAuthButton label='Google' action={() => location.href='/login/google'} />
			<FacebookAuthButton label='Facebook' action={() => location.href='/login/facebook'} />

			<div class={orLineStyle()}>
				<div><hr/></div>
				<div><div>or</div></div>
			</div>

			<div class={verticalStyle()}>	
				<div>
					<label class={labelStyle()} for='email'>Email</label>

					<Show when={errors().email}>
						<div class={errorStyle()}>{errors().email}</div>
					</Show>

					<input class={inputStyle()} id='email' type='email' name='email' value='' autocomplete='off' placeholder='you@example.org' />
				</div>

				<div>
					<label class={labelStyle()} for='password'>Password</label>

					<Show when={errors().password}>
						<div class={errorStyle()}>{errors().password}</div>
					</Show>

					<input class={inputStyle()} id='password' type='password' name='password' autocomplete='off' placeholder='********'/>
				</div>

				<div>
					<button class={submitButtonStyle()} onClick={() => logIn(setErrors)}>Log In</button>
					<div class={forgotStyle()}>
						<a class={aStyle()} href='/forgotPasswordGetAddress.html'>Forgot?</a>
					</div>
				</div>
			</div>
		</section>
	);
}

export function RegistrationForm(props)
{
	const [errors,setErrors] = createSignal({});

	return (
		<section class={style()}>
			<h2>Or register using</h2>

			<GoogleAuthButton label='Google' action={() => location.href='/register/google'} />
			<FacebookAuthButton label='Facebook' action={() => location.href='/register/facebook'} />

			<div class={orLineStyle()}>
				<div><hr/></div>
				<div><div>or</div></div>
			</div>

			<div>
				<label class={labelStyle()} for='firstName'>First name&#42;</label>

				<Show when={errors().firstName}>
					<div class={errorStyle()}>{errors().firstName}</div>
				</Show>

				<input class={inputStyle()} id='firstName' type='text' name='firstname' value='' />
			</div>

			<div>
				<label class={labelStyle()} for='lastName'>Last name&#42;</label>

				<Show when={errors().lastName}>
					<div class={errorStyle()}>{errors().lastName}</div>
				</Show>

				<input class={inputStyle()} id='lastName' type='text' name='lastname' value='' />
			</div>

			<div>
				<label class={labelStyle()} for='regoEmail'>Email&#42;</label>

				<Show when={errors().regoEmail}>
					<div class={errorStyle()}>{errors().regoEmail}</div>
				</Show>

				<input class={inputStyle()} id='regoEmail' type='email' name='email' value='' autocomplete='off' placeholder='you@example.org' />
			</div>

			<button class={submitButtonStyle()} onClick={() => submitRegistration(setErrors)}>
				Register
			</button>
		</section>
	);
}

async function logIn(setErrors)
{
	const userName = Assert.htmlInputElement(document.getElementById('email')).value.trim();
	const password = Assert.htmlInputElement(document.getElementById('password')).value.trim();

	const server = new AjaxConnectionToServer();

	const ret = await server.sendOperation(new LoginMessage(userName,password));

	if (ret.loggedIn)
		document.location = entryUrl(window.site)
	else
		setErrors({[ret.field]: ret.message});
}

async function submitRegistration(setErrors)
{
	const firstName = Assert.htmlInputElement(document.getElementById('firstName')).value.trim();
	const lastName = Assert.htmlInputElement(document.getElementById('lastName')).value.trim();
	const userName = Assert.htmlInputElement(document.getElementById('regoEmail')).value.trim();

	const server = new AjaxConnectionToServer();

	const ret = await server.sendOperation(new RegisterMessage(firstName,lastName,userName));

	if (ret.errors)
		setErrors(ret.errors);
	else
		renderPage('Sent email',RegoEmailSent,{email:userName});
}


/* --- Registration sent message: --- */

const tickStyle = () => css({
	color: 'green',
	fontSize: '7em'
});

function RegoEmailSent(props)
{
	return (
		<div class={utilityPageStyle()}>
			<Banner />

			<div class={tickStyle()}>&#10004</div>

			<p>
				A registration email has been sent to {props.email}.  You will need to click on the link
				in it to complete your registration.
			</p>
			<p>
				If you don&rsquo;t see this email in your inbox within 15 minutes, 
				look for it in your junk mail folder. If you find it there, 
				please mark it as &ldquo;Not Junk&rdquo;. 
			</p>
		</div>
	);
}

