import { IEventProps } from 'Shared/backend/eventPage/EventPage';
import { artistNames } from 'Shared/backend/eventPage/Utils';
import { Text } from 'Shared/forms/Inputs';
import { Wrap } from 'Shared/forms/Wrap';
import { Match,Switch } from 'solid-js';


export function Name(eventProps:IEventProps)
{
	const event = () => eventProps.store;

	const showAlert = () => {
		if (event().eventType=='music') 
			return event().name==undefined && (event().lineup ?? []).length == 0;
		else 
			return event().name==undefined;
	};

	return (
		<Switch>
			<Match when={event().eventType=='music'}>
				<Wrap label='Event Name' notes='You can type above to replace the VOS generated name' alert={showAlert()}>
					<Text {...eventProps} field='name' placeholder={artistNames(event()?.lineup ?? [])} />
				</Wrap>
			</Match>
			<Match when={true}>
				<Wrap label='Event Name' alert={showAlert()} >
					<Text {...eventProps} field='name' />
				</Wrap>
			</Match>
		</Switch>

	);
}

