import {ArtistUrls} from 'Common/ArtistUrls';
import {usePage} from 'Shared/frontend/PageProvider';
import {activeStyle, ArtistProps, searchLinkStyle, sectionHeadingStyle, sectionStyle, verticalStyle} from 'Shared/artists/ProfilePage';
import {DocumentCircle} from 'Shared/backend/icons/DocumentCircle';
import {IconColour} from 'Shared/backend/IconColour';
import {LinkOpen} from 'Shared/backend/icons/LinkOpen';
import {Html, Text} from 'Shared/forms/Inputs';
import {noteStyle, Wrap} from 'Shared/forms/Wrap';
import {Show,createSignal} from 'solid-js';
import { IconPane } from 'Shared/forms/IconPane';
import { innerSearchLinkStyle, linkIcon2Style } from 'Shared/artists/PressShot';
import { css, cx } from '@emotion/css';

export function Biography(artist:ArtistProps)
{
	const maxTaglineLength = 100;

	let taglineNode:HTMLInputElement;

	const urls = new ArtistUrls(usePage().build);

	const [taglineLength,setTaglineLength] = createSignal(artist.tagline?.length ?? 0);

	return (
		<section class={sectionStyle()}>
			<h4 class={sectionHeadingStyle()}>Biography</h4>

			<IconPane>
				<DocumentCircle fill={artist.tagline || artist.biography ? IconColour.active : IconColour.inactive} />

				<div class={verticalStyle()}>
					<Show when={artist.facebook || artist.website}>
						<label classList={{
							[searchLinkStyle()]: true,
							[css({gap:'0.4em'})]: true
						}}>
							Search for info on your&nbsp;

							<Show when={artist.facebook}>
								<a class={cx(innerSearchLinkStyle(),activeStyle())} href={`https://www.facebook.com/${artist.facebook}`} target='_blank'>
									Facebook page
									<LinkOpen class={linkIcon2Style()} fill={IconColour.active} />
								</a>
							</Show>

							<Show when={artist.facebook && artist.website}>
								 or 
							</Show>

							<Show when={artist.website}>
								<a class={cx(innerSearchLinkStyle(),activeStyle())} href={artist.website} target='_blank'>
									website 
									<LinkOpen class={linkIcon2Style()} fill={IconColour.active} />
								</a>
							</Show>
						</label>
					</Show>
			
					<div>
						<Wrap label='Short description' instructionsAfterLabel='Imagine this is the only thing people will read about the artist'>
							<Text {...artist} field='tagline' ref={taglineNode!}
								onInput={value => {
									if (taglineNode.value!.length > maxTaglineLength)
										taglineNode.value = taglineNode.value.slice(0,maxTaglineLength)
									else
										setTaglineLength(value.length)
								} } 
							/>
						</Wrap>

						<div class={noteStyle()}>
							{maxTaglineLength - taglineLength()}/{maxTaglineLength} characters left
						</div>
					</div>

					<Wrap label='More artist info' instructionsAfterLabel='Displayed after "Short Description" so do not repeat that'>
						<Html {...artist} field='biography' urls={urls} options={{
							height: 200,
							menubar: false,
							plugins: ['link'],
							toolbar: 'undo redo | link',
							statusbar: true,
							resize: true 
						}} />
					</Wrap>
				</div>
			</IconPane>
		</section>
	);
}

