import { css } from '@emotion/css'
import { DateTime } from 'luxon'
import { otherFacebookHandles } from 'Shared/backend/eventPage/Handles'
import { VideoCircle } from 'Shared/backend/icons/VideoCircle'
import { Overlay } from 'Shared/forms/Overlay'
import { FacebookCircle } from 'Shared/frontend/icons/FacebookCircle'
import { createMemo, createSignal, For, Match, Show, Switch } from 'solid-js'
import { IconPane } from 'Shared/forms/IconPane'
import { DoneCheckbox } from 'Shared/backend/eventPage/DoneCheckbox'
import { theme } from 'Shared/backend/ThemeProvider'
import { draftPostStyle, overlayContentStyle, PostPane } from 'Shared/backend/eventPage/tasks/PostPane'
import { titleWithMarginStyle } from 'Shared/backend/eventPage/Plan'
import { IconColour } from 'Shared/backend/IconColour'


const postMessageAndDoneStyle = () => css({
	display: 'flex',
	flexWrap: 'wrap',
	alignItems: 'center',
	gap: '1em'
});

const videoOuterStyle = () => css({
	position: 'relative',
	width: '100%',
	height: 0,
	paddingTop: '56.25%'
});

const videoMiddleStyle = () => css({
	position: 'absolute',
	width: '100%',
	height: '100%',
	left: 0,
	top: 0,
	padding: '0 0 0 0'
});

const iframeStyle = () => css({
	width: '100%',
	height: '100%',
	backgroundSize: 'cover',
	backgroundPosition: 'center center',
	border: 'none',
	overflow: 'hidden'
});


export function FacebookVideos(props)
{
	const lineup = () => props.store.lineup ?? [];

	const fbVideoExists = createMemo(() => {
		let ret = false;
		for (const artist of lineup()) 
			ret = ret || artist.facebookVideoUrl!=null;
		return ret;
	});

	const announced = createMemo(() => {
		let ret = true;
		for (const artist of lineup()) 
			if (artist.facebookVideoUrl)
				ret = ret && artist.announceFacebookVideo;
		return ret;
	});

	const [overlay,setOverlay] = createSignal();

	return (<>
		<IconPane>
			<VideoCircle fill={announced() ? IconColour.active : IconColour.red} />

			<Switch>
				<Match when={!fbVideoExists()}>
					<div class={titleWithMarginStyle()}>No artist videos</div>

					Perhaps ask them to add a Facebook Video to their artist profile at 
					<a class={css(theme().a)} href='https://profile.ontoitmedia.com/'>profile.ontoitmedia.com</a>{' '} 
					so you can copy the artists content into your videos.
				</Match>
				<Match when={true}>
					<div class={titleWithMarginStyle()}>Post artist videos to Facebook</div>

					<For each={lineup()}>{(artist,i) => 
						<Show when={artist.facebookVideoUrl}>
							<div class={postMessageAndDoneStyle()}>
								<a class={css(theme().a)} href='#' onClick={() => setOverlay(i())}>
									<i class='fas fa-caret-right'/> Post {artist.name}'s video to Facebook
								</a>

								<DoneCheckbox {...props} location={[...props.location,'lineup',i()]} field='announceFacebookVideo' />
							</div>
						</Show>
					}</For>
				</Match>
			</Switch>
		</IconPane>

		<Show when={overlay()!==undefined}>
			<Overlay close={() => setOverlay()}>
				<div class={overlayContentStyle()}>
					<Video {...props} artist={lineup()[overlay()]} index={overlay()} />
				</div>
			</Overlay>
		</Show>
	</>);
}

function Video(props)
{
	const [postText,setPostText] = createSignal();

	return (
		<PostPane icon={FacebookCircle} title={props.artist.name+' video draft post'} postText={postText()}>
			<Post {...props} setPostText={setPostText} 
				instruction='Then paste into Facebook, where you can edit the text before posting.'
			/>
		</PostPane>
	);
}

function Post(props)
{
	const event = () => props.store;

	/* <p></p> draw users attention to events link as we don't want them clicking video link by mistake */
	return (
		<div>		
			<div class={draftPostStyle()} ref={props.setPostText}>
				<p>
					Great video below from {props.artist.facebook ? '@'+props.artist.facebook : props.artist.name}.
					See live {DateTime.fromISO(event().times.startSingle).toFormat('ccc d LLL')+' '} 
					with { otherFacebookHandles(event().lineup,props.index)+' '}
					at {props.venue.name}.
				</p>
				<p>
					{ props.artist.facebookVideoUrl }
				</p>
			</div>
				
			<div class={videoOuterStyle()}>
				<div class={videoMiddleStyle()}>
					<iframe class={iframeStyle()}
						src={`https://www.facebook.com/plugins/video.php?href=${props.artist.facebookVideoUrl}%2F&show_text=false&width=100&amp;height=60&amp;t=0`}
						allow='autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share'
						allowfullscreen={true} 
					/>
				</div>
			</div>
		</div>			
	);
}

