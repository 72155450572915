
import {css, injectGlobal} from '@emotion/css';
import {EditComponent} from 'Common/components/EditComponent';
import {PageProvider} from 'Shared/frontend/PageProvider';
import {BackendWrap} from 'Shared/backend/BackendWrap';
import {MenuData} from 'Shared/view/backend/MenuDesigner';
import {For,Show,createMemo} from 'solid-js';
import {createStore} from 'solid-js/store';
import { theme, ThemeProvider } from 'Shared/backend/ThemeProvider';
import { Theme } from 'Shared/backend/Theme';
import { standardRules } from 'Shared/common/Sanitise';

const menuStyle = () => css({
	marginBottom: 20,
	display: 'grid',
	//TODO improve the sizing.
	//XXX  consider joining the columns with dots eg:    Lagers ......... 8 items
	
	gridTemplateColumns: '300px 200px',
});

const numberOfItemsStyle = () => css({
	fontStyle: 'italic',
	marginLeft: 20
});


export type MenusProps = MenuDoc & { 
	setStore: (...args: any[]) => void,
	editArtist: EditComponent<MenuData,MenuDoc>
};


export function MenusPage(props)
{
	injectGlobal([standardRules,{body:Theme.body}]);

	const [menuStore,setMenuStore] = createStore(props.menus);

//XXX maybe remove/reuse some of the repeater properties and/or component properties
//TODO right justify menu structures

	return (
		<ThemeProvider theme={Theme}>
			<PageProvider page={props.page}>
				<BackendWrap>
					<p>
						<a href='/admin/menu-designer' class={css(theme().a)}>Go to menu designer</a>
					</p>

					<ul>
						<For each={menuStore}>{(menu,index) => 
							<Menu title={menu.title}
								slug={menu.slug}
								sections={menu.sections}
								items={menu.items} 
								url={`/admin/menus/${menu.slug}`}
							/>
						}</For>
					</ul>
				</BackendWrap>
			</PageProvider>
		</ThemeProvider>
	);
}

/* TODO Share some code between Menu and Section */

function Menu(props)
{
	//XXX Can't use this in the <For each> below as the indexes dont match...
	const sectionsExist = createMemo(() => (props.sections ?? []).findIndex(i => i.widget == undefined) >= 0);

	return (<>
		<div class='headingBar'>
			<h2>Name of menu: {props.title}</h2>
		</div>

		<h4><a href={`/admin/listMenu/${props.slug}`} class={css(theme().a)}>VIEW IN A SINGLE LIST</a></h4>

		<h4>OR VIEW BY SECTION:</h4>

		<div class={menuStyle()}>
			<div style={`padding-left: 30px`}>
				{sectionsExist() ? <a class={css(theme().a)} href={props.url}> (unclassified)</a> : ''}
			</div>

			<div class={numberOfItemsStyle()}>
				<a class={css(theme().a)} href={props.url}>
					{
						'('+(props.items?.length ?? 0)
						+ (props.items?.length==1 ? ' item' : ' items')
						+ ')'
					}
				</a>
			</div>

			<Show when={sectionsExist()}>
				<For each={props.sections ?? []}>
					{(section,index) => 
						<Show when={section.widget == undefined}>
							<Sections {...section} level={1} 
								sections={section.sections} items={section.items} title={section.title} 
								url={createUrl(`/admin/menus/${props.slug}`,section.title ?? '',index())}
							/>
						</Show>
					}
				</For>
			</Show>
		</div>
	</>);
}

function Sections(props)
{
	return (<>
		<div style={`padding-left:${props.level * 30}px`}>
			<a class={css(theme().a)} href={props.url}>
				{props.title}
			</a>
		</div>

		<div class={numberOfItemsStyle()}>
			<a class={css(theme().a)} href={props.url}>
				{
					'('+(props.items?.length ?? 0)
					+ (props.sections?.length>0 ? ' unclassified' : '')
					+ (props.items?.length==1 ? ' item' : ' items')
					+ ')'
				}
			</a>
		</div>

		<Show when={props.sections?.length > 0}>
			<For each={props.sections}>
				{(section,index) => 
					<Sections {...section} level={props.level + 1} sections={section.sections} items={section.items} title={section.title} 
						url={createUrl(props.url,section.title ?? '',index())}
					/>
				}
			</For>
		</Show>
	</>);
}


/* The slugs are for human consumption only. The index is used to locate sections */
function createUrl(prefix:string,title:string,index:number)
{
	const slug = title.replace(/\W/g,'').toLowerCase();
	return `${prefix}/${slug}-${index}`;
}

