import { ClockCircle } from 'Shared/backend/icons/ClockCircle';
import { IconColour } from 'Shared/backend/IconColour';
import {  Match, Switch } from 'solid-js';
import { DateTime } from 'luxon';
import { ISet } from 'Shared/backend/eventPage/artist/ArtistForm';
import { usePage } from 'Shared/frontend/PageProvider';
import { IEventProps } from 'Shared/backend/eventPage/EventPage';
import { addItem, StoreRepeater} from 'Shared/forms/StoreRepeater';
import { css } from '@emotion/css';
import { theme } from 'Shared/backend/ThemeProvider';

const barStyle = () => css({
	display: 'flex',
	alignItems: 'center'
});

export function Lineup(props:IEventProps & {currentAct:()=>number|undefined, setCurrentAct:(index:number|undefined)=>void})
{
	const page = usePage();

	const addAct = async () => {
		//XXX should really set the default values on enabling the agreement
		await addItem({...props,field:'lineup'},page,{bookingStatus:'draft',termsType:'standard'});
		props.setCurrentAct(props.lineup.length - 1);
	};

	return (<>
		<repeater-pane>
			<StoreRepeater
				{...props} 
				field='lineup'
				renderTitle={i => <ActTitle {...i} /> }
				current={props.currentAct}
				setCurrent={props.setCurrentAct}
			>
				{() => <></>}
			</StoreRepeater>
		</repeater-pane>

		<button class={css(theme().button)} onClick={addAct}><i class='fas fa-plus'/> Add act</button>
	</>);
}

function ActTitle(props:ISet)
{
	return (
		<div class={barStyle()}>
			<Switch>
				<Match when={props.setTime}>
					{ DateTime.fromISO(props.setTime).toFormat('hh:mm a') }
				</Match>
				<Match when={true}>
					<ClockCircle fill={IconColour.alert} class={css({width:'1.6em'})} /> &nbsp;
				</Match>
			</Switch>

			{' '}
			{props.name ?? <x-warning>Add artist name or delete</x-warning>}
		</div>
	);
}

