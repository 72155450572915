import {css} from '@emotion/css';
import {createSortable, transformStyle} from '@thisbeyond/solid-dnd';
import { PageFunctions } from 'Browser/PageFunctions';
import { VenueUrls } from 'Common/VenueUrls';
import { usePage } from 'Shared/frontend/PageProvider';
import {stripZeros} from 'Shared/backend/menu/Price'
import {StatusIcons} from 'Shared/backend/menu/StatusIcons'
import { MenuItem } from 'Shared/model/Menu';
import {Match,Switch,For,Show} from 'solid-js'
import {subHeaderBackground } from 'Shared/backend/Theme';

const activeUnderlayStyle = () => css({
	opacity: 0.25,

	'.fa-ellipsis-v': {
		cursor: 'grabbing'
	}
});

export const itemStyle = (imagesEnabled:boolean) => css({
	display: 'grid',
	gridTemplateColumns: '1em 2fr 1fr 0.5fr '+(imagesEnabled ? '70px' : '')+' 2em',  
	gridTemplateAreas: '"grabber name prices tags '+ (imagesEnabled ? 'gridImage' : '') + ' status"',
	gridTemplateRows: 'auto', 
	padding: '0.5em',
	borderTop: subHeaderBackground,
	gridGap: '0 1em',
	alignItems: 'center',
	width: '100%',
	minHeight: '4em',

	'.fa-ellipsis-v': {
		color: '#999',
		cursor: 'grab'
	},

	'.thumbnailPoster': {
		maxHeight: 70
	},

	a: {
		display: 'block',
		/* Make the name easy to click, even if its small */
		width: '100%',
		color: 'unset'
	}
});

const thumbnailStyle = () => css({
	maxHeight: '100%',
	maxWidth: '100%'
});

const gridImageStyle = () => css({
	gridArea: 'gridImage',
	display: 'flex',
	height: '100%',
	alignItems: 'center',
	margin: '0 auto'
});


export type SectionIds = {index:number,name:string}[];

export interface ISectionItem {
	item: MenuItem,
	url: string,
	reorderingId: string,
	itemStyle: string
}

export function SectionItem(props: ISectionItem)
{
	const imagesEnabled = usePage().data.venue.settings.pages?.menu?.itemType != 'imageless';

	const sortable = createSortable(props.reorderingId,props.item as any);

	const imageUrl = () => {
		if (!props.item.image?.hash)
			return '';

		const url = (new PageFunctions()).createImageUrl2(
			new VenueUrls(usePage().build,usePage().site.key),
			props.item.image.hash,
			props.item.image,
			'MenuItem-image',
			'tiny'
		);
		return url;
	};

	return (
		<div
			ref={sortable.ref}
			class={`
				${sortable.isActiveDraggable ? activeUnderlayStyle() : ''}
				${props.itemStyle}
			`}
      		style={transformStyle(sortable.transform)}
		>
			<i class='fas fa-ellipsis-v' {...sortable.dragActivators}></i> 
			<div class='name'>
				<a href={props.url}>
					<Switch>
						<Match when={props.item.brandName && props.item.name}>
							{ props.item.brandName }
							{' '}
							{ props.item.name }
						</Match>
						<Match when={props.item.name}>
							{ props.item.name }
						</Match>
						<Match when={true}>
							<x-warning>
								Add name (or delete if not needed)
							</x-warning>
						</Match>
					</Switch>
				</a>
			</div>

			<div class='prices'>
				<div class='priceWrap'>
					<For each={props.item.prices}>
						{(unit,index) => {
							if (unit.price != undefined) 
								return (
									<div class='priceInner'>
										<span class='unit'>{capitalise(unit.size)} {capitalise(unit.description)} </span> 
										<span class='price'>${stripZeros(unit.price)}</span> 
									</div>
								);
						}}
					</For>
				</div>
			</div>

			<div class='tags'>{(props.item.tags ?? []).join(' ')}</div>

			<Show when={imagesEnabled}>
				<div class={gridImageStyle()}>
					<a href={props.url}>
						<img class={thumbnailStyle()} src={imageUrl()} />
					</a>
				</div>
			</Show>

			<div class='status'>
				<a href={props.url}>
					<StatusIcons status={props.item.status} />
				</a>
			</div>
		</div>
	);
}

function capitalise(s:string|undefined) 
{
	const s2 = s ?? '';
	return s2.charAt(0).toUpperCase() + s2.slice(1);
}

