import { css } from '@emotion/css';
import { actImageUrl, commonGenresStyle } from 'Shared/frontend/eventPosters/MusicPoster';
import { genreList } from 'Shared/common/Macros';
import { palette } from 'Shared/frontend/ThemeProvider';


const posterStyle = () => css({
	margin: '24px 0 0 0',
	display: 'grid',
	gridTemplateColumns: '50% 50%'
});

const artistNameStyle = () => css({
	color: palette().feature,
	fontSize: '0.8em'
});

const artistDetailsStyle = (alignLeft:boolean) => css({
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: alignLeft ? 'flex-start' : 'flex-end',
	margin: '0.2em 0.5em'
});

const pressShotStyle = () => css({
	maxWidth: '100%'
});

const genresStyle = () => css(commonGenresStyle(), {
	fontSize: '0.6em',
	lineHeight: '1.5em'
});


export function ThreeActs(props)
{
	return (
		<div class={posterStyle()}>
			<Artist {...props.lineup[0]} />
			<AltArtist {...props.lineup[1]} />
			<Artist {...props.lineup[2]} />
		</div>
	);
}

function Artist(props)
{
	return (<>
		<img class={pressShotStyle()} src={actImageUrl(props.image,props.proxyProductionAssets ?? false)} />
		<ArtistDescription {...props} alignLeft={true} />
	</>);
}

function AltArtist(props)
{
	return (<>
		<ArtistDescription {...props} alignLeft={false} />
		<img class={pressShotStyle()} src={actImageUrl(props.image,props.proxyProductionAssets ?? false)} />
	</>);
}

function ArtistDescription(props)
{
	return (
		<div class={artistDetailsStyle(props.alignLeft)}>
			<div class={artistNameStyle()}>{props.name}</div>
			<div class={genresStyle()}>{ genreList(props) }</div>
		</div> 
	);
}

