import FileTypes from 'Browser/FileTypes';
import Assert from 'Common/Assert'
import {BuildBasics} from 'Common/BuildBasics';
import {FullLink, ILinks, PartialLink, Urls, hostUrl} from 'Common/Urls';


export class VenueUrls extends Urls
{
	constructor(
		build:BuildBasics,
		private siteKey:string
	) 
	{ 
		super(build);
	}

	/* Some test systems use a mix of dev images and production images (readonly). */
	imageUrl(assetFolder:string,hash:string,size:string,fileType:string,useProduction:boolean,proxyProductionAssets:boolean)
	{
		let source = `/_assets`;

		if (this.build.key=='DEV') {
			source = `/_devAssets`;
			if (useProduction)
				source = proxyProductionAssets ? `/_proxyAssets` : `https://dummy.ontoit.xyz/_assets`;
		}
		else if (!useProduction)
			/* Should be in a build or live test system here, not in prodution */
			source = `/_devAssets`;

		return source+'/'+this.siteKey+'/'+assetFolder+'/'+hash+'-'+size+'.'+FileTypes.suffix(fileType);
	}

	backendUrls(): ILinks
	{
//XXX giving them keys may be useful if we want to be able to manipulate things in NJK
		const jsLinks:any = [
//			{partial:'index.js',id:'indexJsScriptId'}
		];

//TODO would be nice if these were only loaded when actually needed
		const cssLinks = [
			{partial:'resources/fontawesome/css/all.min.css'},
			{partial:'resources/flatpickr.min.css'},
			{partial:'resources/nano.min.css'},
			{partial:'resources/cropper.min.css'},
			{partial:'resources/tom-select.min.css'},
			{partial:'resources/awesomplete.css'},
		];

		const jsUrls = [
				{url: this.buildUrl('backend.js')},
				/* Required for posters: */
				...this.resolveSharedUrls(jsLinks)
			];

		const cssUrls = [
				{url: this.buildUrl('backend.css')},
				...this.resolveSharedUrls(cssLinks),
			];

		const prefixes = {
			/* These will be versioned by build, but not within a build */
			resources: this.buildUrl('resources')
		};

		return { js: jsUrls, css: cssUrls, prefixes:prefixes };
	}

	frontendUrls(): ILinks
	{
		const jsLinks:any = [
		];

		const cssLinks = [
			{partial:'resources/fontawesome/css/all.min.css'},
		];

//XXX MAY be useful to give urls keys instead, eg...
//		{	config: this.buildUrl(...), } 

		const jsUrls = [
				...this.resolveSharedUrls(jsLinks)
			];

		const cssUrls = [
				...this.resolveSharedUrls(cssLinks),
			];

		const prefixes = {
			/* These will be versioned by build, but not within a build */
			resources: this.buildUrl('resources')
		};

		return { js: jsUrls, css: cssUrls, prefixes:prefixes };
	}

	navDemoUrls():ILinks
	{
		const jsLinks:any = [
//			{partial:'index.js',id:'indexJsScriptId'}
		];

		const cssLinks:PartialLink[] = [
	//		{partial:'resources/fontawesome/css/all.min.css'},
		];

		const jsUrls = [
			{url: this.buildUrl('navDemos/templates.js')},
			...this.resolveSharedUrls(jsLinks)
		];

		const cssUrls = [
			{url: this.buildUrl('navDemos/all.css')},
			...this.resolveSharedUrls(cssLinks),
		];

		const prefixes = {
//			resources: this.buildUrl('resources')
		};

		return {js:jsUrls,css:cssUrls,prefixes:prefixes};
	}

	private resolveSharedUrls(links:PartialLink[]):FullLink[]
	{
		const fullLinks:FullLink[] = [];
		for (const link of links) {
			const obj:FullLink = { url:this.buildUrl(Assert.toString(link.partial)) };
			if ('id' in link)
				obj.id = link.id;
			fullLinks.push(obj);
		}
		return fullLinks;
	}
}

