import { css, injectGlobal } from '@emotion/css';
import { PageProvider } from 'Shared/frontend/PageProvider';
import { BackendWrap } from 'Shared/backend/BackendWrap';
import { DetailsTab } from 'Shared/backend/eventPage/DetailsTab';
import { artistNames } from 'Shared/backend/eventPage/Utils';
import { LinkOpen } from 'Shared/backend/icons/LinkOpen';
import { EventDoc } from 'Shared/model/Event';
import { Show, mergeProps } from 'solid-js';
import { SetStoreFunction, createStore } from 'solid-js/store';
import { EventData } from 'Shared/view/backend/Event';
import { Plan } from 'Shared/backend/eventPage/Plan';
import { Type } from 'Shared/backend/eventPage/Type';
import { Id } from 'Common/Id';
import { TabPanes } from 'Shared/backend/Tabs';
import { Breadcrumbs } from 'Shared/backend/Breadcrumbs';
import { Theme } from 'Shared/backend/Theme';
import { theme, ThemeProvider } from 'Shared/backend/ThemeProvider';
import { standardRules } from 'Shared/common/Sanitise';


const topLine = () => css({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	marginBottom: '1em'
});


export interface IEventProps {
	docId: Id;
	store: EventDoc;
	setStore: SetStoreFunction<EventDoc>;
	location: Location;
	permission: string;
}


export function EventPage(props: EventData)
{		
	injectGlobal([standardRules,{body:Theme.body}]);

	const [event,setEvent] = createStore(props.event);

	const eventProps:IEventProps = mergeProps({
		page: props.page,
		venue: props.venue,
		docId:event._id,
		store:event,
		setStore:setEvent,
		location:[],
		permission:'edit'
	});

/* FIXME look at...
	{# 
		XXX Use in page preview... needs to be customised for each site
		TODO put in header rather than body
	#}
	<link href="https://fonts.googleapis.com/css?family=Questrial&display=swap" rel=stylesheet> 
*/	

	const tabs = {
		'details': {label:'Details', component:DetailsTab},
		'marketing': {label:'Marketing', component:Plan}
	};

	const crumb = () => {
		if (event.name)
			return event.name;
		else if (event.eventType == 'music' && event.lineup?.length > 0)
			return artistNames(event.lineup);
		else
			return 'New event';
	};

	return (
		<ThemeProvider theme={Theme}>
			<PageProvider page={props.page}>
				<BackendWrap>
					<div class={topLine()}>
						<Breadcrumbs items={{
							Events: '/admin/events',
							[crumb()]: null
						}} />

						<Show when={eventProps.store.status=='published'}>
							<a href={`/event/${event.slug}`} class='linkInline' target='_blank'>
								<LinkOpen class={css(theme().linkIcon)} />
							</a>
						</Show>
					 </div>

					<Show when={event.eventType} fallback={<Type {...eventProps} />}>
						<TabPanes tabs={tabs} initial={props.params.topTab} tabProps={eventProps} clearBorder={true}
							onChange={tab => window.pageJs(props.url+'/'+tab)}
						/>
					</Show>
				</BackendWrap>
			</PageProvider>
		</ThemeProvider>
	);
}

