import { z } from 'zod';
import {special as specialModel} from 'Shared/model/Specials';
import {DocumentQueries} from 'Common/config/PageConfigTypes';
import * as t from 'Shared/model/basic';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {BackendPageConfig, backendData, backendParams} from 'Common/BackendPageConfig';
import { SpecialsPage } from 'Shared/backend/specials/SpecialsPage';

export const specialsParams = backendParams.extend({ }).strict();	
export type SpecialsParams = z.infer<typeof specialsParams>;


export const specialsData = backendData.extend({
	specials: z.array(specialModel),

	/* Components: */
	createSpecial: z.object({
		id: t.id
	})
}).strict();
export type SpecialsData = z.infer<typeof specialsData>;


export class Specials extends BackendPageConfig<SpecialsData,SpecialsParams>
{
	static readonly pageName = 'backend/specials';
	name() { return Specials.pageName; }

	access() { return 'venueMenuEditor' as AccessAreaName; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			template: SpecialsPage
		};
	}

	documents(params:SpecialsParams)
	{
		return <DocumentQueries> ({
			...super.documents(params),
			specials: {
				type: 'array',
				collection: 'special'
			}
		});
	}

	permissions()
	{
		return ({
			edit: { collection: 'special' }
		});
	}
}

