import {PageFunctions} from 'Browser/PageFunctions';
import {BuildBasics} from 'Common/BuildBasics';
import {DocumentQueries} from 'Common/config/PageConfigTypes';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {VenueBasics} from 'Common/VenueBasics';
import {venueData, VenuePageConfig, venueParams} from 'Common/VenuePageConfig';
import {VenueUrls} from 'Common/VenueUrls';
import {image} from 'Shared/model/basic';
import { z } from 'zod';
import {Title} from 'Shared/backend/Title';
import {HeadInsert} from 'Shared/backend/HeadInsert';
import {PageSettingsType} from 'Shared/view/VenueSettingsType';

export const backendParams = venueParams.extend({ }).strict();
export type BackendParams = z.infer<typeof backendParams>;


export const backendData = venueData.extend({
	params: backendParams,
	htmlClasses: z.string(),
	banner: z.object({
		logo: image
	})
}).strict();
export type BackendData = z.infer<typeof backendData>;


export abstract class BackendPageConfig<PD extends BackendData,PP extends BackendParams> 
	extends VenuePageConfig<PD,PP>
{
	protected htmlClasses: string|undefined;

	constructor(inBrowser:boolean,build:BuildBasics,venue?:VenueBasics) 
	{ 
		super(inBrowser,build,venue);

		/* Not used for operations, only page generation */
		this.htmlClasses = `backend ${venue?.document?.settings?.frontend?.venueClass ?? ""}`;
	}

	access() { return 'venueBareEditor' as AccessAreaName; }

	defaultSettings()
	{
		return <PageSettingsType>{
			titleTemplate: Title,
			headTemplate: HeadInsert
		};
	}

//TODO add permissions() here	

	includes() { return (new VenueUrls(this.build,this.venue.key)).backendUrls(); }

	documents(params:PP): DocumentQueries 
	{
		return ({
			/* 'venue' is now retrieved for all venue sites and added in VenuePageConfig from VenueBasics */
		});
	}

//TODO can probably delete after going to Solid
	beforeDisplay(data:PD)
	{
		const funcs = new PageFunctions();
		const urls = new VenueUrls(this.build,this.venue.key);

		if (typeof data.venue.logo != 'undefined')
			data.venue.logo.url = funcs.createImageUrl(urls,data.venue.logo,'Venue-logo','full');
	}
}

