import { IconColour } from 'Shared/backend/IconColour';
import { css } from '@emotion/css';
import { Dynamic, Show } from 'solid-js/web';
import { theme } from 'Shared/backend/ThemeProvider';
import { copyToClipboard } from 'Shared/backend/CopyToClipboard';

//XXX maybe put this in Plan or Overlay?
export const overlayContentStyle = () => css({
	width: 'fit-content',
	backgroundColor: 'white',
	margin: 'auto',
	padding: '1em 1em'
});


//XXX cf remove export?
export const titleBarStyle = () => css({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	padding: '1em 0',
});

const iconStyle = () => css({
	marginRight: '0.5em',
	width: '2em'
});

const titleStyle = () => css({
	color: 'black',
	fontSize: '1.6em',
	fontWeight: 'bold',
	textTransform: 'uppercase',
});

//XXX cf remove export?
export const draftPostStyle = () => css({
	color: 'grey',
	background: 'white',
	border: '1px solid #4267B2',
	borderRadius: 5,
	padding: '1em',
	margin: '0.5em 0',
	maxWidth: 700,
	overflowX: 'hidden'
});

const copyButtonLineStyle = () => css({
	display: 'flex',
	alignItems: 'center',
	flexWrap: 'wrap',
	columnGap: '0.5em',
	rowGap: '0.2em'
});


export function PostPane(props)
{
	return (
		<div class={overlayContentStyle()}>
			<div class={titleBarStyle()}>
				<Dynamic component={props.icon} fill={IconColour.black} class={iconStyle()} />
				<div class={titleStyle()}>{props.title}</div>
			</div>

			<Show when={!props.hideCopyButton}>
				<div class={copyButtonLineStyle()}>
					<button class={css(theme().button)} onClick={() => copyToClipboard(props.postText)}>Copy Post</button>
				
					<Show when={props.instruction}>
						{props.instruction}
					</Show>
				</div>
			</Show>

			<div class={draftPostStyle()}>
				{props.children}
			</div>

			<Show when={props.instructions}>
				{props.instructions}
			</Show>
		</div>
	);
}

