import { Match, Switch } from 'solid-js';
import { Poster } from 'Shared/frontend/Poster';
import { PhotoPoster, PhotoPosterDimensions } from 'Shared/frontend/eventPosters/PhotoPoster';
import { SuppliedPoster } from 'Shared/frontend/eventPosters/SuppliedPoster';
import { ImageSchema } from 'Shared/model/basic';

interface ISpecialsPoster {
	tagline?: string;
	moreInfo?: string;
	imageType: 'photoPoster' | 'suppliedPoster';
	image: ImageSchema;
	imageDimensions: PhotoPosterDimensions;
	proxyProductionAssets?: boolean;
}


export function SpecialsPoster(props: ISpecialsPoster)
{
	const showDetails = () => {
		const overLimit = (props.tagline?.length ?? 0) > 57;
		return overLimit || props.moreInfo;
	}

//FIXME photo = photoPoster
//FIXME poster = suppliedPoster

	return (
		<Poster {...props} showDetails={showDetails()}>
			<Switch>
				<Match when={props.imageType == 'photoPoster' || props.imageType == 'photo'}>
					<PhotoPoster {...props} folder='Special-image' size='medium' />
				</Match>
				<Match when={props.imageType == 'suppliedPoster' || props.imageType == 'poster'}>
					<SuppliedPoster {...props} folder='Special-image' size='medium' />
				</Match>
			</Switch>
		</Poster>
	);
}

