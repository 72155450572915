import { ImageUploadButtons, ImageUploadDragAndDrop } from 'Shared/forms/ImageUpload';
import { SingleSelect} from 'Shared/forms/Inputs';
import { Wrap } from 'Shared/forms/Wrap';
import { createSignal, Match, mergeProps, Switch } from 'solid-js';
import { usePage } from 'Shared/frontend/PageProvider';
import { VenueUrls } from 'Common/VenueUrls';
import { reconcile } from 'solid-js/store';
import { css } from '@emotion/css';

const layoutStyle = () => css({
	display: 'flex',
	flexDirection: 'column',
	gap: '1em'
});


export function ImageSelector(props:ISpecial) 
{
	const setImage = value => props.setStore(...props.location,'image',reconcile(value));
	const [buttonNode,setButtonNode] = createSignal();

//TODO eventPoster (in ImageSizes.ts) ==> poster?	
//TODO probably move this field image into 'image'. See events and menus for reference
	const dimensions = () => props.store.imageType=='poster' ? 'eventPoster' : props.store.imageDimensions;

	/* mergeProps() is not in a tracking scope. Have to separate out 'image' and 'dimensions'. */
	const imageProps = mergeProps(props,{
		location: [...props.location,'image'],
		permission: 'image',
		setImage: setImage,
		page: usePage(),
		urls: new VenueUrls(usePage().build,usePage().site.key),
		node: buttonNode,
		setNode: setButtonNode,
	});

//TODO? possibly reuse this imageDimensions stuff. Share with menus, specials, ...

	return (
		<ImageUploadDragAndDrop {...imageProps} dimensions={dimensions()} image={props.store.image}>
			<div class={layoutStyle()}>
				<SingleSelect {...props} field='imageType' placeholder='Select Poster Type' required={true}
					options={ {
						poster: 'Poster containing info is uploaded',
						photo: 'Image is uploaded and VOS overlays special info'
					} } 
				/>

				<Switch>
					<Match when={props.store.imageType == 'photo'}>
						<Wrap label='Dimensions'>
							<SingleSelect {...props} field='imageDimensions' required={true}
								options={ {
									landscape:		'▬ Landscape',
									portrait4x5:	'▋ Portrait 4x5 ratio',
									square:			'■ Square'
								} } 
							/>
						</Wrap>

						<ImageUploadButtons {...imageProps} dimensions={dimensions()} image={props.store.image}/>
					</Match>
					<Match when={props.store.imageType == 'poster'}>	
						<ImageUploadButtons {...imageProps} dimensions={dimensions()} image={props.store.image}/>
					</Match>
				</Switch>
			</div>
		</ImageUploadDragAndDrop>
	);
}	

