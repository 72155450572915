import { BackendWrap } from 'Shared/backend/BackendWrap';
import { capitalise } from 'Shared/common/Capitalise';
import { DateAsLabel } from 'Shared/frontend/eventPage/Times';
import { formatDateTime } from 'Shared/common/Macros';
import { PageProvider } from 'Shared/frontend/PageProvider';
import { Match, Show, Switch } from 'solid-js';

export function ArtistAgreementPage(props: EventData)
{		
/*	
	const [event,setEvent] = createStore(props.event);

	const eventProps:IEventProps = mergeProps({
		page: props.page,
		venue: props.venue,
		docId:event._id,
		store:event,
		setStore:setEvent,
		location:[],
		permission:'edit'
	});
*/	

	return (
		<PageProvider page={props.page}>
			<BackendWrap>
				<ArtistAgreement {...props} />
			</BackendWrap>
		</PageProvider>
	);
}

export function ArtistAgreement(props)
{
	return (
		<div class='agreement'>
			<div />

			<section class='header'>
				<img class='logo' src='https://images.squarespace-cdn.com/content/v1/611c57a0d298ea3b78c2d832/d0876d07-9a17-4db7-94bf-a4b72d93aeae/Untitled-6.png?format=1500w' alt='L' />
				<div class='subHeading'>Performance Agreement</div>
				<div class='heading'>{props.artist.name} at {props.venue.name}</div>
			</section>

			<section class='introduction'>
				<p class='larger'>
					This Agreement is made between <b>{props.venue.name} [The Venue]</b> and <b>{props.artist.name} [The Artist]</b>.
				</p>

				<p>All Performers must read this Agreement and understand that:</p>   
				<ul>
					<li>
						The term <b>Artist</b> means all individual Performers in the act.
						<br/><br/>
					</li>
					<li >
						<b>Venue Contact</b> means the relevant contact person listed in the Worksheet Section (eg if the Worksheet lists a contact 
						for sound/production, the Artist contacts them for technical matters).
						<br/><br/>
					</li>
					<li>
						The terms <b>&lsquo;required&rsquo;</b> and &lsquo;<b>must&rsquo; </b>mean that it is expected that the Artist will do the 
						specified task, and the Artist has broken the Agreement if the task is not done.
						<br/><br/>
					</li>
					<li>
						<b>&lsquo;May&rsquo;</b> or &lsquo;<b>is encouraged&rsquo;</b> means the task is optional and the Agreement is not broken if not done.
					</li> 
				</ul>

				 <p>Other definitions, tasks and conditions are placed in relevant sections throughout the Agreement.</p>
			</section>

			<section class='performance'>
				<div class='subHeading'>Performance</div>
				<div class='gridTable'>
					<div class='row'>
						<div class='gridLabel'>Artist:</div>
						<div class='gridData'>{props.artist.name}</div>
					</div>

					<Show when={props.event.name}>
						<div class='row'>
							<div class='gridLabel'>Advertised event name:</div>
							<div class='gridData'>{props.event.name}</div>
						</div>
					</Show>

					<div class='row'>
						<div class='gridLabel'>Event date:</div>
{/*
TODO CJ create macro in shared/backend/timesInLists.njk that displays just the DATE for Event Date 
Then create just  Start Time, And just End Time (or venue close) to place in a runsheet section if multiple acts
*/}
						<div class='gridData'>
							<DateAsLabel {...props.times} />
						</div>
					</div>
					<div class='row'>
						<div class='gridLabel'>Performance start:</div>
						<div class='gridData'>
							<Show when={props.artist.setTime}>
								<Switch>
									<Match when={formatDateTime(props.artist.setTime,'mm') == '00'}>
										{formatDateTime(props.artist.setTime,'ha')}
									</Match>
									<Match when={true}>
										{formatDateTime(props.artist.setTime,'h:mma')}
									</Match>
								</Switch>
							</Show>
						</div>
					</div>
					<div class='row'>
						<div class='gridLabel'>Performance duration:</div>
						<div class='gridData'>{props.artist.performanceDuration}</div>
					</div>
					<div class='row'>
						<div class='gridLabel'>Booking Contact:</div>
						<div class='gridData'>
					 	  {/* TODO CJ urgent get contact info in */}
						</div>
					</div>
				</div>

			   {/*
			   		TODO cj urgent get 'placeholder' to appear here if there's no a.data.[field]_Custom for ALL _Custom fields below 
			   		OR instead of using placeholder write templates into 'initial' in fields to they're always data in the field 
				*/}
				   
				<Show when={props.artist.performanceTerms_Custom}>
					<div>PERFORMANCE TERMS</div>

				   {props.artist.performanceTerms_Custom} 
				</Show>
			</section>

			<section class='payment'>
				<div class='subHeading'>Payment</div>
				<div class='gridTable'>
					<div class='row'>
						<div class='gridLabel'>Performance Fee Type:</div>
						<div class='gridData'>
							<Switch>
								<Match when={props.artist.feeType == 'other'}>
								 	{props.artist.feeTypeOther}
								</Match>
								<Match when={true}>
									{capitalise(props.artist.feeType)}
								</Match>
							</Switch>
						</div>
					</div>

					<Show when={props.artist.feeDetails}>
						<div class='row'>
							<div class='gridLabel'>Fee Details:</div>
							<div class='gridData'>{props.artist.feeDetails}</div>
						</div>
					</Show>
				</div>

				<Show when={props.artist.paymentTerms_Custom}>
					<div>PAYMENT TERMS</div>
					{props.artist.paymentTerms_Custom}
				</Show>
			</section>
					
			<section class='worksheet'>
				<div class='subHeading'>Worksheet</div>
				<div class='gridTable'>
					<div class='row'>
						<div class='gridLabel'>Soundcheck:</div>
						<div class='gridData'>
							<Show when={props.artist.soundCheck} fallback='TBA'>
								{props.artist.soundCheck}
							</Show>
						</div>
					</div>

{/* 
TODO CJ low priority create runsheet macro in shared/backend/timesInLists.njk 
that displays just the DATE and just the Start Time, And End Time (or venue close)
so booking can specify event times in runsheet
*/}
					<div class='row'>
						<div class='gridLabel'>Load-in info:</div>
						<div class='gridData'>
							<Show when={props.artist.loadInOut_Template} fallback='TBA'>
								{props.artist.loadInOut_Template}
							</Show>
						</div>
					</div>

					<Show when={props.artist.techContact}> 
						<div class='row'>
							{/* TODO create engineer contact */}
							<div class='gridLabel'>Venue Contact:</div>
							<div class='gridData'>
								{props.artist.techContact}
							</div>
						</div>
					</Show>
				</div>

				<Show when={props.artist.worksheetTerms_Custom}>
					 <div>WORKSHEET TERMS</div>
					{props.artist.worksheetTerms_Custom}
				</Show>
			</section>

			<Show when={props.artist.otherTerms_Custom}>
				<section class='otherTerms_Custom'>
					<div class='subHeading'>Other Terms & Conditions</div>
					{props.artist.otherTerms_Custom}
				</section>
			</Show>

			 <section class='acceptAgreementInstructions'>
				<div class='subHeading'>To secure this Booking</div>
				<p>
					Please <a href=''>register your <b>Onto It Media Profile</b> here</a>. It's a brilliant platform that's free for performers,
					and we use it to gather your marketing content to promote you across our website, social channels, gig guides and google.
				</p>
			</section>

{/*
 TODO Ben discuss with CJ potential workflow for artists to register and secure booking 
1. when clicking link can we copy the url of the agreement and launch the profile register/login page with that url in the string.
2.When they register (or login to existing account) it passes the agreement page into their account as a view in.
*/}


{/*
		CJs failed attempt retrieve artist url  ben im running some js to retrieve a current pages url using this code but when i try to render the result into the {% set artistUrl %}
		it displays the code (<div id="url"></div> ) not the actual url i successfully retieved with the JS.

		Any tips to fix, it's on 
			<script> 
				function GetURL() { 
					var gfg = document.URL; 
					document.getElementById("url").innerHTML = gfg; 
				} 
				window.onload = GetURL;
			</script> 

		  <div id="url"></div> 

		  {% set artistURL %}

		<div id="url"></div> 

		  {% endset %}

*/}

			<br/>
		</div>
	);
}

