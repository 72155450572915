
export function Tv(props)
{
	return (
		<svg class={props.class} style={`fill:${props.fill ?? IconColour.text}; stroke:${props.stroke ?? 'inherit'};`} viewBox="0 0 64 64" enable-background="new 0 0 64 64" xmlns="http://www.w3.org/2000/svg">
		<defs></defs>
  <path id="path0" d="M 16.467 4.268 C 15.439 5.296 16.759 7.158 23.318 13.936 L 30.522 21.38 L 19.596 21.38 C 11.253 21.38 8.237 21.561 6.836 22.146 L 5 22.913 L 5 40.611 C 5 57.247 5.061 58.383 6.012 59.558 L 7.023 60.807 L 32.52 60.807 L 58.016 60.807 L 59.054 59.525 C 60.046 58.3 60.086 57.469 59.934 40.924 C 59.739 19.672 60.47 21.38 51.571 21.38 C 48.168 21.38 43.199 21.224 40.527 21.034 L 35.67 20.69 L 42.563 13.679 C 46.988 9.177 49.455 6.27 49.455 5.556 C 49.455 2.971 47.329 4.417 38.775 12.824 L 33.121 18.379 L 25.615 11.147 C 18.537 4.327 17.321 3.413 16.467 4.268 M 53.582 28.478 C 53.483 29.174 53.036 29.582 52.373 29.582 C 51.131 29.582 50.678 28.014 51.599 26.904 C 52.458 25.869 53.803 26.935 53.582 28.478 M 46.381 41.093 C 46.471 49.025 46.426 55.634 46.28 55.779 C 46.134 55.925 37.978 55.972 28.154 55.885 L 10.292 55.726 L 10.292 41.552 C 10.292 33.756 10.451 27.218 10.645 27.025 C 10.839 26.831 18.922 26.672 28.608 26.672 L 46.217 26.672 L 46.381 41.093 M 53.841 32.625 C 54.178 33.556 52.683 35.126 51.787 34.782 C 51.019 34.488 50.773 32.939 51.396 32.317 C 51.99 31.723 53.587 31.925 53.841 32.625" style=""></path>
</svg>
	);
}

