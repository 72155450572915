export const dayLabels = {
	'mon':		'Monday',
	'tue':		'Tuesday',
	'wed':		'Wednesday',
	'thu':		'Thursday',
	'fri':		'Friday',
	'sat':		'Saturday',
	'sun':		'Sunday',
	'holidays':	'Public Holidays',
	'other':	'Other'
};

export type DayLabels = typeof dayLabels;
