import {DocumentQueries} from 'Common/config/PageConfigTypes';
import { z } from 'zod';
import { artistDoc as artistDoc} from 'Shared/model/Artist';
import * as t from 'Shared/model/basic';
import {IServerFunctions} from 'Server/ServerFunctions';
import {ArtistPageConfig, artistData, artistParams} from 'Common/pages/artist/ArtistPageConfig';
import {ProfilePage} from 'Shared/artists/ProfilePage';
import { css } from '@emotion/css';



export const profileParams = artistParams.extend({
	id: t.id
}).strict();	
export type ProfileParams = z.infer<typeof profileParams>;


export const profileData = artistData.extend({
	artist: artistDoc,
}).strict();
export type ProfileData = z.infer<typeof profileData> 



export class Profile extends ArtistPageConfig<ProfileData,ProfileParams>
{
	static readonly pageName = 'artist/profile';
	name() { return Profile.pageName; }

//XXX should this have access()?

	settings()
	{
		return {
			...super.settings(),
			template: ProfilePage
		};
	}

	documents(params:ProfileParams): DocumentQueries
	{
		return ({
//			...bannerQuery(),
			artist: {
				type:'object',
				collection: 'artist',
				aggregate: (funcs:IServerFunctions) => [
					{$match: {_id: funcs.toObjectId(params.id) }}	
				]
			}
		});
//TODO pass in server functions into transforms too, and remove them as PageConfig dependencies... 
	}

	permissions()
	{
		return ({
			edit: {collection:'artist'},
			image: { collection:'artist', assetFolder:'PressShot'},
		});
	}
}

