import { css, cx } from '@emotion/css';
import KeenSlider, {SliderInstance} from 'keen-slider';
import { onCleanup, onMount } from 'solid-js';


const outerStyle = () => css({ });

const navigationWrapperStyle = () => css({
	position: 'relative',
	overflow: 'hidden',
});

const sliderStyle = () => css({
	display: 'flex',
	flexDirection: 'row',
	userSelect: 'none',

	touchAction: 'pan-y',
    WebkitTouchCallout: 'none',
    WebkitTapHighlightColor: 'transparent'
});

const dotsStyle = () => css({
	display: 'flex',
	padding: '10px 0',
	justifyContent: 'center'
});

const dotStyle = () => css({
	border: 'none',
	width: '1em',
	height: '1em',
	background: '#c5c5c5',  //FIXME text?
	borderRadius: '50%',
	margin: '0 5px',
	padding: 5,
	cursor: 'pointer'
});

const activeDotStyle = () => css({
	background: 'gray'
});

function init(anchorNode:HTMLElement,options)
{
	let opts = options ?? {};
	opts = {
		loop:		opts.loop ?? true, 
		navigation:	opts.navigation ?? true,
		auto:		opts.auto ?? true,
		sleep:		opts.sleep ?? 2000, 
		...opts
	};

	return new KeenSlider(anchorNode, opts,
		[
			...(opts.navigation ? [navigation] : []),
			...(opts.auto       ? [(slider:SliderInstance<any,any,any>) => autoSlide(slider,opts.sleep)] : []),
		]
	);
}


/* ----- Plugins. Copied from the Keen examples. ---- */

function navigation(slider:SliderInstance<any,any,any>) 
{
	let wrapper:HTMLElement, dots:HTMLElement;
	let arrowLeft:HTMLElement, arrowRight:HTMLElement;

	function markup(remove:boolean) 
	{
		wrapperMarkup(remove);
		dotMarkup(remove);
		arrowMarkup(remove);
	}

	function removeElement(e:HTMLElement) 
	{
		e?.parentNode?.removeChild(e);
	}

	function createDiv(className:string) 
	{
		const div = document.createElement('div');
		const classNames = className.split(' ');
		classNames.forEach(name => div.classList.add(name));
		return div;
	}

	function arrowMarkup(remove:boolean) 
	{
		if (remove) {
			removeElement(arrowLeft);
			removeElement(arrowRight);
			return;
		}
		arrowLeft = createDiv('arrow arrow--left');
		arrowLeft.addEventListener('click', () => slider.prev());
		arrowRight = createDiv('arrow arrow--right');
		arrowRight.addEventListener('click', () => slider.next());

		wrapper.appendChild(arrowLeft);
		wrapper.appendChild(arrowRight);
	}
		

	function wrapperMarkup(remove:boolean) 
	{
		if (remove) {
			const parent = wrapper.parentNode;
			if (parent)
				while (wrapper.firstChild)
					parent!.insertBefore(wrapper.firstChild, wrapper);
			removeElement(wrapper);
			return;
		}
		wrapper = createDiv(navigationWrapperStyle());
		slider.container.parentNode.appendChild(wrapper);
		wrapper.appendChild(slider.container);
	}

	function dotMarkup(remove:boolean) 
	{
		if (remove) {
			removeElement(dots);
			return;
		}
		dots = createDiv(dotsStyle());
		slider.track.details.slides.forEach((_e:any,idx:any) => {
			const dot = createDiv(dotStyle());
			dot.addEventListener('click', () => slider.moveToIdx(idx));
			dots.appendChild(dot);
		});
		wrapper.appendChild(dots);
	}

	function updateClasses() 
	{
		const activeStyle = activeDotStyle();

		let slide = slider.track.details.rel;
		slide === 0
			? arrowLeft.classList.add('arrow--disabled')
			: arrowLeft.classList.remove('arrow--disabled');
		slide === slider.track.details.slides.length - 1
			? arrowRight.classList.add('arrow--disabled')
			: arrowRight.classList.remove('arrow--disabled');
		Array.from(dots.children).forEach((dot, idx) => {
			idx === slide
				? dot.classList.add(activeStyle)
				: dot.classList.remove(activeStyle);
		});
	}

	slider.on('created', () => {
		markup(false);
		updateClasses();
	});

	slider.on('optionsChanged', () => {
		markup(true);
		markup(false);
		updateClasses();
	});

	slider.on('slideChanged', updateClasses);

	slider.on('destroyed', () => markup(true));
}


function autoSlide(slider:SliderInstance<any,any,any>,sleep:number)
{
	let timeout:NodeJS.Timeout;
	let mouseOver = false;

	function clearNextTimeout() 
	{
		clearTimeout(timeout);
	}

	function nextTimeout() 
	{
		clearTimeout(timeout);
		if (mouseOver) return;
		timeout = setTimeout(() => {
			slider.next();
		}, sleep);
	}

	slider.on('created', () => {
		slider.container.addEventListener('mouseover', () => {
			mouseOver = true;
			clearNextTimeout();
		});
		slider.container.addEventListener('mouseout', () => {
			mouseOver = false;
			nextTimeout();
		});
		nextTimeout();
	})

	slider.on('dragStarted', clearNextTimeout);
	slider.on('animationEnded', nextTimeout);
	slider.on('updated', nextTimeout);
}

export function Slider(props)
{
	let node!:HTMLDivElement;

	onMount(() => {
		const slider = init(node,props.options ?? {});
		onCleanup(() => slider.destroy());
	});

//XXX extra <div> use to prevent parentNode refs from failing...	
	return (
		<div class={outerStyle()}>
			<div ref={node} class={cx('keen-slider',sliderStyle())} >
				{props.children}
			</div>
		</div>
	);
}

export function Slide(props)
{
	return (
		<div class={'keen-slider__slide'}>
			{props.children}
		</div>
	);
}

