import {SocialIcons} from 'Shared/frontend/footer/SocialIcons';
import {For, createSignal} from 'solid-js'
import {Phone} from 'Shared/frontend/icons/Phone';
import {Pin} from 'Shared/frontend/icons/Pin';
import {Show} from 'solid-js';
import { css, cx } from '@emotion/css';

//TODO look at sharing issue wrt leadingIconStyle only. Probably dont need to wrap the SVG
import {displayState} from 'Shared/frontend/footer/BaseFooter';
import { fluidType } from 'Shared/common/FluidType';
import { sizes,palette, theme, pageTheme } from 'Shared/frontend/ThemeProvider';
import { leadingIconStyle } from 'Shared/frontend/leadingIconStyle';

const overlayStyle = () => css({
	/* On Chrome '100vw' creates a horizontal scrollbar when a vertical scrollbar is present */
	width: '100%',

	minHeight: '100vh',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	gap: '1em',
	paddingBottom: 100
});

const logoStyle = () => css({
	width: fluidType('px',sizes().endFontShrink,sizes().flipToMobileNav,100,300),
});

const buttonStyle = () => css({
//TODO add a mobileNavMore.button or mobile.nav.more.button or mobileNav.more.button
	...pageTheme().button,
	textTransform: 'capitalize',  //XXX undoing theme.button

	width: '100%',
	borderStyle: 'none',
	color: '#e2e2e2',
	backgroundColor: 'transparent',
	padding: '0.8em 0',
	fontSize: fluidType('px',sizes().endFontShrink,sizes().flipToMobileNav,20,30),

	':hover': {
		backgroundColor: palette().dimBackground
	}
});

const activeStyle = () => css(theme().nav.mobile.active);	

const itemWithIconStyle = () => css({
	color: palette().text,
	textDecoration: 'none'
});

const addressStyle = () => css({
	...theme().address,
	textAlign: 'center'
});


export function NavMore(props)
{
	return (
		<div class={overlayStyle()}>
			<img class={logoStyle()} src={props.venue.logo.url} /> 

			<nav>
				<Contents nav={props.config.nav.desktop} links={props.config.link} currentPage={props.pageName} venue={props.venue} />
			</nav>

			<ThirdPartyServices links={props.venue.thirdPartyService} venue={props.venue} />
			<PhoneAndAddress venue={props.venue}/>
			<SocialIcons venue={props.venue}/>
		</div>
	);
}

function Contents(props)
{
	const [active,setActive] = createSignal(false);

	return (
		<For each={props.nav}>{ item =>
			<a href={props.links[item.link].target}>
				<button 
					tabindex='-1' 
					class={cx(buttonStyle(), item.link==props.currentPage ? activeStyle() : '')}
					onClick={item.link==props.currentPage && setActive(!active())} 
				>
					{item.label}
				</button>
			</a>
		}</For>
	);
}

function PhoneAndAddress(props)
{
	return (
		<>
			<a class={itemWithIconStyle()} href={'tel:'+props.venue.phoneNumber}>
				<div class={leadingIconStyle()}>
					<Phone />  
				</div>
				{' '}

				<span class={css(theme().a)}>
					{props.venue.phoneNumber}
				</span>
			</a>

			 <a class={itemWithIconStyle()} href={props.venue.mapUrl} target='_blank'>
				<div class={leadingIconStyle()}>
					<Pin /> 
				</div>

				<address class={addressStyle()}>
					{props.venue.streetAddress}<br/>
					{props.venue.suburb+' '}
					{displayState(props.venue.state)+' '}
					{props.venue.postcode}
				</address>
			</a> 

			<Show when={props.venue.postalAddress}>
				<p>Postal Address: {props.venue.postalAddress}</p>
			</Show>
		</>
	);
}

function ThirdPartyServices(props)
{
	return (
		<div class='thirdPartyServices'>
			<For each={props.links}>{ service =>
				<a href={service.url} target='_blank'>
					<button>
						{ service.title != 'other' ? service.title : service.other }
					</button>
				</a>
			}</For>
		</div>
	);
}

