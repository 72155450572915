/*
	Can set a #hex color or the name of color if it's defined in iconsAsMacros.njk
	'default' colors should be set to the most logical and commonly used color for the icon 
	(eg 'red' warning icons),
	or write textColour with no brackets '' if you want to match the theme's textColour.
*/

import {ISvgColours,IconColour} from 'Shared/backend/IconColour'

export function InfoCircle(props:ISvgColours)
{
	return (
		<svg id='info_circle' class='icon' 
			style={`fill:${props.fill ?? IconColour.text}; stroke:${props.stroke ?? '#fff0'};`} 
			viewBox="0 0 64 64" enable-background="new 0 0 64 64" xmlns="http://www.w3.org/2000/svg">
		  <path d="M 32 2 C 15.469 2 2 15.469 2 32 C 2 48.531 15.469 62 32 62 C 48.531 62 62 48.531 62 32 C 62 15.469 48.531 2 32 2 Z M 34.449 42.714 C 34.449 43.939 33.531 45.163 32 45.163 C 30.469 45.163 29.551 44.245 29.551 42.714 L 29.551 30.469 C 29.551 29.245 30.469 28.02 32 28.02 C 33.531 28.02 34.449 28.939 34.449 30.469 L 34.449 42.714 Z M 32 24.347 C 30.469 24.347 28.939 22.816 28.939 21.285 C 28.939 19.755 30.469 18.224 32 18.224 C 33.531 18.224 35.061 19.755 35.061 21.285 C 35.061 22.816 33.531 24.347 32 24.347 Z" fill="" bx:origin="-0.033333 -0.033333"></path>
		</svg>
	);
}
