import {LinkOpen} from 'Shared/backend/icons/LinkOpen';
import {ImageUploadButtons, ImageUploadDragAndDrop} from 'Shared/forms/ImageUpload';
import {SingleSelect} from 'Shared/forms/Inputs';
import { labelStyle, Wrap } from 'Shared/forms/Wrap';
import {mergeProps, Show} from 'solid-js'
import { usePage } from 'Shared/frontend/PageProvider';
import { VenueUrls } from 'Common/VenueUrls';
import { createSignal } from 'solid-js';
import { css } from '@emotion/css';
import { theme } from 'Shared/backend/ThemeProvider';

const uploadButtonsStyle = () => css({
	marginTop: '2em'
});


export function ImageSelector(props:IItemSelector)
{
	const setImage = value => props.setStore(...props.location,'image',value);
	const [buttonNode,setButtonNode] = createSignal();

	//WARNING not a tracking scope, so separated out dimensions and image
	const uploadProps = mergeProps(props,{
		location: [...props.location,'image'],
		permission: 'photo', 
		setImage: setImage,
		page: usePage(), 
		urls: new VenueUrls(usePage().build,usePage().site.key),
		node: buttonNode,
		setNode: setButtonNode
	});

	const searchStyle = () => css(theme().a, {
		display: 'flex',
		alignItems: 'center',
		gap: '1em'
	});


	return (<>
		<h4 class={css(theme().menu.heading)}>Image</h4>

		<div id='builder_sharePoster'>
			<ImageUploadDragAndDrop {...uploadProps} image={props.item.image} dimensions={props.item.imageDimensions}>

				<Show when={props.item.brandName}>
					<a class={searchStyle()}
						target='_blank'
						href={`http://images.google.com/images?um=1&hl=en&safe=active&nfpr=1&q=${props.item.brandName}+${props.item.name}+logo`} 
					>
						<label class={labelStyle()}>
							Search Google for image 
						</label>
						<LinkOpen class={css(theme().linkIcon)} />
					</a>
				</Show>

				<Wrap label='Dimensions' required={false}
					instructionsAfterLabel='Will be cropped to square on your menu, but full dimensions will show on details page'
				>
					<SingleSelect {...props} field='imageDimensions' required={true} 
						options={ {
							landscape:	'▬ Landscape',
							portrait:	'▋ Portrait',
							square:		'■ Square'
						} } 
					/>
				</Wrap>

				<div class={uploadButtonsStyle()}>
					<ImageUploadButtons {...uploadProps} image={props.item.image} dimensions={props.item.imageDimensions}/>
				</div>
			</ImageUploadDragAndDrop>
		</div>
	</>);
}	

