
import {For,Show,Match,Switch,createSignal, createMemo, ErrorBoundary} from 'solid-js';
import {CardItem} from 'Shared/frontend/menuPage/CardItem';
import {ImagelessItem} from 'Shared/frontend/menuPage/ImagelessItem';
import {StandardDefaultLayout} from 'Shared/frontend/layouts/StandardDefaultLayout';
import {Overlay} from 'Shared/frontend/Overlay';
import {locateSubDocument} from 'Common/ViewUtils';
import {jumpToAnchor, SubNav} from 'Shared/frontend/SubNav';
import {Location} from 'Common/config/PageConfigTypes';
import {PageProvider} from 'Shared/frontend/PageProvider';
import { SpecialsOverlayContent } from 'Shared/frontend/specialsPosters/SpecialsOverlayContent';
import { SpecialsPoster } from 'Shared/frontend/specialsPosters/SpecialsPoster';
import { css, injectGlobal } from '@emotion/css';
import { standardRules } from 'Shared/common/Sanitise';
import { pageTheme, palette, sizes, theme, ThemeProvider } from 'Shared/frontend/ThemeProvider';
import { capitalise } from 'Shared/common/Capitalise';
import { posterGridStyle } from 'Shared/frontend/posterGridStyle';
import { OverlayItem } from 'Shared/frontend/menuPage/OverlayItem';
import { fluidType } from 'Shared/common/FluidType';
import { FullWidthStyle } from 'Shared/frontend/FullWidthStyle';

const topContentStyle = () => css({
	margin: '1em auto 2em auto',
	padding: '0 1em',
	textAlign: 'center',
	width: '100%',
	maxWidth: 1250
});	

const pageHeadingStyle = () => css(theme().menu.heading,{
	textAlign: 'center'
});

const stickyStyle = () => css({
	position: 'sticky',
	top: '0',
	zIndex: '10',
});

const sectionStyle = () => css({
	marginTop: '1em',
	marginBottom: '3em'
});

const sectionHeadingStyle = (level:number,section) => css(
	theme().menu.section.heading,
	theme().menu.section.headingType(level,section),
);

//XXX it may make sense to separate the grid -- much of MenuPage? -- into separate Standard and Imageless files in time.

const imagelessGridStyle = () => css({});

const standardGridStyle = () => css({
	maxWidth: '100%',
	display: 'flex',
	flexWrap: 'wrap',
	justifyContent: 'center',
	gap: '1.5em',
	alignItems: 'stretch',

	['@media screen and (min-width: 745px)']: {
		margin: '0 20px',
	},

	['@media screen and (max-width: 745px)']: {
		margin: '40px auto'
	}
});

const linkWrapStyle = () => css(theme().a,{
	display: 'block',

	['@media screen and (max-width: 745px)']: {
		width: '100%',
	},

	/* The cards look a little wide on narrow desktop pages. Larger margins help a bit. */
//XXX NB only applies to menus with images
	['@media screen and (min-width:600px) and (max-width: 745px)']: {
		marginLeft: fluidType('px',500,745,14,38),
		marginRight: fluidType('px',500,745,14,38)
	}
});

const buttonRowStyle = () => css({
	display: 'flex',
	justifyContent: 'center'
});

//const buttonRowButtonStyle = () => css(theme().widgets.subNav.button, {
const buttonRowButtonStyle = () => css(pageTheme().button, {
	textTransform:'capitalize'
});

const subNavButtonStyle = type => css(
	theme().menu.subNav.button,
	theme().menu.subNav.buttonType(type),
	{
		[`@media (max-width: ${sizes().flipToMobileNav}px)`]: {
			paddingLeft: '1em',
			paddingRight: '1em',
			marginLeft: '0.25em',
			marginRight: '0.25em',
		}
	}
);


export const specialItemOverlayStyle = () => css({
	display: 'block',
	maxWidth: '450px',
	minHeight: 'auto',
	backgroundColor: palette().background
});

const aStyle = () => css(theme().a);

const detailsStyle = () => css({
	textAlign: 'center'
});


export function MenuPage(props)
{
	injectGlobal([standardRules,{body:props.theme.body}]);

	return (
		<ThemeProvider theme={props.theme}>
			<PageProvider page={props.page}>
				<StandardDefaultLayout {...props} >
					<MenuPageContents {...props} />
				</StandardDefaultLayout>
			</PageProvider>
		</ThemeProvider>
	);
}

//TODO separate grid out into StandardMenu and ImagelessMenu	

function MenuPageContents(props)
{
	const [currentLocation,setCurrentLocation] = createSignal();
	const [currentSpecial,setCurrentSpecial] = createSignal();

	const overlayItem = () => locateSubDocument(props.menu,currentLocation());
	const topSection = () => locateSubDocument(props.menu,currentLocation().slice(0,2));

	const itemsExist = createMemo(() => props.menu.items?.find(i => i.status=='published'));

	const style = () => props.settings.pages.menu.itemType;

	return (<>
		<div class='anchor' id='top' />

		<div>
{/* TODO compare this topContentStyle to hire page and home page styling. Remove it? */}
			<div class={topContentStyle()}> 
				<Show when={props.menu.teaser}>
					<div>{props.menu.teaser}</div>
				</Show>

				<Show when={props.menu.title}>
					<h3 class={pageHeadingStyle()}>{props.menu.title}</h3>
				</Show>

				<Show when={props.menu.tagline}>
					<div>{props.menu.tagline}</div>
				</Show>

				<Show when={props.menu.details}>
					<div innerHTML={props.menu.details} />
				</Show>
			</div>

			<Show when={itemsExist()}>
				<div class={style()=='imageless' ? imagelessGridStyle() : standardGridStyle()}>
					<For each={props.menu.items}>{ (item,index) => 
						<Show when={item.status=='published'}>
							<MenuItem 
								settings={props.venue.settings} item={item} menu={props.menu} 
								location={['items',index()]} setCurrentLocation={setCurrentLocation} 
							/>
						</Show>
					}</For>
				</div>
			</Show>

			<Show when={props.menu.sections && props.menu.sections.length > 0}>
				<For each={props.menu.sections}>{ (section,index) => 
					<DisplaySectionOrWidget settings={props.venue.settings} level={2} 
						section={section} 
						menu={props.menu}
						location={['sections',index()]}
						setCurrentLocation={setCurrentLocation} 
						specials={props.specials}
						setCurrentSpecial={setCurrentSpecial} 
					/>
				}</For>
			</Show>
		</div>

		{/* Overlays: */}

		<Show when={currentLocation()}>
			<Overlay iconOverImage={false} animation='slideIn' close={() => setCurrentLocation(undefined)}>
				<OverlayItem
					menu={props.menu}
					location={currentLocation()}
					settings={props.venue.settings}
					item={overlayItem()}
					productType={topSection().productType}
					section={topSection()}
				/>
			</Overlay>
		</Show>

		{/*
			TODO add product type colouring/styling to special overlays? And maybe posters?
		*/}
		<Show when={currentSpecial()}>
			<Overlay iconOverImage={true} animation='slideIn' close={() => setCurrentSpecial(undefined)}>
				<ErrorBoundary fallback={(err) => <div>Problem showing item</div>}> 
					<div class={specialItemOverlayStyle()}>
						<SpecialsOverlayContent special={props.specials[currentSpecial()]} times={props.specials[currentSpecial()].times} />
					</div>
				</ErrorBoundary>  
			</Overlay>
		</Show>
	</>);
}

//TODO compare to MenuPageContents()
function Section(props)
{
	const style = () => props.settings.pages.menu.itemType;

	return (<>
		<div class='anchor' id={menuSelector(props.menu,props.location)} />

		<section class={sectionStyle()}>
			<Show when={props.section.teaser}>
				<div>{props.section.teaser}</div>
			</Show>

			<Show when={props.section.title}>
				<h3 class={sectionHeadingStyle(props.level,props.section)}>{props.section.title}</h3>
			</Show>

			<Show when={props.section.tagline}>
				<div>{props.section.tagline}</div>
			</Show>

			<Show when={props.section.details}>
				<div class={detailsStyle()} innerHTML={props.section.details} />
			</Show>

			<div class={style()=='imageless' ? imagelessGridStyle() : standardGridStyle()}>
				<For each={props.section.items}>{ (item,index) => 
					<Show when={item.status=='published'}>
						<MenuItem {...props}
							location={[...props.location,'items',index()]} 
							setCurrentLocation={props.setCurrentLocation} 
							item={item} 
						/>
					</Show>
				}</For>
			</div>

			<Show when={props.section.sections && props.section.sections.length > 0}>
				<For each={props.section.sections}>{ (subsection,index) => 
					<DisplaySectionOrWidget {...props}
						level={props.level+1} 
						section={subsection} 
						location={[...props.location,'sections',index()]}
						setCurrentLocation={props.setCurrentLocation} 
						setCurrentSpecial={props.setCurrentSpecial} 
					/>
				}</For>
			</Show>
		</section>
	</>);
}

function DisplaySectionOrWidget(props)
{
	return (<>
		{props.section.widget ? 
			<Widget {...props} widget={props.section} />  :  
			<Section {...props} />}
	</>);
}

function MenuItem(props) 
{
	const style = () => props.settings.pages.menu.itemType;
	/* TODO We could be more efficient about calculating this... */
	const productType = () => calculateProductType(props.menu,props.location);
	const id = createMemo(() => menuSelector(props.menu,props.location));

	return (<>
		<Switch>
			<Match when={style()=='standard'}>
				<a class={linkWrapStyle()} href='' onclick={() => props.setCurrentLocation(props.location)} >
					<div id={id()} class='anchor' />
					<CardItem {...props} id={id()} productType={productType()} />
				</a>
			</Match>
			<Match when={style()=='imageless'}>
				<div id={id()} class='anchor' />
				<ImagelessItem {...props} id={id()} productType={productType()} />
			</Match>
		</Switch>
	</>);
}

/* Used by the backend */
export function MenuItemPreview(props) 
{
	const style = () => props.settings.pages.menu.itemType;
	/* TODO We could be more efficient about calculating this... */
	const productType = () => calculateProductType(props.menu,props.location);

	return (<>
		<Switch>
			<Match when={style()=='standard'}>
				<CardItem {...props} id='unused' productType={productType()} />
			</Match>
			<Match when={style()=='imageless'}>
				<ImagelessItem {...props} id='unused' productType={productType()} />
			</Match>
		</Switch>
	</>);
}

function Widget(props)
{
	return (
		<Switch>
			<Match when={props.widget.widget=='buttonRow'}>
				<ButtonsRowWidget {...props} />
			</Match>
			<Match when={props.widget.widget=='specials'}>
				<SpecialsWidget {...props} />
			</Match>
		</Switch>
	);
}

function ButtonsRowWidget(props)
{
//XXX cf 'open in new window' open? ALSO make consistent between the sticky version and the non-sticky

	return (
		<Switch>
			<Match when={props.widget.sticky}>
				<div class={stickyStyle()}>
					<FullWidthStyle>
						<SubNav>
							<For each={props.widget.buttons}>{button =>
								<button class={subNavButtonStyle(button.style)} onClick={() => jumpToAnchor(button.url,-100)}>{button.label}</button>
							}</For>
						</SubNav>
					</FullWidthStyle>
				</div>
			</Match>
			<Match when={true}>
				<div class={buttonRowStyle()}>
					<For each={props.widget.buttons}>{button =>
						<a href={button.url}>
							<button class={buttonRowButtonStyle()}>{button.label}</button>
						</a>
					}</For>
				</div>
			</Match>
		</Switch>
	);
}


function SpecialsWidget(props)
{
//TODO insert title standard way + taglines etc

	return (
		<Show when={props.specials?.length > 0}>
			<div id='specials' />

			<section>
				<h3 class={sectionHeadingStyle(props.level,props.section)}>Specials</h3>
				<div class={posterGridStyle()}>
					<For each={props.specials}>{(special,index) => 
						<a class={aStyle()} href='' onclick={() => props.setCurrentSpecial(index)} >
							<ErrorBoundary fallback={(err) => <div>Problem showing item</div>}>  
								<SpecialsPoster {...special} location={[index]} />
							</ErrorBoundary>	
						</a>
					}</For>
				</div>
			</section>
		</Show>
	);
}

function calculateProductType(store,location:Location)
{
	if (store.productType!=undefined && store.productType!='mixed')
		return store.productType;

	let data = store;
	for (let i=0; i<location.length; i++) {
		data = data[location[i]];

		if (data.productType!=undefined && data.productType!='mixed')
			return data.productType;
	}

	return 'mixed';
}


/* Selectors used for sections, subsections and menu items. */
export function menuSelector(menu,location:Location)
{
	let subdoc = menu;
	let useSection = false;
	let useItems = false;
	let id = '';
	let sep = '';

	let i = 0;
	for (const e of location) {
		subdoc = subdoc[e];

		if (useSection) {
			id += sep + capitalise(subdoc.title ?? ''+i).replace(/\W/g,'');
			sep = '.';
		}
		if (useItems) {
			id += sep + capitalise(subdoc.name ?? ''+i).replace(/\W/g,'');
			sep = '.';
		}

		useSection = e=='sections';
		useItems = e=='items' ;
		i++;
	}
	return id;
}

