
import { css, cx } from '@emotion/css';
import { FullWidthStyle } from 'Shared/frontend/FullWidthStyle';
import { onCleanup, onMount } from 'solid-js';


const parallaxStyle = () => css({
	/* overflowY doesn't appear to work with position:static */
	position: 'relative',
	overflowY: 'hidden',
	marginBottom: -1,
});

const foregroundStyle = () => css({
	zIndex: 1,
	position: 'relative',
	margin: '0.5em 1em',
	textAlign: 'center'
});

const backgroundStyle = () => css({
	position: 'absolute',
	top: 0,
	width: '100%',
	height: '100%',
	backgroundSize: 'cover',
	backgroundPosition: 'center',
	willChange: 'transform'
});

const bannerStyle = () => css({
	width: '100%',
	height: '100vh',
	minHeight: 500
});


function adjustBackground(foregroundNode:HTMLDivElement,backgroundNode:HTMLDivElement,scrollRate:number)
{
	const back = backgroundNode.getBoundingClientRect();

	/* Use this value if it scrolled off the top */
	let offset = '-100%';

	if (window.innerHeight > back.top)
	{
		/* Add extra height to the background image when images < 100vh */
		const extraHeight = (window.innerHeight - back.height) * scrollRate;
		if (extraHeight>=1) {
			const newHeight = back.height + extraHeight;
			backgroundNode.style.height = newHeight+'px';
		}

		/* Reduce the scroll of the background: */
		const fractionUpPage = back.top / back.height; 
		offset = (-fractionUpPage * window.innerHeight * scrollRate) + 'px';
	}

	backgroundNode.style.transform = 'translateY('+offset+')';
}

export function Parallax(props)
{
	let backgroundNode!: HTMLDivElement;
	let foregroundNode!: HTMLDivElement;

//TODO maybe enable/disable using Intersection Observer	

	onMount(() => {
		const handler = e => window.requestAnimationFrame(() => adjustBackground(foregroundNode,backgroundNode,props.scrollRate));
		window.addEventListener('scroll',handler); 
		window.addEventListener('resize',handler);

		onCleanup(() => {
			window.removeEventListener('scroll',handler); 
			window.removeEventListener('resize',handler);
		});

		adjustBackground(foregroundNode,backgroundNode,props.scrollRate);
	});

	return (
		<FullWidthStyle>
			<div class={cx(bannerStyle(),parallaxStyle())}>
				<div ref={foregroundNode} class={foregroundStyle()}>
					{props.children}
				</div>
				<div ref={backgroundNode} class={backgroundStyle()}
					style={`background-image:url('${props.imageUrl}')`}
				/>
			</div>
		</FullWidthStyle>
	);
}

