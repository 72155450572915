import { Show } from 'solid-js';
import {DateTime} from 'luxon';
import { InfoCircle } from 'Shared/backend/icons/InfoCircle';
import { IconColour } from 'Shared/backend/IconColour';
import { Html, SingleSelect, Text } from 'Shared/forms/Inputs';
import { labelStyle, noteStyle, Wrap } from 'Shared/forms/Wrap';
import { ClockCircle } from 'Shared/backend/icons/ClockCircle';
import { PersonCircle } from 'Shared/backend/icons/PersonCircle';
import { DocumentCircle } from 'Shared/backend/icons/DocumentCircle';
import { LineupSet } from 'Shared/model/lineup';
import { DollarCircle } from 'Shared/backend/icons/DollarCircle';
import { TruckCircle } from 'Shared/backend/icons/TruckCircle';
import { VenueUrls } from 'Common/VenueUrls';
import { usePage } from 'Shared/frontend/PageProvider';
import { Offer } from 'Shared/backend/eventPage/artist/Offer';
import { IconPane } from 'Shared/forms/IconPane';
import { css } from '@emotion/css';
import { sectionStyle, verticalStyle } from 'Shared/backend/eventPage/artist/ArtistForm';
import { theme } from 'Shared/backend/ThemeProvider';

const htmlOptions = {
	height: 200,
	menubar: false,
	promotion: false,
	plugins: ['link'],
	statusbar: false,
	toolbar: 'undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent | link '
};


export function Booking(props:LineupSet)
{
	return (<>
		<h4 class={css(theme().section.heading,{marginBottom: '2em'})}>
			Artist: &nbsp;&nbsp; {props.name ?? <x-warning>Add above</x-warning>}
		</h4>

		<div class={verticalStyle()}>
			<div>
				Performance Starts: &nbsp;&nbsp;
				<Show when={props.setTime} fallback={<x-warning>Add above</x-warning>}>
					{ DateTime.fromISO(props.setTime).toFormat('hh:mm a') }
				</Show>
			</div>

			<IconPane>
				<InfoCircle fill={props.performanceDuration ? IconColour.active : (!props.useAgreement ? IconColour.inactive : IconColour.alert) } />

				<Wrap notes='Eg number of sets and length, and other time requirements'
					label={'Performance duration' + (
						!props.performanceDuration && !props.useAgreement ? ' (Optional)' : ''
					)}
				>
					<Text {...props} field='performanceDuration' />
				</Wrap>
			</IconPane>
		</div>

		<FeeStructure {...props} />

		<Worksheet {...props} />

		<TermsAndConditions {...props} />

		<Offer {...props} artist={props} />
	</>);
}

function FeeStructure(props)
{
	return (
		<section class={sectionStyle()}>
			<h4 class={css(theme().section.heading)}>Fee Structure</h4>

			<div class={verticalStyle()}>
				<IconPane>
					<DollarCircle fill={props.feeType ? IconColour.active : IconColour.alert} />

					<div class={verticalStyle()}>
						<Wrap label='Fee Type'>
							<SingleSelect {...props} field='feeType' options={{
								guarantee: 'Venue Guarantee',
								ticketSales: 'Ticket Sales',
								door: 'Percentage of Door',
								other: 'Other (not listed)'
							}} />
						</Wrap>

						<Show when={props.feeType == 'other'}>
							<Wrap label='Other Fee terms'>
								<Text {...props} field='feeTypeOther' />
							</Wrap>
						</Show>
					</div>
				</IconPane>

				<IconPane>
					<InfoCircle fill={props.feeDetails ? IconColour.active : IconColour.alert} />

					<Wrap label='Details' instructionsAfterLabel='ie total $ if guarantee, % if split deal'>
						<Text {...props} field='feeDetails' />
					</Wrap>
				</IconPane>
			</div>
		</section>
	);
}

function Worksheet(props)
{
	const urls = new VenueUrls(usePage().build,usePage().site.key);

	return (
		<section class={sectionStyle()}>
			<h4 class={css(theme().section.heading)}>Worksheet</h4>

			<div class={verticalStyle()}>
				<IconPane>
					<ClockCircle fill={props.soundCheck ? IconColour.active : IconColour.alert} />

					<Wrap label='Sound Check' notes='Eg write a time and tips, or "TBA" if To Be Announced or "NA" if not available'>
						<Text {...props} field='soundCheck' />
					</Wrap>
				</IconPane>

				<IconPane>
					<TruckCircle fill={props.loadInOut_Custom ? IconColour.active : IconColour.inactive} />

					<Wrap label='Load In/Out Instructions'>
						<Html {...props} field='loadInOut_Custom' urls={urls} options={htmlOptions} />
					</Wrap>
				</IconPane>

				<i>To do add Tech Specs here</i>

				<IconPane>
					<PersonCircle fill={props.venueEventContacts_Custom ? IconColour.active : IconColour.inactive} />

					{/* TODO call venue templates into .ts file and inject as default data, not placeholder */}

					<Wrap {...props} label='Venue Contacts'>
						<Html {...props} field='venueEventContacts_Custom' urls={urls} options={htmlOptions} />
					</Wrap>
				</IconPane>
			</div>
		</section>
	);
}

function TermsAndConditions(props)
{
	return (
		<section class={sectionStyle()}>
			<h4 class={css(theme().section.heading)}>Terms and Conditions</h4>

			<div class={verticalStyle()}>
				<IconPane>
					<DocumentCircle fill={props.termsType ? IconColour.active : IconColour.inactive} />

					<SingleSelect {...props} field='termsType' options={{
						standard:'Standard Terms', 
						custom:'Customise Terms'	 
					}} />
				</IconPane>

				<Show when={props.termsType == 'custom'}>
					<CustomTerms {...props} />
				</Show>
			</div>
		</section>
	);
}

function CustomTerms(props)
{
	const urls = new VenueUrls(usePage().build,usePage().site.key);

	return (
		<div class={verticalStyle()}>
			<div>
				<label class={labelStyle()}>
					Your Standard Terms have been pasted into relevant sections below. You can edit them if this booking has special changes.
				</label>
				<div class={noteStyle()}>
					Want to change Terms for all future agreements?
					{' '}
					<a class={css(theme().a)}>Edit your Agreement Template here</a>
				</div>
			</div>
		 
			<Wrap label='Performance Terms'>
				<Html {...props} field='performanceTerms_Custom' urls={urls} options={htmlOptions} />
			</Wrap>

			<Wrap label='Worksheet Terms'>
				<Html {...props} field='worksheetTerms_Custom' urls={urls} options={{...htmlOptions,height:400}} />
			</Wrap>

			<Wrap label='Payment Terms'>
				<Html {...props} field='paymentTerms_Custom' urls={urls} options={htmlOptions} />
			</Wrap>

			<Wrap label='Other Terms'>
				<Html {...props} field='otherTerms_Custom' urls={urls} options={{...htmlOptions,height:600}} />
			</Wrap>
		</div>
	);
}

