import {Text, SingleSelect} from 'Shared/forms/Inputs';
import { Wrap } from 'Shared/forms/Wrap';
import { IItemProps } from 'Shared/backend/menu/MenuItemPage';
import { css } from '@emotion/css';

const fieldsRowStyle = () => css({
	display: 'flex',
	gap: '1em'
});

const fieldStyle = () => css({
	width: '16ch'
});

export function BeerDescription(props:IItemProps)
{
	const states = {
		act: 'ACT',
		nsw: 'NSW',
		nt: 'NT',
		qld: 'QLD',
		sa: 'SA',
		tas: 'TAS',
		vic: 'VIC',
		wa: 'WA'
	};

	return (<>
		<div class={fieldsRowStyle()}>
			<div class={fieldStyle()}>
				<Wrap label='Region' required={false}>
					<Text {...props} field='region' />
				</Wrap>
			</div>

			<div class={fieldStyle()}>
				<Wrap label='State/Territory' required={false}>
					<SingleSelect {...props} field='state' options={states} />
				</Wrap>
			</div>

			<div class={fieldStyle()}>
				<Wrap label='Country' required={false}>
					<Text {...props} field='country' />
				</Wrap>
			</div>
		</div>

		<Wrap label='Short description' required={false}>
			<Text {...props} field='tagline' />
		</Wrap>
	</>);
}

