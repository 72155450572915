import { For } from 'solid-js';

export function ThirdPartyServices(props)
{
	return (
		<div class='thirdPartyServices'>
			<For each={props.venue.thirdPartyServices}>{service =>
				<div>
					<a href={service.url} target='_blank'>
						<button>
							{/* TODO CJ get images working for uber or maybe discontinue? 
								{% if service.image %}
									<img src="{{ service.image }}">
								{% endif %}
							*/}

							{/* use <label> instead? 
								<div class=label>{{service.title}}</div>
							*/}

							{ service.title == 'other' ? service.other : service.title }
						</button>
					</a>
				</div>
			}</For>
		</div>
	);
}
