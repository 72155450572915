import { BaseTheme, BaseThemeType} from 'Shared/frontend/BaseTheme';
import { merge } from 'Shared/frontend/merge';


export class IndieIslandTheme extends BaseTheme
{
	palette()
	{
		return {
			background: 'black',
			text: '#E2E2E2',
			feature: '#FCB03B',

			/* Eg the mobile nav colour */
			dimBackground: '#1C1513'
		};
	}

	fonts()
	{
		return {
			text: '"Kiwi Maru", serif',
			headings: '"Kiwi Maru", serif',
			buttons: '"Kiwi Maru", serif',
			desktopNav: '"Kiwi Maru", serif',
			mobileNav: '"Open Sans", sans-serif',
			subNav: '"Kiwi Maru", serif'
		};
	}

	settings()
	{
		const base = super.settings();

		const settings = merge(base, <Partial<BaseThemeType>>{
			/* Add base theme overrides here */
		});

		// XXX if we want to entirely remove or overwrite a base setting or two then do so here 
		return settings;
	}
};

