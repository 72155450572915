import { css } from '@emotion/css'
import { Overlay } from 'Shared/frontend/Overlay';
import { SubNav } from 'Shared/frontend/SubNav';
import { For,Switch,Match,Show, createSignal } from 'solid-js';
import { Parallax as ParallaxWrap } from 'Shared/frontend/parallaxWidget/Parallax';
import { VenueUrls } from 'Common/VenueUrls';
import { usePage } from 'Shared/frontend/PageProvider';
import { PageFunctions } from 'Browser/PageFunctions';
import { Slideshow } from 'Shared/frontend/showcaseWidgets/Slideshow';
import { Image } from 'Common/config/Fields';
import { pageTheme, sizes, theme } from 'Shared/frontend/ThemeProvider';
import { Info } from 'Shared/frontend/showcaseWidgets/Info';
import { Posters } from 'Shared/frontend/showcaseWidgets/Posters';
import { FullWidthStyle } from 'Shared/frontend/FullWidthStyle';


const sectionStyle = (noMaxWidth:boolean) => css({
	width: '100%',
	maxWidth: noMaxWidth ? 'unset' : sizes().maxPageWidth,
	overflow: 'clip',
	margin: '4em auto',

	'&:first-child': {
		marginTop: '2em'
	},

	'&:last-child': {
		marginBottom: '2em'
	},

});

const tocStyle = () => css({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	gap: '0.7em'
});

const tocItemStyle = () => css(theme().a);

const stickyStyle = (stick:boolean) => css({
	position: stick ? 'sticky' : 'static',
	top: '0',
	zIndex: '10',
});

//TODO hover
const stickyNavButtonStyle = () => css(pageTheme().widgets.subNav.button);

const parallaxLogoStyle = () => css({
	width: '60%',
	paddingTop: 'initial',
	height: 'auto',
	maxWidth: '100%'
});

// todo CJ finish gallery and add to section.layout options
const galleryStyle = () => css({
	display: 'flex',
});

const galleryItemStyle = () => css({
	width: '25%',
});

interface IWidget {
	carouselImageFolder: string,
	carouselImageSize: keyof Image,
	parallaxImageFolder: string,
	parallaxImageSize: keyof Image
}

export function Widget(props:IWidget)
{
//XXX difference between displayTOC and buttons?

	return (
		<Switch>
			<Match when={props.layout == 'parallax'}>
				{/* FIXME sometimes the parallax should use 'sameInfo' (eg a feature product), and sometimes not (eg homepage banner) */}
				<Parallax {...props} />
			</Match>
			<Match when={props.layout == 'venueImageAndLogo'}>
				{/* TODO ben low priority  place parrallaxBanner here */}
			</Match>
			<Match when={props.layout == 'popup'}>
				<Popup {...props} />
			</Match>
			<Match when={props.layout == 'buttons'}>
				<Buttons {...props} />
			</Match>
			<Match when={true}>
				<SectionWrap {...props}>
					<Section {...props}/>
				</SectionWrap>
			</Match>
		</Switch>
	);
}

function SectionWrap(props:{index:number,children})
{
	return (
		<Switch>
			<Match when={props.layout=='slideshow'}>
				<FullWidthStyle>
					<section id={`widget${props.index}`} class={sectionStyle(props.noSectionWidth)}>
						{props.children}
					</section>
				</FullWidthStyle>
			</Match>
			<Match when={true}>
				<section id={`widget${props.index}`} class={sectionStyle(props.noSectionWidth)}>
					{props.children}
				</section>
			</Match>
		</Switch>
	);
}

function Section(props)
{
	return (
		<Switch>
			<Match when={props.layout == 'gallery'}>
				<GalleryItems {...props} />
			</Match>
			<Match when={props.layout == 'embedCode'}>
				{props.embedCode}
			</Match>
			<Match when={props.layout == 'noImage'}>
				<Info {...props} />
			</Match>
			<Match when={props.layout == 'displayTOC'}>
				<TableOfContents {...props} items={props.showcase} />
			</Match>
			<Match when={props.layout?.includes('carousel')}>
				<Posters {...props} />
			</Match>
			<Match when={props.items.length > 0}>
				<Slideshow {...props} />
			</Match>
			<Match when={true}>
				<Info {...props} />
			</Match>
		</Switch>
	);
}

/* Table of contents for hirepage sections displayed as a vertical list (NOT buttons) */
function TableOfContents(props)
{
	return (
		<div class={tocStyle()}>
			<For each={props.items}>{(item,index) =>
				<Show when={item.title}>
					<a class={tocItemStyle()} href={`#widget${index()}`}>
						<Show when={item.capacity}>
							{item.capacity},  
						</Show>

						<Show when={item.area}>
							{' '}{item.area}:{' '}
						</Show>

						{item.title}
					</a>
				</Show>
			}</For>
		</div>
	);
}	

function Buttons(props)
{
	const handleClick = item => {
		if (item.linkTarget_self) { 	//TODO improve name
			//TODO share with jumpToAnchor()
			document.location = item.buttonLink;
 			window.scrollBy(0,-100);
		}
		else
			window.open(item.buttonLink, '_blank')?.focus();
	}

//TODO move the gDriveDownloadAsPDF stuff into handleClick()	
	return (
		<div id={`widget${props.index}`} class={stickyStyle(props.stickyButtons ?? false)}>
			<FullWidthStyle>
				<SubNav>
					<For each={props.items}>{item =>
						<Show when={item.buttonLink}>
							<Switch>
								{/* Sanity check if google doc url doesn't contain /edit?usp=sharing we can't convert it to a PDF */}
								<Match when={
									item.gDriveDownloadAsPDF &&
									item.buttonLink?.includes('google.com/document/') && 
									item.buttonLink?.includes('/edit?usp=sharing') 
								}>
									<a href={item.buttonLink.replace('/edit?usp=sharing','/export?format=pdf')} target='_blank'>
										<button class={stickyNavButtonStyle()}>
											{item.buttonText ?? 'Button'}
										</button>
									</a>
								</Match>
								<Match when={true}>
									<button class={stickyNavButtonStyle()} onClick={() => handleClick(item)}>
										{item.buttonText ?? 'Button'}
									</button>
								</Match>
							</Switch>
						</Show>
					}</For>
				</SubNav>
			</FullWidthStyle>
		</div>
	);
}

function Parallax(props)
{
	const urls = new VenueUrls(usePage().build,usePage().site.key);
	const imageUrl = () => (new PageFunctions()).createImageUrl2(urls,props.image?.hash ?? '',props.image,props.parallaxImageFolder,props.parallaxImageSize);
	const logoUrl = () => (new PageFunctions()).createImageUrl2(urls,props.logo?.hash ?? '',props.logo,props.parallaxImageFolder,props.parallaxImageSize);

	return (
		<ParallaxWrap scrollRate={props.scrollRate ?? 0.3} imageUrl={imageUrl()}>
			<Show when={props.logo}>
				<img class={parallaxLogoStyle()} style={`margin-top:`+(props.logoOffset ?? 0)} src={logoUrl()} />
			</Show>
			<Show when={props.text}>
				<h1>{props.text}</h1>
			</Show>
		</ParallaxWrap>
	);
}

function Popup(props)
{
	const [overlay,setOverlay] = createSignal(true);

	return (
		<Show when={overlay()}>
			<Overlay animation='fadeIn' close={() => setOverlay(false)}>
				<iframe src={props.embedUrl} style={`height:${props.embedHeight}; width:${props.embedWidth}; max-width:100%`} 
					frameborder='0' marginheight='0' marginwidth='0'
				>Loading…</iframe>	
			</Overlay>
		</Show>
	);
}

function GalleryItems(props)
{
	const urls = new VenueUrls(usePage().build,usePage().site.key);
	const imageUrl = image => (new PageFunctions()).createImageUrl2(urls,image?.hash ?? '',image,props.carouselImageFolder,props.carouselImageSize);

//TODO replace background-image with img	
//TODO rename itemTeaser etc	

	return (
		<div class={galleryStyle()}>
			<For each={props.items}>{(item,index) =>
				<div class={galleryItemStyle()}>
					<div class='imageWrap'>
						<div class='landscape'>
							<div>
								<div style={`background-image: url('${imageUrl(item.image)}')`}></div>
							</div>
						</div>
					</div>
					<Info {...item}
						teaser={item.itemTeaser} title={item.itemTitle} tagline={item.itemTagline} 
						details={item.itemDetails} link={item.buttonLink} addButton={item.addItemButton}
					/>
				</div>
			}</For>
		</div>
	);
}


