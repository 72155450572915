import { css } from '@emotion/css';
import { navHeight } from 'Shared/backend/Theme';

const singleColumnWidth = 900;

const entryPaneStyle = () => css({
	display: 'inline-block',
	width: '50%',

	[`@media (max-width:${singleColumnWidth}px)`]: {
		width: '100%',
	},
});

const previewStyle = () => css({
	display: 'block',
	width: '49%',
	float: 'right',
	position: 'sticky',
	top: `calc(${navHeight} + 1em)`,

	[`@media (max-width:${singleColumnWidth}px)`]: {
		width: '100%',
		float: 'none'
	},
});


export function ContentAndPreviewPanes(props)
{
	return (
		<div>
			<div class={entryPaneStyle()}>
				{props.children[0]}
			</div>

			<div class={previewStyle()}>
				{props.children[1]}
			</div>
		</div>
	);
}

