import {stripZeros, TitleAndQuickDetails} from 'Shared/frontend/menuPage/CardItem';
import { css } from '@emotion/css';
import { createSignal, For, onCleanup, Show } from 'solid-js';
import { capitalise } from 'Shared/common/Capitalise';
import { theme } from 'Shared/frontend/ThemeProvider';
import { isServer } from 'solid-js/web';
import { onMount } from 'solid-js';

//XXX NB StandardMenuItem is using this to flip to mobile
export const singleColumn = 745;

const itemStyle = () => css({
	width: '100%',
//	height: initial,
//	color: #ffffff,
	margin: '0 auto'
})

const detailsStyle = () => css({
	paddingBottom: '2em',
});

const topLineStyle = () => css({
	display: 'flex',
	flexDirection: 'row',
	/*
		Probably doesnt work so well when the top line wraps - 
		would rather have the price line up with the top line.
	*/
	alignItems: 'stretch',
	marginBottom: '0.5em'
});

const titleAndQuickDetailsStyle = () => css({
	textTransform: 'uppercase',
	fontSize: '1.1em',  //XXX fold title style into here??
});

const fillerStyle = () => css({
	flex: '1 1',
	width: '99%',
	background: 'radial-gradient(circle at 50% 72%, #999 3%, transparent 3%)',
	backgroundSize: '0.5em',
	backgroundRepeat: 'repeat-x',
});

const descriptionStyle = () => css({
	fontSize: '1em',
	marginBottom: '0.5em',
	textAlign: 'left'
});

const priceInnerStyle = () => css({
	alignSelf: 'flex-end',
	textAlign: 'right !important'
});

const priceWrapStyle = () => css({
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'flex-start',
	justifyContent: 'flex-end',
	gap: '1ch',

	...theme().menu.item.price
});

const unitStyle = () => css({});


export function ImagelessItem(props)
{
	//XXX maybe creater a helper for this. Share with eventPage/Artist.tsx
	const [wide,setWide] = createSignal(isServer ? true : undefined);

	onMount(() => {
		setWide(window.innerWidth >= singleColumn && !props.isPreview);

		const widthHandler = () => setWide(window.innerWidth >= singleColumn && !props.isPreview);
		window.addEventListener('resize', widthHandler);
		onCleanup(() => window.removeEventListener('resize', widthHandler));
	});

	return (<>
		<div class={itemStyle()}>
			<div class={detailsStyle()}>
				{wide() ? <WideItem {...props} /> : <NarrowItem {...props} />}
			</div>
		</div>
	</>);
}

function WideItem(props)
{
	return (<>
		<div class={topLineStyle()}>
			<div class={titleAndQuickDetailsStyle()}>
				<TitleAndQuickDetails item={props.item} />
			</div>

			{/* Used for dot leaders etc */}
			<div class={fillerStyle()} />

			<Prices item={props.item}/>
		</div>

		<div class={descriptionStyle()}>
			{props.item.tagline}
		</div>
	</>);
}

function NarrowItem(props)
{
	return (<>
		<div class={titleAndQuickDetailsStyle()}>
			<TitleAndQuickDetails item={props.item} />
		</div>

		<div class={descriptionStyle()}>
			{props.item.tagline}
		</div>

		<Prices item={props.item}/>
	</>);
}

function Prices(props)
{
	return (
		<div class={priceWrapStyle()}>
			<For each={props.item.prices}>{ unit =>
				<Show when={unit.price !== null }>
					{/* XXX probably shouldnt be required but currently these items contain nulls */}
					<div class={priceInnerStyle()}>
						{/* Only 1 of these unit options will appear */}
						<span class={unitStyle()}>{capitalise(unit.size)}{capitalise(unit.description)}{' '}</span>
						<span>${stripZeros(unit.price)}</span>
					</div>
				</Show>
			}</For>
		</div>
	);
}

