import { DateTime } from 'Shared/forms/DateTimeInputs';
import { Match, Switch } from 'solid-js';
import { DateTime as Luxon } from 'luxon';
import { AnnounceCircle } from 'Shared/backend/icons/AnnounceCircle';
import { css } from '@emotion/css';
import { IconPane } from 'Shared/forms/IconPane';
import { titleStyle } from 'Shared/backend/eventPage/Plan';
import { theme } from 'Shared/backend/ThemeProvider';

// TODO ben get announceDate input working below 

const contentStyle = () => css({
	paddingTop: '1em'
});


export function AnnounceDate(props)
{
	const event = () => props.store;

	return (
		<IconPane>
			<AnnounceCircle fill='grey' stroke='white' />

			<Switch>
				<Match when={event().scheduleDate && event().status=='scheduled'}>
					<Switch>
						<Match when={event().announceDate}>
							<div class={titleStyle()}>The VOS dashboard lists this event to be announced on:</div>
						  	<div class={contentStyle()}> 
								<DateTime {...props} field='announceDate' />
								You can change the announce date above or delete it if you want to announce when the event is published
							</div>
						</Match>
						<Match when={true}>
							<div class={titleStyle()}>
								The VOS Dashboard lists this event to be announced when it's published on your website on
								{Luxon.fromISO(event().scheduleDate).toFormat('ccc, d/MM/yyyy')}
							</div>

							<div class={contentStyle()}>
								You can set different announcement date below to be reminded on the Dashboard then:
								<DateTime {...props} field='announceDate' />
							</div>
						</Match>
					</Switch>
				</Match>
				<Match when={event().status=='draft'}>
					You can't announce event until it's 
					<a class={css(theme().a)} target='_parent' href={`/admin/event/${event().slug}/details`}>published (or scheduled to be visible) on your website.</a>
				</Match>
				<Match when={true}>
					<Switch>
						<Match when={event().announceDate}>
							<div class={titleStyle()}>The VOS dashboard will remind you to announce this event on:</div>
							<div class={contentStyle()}>
								<DateTime {...props} field='announceDate' />
								You can change the announce date above or delete it if you want to announce on event published date
							</div>
						</Match>
						<Match when={true}>
							<div class={titleStyle()}>Announce on social media now</div>
							<div class={contentStyle()}>
								Or set announcement date to be reminded then: 
								<DateTime {...props} field='announceDate' />
							</div>
						</Match>
					</Switch>
				</Match>
			</Switch>
		</IconPane>
	);
}
