import {FoodMenu} from 'Shared/frontend/icons/FoodMenu';
import {Calendar} from 'Shared/frontend/icons/Calendar';
import {Home} from 'Shared/frontend/icons/Home';
import {Nav} from 'Shared/frontend/icons/Nav';
import {Contact} from 'Shared/frontend/icons/Contact';
import {NavMore} from 'Shared/frontend/navWidget/NavMore';
import {createSignal} from 'solid-js';
import {Show,For} from 'solid-js'
import { css, cx } from '@emotion/css';
import { fluidType } from 'Shared/common/FluidType';
import { palette, sizes, theme } from 'Shared/frontend/ThemeProvider';
import { sanitise } from 'Shared/common/Sanitise';


const overlayStyle = () => css({
	position: 'fixed',
	top: 0,
	left: 0,
	right: 0,
	height: '100vh',
	/* Should appear over other overlays: */
	zIndex: 50,
	maxWidth: sizes().maxPageWidth,
	margin: '0 auto',
	scrollbarWidth: 'none',
	overflow: 'auto',
	animationName: 'slideIn',
	animationDuration: '0.5s',
	backgroundColor: palette().background
});

const mobileStyle = () => css({
	display: 'flex',
	flexWrap: 'nowrap',
	padding: 0,
	position: 'fixed',
	bottom: 0,
	left: 0,
	width: '100%',
//	backgroundColor: palette().dimBackground,
	backgroundColor: theme().nav.mobile.button.backgroundColor,
	paddingBottom: '1em',
	zIndex: 5,

//XXX Shared with StandardDefaultLayout etc
	maxWidth: 1250,
	marginLeft: 'auto',
	marginRight: 'auto',

	a: {
		flex: '1 1'
	}
});

const buttonStyle = () => css(theme().nav.mobile.button, {
//TODO use flex instead of this	
	width: '100%',

	padding: '0.6em 0 3px 0',

	':hover': {
		//XXX not sure how well this will work with a light theme
		backgroundColor: `hsl(from ${palette().background} h calc(s + 15) calc(l + 15))`
	}
});

const activeStyle = () => css(theme().nav.mobile.active);

const buttonContentsStyle = (isActive:boolean) => css({
	display: 'block',
	margin: '0 auto',
	width: 'calc(30px + 20 * ((100vw - 375px ) / 225 ))',
	maxWidth: '90%',

	svg: {
		fill: isActive ? theme().nav.mobile.active.color : palette().text
	}
});

const iconLabelStyle = () => css({
	fontSize: fluidType('px',sizes().endFontShrink,sizes().flipToMobileNav,10,16),
});

const closeIconStyle = () => css({
	...sanitise.button,
	position: 'fixed',
	top: 0,
	right: 0,
	padding: 20,
	color: 'inherit',
	backgroundColor: 'inherit',
	borderRadius: 0,
	fontSize: 40,
	fontWeight: 'bold'
});


export function MobileFooterNav(props)
{
	const mobile = () => props.config.nav.mobile;
	const maxButtons = () => mobile().includeMoreButton ? mobile().maxButtons - 1 : mobile().maxButtons;

	const [visible,setVisible] = createSignal(false);

	/* Warning: only supports a single overlay. Otherwise cf an 'overlayed' count. cf <Portal> */
	const openOverlay = () => {
		document.body.classList.add('overlayed');
		setVisible(true);

		/*
			Following a link to another page requires this BUT DOESNT WORK - as outside Solid tree.
				onCleanup(() => document.body.classList.remove('overlayed'));
			Relying on the router to cleanup 'overlayed'.
			MutationObserver might be better, but bit verbose.
		*/
	};

	const closeOverlay = () => {
		document.body.classList.remove('overlayed');
		setVisible(false);
	};

	return (<>
		<nav class={mobileStyle()}>
			<For each={mobile().links.slice(0,maxButtons())}>{item =>
				<a href={props.config.link[item.link].target}>
					<button tabindex='-1' class={cx(buttonStyle(),item.link==props.pageName && activeStyle())}>
						<div class={buttonContentsStyle(item.link==props.pageName)}>
							{navIcon(item.icon)}
						</div>
						<div class={iconLabelStyle()}>{item.label}</div>
					</button>
				</a>
			}</For>

			<Show when={mobile().includeMoreButton}>
				<a href=''>
					<button class={buttonStyle()} tabindex='-1' onClick={openOverlay}>
						<div class={buttonContentsStyle(false)}>
							<Nav />
						</div>
						<div class={iconLabelStyle()}>More</div>
					</button>
				</a>
			</Show>
		</nav>

		<Show when={visible()}>
			<div class={overlayStyle()}>
				<button class={closeIconStyle()} onClick={closeOverlay}>X</button>

				<NavMore {...props} />
			</div>
		</Show>
	</>);
}

function navIcon(name:string)
{
	switch(name) {
		case 'home.svg.njk': return <Home />;
		case 'foodMenu.svg.njk': return <FoodMenu />
		case 'calendar.svg.njk': return <Calendar />;
		case 'contact.svg.njk': return <Contact />;
	}
}

