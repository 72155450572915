
import { css, injectGlobal } from '@emotion/css';
import { PageProvider } from 'Shared/frontend/PageProvider';
import { standardRules } from 'Shared/common/Sanitise';
import { StaffResourceForPerson } from 'Shared/frontend/staffResources/StaffResourceForPerson';
import { jumpToAnchor, SubNav } from 'Shared/frontend/SubNav';
import { ThemeProvider } from 'Shared/frontend/ThemeProvider';
import { For } from 'solid-js';
import { createStore } from 'solid-js/store';
import { StaffResourceData } from 'Shared/view/frontend/StaffResource';
import { FullWidthStyle } from 'Shared/frontend/FullWidthStyle';

//TODO CJ remove from frontend once ben gives employees access to backend vos for staff resources

//TODO use backend styles when we move code into the backend

const outerStyle = () => css({ 
	minHeight: '100vh',
});

const navButtonStyle = () => css({
	textTransform: 'capitalize',
	margin: '0 0.4em',
	background: '#DAB670',
	color: 'black',
	borderRadius: '0.4em',
	padding: '0.44em 2.25em',
	display: 'inline-block',
	minWidth: 'fit-content',
	maxWidth: '100%',
	border: 'none',
	borderWidth: 1,
	cursor: 'pointer',
	textAlign: 'center',
	appearance: 'none',
	fontFamily: 'Questrial, sans-serif',	//XXX townie thing
	fontSize: 16
});

const stickyStyle = () => css({
	position: 'sticky',
	top: '0',
	zIndex: '10',
});

export function StaffResourcePage(props:StaffResourceData)
{
	injectGlobal([standardRules,{body:props.theme.body}]);

	const [theme] = createStore(props.theme);

	return (
		<ThemeProvider theme={theme}>
			<PageProvider page={props.page}>
				{/* A single parent is required for events to hydrate properly */}			
				<div class={outerStyle()}>
					<Shortcuts {...props} />

					<For each={props.staffResources}>{ (resource,index) =>
						<StaffResourceForPerson {...resource} />
					}</For>
				</div>				
			</PageProvider>
		</ThemeProvider>
	);
}

function Shortcuts(props)
{
	return (
		<div class={stickyStyle()}>
			<FullWidthStyle>
				<SubNav>
					<For each={props.staffResources}>{(resource,index) => 
						<button class={navButtonStyle()} onClick={() => jumpToAnchor('#'+anchorId(resource.name),-160)} >
							{resource.name}
						</button>
					}</For>
				</SubNav>
			</FullWidthStyle>
		</div>
	)
};


//TODO possibly use across artist, events, specials, ...
export function anchorId(name:string)
{
	return name?.toLowerCase()?.replaceAll(' ','');
}

