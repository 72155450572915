import { VenueUrls } from 'Common/VenueUrls';
import { DateTime } from 'luxon';
import { otherFacebookHandles } from 'Shared/backend/eventPage/Handles';
import { announceStyle, linkedTitleStyle, musicExists, titleStyle, videoExists } from 'Shared/backend/eventPage/Plan';
import { ExclamationCircle } from 'Shared/backend/icons/ExclamationCircle';
import { IconColour } from 'Shared/backend/IconColour';
import { TickCircle } from 'Shared/backend/icons/TickCircle';
import { FacebookCircle } from 'Shared/frontend/icons/FacebookCircle';
import { usePage } from 'Shared/frontend/PageProvider';
import { Switch,Match, Show } from 'solid-js';
import { IconPane } from 'Shared/forms/IconPane';
import { css } from '@emotion/css';
import { DoneCheckbox } from 'Shared/backend/eventPage/DoneCheckbox';
import { Overlay } from 'Shared/forms/Overlay';
import { createSignal } from 'solid-js';
import { PostPane } from 'Shared/backend/eventPage/tasks/PostPane';

const overlayContentStyle = () => css({
	width: 'fit-content',
	margin: 'auto'
});


export function AnnounceFacebook(props)
{
	const event = () => props.store;

	const [overlay,setOverlay] = createSignal(false);
	const [postText,setPostText] = createSignal();

	return (<>
		<IconPane>
			<Switch>
				<Match when={event().announceOnFacebook}>
					<TickCircle fill={IconColour.green} />
				</Match>
				<Match when={true}>
					<ExclamationCircle fill={IconColour.red} />
				</Match>
			</Switch>

			<div>
				<div class={announceStyle()}>
					<a class={linkedTitleStyle()} onClick={() => setOverlay(true)}><i class='fas fa-caret-right'/> Announce on Facebook</a>
					<DoneCheckbox {...props} field='announceOnFacebook' />
				</div>
			</div>
		</IconPane>

		<Show when={overlay()}>
			<Overlay close={() => setOverlay(false)}>
				<div class={overlayContentStyle()}>
					<PostPane icon={FacebookCircle} title='Draft Post' postText={postText()}>
						<Post {...props} setPostText={setPostText} 
							instruction='Then paste into Facebook, where you can edit the text before posting.'
						/>
					</PostPane>
				</div>
			</Overlay>
		</Show>
	</>);
}

function Post(props)
{
	const event = () => props.store;
	const lineup = () => props.store.lineup ?? [];
	const firstAct = () => props.store.lineup?.[0];

	const posterUrl = () => !event().poster ? '' :
		(new VenueUrls(usePage().build,usePage().site.key)).imageUrl('Event-poster',event().poster.hash,'tiny','jpeg',!event().poster.dev,false);

	return (
		<div>
			<div ref={props.setPostText}>
				{/* Customise the text that can be copied and pasted to social media */}

				Announcing 
				{ firstAct()?.facebook ? '@' + firstAct()?.facebook : firstAct()?.name }

				<Show when={lineup().length > 1}>	
					{' '}
					with 
					{ otherFacebookHandles(lineup(),0) }
					{' '}
					{DateTime.fromISO(event().times.startSingle).toFormat('ccc d LLL')} 
					{' '}
					@{props.venue.facebook}
				</Show>

				<p>
					Click Poster ↓ to discover
					{' '}

					<Switch>
						<Match when={musicExists(lineup()) && videoExists(lineup())}>
							music, videos and event details
						</Match>
						<Match when={musicExists(lineup()) && !videoExists(lineup())}>
							music and event details
						</Match>
						<Match when={!musicExists(lineup()) && videoExists(lineup())}>
							videos and event details
						</Match>
						<Match when={!musicExists(lineup()) && !videoExists(lineup())}>
							event details
						</Match>
					</Switch>
					{' '}
					{usePage().site.hostUrl}/event/{event().slug}
				</p>
			</div>
			
			<Show when={event().poster} fallback={<x-warning>Poster missing</x-warning>}>
				<img src={posterUrl()}
					width={event().poster?.formats?.tiny?.width}
					height={event().poster?.formats?.tiny?.height}
				/>
			</Show>
		</div>
	);
}
