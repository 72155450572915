import { css, injectGlobal } from '@emotion/css';
import { PageFunctions } from 'Browser/PageFunctions';
import { VenueUrls } from 'Common/VenueUrls';
import { DateTime } from 'luxon';
import { PageProvider, usePage } from 'Shared/frontend/PageProvider';
import { Artist, artistAnchorId } from 'Shared/frontend/eventPage/Artist';
import { DateAsLabel } from 'Shared/frontend/eventPage/Times';
import { StandardDefaultLayout } from 'Shared/frontend/layouts/StandardDefaultLayout';
import { jumpToAnchor, SubNav } from 'Shared/frontend/SubNav';
import { EventDoc } from 'Shared/model/Event';
import { EventData } from 'Shared/view/frontend/Event';
import { For, Match, Show, Switch } from 'solid-js';
import { createStore } from 'solid-js/store';
import { standardRules } from 'Shared/common/Sanitise';
import { theme, palette, ThemeProvider, sizes } from 'Shared/frontend/ThemeProvider';
import { FullWidthStyle } from 'Shared/frontend/FullWidthStyle';


/*
	Regarding backend previews of event pages:
	1. Decided to go for a desktop view (even if viewed on a mobile) as it is more compact.
	2. Rely primarily on 'font-size' do shrink things down. Can increase it a bit for readability
	   or decrease it a bit to get a more realistic layout of the page.
*/

const rootStyle = (isPreview:boolean) => css({
//	maxWidth: isPreview ? '100%' : `calc(min(100%,${sizes().maxPageWidth}px) - 4em)`, 
	maxWidth: theme().sizes.maxPageWidth+'px',
	fontSize: isPreview ? '0.8em' : '1em',
	margin: isPreview ? '1em 1em' : '0 auto',
});

const stickyStyle = () => css({
	position: 'sticky',
	top: '0',
	zIndex: '10',
});

const stickyNavButtonStyle = () => css(theme().event.subNav.button);

const priceButtonStyle = () => css(theme().event.subNav.button);

const eventDetailsStyle = () => css({
	margin: '2em 0'
});

const headerStyle = () => css({});

const nameStyle = () => css(theme().event.heading,{
	margin: 0
});

const eventImageStyle = (isPreview:boolean) => css({
	display: 'block',
	float: 'left',

	...(isPreview ? {
		width: '100%',
	} : {
		[`@media (min-width:${sizes().flipToOneColumn}px)`]: {
			width: '30%',
			paddingRight: '1em',
		},
		[`@media (max-width:${sizes().flipToOneColumn}px)`]: {
			width: '100%'
		}
	})
});

const imgStyle = () => css({
	width: '100%'
});

const taglineStyle = () => css(theme().blockquote,{
	fontSize: '1.25em'
});


/*
	Thoughts on wrapping text around the image...
	Technically this is difficult to achieve when combined with these other requirements:
		1. Supporting a possibility wide description content
		2. The desirability of using collapsible margins (maybe using flex would be simpler?)

	Note though that NOT wrapping looks BETTER when the description has complicated formatting,
	provided we keep the image reasonably narrow.
*/

/* 
	The description has two divs so that we can allow for horizontal scrolling of 
	wide content without giving up collapsible margins. 
	Flex or grid could be used instead to manage vertical spacing. Don't know that either
	would allow wrapping around an image.
*/
const moreInfoOuterStyle = () => css({
//	maxWidth: '100%',  
	margin: '1em 0 2em 0',
});

const moreInfoInnerStyle = () => css({
	/*
		This breaks the wrapping of content around the image.
		"overflowX: 'clip'" allows it, but then you can't see any wide content that may be pasted in.
	*/
	overflowX: 'scroll',

	scrollbarWidth: 'none',

	a: {
		color: palette().feature,
		textDecoration: 'none'
	},

	'p:first-child' : {
		marginTop: 0
	},

	'p:last-child' : {
		marginBottom: 0
	},
});


export function EventPage(props:EventData)
{
	injectGlobal([standardRules,{body:props.theme.body}]);

	const [theme,setTheme] = createStore(props.theme);

//XXX eventPage class currently required to avoid resets and standard page stuff	
	return (
		<ThemeProvider theme={theme}>
			<PageProvider page={props.page}>
				<StandardDefaultLayout {...props} expandDesktopNav={false} classes='noReset'>
					{/* 
						Reclaim some space in preview mode + clearer without the shortcuts.
						On the frontend avoid the extra margins.
					 */}
					<Shortcuts {...props.event} />

					<EventPageContents {...props} isPreview={false}/>
				</StandardDefaultLayout>
			</PageProvider>
		</ThemeProvider>
	);
}

export function EventPageContents(props:EventData)
{
	return (
		<div class={rootStyle(props.isPreview)}>
			<div class={eventDetailsStyle()}>				
				<EventImage {...props.event} />

				<Header {...props.event} />

				<Show when={props.event.tagline}>
					<blockquote class={taglineStyle()}>{ props.event.tagline }</blockquote>
				</Show>

				<Show when={props.event.moreInfo}>
					<div class={moreInfoOuterStyle()}>
						<div class={moreInfoInnerStyle()} innerHTML={props.event.moreInfo} />
					</div>
				</Show>

				<div style='clear:both'/>
			</div>			

			<Show when={props.event.eventType=='music'}>
				<For each={props.event.lineup ?? []}>{artist =>
					<Artist {...artist} isPreview={props.isPreview} />
				}</For>
			</Show>
		</div>
	);
}

function Header(props:EventDoc)
{
	/* Note that Google MAY use <header> content to help populate itself */
	return (
		<header class={headerStyle()}>
			<h3 class={nameStyle()}>
				<DateAsLabel {...props.times} />
			</h3>
			<Show when={props.name}>
				<h3 class={nameStyle()}>{props.name}</h3>
			</Show>
		</header>
	);
}

function Shortcuts(props:EventDoc)
{
	const timeLabel = value => {
		const time = DateTime.fromISO(value);
		return time.toFormat('mm') == '00' ? time.toFormat('ha') : time.toFormat('h:mma');
	};

	const showLineupShortcuts = () => props.eventType == 'music' && (props.lineup ?? []).length > 1;

	return (
		<Show when={props.priceOptions || showLineupShortcuts()}>
			<div class={stickyStyle()}>
				<FullWidthStyle>
					<SubNav>
						<Show when={props.priceOptions}>
							<PriceButton {...props} />
						</Show>

						<Show when={showLineupShortcuts()}>
							<For each={props.lineup}>{a => 
								<button class={stickyNavButtonStyle()} onClick={() => jumpToAnchor('#'+artistAnchorId(a.name),-100)} >
									<Show when={a.setTime}>
										{timeLabel(a.setTime)}
										{' '}
									</Show>
									{ a.name }
								</button>
							}</For>
						</Show>
					</SubNav>

				</FullWidthStyle>
			</div>
		</Show>
	)
};

function PriceButtonLabel(props:EventDoc)
{
	return (
		<Switch>
			<Match when={props.priceOptions == 'free'}>
				FREE ENTRY
			</Match>
			<Match when={props.priceOptions == 'buyNow'}>
				BUY NOW
			</Match>
			<Match when={props.priceOptions == 'bookNow'}>
				BOOK NOW
			</Match>
			<Match when={true}>
				{props.customPrice}
			</Match>
		</Switch>
	);
}

function PriceButton(props:EventDoc)
{
	return (
		<Switch>
			<Match when={props.purchaseLink}>
				<a href={props.purchaseLink} target='_blank'>
					<button class={priceButtonStyle()}>
						<PriceButtonLabel {...props} />
					</button>
				</a>
			</Match>
			<Match when={true}>
				{/*TODO disable this button + hover over */}				
				<button class={priceButtonStyle()}>
					<PriceButtonLabel {...props} />
				</button>
			</Match>
		</Switch>
	);
}

function EventImage(props:EventDoc)
{
	const urls = new VenueUrls(usePage().build,usePage().site.key);
	const imageUrl = () => (new PageFunctions()).createImageUrl2(urls,props.image?.hash ?? '',props.image,'Event-image','medium');

	return (
		<Show when={(props.imageType == 'poster' || props.imageType == 'photo') && imageUrl()}>
			<div class={eventImageStyle(props.isPreview)}>
				<div class={`${props.imageDimensions}Dimension`}>
					<img class={imgStyle()} src={imageUrl()} />
				</div>
			</div>
		</Show>
	);
}

