import { fluidType } from 'Shared/common/FluidType';
import { sanitise } from 'Shared/common/Sanitise';
import { merge } from 'Shared/frontend/merge';


export abstract class BaseTheme
{
	sizes()
	{
		return {
			flipToMobileNav: 600,

			/* Sometimes its good to shrink the margins a bit when approaching flipToMobileNav... */
			startMarginShrink: 700,
			widePageSideMargin: 32,
			narrowPageSideMargin: 0,

			desktopWidth: 1000,
			maxPageWidth: 1250,
			maxFooterWidth: 1380,

			/* Width of an iPhone5 is 320px, iPhone6 is 375px */
			endFontShrink: 375,

			navButtonsMaxWidth: 800,

			/* Events use a different flip point because they are so busy */
//TODO look at this use. Probably better moved to the individual page files.
			flipToOneColumn: 700,	
		};
	}

	abstract palette();

	abstract fonts();

//TODO create a button function too?

	subNavButton()
	{
		return {
			...sanitise.button,
			fontFamily: this.fonts().subNav,
			textTransform: 'capitalize',
			borderWidth: 1,
			backgroundColor: this.palette().dimBackground,
			color: this.palette().text,
			margin: '0 0.4em',
			padding: '0.44em 2.25em',
		};
	}

	page()
	{
		return {
			main: {
				flexGrow: 1,
				maxWidth: this.sizes().maxPageWidth,  
				marginTop: 0,
				marginBottom: '2em',
				paddingLeft: '32px',
				paddingRight: '32px'
			},

			heading: {
				fontFamily: this.fonts().heading,
				fontWeight: 500,
				lineHeight: 1.3,
				textTransform: 'uppercase',
				/* Using ems makes this scalable via fontSize - useful for backend previews */
				fontSize: fluidType('em',this.sizes().endFontShrink/16,this.sizes().desktopWidth/16,42/16,64/16),
				textAlign: 'center',
				margin: '32px 0 24px 0',
				color: this.palette().text
			},

			section: {
				heading: {
					fontFamily: this.fonts().heading,
					fontWeight: 500,
					lineHeight: 1.3,
					textTransform: 'uppercase',
					fontSize: fluidType('em',this.sizes().endFontShrink/16,this.sizes().flipToMobileNav/16,37.5/16,48/16),
					margin: '15px 0',
					color: this.palette().text
				}
			},

			subNav: {
				inner: {
					marginTop: '2em',
					marginBottom: '2em',
				}
			},

			widgets: {    //XXX cf ==> layout/design/pageDesign/showcase or promote slideshow 
				slideshow: {
					teaser: { }
				},
				subNav: {
					button: this.subNavButton()
				}
			},

			button: {
				...sanitise.button,
				fontSize: '1em',
				fontFamily: this.fonts().buttons,
				color: this.palette().text,
				borderWidth: 1,
				padding: '0.7em 2.25em',
				textTransform: 'uppercase'
			},

			embeddedHtml: {
				h4: {	
					fontFamily: this.fonts().heading,
					lineHeight: 1.3,
					textTransform: 'uppercase',
					fontSize: fluidType('px',this.sizes().endFontShrink,this.sizes().flipToMobileNav,37.5,48),
					fontWeight: 700,
					margin: '2em 0 1em 0',
//XXX double I want this in here by default					
					color: this.palette().feature,
				},

				strong: {
					fontWeight: 700,
				},

				a: {
					...sanitise.a,
					color: this.palette().feature
				},

				p: {
					margin: '16px 0',
					lineHeight: 1.5
				},

				img: {
					maxWidth: '100%',
					height: 'auto'
				}
			},
		};
	}

	settings()
	{
		const palette = this.palette();
		const fonts = this.fonts();

		return {
			sizes: this.sizes(), 	//TODO maybe rename to 'mediaPoints', 'turningPoints', 'pageWidths', 'widths' or something

			palette: palette,

			fonts: fonts,

			page: this.page(),

			/* The body settings are inherited through the page */
			body: {
				...sanitise.body,
				backgroundColor: palette.background,
				color: palette.text,
				fontFamily: fonts.text,
				lineHeight: 1.5,
				margin: 0,
				/* FullWidthStyle needs this - without it Chrome has a small horizontal scrollbar due to use of 100vw. */
				overflowX: 'hidden'
			},

			footer: {
				divider: {
					borderColor: 'inherit'
				},
				title: {
					fontFamily: fonts.heading,
					fontWeight: 700,
					textTransform: 'uppercase',
					margin: '8px 0',
				},
			},

			poster: {
				backgroundColor: palette.dimBackground,

				dateBar: {
					backgroundColor: palette.feature,
				},

				photoPoster: {
					inlineName: {
						color: 'inherit'
					}
				},

				detailsButton: {
					textColor: palette.text,
					backgroundColor: palette.background
				}
			},

			nav: {
				desktop: {
					button: {
						...sanitise.button,
						fontFamily: fonts.desktopNav,
						backgroundColor: 'inherit',
						color: palette.text,
						padding: '0.44em 2.25em',
						margin: '0 0.4em',
						textTransform: 'uppercase'
					},
					active: {
						color: palette.feature
					},
					hover: {
						color: palette.feature
					}
				},
				mobile: {
					button: {
						...sanitise.button,
						fontFamily: fonts.mobileNav,
						backgroundColor: palette.dimBackground,
						color: palette.text
					},
					active: {
						color: palette.feature
					},
					/* hover is bit academic on mobile: */
					hover: {
						color: palette.feature
					}
				}
			},

			events: this.page(),

			event: merge(this.page(), {
				subNav: {
					button: this.subNavButton()
				},
				heading: {
					...this.page().section.heading,

//TODO? Here I undo the default page heading setting by including textAlign and colour. Maybe add a mechanism to replace or delete a property- 
//      eg this.page().delete('heading').merge().  Good if it could take a path and not just delete topmost items.					

					textAlign: 'left',
					color: palette.text 
				},
				artist: {
					heading: {
						...this.page().section.heading,
						fontSize: fluidType('em',this.sizes().endFontShrink/16,this.sizes().desktopWidth/16,27/16,46/16)
					},
					wide: {
						nameAndTime: {}
					},
					narrow: {
						nameAndTime: {
							backgroundColor: palette.text
						}
					}, 
					genresAndLocation: {} // for overriding
				}
			}),

//XXX could potentially pass 'props' to menu instead, or to the whole theme
			menu: merge(this.page(), {
				subNav: {
					button: this.subNavButton(),
//XXX separating out the customisation part so 'button' can be patched by TheTownieTheme etc				
//    Consider patching the result of a function call when it exists. cf efficiency.				
					buttonType: type => {{}}
				},
				main: {
					paddingLeft: '1em',
					paddingRight: '1em'
				},
				section: {
					heading: {
						textAlign: 'center',
					},

					headingType: (level:number,section) => level > 2 ? {fontSize:'clamp(1rem, 10vw, 2.2rem)'} : {} 
				},
				item: {
					title: {},
					//XXX The Townie uses different colours for different section types, so using closure here
					titleType: section => {},

					//XXX actually applied to 'priceWrap'...
					price: { }
				}
			}),

			hire: merge(this.page(),{
				main: {
					...this.page().main,
					paddingLeft: '1em',
					paddingRight: '1em'
				},
			}),

			contact: merge(this.page(),{
				heading: {
					marginBottom: 48
				}
			}),

			staffResources: merge(this.page(), {
				subNav: {
					inner: {
						marginTop: 16,
						marginBottom:16 
					}
				},
			}),

//TODO probably move most of these to page()...

			a: {
				...sanitise.a,
				color: palette.feature
			},

			list: {
				...sanitise.list,
				lineHeight: 1.4,
			},

			definitions: {
				...sanitise.definitions,
				lineHeight: 1,

				dt: {
					...sanitise.definitions.dt,
					fontWeight: 'bold',
					paddingRight: '0.6em',
				},

				dl: {
					...sanitise.definitions.dl,
				}
			},

			overlay: {
				button: {
					...sanitise.button,
					fontFamily: fonts.subNav,
					fontSize: '3em',
					fontWeight: 700,
					borderRadius: '50%',
					width: '1.3em',
					height: '1.3em',
					padding: 0,
					color: palette.text,
					backgroundColor: palette.background,
				}
			},

			blockquote:  {
				...sanitise.blockquote,
				fontStyle: 'italic',
				fontWeight: 300,
				lineHeight: 1.3,
				margin: '1em 0'
			},

			iframe: {
				...sanitise.iframe
			},

			phone: {
				color:  palette.feature,
				lineHeight: 'normal'
			},

			address: {
				...sanitise.address,
				color:  palette.feature,
				lineHeight: 'normal',
				display: 'inline-block',
				verticalAlign: 'middle',
			},
		};
	}
}

export type BaseThemeType = ReturnType<BaseTheme['settings']>;

export type BasePageThemeType = ReturnType<BaseTheme['page']>;

export type BaseSizesType = ReturnType<BaseTheme['sizes']>;
