import { css } from '@emotion/css';
import { BackendWrap } from 'Shared/backend/BackendWrap';
import { Breadcrumbs } from 'Shared/backend/Breadcrumbs';
import { IconColour } from 'Shared/backend/IconColour';
import { LinkOpen } from 'Shared/backend/icons/LinkOpen';
import { DetailsTab } from 'Shared/backend/specials/DetailsTab';
import { TabPanes } from 'Shared/backend/Tabs';
import { inlineOpenLinkStyle, Theme } from 'Shared/backend/Theme';
import { theme, ThemeProvider } from 'Shared/backend/ThemeProvider';
import { PageProvider } from 'Shared/frontend/PageProvider';
import { Special } from 'Shared/model/Specials';
import { SpecialData } from 'Shared/view/backend/Special';
import { mergeProps } from 'solid-js';
import { createStore } from 'solid-js/store';

//TODO share with EventPage.tsx?
const topLine = () => css({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	marginBottom: '1em'
});

const activeStyle = () => css({
	color: `${IconColour.green} !important`,
});

const viewStyle = () => css(
	inlineOpenLinkStyle(),
	activeStyle(),
	{
//		a: {
			whiteSpace: 'nowrap'
//		}
	}
);


export interface ISpecial {
	store: Special;
}


export function SpecialPage(props: SpecialData)
{		
	const [special,setSpecial] = createStore(props.special);

	const specialProps:ISpecial = mergeProps({
		page: props.page,
		venue: props.venue,
		docId:special._id,
		store:special,
		setStore:setSpecial,
		location:[],
		permission:'edit'
	});

	const tabs = {
		'details': {label:'Details',component:DetailsTab}
	};

/* TODO create and include marketingTab.njk {% include 'App/backend/specials/marketingTab.njk' %} */

	return (
		<ThemeProvider theme={Theme}>
			<PageProvider page={props.page}>
				<BackendWrap>
					<div class={topLine()}>
						<Breadcrumbs items={{
							Admin: '/admin',
							Specials: '/admin/specials',
							[special.name ?? 'Special']: null
						}} />

{/* TODO status bar... {{ statusBar(component,PATH,special.status,special.scheduleDate,'/#specials') }} */}

{/*
		TODO currently just linking to homepage but we probably need it to link to specials on a menu page but now we have 
		separate food and drink pages in different drummer unsure where to link 
*/}

						<a href='/' class={viewStyle()} target='_blank'>
							View on website <LinkOpen class={theme().linkIcon} />
						</a>
					</div>
					
					<TabPanes tabs={tabs} tabProps={specialProps} />
				</BackendWrap>
			</PageProvider>
		</ThemeProvider>
	);
}

