import { css } from '@emotion/css';
import { palette } from 'Shared/backend/Theme';
import { createSignal, JSX, Show } from 'solid-js';
import { For } from 'solid-js';
import { Dynamic } from 'solid-js/web';


const tabsStyle = () => css({ 
	whiteSpace: 'noWrap'
});

const tabStyle = () => css({
	fontSize: 16,
	color: palette.dimText,
	border: '1px solid #eee',
	padding: '0.6em',
	display: 'inline-block',
	cursor: 'pointer',
	userSelect: 'none',

	/* Needed to clear the bottom margin: */
	position:'relative'	
});

const activeTabStyle = () => css(tabStyle(),{
	borderBottom: `1px solid ${palette.background}`,
	color: palette.darkText
});

const nestedTabStyle = () => css(tabStyle(),{
	display: 'inline-block',
	border: `1px solid ${palette.dimBackground}`
});

const nestedActiveTabStyle = () => css(tabStyle(),{
	background: palette.dimBackground,
	color: palette.darkText
});

/* The pane itself needs to be given a negative zIndex for this to work */
const clearStyle = () => css({
	position: 'absolute',
	background: 'white',
	width: '100%',
	bottom: -2,
	left: 0,
	height: 3
});

export interface ITabs {
	tabs: {[name:string]: {label:string,component:JSX.Element}};
	initial?: string;
	nested?: boolean;
	tabProps: any;
	onChange?: (tab:string) => void;
}


export function TabPanes(props:ITabs)
{		
//XXX MAY be better to pass this in (possibly optionally) from parent
	const [current,setCurrent] = createSignal(props.initial ?? Object.keys(props.tabs)[0]);

	return (<>
		<TabBar  {...props} current={current} setCurrent={setCurrent} />
		<TabPane {...props} current={current}  />
	</>);
}

export function TabBar(props)
{	
	const style = key => props.nested ?
		(key==props.current() ? nestedActiveTabStyle() : nestedTabStyle())
		:
		(key==props.current() ? activeTabStyle() : tabStyle());

	return (
		<div class={tabsStyle()}>
			<For each={Object.entries(props.tabs)}>{pair => 
				<div class={style(pair[0])} 
					onClick={() => { props.setCurrent(pair[0]); props.onChange?.(pair[0]); }}
				>
					<Show when={props.clearBorder && pair[0]==props.current()}>
						<div class={clearStyle()} />
					</Show>

					{pair[1].label}
				</div>
			}</For>
		</div>
	);
}

export function TabPane(props)
{
	return <Dynamic component={props.tabs[props.current()].component} {...props.tabProps} />;
}

