import { css, injectGlobal } from '@emotion/css';
import { Id } from 'Common/Id';
import { DeleteMessage2 } from 'Common/Messages';
import { BackendWrap } from 'Shared/backend/BackendWrap';
import { StaffResourceSectionFields } from 'Shared/backend/staffResources/StaffResourceSectionFields';
import { Theme } from 'Shared/backend/Theme';
import { theme, ThemeProvider } from 'Shared/backend/ThemeProvider';
import { standardRules } from 'Shared/common/Sanitise';
import { DeleteButton } from 'Shared/forms/DeleteButton';
import { Text } from 'Shared/forms/Inputs';
import { Overlay } from 'Shared/forms/Overlay';
import { addItem, StoreRepeater } from 'Shared/forms/StoreRepeater';
import { PageProvider, usePage } from 'Shared/frontend/PageProvider';
import { StaffResourceForPerson } from 'Shared/frontend/staffResources/StaffResourceForPerson';
import { ThemeProvider as FrontendThemeProvider } from 'Shared/frontend/ThemeProvider';
import { StaffResource } from 'Shared/view/backend/StaffResource';
import { Show } from 'solid-js';
import { createSignal } from 'solid-js';
import { mergeProps } from 'solid-js';
import { createStore } from 'solid-js/store';

const resourceHeaderStyle = () => css({
	backgroundColor: '#1b2126',
	color: 'white',
	display: 'flex',
	alignItems: 'center',
	marginBottom: '1em',
	padding: '6px 6px',

	input: {
		border: 'none',
		color: 'white'
	}
});

const resourceHeaderLabelStyle = () => css({
	color: 'white',
	whiteSpace: 'nowrap',
	fontSize: '1em',
	fontWeight: 'bold'
});

const stickyStyle = () => css({
	'repeater-bar': {
		position: 'sticky',
		top: 70,
		zIndex: 3,
		backgroundColor: 'lightGrey',

		'.fa-expand,.fa-trash': {
			color: 'black !important',
		},
	},

	'repeater-bar:hover': {
	    backgroundColor: '#a0a0a0'
	}
});

const backStyle = () => css({
	display: 'block',
    color: '#44C4A1',
    fontWeight: 'bold',
    margin: '6px 0',
    fontSize: 12,
    fontFamily: 'inherit',
    cursor: 'pointer',
	padding: '2px 0',

	':visited': {
    	color: '#44C4A1',
	}
});

export const smallButtonStyle = () => css(theme().button,{
    padding: '6px 0.6em',
    fontSize: '0.7em'
});


export function StaffResourcePage(props)
{
	injectGlobal([standardRules,{body:Theme.body}]);

	const [store,setStore] = createStore(props.staffResource);

	const itemProps = mergeProps({page: props.page,
		store: store,
		setStore: setStore,
		location: [],
		permission: 'editStaffResource',
		docId: store._id,
	});

	return (
		<ThemeProvider theme={Theme}>
			<PageProvider page={props.page}>
				<BackendWrap>
					<Content {...itemProps} />
				</BackendWrap>
			</PageProvider>
		</ThemeProvider>
	);
}

function Content(props)
{
	const page = usePage();
	const [overlay,setOverlay] = createSignal(false);
	const [current,setCurrent] = createSignal();

	const addSection = async () => {
		//XXX should really set the default values on enabling the agreement
		await addItem({...props,field:'section'},page,{});
		setCurrent(props.store.section.length - 1);
	};

	return (
		<div>
			<div>
				<a href='/admin/staffResources' class={backStyle()}> 
					Back to all resources
				</a>
			 </div>

			<div class={resourceHeaderStyle()}>
				<label class={resourceHeaderLabelStyle()}>Resource title:</label>
				<Text {...props} field='name' options={{placeholder:'___________________'}} />
			
				<button class={smallButtonStyle()} onClick={() => setOverlay(true)}>Preview Resource</button>
			</div>

			<Show when={overlay()}>
				<Overlay close={() => setOverlay(false)}>
					<FrontendThemeProvider theme={page.theme}>
						<StaffResourceForPerson {...props.store} isPreview={true} />
					</FrontendThemeProvider>
				</Overlay>
			</Show>

			<div class={stickyStyle()}>
				<repeater-pane>
					<StoreRepeater
						{...props} 
permission='editStaffResource'					
						field='section'
						renderTitle={i => <>{ i.name }</> }
						current={current}
						setCurrent={setCurrent}
					>{
						(item,index) => <StaffResourceSectionFields {...props} {...item} location={['section',index]} setOverlay={setOverlay} />
					}</StoreRepeater>
				</repeater-pane>

				<button class={css(theme().button)} onClick={addSection}><i class='fas fa-plus'/> Add section</button>
			</div>

			<DeleteButton label='Delete resource' delete={() => deleteResource(page,props.docId)} />
	</div>);
};


async function deleteResource(page,docId:Id)
{
	if (!confirm('Delete this resource?'))
		return;

	await page.server.sendOperation(
		new DeleteMessage2(StaffResource.pageName,'editStaffResource',docId)
	);

	window.pageJs('/admin/staffResources');
}

