import { css } from '@emotion/css';
import { facebookPageUrl, instagramPageUrl, websiteUrl } from 'Browser/InputTypes';
import { ISet, sectionStyle, verticalStyle } from 'Shared/backend/eventPage/artist/ArtistForm';
import { labelLinkStyle } from 'Shared/backend/eventPage/artist/ArtistDetails';
import { FacebookCircle } from 'Shared/backend/icons/FacebookCircle';
import { IconColour } from 'Shared/backend/IconColour';
import { InstagramCircle } from 'Shared/backend/icons/InstagramCircle';
import { LinkOpen } from 'Shared/backend/icons/LinkOpen';
import { WebsiteCircle } from 'Shared/backend/icons/WebsiteCircle';
import { Text } from 'Shared/forms/Inputs';
import { Wrap } from 'Shared/forms/Wrap';
import { palette } from 'Shared/backend/Theme';
import { IconPane } from 'Shared/forms/IconPane';
import { HeadingWithNote } from 'Shared/backend/eventPage/artist/HeadingWithNote';

const activeLabelStyle = () => css(labelLinkStyle, {
	color: palette.active
});

const inactiveLabelStyle = () => css(labelLinkStyle,{
	color: palette.label
});


export function SocialSites(props:ISet)
{
	return (
		<section class={sectionStyle()}>
			<HeadingWithNote
				heading='Sites & Socials'
				note='Important so VOS can find artist content'
			/>

			<div class={verticalStyle()}>
				<Facebook {...props} />
				<Instagram {...props} />
				<Website {...props} />
			</div>
		</section>
	);
}


function Facebook(props:ISet)
{
	const label = () => props.facebook ?
		<a class={activeLabelStyle()} href={`https://www.facebook.com/${props.facebook}`} target='_blank'>
			Facebook &nbsp; 
			<LinkOpen fill={IconColour.active} />
		</a>
	:
		<a class={inactiveLabelStyle()} target='_blank'
			href={searchUrl(['facebook','page',props.name,...styleTerms(props),...locationTerms(props)])}
		>
			Search Facebook for artist &nbsp;
			<LinkOpen fill={IconColour.inactive} />
		</a>;

	return (
		<IconPane>
			<FacebookCircle fill={props.facebook ? IconColour.active : IconColour.inactive} />

			<Wrap label={label()}>
				<Text {...props} field='facebook' processInput={facebookPageUrl} placeholder='Paste web link or handle here'  />
			</Wrap>
		</IconPane>
	);
}


function Instagram(props:ISet)
{
	const label = () => props.instagram ?
		<a class={activeLabelStyle()} href={`https://www.instagram.com/${props.instagram}`} target='_blank'>
			Instagram &nbsp; 
			<LinkOpen fill={IconColour.active} />
		</a>
	:
		<a class={inactiveLabelStyle()} target='_blank'
			href={searchUrl(['instagram','profile',props.name,...styleTerms(props),...locationTerms(props)])}
		>
			Search Instagram for artist &nbsp;
			<LinkOpen fill={IconColour.inactive} />
		</a> ;

	return (
		<IconPane>
			<InstagramCircle fill={props.instagram ? IconColour.active : IconColour.inactive} />

			<Wrap label={label()}>
				<Text {...props} field='instagram' processInput={instagramPageUrl} placeholder='Paste web link or handle here' />
			</Wrap>
		</IconPane>
	);
}


function Website(props:ISet)
{
	const label = () => props.website ?
		<a class={activeLabelStyle()} href={props.website} target='_blank'>
			Website &nbsp; 
			<LinkOpen fill={IconColour.active} />
		</a>
	:
		<a class={inactiveLabelStyle()} target='_blank'
			href={searchUrl([props.name,...styleTerms(props),'website',...locationTerms(props)])}
		>
			Search for artist website &nbsp;
			<LinkOpen fill={IconColour.inactive} />
		</a>;

	return (
		<IconPane>
			<WebsiteCircle fill={props.website ? IconColour.active : IconColour.inactive} />

			<Wrap label={label()}>
				<Text {...props} field='website' processInput={websiteUrl} placeholder='Paste website address here starting with http' />
			</Wrap>
		</IconPane>
	);
}

function locationTerms(props)
{
	const terms = [];
	if (props.city) terms.push(props.city);
	if (props.state) terms.push(props.state);
	if (props.country) terms.push(props.country);
	return terms;
}

function styleTerms(props)
{
	if (props.performanceType && props.performanceType.includes('comedy')) 
		return ['Comedian'];
	else if (props.performanceType && props.performanceType.includes('dj'))
		return ['DJ'];
	else
		return ['Music','Band'];
}

function searchUrl(terms:string[])
{
	let url = 'https://www.google.com/search?q=';
	let sep = '';

	for (const term of terms) 
		if (term) {
			url += sep + term;
			sep = '%20';
		}

	return url;
}

