import { css } from '@emotion/css';

const paneStyle = () => css({
	display: 'grid',
	gridTemplateColumns: '30px 1fr',
	gap: '1em'
});

const contentsStyle = () => css({
	overflow: 'auto'
});


export function IconPane(props)
{
	return (
		<div class={paneStyle()}>
			<div>
				{props.children[0]}
			</div>
			<div class={contentsStyle()}>
				{props.children[1]}
			</div>
		</div>
	);
}
