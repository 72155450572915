import { css, cx } from '@emotion/css';
import { sanitise } from 'Shared/common/Sanitise';
import { pageTheme, palette } from 'Shared/frontend/ThemeProvider';
import {createSignal, onCleanup, onMount, Show} from 'solid-js';

const arrowButtonWidth = 50;
const sideMargin = '1.5em';

const navStyle = () => css({
	display: 'flex',
	justifyContent: 'center',
	background: palette().background, 
});

const innerStyle = () => css(pageTheme().subNav.inner,{
	maxWidth: '100%',
	display: 'flex',
});

const scrollStyle = () => css({
	flex: '1 1',
	overflow: 'scroll',
	scrollbarWidth: 'none'
});

const itemsStyle = () => css({
	whiteSpace: 'nowrap',
	userSelect: 'none',
});

const arrowStyle = () => css({
	...sanitise.button,
	color: palette().text,
	backgroundColor: 'inherit',
	borderRadius: 0,
	fontSize: 16,
	padding: 0
});

const leftArrowStyle = () => css({
	width: arrowButtonWidth
});

const rightArrowStyle = () => css({
	width: arrowButtonWidth
});

/* Utility function that is useful for users */
export function jumpToAnchor(url:string,scrollAmount:number)
{
	document.location = url;
	/* -100 could be put in theme. Otherwise need to calculate from the nav above */
 	window.scrollBy(0,scrollAmount);
}


interface ISubNav {
}


//XXX for some pages could move the arrows into the page margin. 
//    Note menu page margins are narrow for small pages

export function SubNav(props:ISubNav) 
{
	const [showLeft,setShowLeft] = createSignal(true);
	const [showRight,setShowRight] = createSignal(true);

	let scrollPane!: HTMLDivElement;
	let leftArrow!: HTMLButtonElement;
	let rightArrow!: HTMLButtonElement;

	const handler = () => updateArrows(scrollPane,setShowLeft,setShowRight,showLeft());

	onMount(() => {
		window.addEventListener('resize',handler);
		updateArrows(scrollPane,setShowLeft,setShowRight,showLeft());

		onCleanup(() => window.removeEventListener('resize', handler));
	});

	return (
		<div class={navStyle()} style={{
			'padding-left':showLeft() ? 0 : sideMargin,
			'padding-right':showRight() ? 0 : sideMargin
		}}>
			<div class={innerStyle()}>
				<Show when={showLeft()}>
					<button ref={leftArrow} class={cx(arrowStyle(),leftArrowStyle())} onClick={() => scrollLeft(scrollPane)}>
						&#10094;
					</button>
				</Show>

				<div ref={scrollPane} class={scrollStyle()} 
					onScroll={() => updateArrows(scrollPane,setShowLeft,setShowRight,showLeft())}
				>
					<div class={itemsStyle()}>
						{props.children}
					</div>
				</div>

				<Show when={showRight()}>
					<button ref={rightArrow} class={cx(arrowStyle(),rightArrowStyle())} onClick={() => scrollRight(scrollPane)}>
						&#10095;
					</button>
				</Show>
			</div>
		</div>
	);
}

function updateArrows(scrollPane:HTMLElement,setShowLeft,setShowRight,showLeft)
{
//XXX Note most browsers support the 'scrollend' event, which could be used instead 

	setShowLeft(scrollPane.scrollLeft > 0);

	setShowRight(scrollPane.scrollLeft < scrollPane.scrollWidth - scrollPane.clientWidth - (showLeft ? arrowButtonWidth : 0));
}

function scrollLeft(scrollPane:HTMLElement)
{
	/* Think the browser trims these... */
	/* The 0.85 ensures a bit of overlap when paging left or right */

	scrollPane.scrollBy({left: -scrollPane.clientWidth * 0.85, behavior: 'smooth'});
	/* The scroll event will be called by the event handler */
}

function scrollRight(scrollPane:HTMLElement)
{
	scrollPane.scrollBy({left: scrollPane.clientWidth * 0.85,behavior: 'smooth'});
}

