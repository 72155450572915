
import { Match, Show, Switch } from 'solid-js'
import { DateTime } from 'luxon';
import { CleanTime, daysOfWeekly, weekday } from 'Shared/common/Macros';

export function DateAsLabel(times) 
{	
	const hide = () => times.options?.user?.hideDetails ?? [];

	return (
		<Switch>
			<Match when={times.frequency == 'once'}>
				<Show when={!hide().recordDate && times.startSingle}>
					{DateTime.fromISO(times.startSingle).toFormat('EEE, dd LLL')}
				</Show>
				<Show when={!hide().includes('startTime') && times.startSingle}>
					{' '}
					<CleanTime time={times.startSingle} />
				</Show>
			</Match>
			<Match when={times.frequency == 'daily'}>
				Every day
				<Show when={!hide().includes('startTime') && times.startTime}>
					{' '}
					<CleanTime time={times.startTime} />
				</Show>
			</Match>
			<Match when={times.frequency == 'weekly'}>
				{daysOfWeekly(times.weekdays)}

				<Show when={!hide().includes('startTime') && times.startTime}>
					{' '}
					<CleanTime time={times.startTime} />
				</Show>

				<Show when={!hide().includes('endTime') && times.endTime}>
					{' - '}
					<CleanTime time={times.endTime} />
				</Show>
			</Match>
			<Match when={times.frequency == 'monthly'}>
				{times.week}
				{' '}
				{weekday(times.weekday)} of month,

				<Show when={!hide().includes('startTime') && times.startTime}>
					{' '}
					<CleanTime time={times.startTime} />
				</Show>

				<Show when={!hide().includes('endTime') && times.endTime}>
					{' - '}
					<CleanTime time={times.endTime} />
				</Show>
			</Match>
		</Switch>
	);
}

