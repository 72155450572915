
/*
	Styles to be injected globally.
	This is the last resort.
*/
export const standardRules = {
	'*': {
		boxSizing: 'border-box'
	}
};

/*
	Remove stupid default browser styling.
	Mostly site themes should use these via composition.
	If pages don't want the theme settings they can use the sanitised settings directly.
*/
export const sanitise = {
	body: {
		/* Prevent horizontal movement, especially important for mobile */
//		overflowX: 'hidden'  XXX wide pages may need a scroll...
	},

	a: {
		textDecoration: 'none'
	},

	button: {
		cursor: 'pointer',
		appearance: 'none',
		border: 'none',
		fontFamily: 'inherit',
		/* Chrome buttons set this down to 0.8em */
		fontSize: '1em'
	},

	iframe: {
		border: 'none',
	},

	blockquote: {
		margin: 0
	},

	address: {
		fontStyle: 'normal'
	},

	list: {
		padding: 0,
		margin: 0,

		li: {
			listStyle: 'none',
			padding: 0
		}
	},

	definitions: {
		margin: 0,

		dt: {
			display: 'inline-block',
			padding: 0,
			margin: 0
		},
		dd: {
			display: 'inline-block',
			padding: 0,
			margin: 0
		}
	}


	/* Remove dotted border in select elements in FF: */
/*	
	'select:focusring': {
		color: 'transparent',
		textShadow: '0 0 0 #000'
	},
*/	
};
