import {css, injectGlobal} from '@emotion/css';
import {CreateMessage2, DeleteMessage2} from 'Common/Messages';
import {VenueUrls} from 'Common/VenueUrls';
import {EditComponent} from 'Common/components/EditComponent';
import { Location } from 'Common/config/PageConfigTypes';
import {PageProvider, usePage} from 'Shared/frontend/PageProvider';
import {BackendWrap} from 'Shared/backend/BackendWrap';
import {Checkbox, Html, SingleSelect, Text} from 'Shared/forms/Inputs';
import { labelStyle, Wrap } from 'Shared/forms/Wrap';
import {IStoreRepeaterDelete, StoreRepeater, addItemAndOpen, deleteItem} from 'Shared/forms/StoreRepeater';
import {MenuDoc} from 'Shared/model/Menu';
import {MenuData} from 'Shared/view/backend/MenuDesigner';
import {Show} from 'solid-js';
import {For,Switch,Match,createSignal,mergeProps} from 'solid-js';
import {createStore} from 'solid-js/store';
import {locateSubDocument} from 'Common/ViewUtils';
import { repeaterColour } from 'Shared/forms/Repeater';
import { textColour } from 'Shared/backend/Theme';
import { theme, ThemeProvider } from 'Shared/backend/ThemeProvider';
import { standardRules } from 'Shared/common/Sanitise';
import { Theme } from 'Shared/artists/Theme';


const menuTitleStyle = () => css({
	display: 'block',
	fontSize: 30,
	margin: '20px 0',
	color: textColour,
	'&:visited': {
		color: textColour
	}
});

const sectionStyle = () => css({
	borderLeft: `5px solid ${repeaterColour}`,
	paddingLeft: 8,
	display: 'flex',
	flexDirection: 'column',
	gap: '1em'
});

export const singleLineStyle = () => css({
	display: 'flex',
	alignItems: 'center',
	gap: 10
});

export const productTypes = {
	food: 'Food',
	drinks: 'Drink',
	beers: 'Beer',
	wines: 'Wine'
};

export const sectionProductTypes = {
	mixed: 'Mixed',

	food: 'Food',
	drinks: 'Drinks',
	beers: 'Beers',
	wines: 'Wines'
};

const htmlEditorOptions = {
	height: 200,
	menubar: false,
	plugins: ['link'],
	toolbar: 'removeformat | undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent | link ',
	statusbar: true,
	resize: true 
};

const verticalStyle = () => css({
	display: 'flex',
	flexDirection: 'column',
	gap: '1.5em'
});

const sectionsHeadingStyle = () => css(labelStyle(), {
	display: 'block',
	marginTop: '2em'
});

const buttonRowStyle = () => css({
	display: 'flex',
	gap: '0.3em'
});


export type MenuDesignerProps = MenuDoc & { 
	setStore: (...args: any[]) => void,
	editArtist: EditComponent<MenuData,MenuDoc>
};


export function MenuDesignerPage(props)
{
	injectGlobal([standardRules,{body:Theme.body}]);

	const [menusStore,setMenusStore] = createStore(props.menus);

	const [current,setCurrent] = createSignal(undefined);

	return (
		<ThemeProvider theme={Theme}>
			<PageProvider page={props.page}>
				<BackendWrap>
					<p>
						<a class={css(theme().a)} href='/admin/menus'>Go to menu items</a>
					</p>

					<div>
						<h1>Menu Designer</h1>				

						<Show when={menusStore.length > 0} fallback={<>There are no menus yet</>}>
							Available menus:
						</Show>

						<For each={menusStore}>
							{(menu,index) => {
								const menuStore = () => menusStore[index()];
								const setMenuStore = (...args) => setMenusStore(index(),...args);
								const menuProps = mergeProps(menuStore,{setStore: setMenuStore});

								return (<>
									<Switch>
										<Match when={index()==current()}>
											<a href=''onClick={() => setCurrent(undefined)} class={menuTitleStyle()}>&#x2795; {menu.title}</a> 
											<Menu
												page={props.page}
												permission='edit'
												menusStore={menusStore} setMenusStore={setMenusStore}
												store={menuProps} setStore={setMenuStore}
												location={[]}
												setCurrent={setCurrent} docId={menu._id} 
											/>
										</Match>
										<Match when={true}>
											<a href=''onClick={() => setCurrent(index())} class={menuTitleStyle()}>&#x2796; {menu.title}</a>
										</Match>
									</Switch>
								</>);
							}}
						</For>

						<a class={css(theme().a)} href='' onClick={() => createMenu(props,menusStore,setMenusStore,setCurrent)}>Add menu</a>
					</div>
				</BackendWrap>
			</PageProvider>
		</ThemeProvider>
	);
}

function Menu(props)
{
	const page = usePage();

	const url = () => '/menu/'+props.index;

	const title = i => typeof i.widget != 'undefined' ? 'WIDGET: '+widgetOptions[i.widget] : i.title;

	let itemsNode!:HTMLElement;

	return <div>
		<MenuFields {...props} />

		<label class={sectionsHeadingStyle()}>Sections</label>

		<div>
			<StoreRepeater {...props} ref={itemsNode} page={page} permission='sections' location={[]} field='sections' renderTitle={title} 
				deleteItem={(event,index:number) => deleteSection(event,{...props,page:page,location:[],permission:'sections'},index)}
			>
				{(item,index) => <SectionsAndWidgets {...props} location={['sections',index]} url={url()} index={index} />}
			</StoreRepeater>

			<div class={buttonRowStyle()}>
				<button class={css(theme().button)}
					onClick={() => addItemAndOpen({...props,permission:'sections',location:[],field:'sections',page:page},itemsNode,{})}
				>
					<i class='fas fa-plus'/> Add section
				</button>

				<button class={css(theme().button)}
					onClick={() => addItemAndOpen({...props,permission:'sections',location:[],field:'sections',page:page}, itemsNode, {widget: 'buttonRow'})}
				>
					<i class='fas fa-plus'/> Add widget
				</button>
			</div>
		</div>

		<p><a class={css(theme().a)} href='' onClick={() => deleteMenu(page,props)}>Delete menu</a></p>
	</div>
}

async function deleteSection(event:Event,props:IStoreRepeaterDelete,index:number)
{
	event.stopPropagation();

	const items = locateSubDocument(props.store,[...props.location,'sections',index,'items']) ?? [];
	const sections = locateSubDocument(props.store,[...props.location,'sections',index,'sections']) ?? [];

	if (items.length > 0 || sections.length > 0) {
		alert('You need to delete out the menu items, subsections and widgets first');
		return;
	}

	if (!confirm('Are you sure you wish to delete this item?'))
		return;

	await deleteItem({...props,field:'sections',page:props.page},index);
}

function SectionsAndWidgets(props)
{
	const data = () => locateSubDocument(props.store,props.location);

	return <div class={sectionStyle()}>
		<Switch>
			<Match when={typeof data().widget != 'undefined'}>
				<WidgetFields {...props} />
			</Match>

			<Match when={true}>
				<SectionContents {...props} />
			</Match>
		</Switch>
	</div>
}

function MenuFields(props)
{
	const urls = new VenueUrls(usePage().build,usePage().site);

	return (
		<div class={verticalStyle()}>
			<Wrap label='Menu title' required={true}>
				<Text {...props} field='title' />
			</Wrap>

			{/* TODO auto-populate by default. cf having an enable checkbox */}
			<Wrap label='URL slug' required={true}>
				<Text {...props} field='slug' />
			</Wrap>

			<Wrap label='Product type' required={true} disable={true}>
				<SingleSelect {...props} field='productType' options={sectionProductTypes} />
			</Wrap>

			<Wrap label='Teaser'>
				<Text {...props} field='teaser' placeholder='Optional'/>
			</Wrap>

			<Wrap label='Tagline'>
				<Text {...props} field='tagline' placeholder='Optional'/>
			</Wrap>

			<Wrap label='Details'>
				<Html {...props} field='details' placeholder='Optional' urls={urls} options={htmlEditorOptions} />
			</Wrap>
		</div>
	);
}

const widgetOptions = {buttonRow:'Button Row',specials:'Specials'};

function WidgetFields(props)
{
	const data = () => locateSubDocument(props.store,props.location);

	return (<>
		<Wrap label='Widget type' required={true}>
			<SingleSelect {...props} field='widget' required={true} options={widgetOptions} />
		</Wrap>

		<Switch>
			<Match when={data().widget == 'buttonRow'}>
				<ButtonRow {...props} />
			</Match>
			<Match when={data().widget == 'specials'}>
				SPECIALS - could put in tagline etc
			</Match>
		</Switch>
	</>);
}

function ButtonRow(props)
{
	const page = usePage();
	let itemsNode!: HTMLElement;

	/* XXX
		'Menu Shortcuts' vs 'Shortcuts' - I'm thinking menu shortcuts might have the food/drinks colour distinction.
		The stickiness could potentially be subsumed within 'Menu Shortcuts'.
	 */
	const rowStyleOptions = {
		food: 'Food',
		drinks: 'Drinks',
		plain: 'Plain'
	};

	return (
		<>
			<Wrap label='Sticky'>
				<Checkbox {...props} field='sticky' />
			</Wrap>

			<div>
				<StoreRepeater {...props} ref={itemsNode} page={page} permission='sections' location={props.location} field='buttons' renderTitle={i => i.label } >
					{(item,index) => 
						<div class={verticalStyle()}>
							<Wrap label='Label'>
								<Text {...props} location={[...props.location,'buttons',index]} field='label' required={true} />
							</Wrap>

							<Wrap label='Style'>
								<SingleSelect {...props} location={[...props.location,'buttons',index]} field='style' required={true} options={rowStyleOptions} />
							</Wrap>

							<Wrap label='URL'>
								<Text {...props} location={[...props.location,'buttons',index]} field='url' required={true} />
							</Wrap>
						</div> 
					}
				</StoreRepeater>

				<button class={css(theme().button)}
					onClick={() => addItemAndOpen({...props,page:page,permission:'sections',location:props.location,field:'buttons'}, itemsNode,{})}
				>
					<i class='fas fa-plus'/> Add button
				</button>
			</div>
		</>
	);
}

function SectionContents(props)
{
	const page = usePage();

	const url = () => props.url+'/'+props.index;

	let itemsNode!:HTMLElement;

	return <>
		<SectionFields {...props} />

		<label class={sectionsHeadingStyle()}>Sections</label>

		<div>
			<StoreRepeater {...props} ref={itemsNode} page={page} permission='sections' location={props.location} field='sections' renderTitle={i => i.title} 
				deleteItem={(event,index:number) => deleteSection(event,{...props,page:page,permission:'sections'},index)}
			>
				{(item,index) => <SectionsAndWidgets {...props} location={[...props.location,'sections',index]} url={url()} index={index} />}
			</StoreRepeater>

			<div class={buttonRowStyle()}>
				<button class={css(theme().button)}
					onClick={() => addItemAndOpen({...props,page:page,permission:'sections',location:props.location,field:'sections'}, itemsNode,{})}
				>
					<i class='fas fa-plus'/> Add section
				</button>

				<button class={css(theme().button)}
					onClick={() => addItemAndOpen({...props,page:page,permission:'sections',location:props.location,field:'sections'}, itemsNode, {widget: 'buttonRow'})}
				>
					<i class='fas fa-plus'/> Add widget
				</button>
			</div>
		</div>
		{/*TODO add a more cautious delete*/}
	</>;
}

function SectionFields(props)
{
//TODO 'required' should be read from the input field by default. Possibly overridable in Wrap

//FIXME - if ancestor is not 'mixed' fixed descendant product types
//					<SingleSelect {...props} field='productType' options={productTypes} value={parentProductType} disable={true} />

	const urls = new VenueUrls(usePage().build,usePage().site);
	const parentProductType = calculateProductType(props.store,props.location.slice(0,-1));  //TODO memoise

	return (<>
		<Wrap label='Title' required={true}>
			<Text {...props} field='title' required={true} />
		</Wrap>

		<Switch>
			<Match when={parentProductType == 'mixed'}>
				<Wrap label='Product type' required={true}>
					<SingleSelect {...props} field='productType' options={sectionProductTypes} />
				</Wrap>
			</Match>
			<Match when={true}>
				<Wrap classes={singleLineStyle()} label='Product type' disable={true}>
					<div>{sectionProductTypes[parentProductType]}</div>
				</Wrap>
			</Match>
		</Switch>

		<Wrap label='Teaser'>
			<Text {...props} field='teaser' placeholder='Optional' />
		</Wrap>

		<Wrap label='Tagline'>
			<Text {...props} field='tagline' placeholder='Optional'/>
		</Wrap>

		<Wrap label='Details'>
			<Html {...props} field='details' placeholder='Optional' urls={urls} options={htmlEditorOptions} />
		</Wrap>
	</>);
}

//FIXME use createResource() I guess (necessary?)
async function createMenu(props,menusStore,setMenusStore,setCurrent)
{
	const {id} = await props.page.server.sendOperation(new CreateMessage2(props.page.name(),'createMenu',{}));

	const numMenus = menusStore.length;
	setMenusStore([...menusStore, {_id:id}]);
	setCurrent(numMenus);
}

async function deleteMenu(page,props)
{
//TODO probably have an archive option instead. Maybe allow deletion after a day or something.

	const sections = props.store.sections ?? [];
	const items = props.store.items ?? [];

	if (sections.length > 0 || items.length > 0) {
		alert('You need to delete out the menu items, subsections and widgets first');
		return;
	}

	if (!confirm('Are you sure you wish to delete this menu?'))
		return;

    await page.server.sendOperation(new DeleteMessage2(page.name(),'deleteMenu',props.store._id));

	const i = props.menusStore.findIndex(e => e._id == props.store._id);
	props.setMenusStore([...props.menusStore.slice(0,i), ...props.menusStore.slice(i+1)]);
	props.setCurrent(undefined);
}

export function calculateProductType(store,location:Location)
{
	if (store.productType!=undefined && store.productType!='mixed')
		return store.productType;

	let data = store;
	for (let i=0; i<location.length; i++) {
		data = data[location[i]];

		if (data.productType!=undefined && data.productType!='mixed')
			return data.productType;
	}

	return 'mixed';
}

