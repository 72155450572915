
import {PageFunctions} from 'Browser/PageFunctions';
import {VenueUrls} from 'Common/VenueUrls';
import {usePage} from 'Shared/frontend/PageProvider';
import {stripZeros} from 'Shared/backend/menu/Price';
import {For, Show} from 'solid-js';
import {unwrap} from 'solid-js/store';
import { css } from '@emotion/css';
import { palette, sizes, theme } from 'Shared/frontend/ThemeProvider';


const itemStyle = (isPreview:boolean) => css({
	display: 'block',
	maxWidth: isPreview ? 'min(100%,450px)' : '450px',
	backgroundColor: palette().dimBackground,

	[`@media (max-width:${sizes().flipToMobileNav}px)`]: {
    	width: '100vw', 
	}
});

const imageStyle = () => css({
	width: '100%',
});

const detailsWrapStyle = () => css({
    display: 'flex',
    padding: '1em',
    flexDirection: 'column',
    justifyContent: 'space-between',
	fontSize: 24,
	letterSpacing: '0.1em',
	lineHeight: 1.2,

	['@media (max-width: 600px)']: {
		padding: 10
	},

	[`@media (min-width:${sizes().flipToMobileNav}px)`]: {
		/* Using a margin here causes a problem in preview */
		paddingBottom: '2em'
	},
});

const titleWrapStyle = () => css({
	marginBottom: '0.5em'
});

const titleStyle = (section) => css(
	theme().menu.item.title,
	theme().menu.item.titleType(section)
);

const smallStyle = () => css({
	fontSize: '0.7em'
});

const unitStyle = () => css({
	fontSize: '0.8em'
});

const priceInnerStyle = () => css({
	textAlign: 'right'
});

const priceWrapStyle = () => css(theme().menu.item.price,{
	marginTop: '1em'
});

const descriptionStyle = () => css({
	fontSize: '0.8em'
});	


export function OverlayItem(props) 
{
//TODO helper function	
	const imageUrl = () => {
		if (!props.item.image?.hash)
			return '';

		const url = (new PageFunctions()).createImageUrl2(
			new VenueUrls(usePage().build,usePage().site.key),
			props.item.image.hash,
			props.item.image,
			'MenuItem-image',
			'medium'
		);
		return url;
	};

	return (<>
		<div class={itemStyle(props.isPreview)}>
			<img class={imageStyle()} src={imageUrl()} />

			<div class={detailsWrapStyle()}>
				<TitleWrap {...props} />

				<div class={descriptionStyle()}>
					{props.item.tagline}
				</div>

				<MenuItemPrices item={props.item}/>
			</div>
		</div>
	</>);
}

export function TitleWrap(props)
{
	const tags = () => unwrap(props.item.tags) ?? [];

	//FIXME townie specific
	const tagsStr = () => tags().filter(i => i!='0%' && i!='☺').join(' ');

	return (
		<div class={titleWrapStyle()}>
			<span class={titleStyle(props.section)}>
				{props.item.brandName} {props.item.name}
				<Show when={tags().includes('0%')}>
					{' '}0%
				</Show>
				<Show when={tags().includes('☺')}>
					{' '}☺
				</Show>
			</span>
			<Show when={props.item.variety}>
				{' '}
				<span class={smallStyle()}>{props.item.variety} {props.item.varietyOther}</span>
			</Show>
			<Show when={tagsStr().length > 0 }>
				{' '}
				<span class={smallStyle()}> 
					({tagsStr()})
				</span>
			</Show>

			<Show when={props.item.year || props.item.region || props.item.state || props.item.country }>
				{' '}
				<span class={smallStyle()}>
					{props.item.year} {props.item.region} {props.item.state?.toUpperCase()}
					<Show when={props.item.country != 'Australia'}>
						{' '}
						{props.item.country}
					</Show>
				</span>
			</Show>
		</div>
	);
}

export function MenuItemPrices(props)
{
	return (
		<div class={priceWrapStyle()}>
			<For each={props.item.prices}>{ unit =>
				<Show when={unit.price !== null }>
					{/* XXX probably shouldnt be required but currently these items contain nulls */}
					<div class={priceInnerStyle()}>
						{/* Only 1 of these unit options will appear */}
						<span class={unitStyle()}>{capitalise(unit.size)}{capitalise(unit.description)}{' '}</span>
						<span>${stripZeros(unit.price)}</span>
					</div>
				</Show>
			}</For>
		</div>
	);
}

function capitalise(s:string) 
{
	const str = s ?? '';
    return str.charAt(0).toUpperCase() + str.slice(1);
}

