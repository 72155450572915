import FileTypes from 'Browser/FileTypes';
import {ILinks,Urls,hostUrl} from 'Common/Urls';


export class ArtistUrls extends Urls
{
	/* Some test systems use a mix of dev images and production images (readonly). */
	imageUrl(assetFolder:string,hash:string,size:string,fileType:string,useProduction:boolean,proxyProductionAssets:boolean)
	{
		let source = `/_assets`;

		if (this.build.key=='DEV') {
			source = `/_devAssets`;
			if (useProduction)
				source = proxyProductionAssets ? `/_proxyAssets` : `https://dummy.ontoit.xyz/_assets`;
		}
		else if (!useProduction)
			/* Should be in a build or live test system here, not in prodution */
			source = `/_devAssets`;

		return source+'/artists/'+assetFolder+'/'+hash+'-'+size+'.'+FileTypes.suffix(fileType);
	}

	urls():ILinks
	{
		const jsUrls = [
			{url: this.buildUrl('backend.js')}
		];

		const cssUrls = [
			{url: this.buildUrl('resources/fontawesome/css/all.min.css')},
			{url: this.buildUrl('resources/nano.min.css')},
			{url: this.buildUrl('resources/tom-select.min.css')},

			//XXX used in artist profiles:
			{url: this.buildUrl('resources/cropper.min.css')},
		];

		const prefixes = {
			resources: this.buildUrl('resources')
		};

		return {js:jsUrls,css:cssUrls,prefixes:prefixes};
	}
}

