import { css } from "@emotion/css";
import {palette as palette2} from 'Shared/artists/Palette';
import { sanitise } from 'Shared/common/Sanitise';

/* Palette */
//XXX try not to define these by function. The .tsx files provide the function aspect
export const palette = {
	background: 'white',
	dimBackground: '#f5f5f5',   //XXX alt name - could be 'shaded'
	darkText: 'black',  //TODO ==> 'text'
	dimText: '#aaa',		// e.g. disabled

	label: '#666',

	//XXX cf relationship with IconColour
	active: '#44C4A1',
	inactive: 'lightgrey',

	link: '#005394',
	error: 'red'
};



/*
	Media queries, adapted from https://www.w3schools.com/howto/howto_css_media_query_breakpoints.asp 
 */
export const phone = `@media (max-width: 600x)`; 
/* Tablets in portrait mode + large phones */
export const tabletOrDesktop = `@media (min-width: 600px)`; 
export const phoneOrTablet = `@media (max-width: 992px)`; 
/* Desktops and laptops */
export const desktop = `@media (min-width: 992px)`; 


export const maxBodyWidth = '1100px';
export const textColour = '#000000'; 
export const headerBackground = '#1b2126';
export const headerText = 'white';
export const navHeight = '70px';
export const subHeaderBackground = '#fafafa';
export const subHeaderText = '#000000';
export const borderTop = subHeaderBackground;
export const alertColour = 'red'; 
export const borderBackgroundColour = '#1b2126';
export const backgroundColour = 'white';

export const navTextColour = 'white';
export const topBarHeight = '2em';

//XXX there is a palette...
export const linkColour = '#005394'; 
export const shadingColour = '#f5f5f5';  
export const tab3Colour = palette.dimBackground;

//XXX cf using a minimal 'sanitise' class in htmlClasses instead...


export const sizes = {
	mobile: 600
};

export const Theme = {
	body: {
		...sanitise.body,

		margin: 0,
		width: '100%',
		minWidth: '100%',
		font: '15px "Open Sans",Helvetica,Arial,sans-serif',
		backgroundColor: 'white',
		color: 'black'
	},

	fieldWrap: {
		display: 'block',
		marginBottom: 15,
		width: '100%',
		padding: 6
	},

//	'button, input[type="submit"], input[type="button"]': {
	button: {
		textAlign: 'center',
		verticalAlign: 'middle',
		backgroundColor: palette2.button,
//	backgroundColor: '#7e94a5',
		borderRadius: 3,
		border: '1px solid #728a9d',
		color: '#FFF',
		padding: '8px 12px',
		fontSize: 13,
		fontWeight: 400,
		lineHeight: '16px',
		userSelect: 'none',
		whiteSpace: 'noWrap',

		'&:hover': {
			cursor: 'pointer',

			backgroundColor: '#627b8e',
			borderColor: '#566b7c',
		},

		'&[disabled]': {
			filter: 'brightness(125%)',

			'&:hover': {
				cursor: 'not-allowed'
			}
		},

		'&:focus': {
			border: `solid 1px brightness(70%)`
		}
	},

	a: {
		color: palette.link,
		cursor: 'pointer',

		'&:visited': {
			color: palette.link
		}
	},

	section: {
		heading: {
			color: 'black',
			textTransform: 'uppercase',
			fontWeight: 600,
			margin: '12px 0'
		}
	},

	menu: {
		heading: {
			fontSize: '1.1em',
			fontWeight: 'normal',
			margin: '2em 0 1em 0',
			color: '#7e94a5'
		}
	},

	detailsPane: {
		border: '1px solid #eee',
		borderBottom: 'none',
		padding: '1em 1em 0 1em',
		minHeight: 315,
		/* Allow the active tab to cut of hole in the bottom border */ 
		zIndex: -1
	},

	linkIcon: {
		width: '2em'
	}
};


//TODO fold this into Theme class...

export const inlineOpenLinkStyle = () => css({
	display: 'inline-flex',
	alignItems: 'center',
	gap: '0.5em',
	padding: '0 0.6em'
});

