import { css } from '@emotion/css';
import { emailTypes } from 'Shared/frontend/emailTypes';
import { theme } from 'Shared/frontend/ThemeProvider';
import { Show,For } from 'solid-js'


export function Emails(props)
{
	return (
		<dl class={css(theme().list)}>
			<For each={props.venue.email}>{ email =>
				<li>
					{email.type!='custom' ? emailTypes[email.type] : email.customLabel}

					<Show when={email.tips}>
						({email.tips})
					</Show>
					:
					{' '}
					{email.email}
				</li>
			}</For>
		</dl>
	);
}
