import { css } from '@emotion/css';
import { DayLabels, dayLabels } from 'Shared/frontend/DayLabels';
import { formatDateTime } from 'Shared/common/Macros';
import { pageTheme, sizes, theme } from 'Shared/frontend/ThemeProvider';
import { For, Match, Show, Switch } from 'solid-js'

const businessHoursStyle = () => css({
	width: '100%',
	marginBottom: '1em',

	[`@media (min-width: ${sizes().desktopWidth}px)`]: {
		textAlign: 'left'
	},

	[`@media (min-width: ${sizes().flipToMobileNav}px)`]: { 
		width: '50%',
		float: 'right'
	}
});

const headingStyle = () => css(pageTheme().section.heading, {
	marginTop: 0,

	[`@media (min-width: ${sizes().desktopWidth}px)`]: {
		/* Trim the empty space from the text so the top aligns with the venue photo */
		marginTop: -12
	}
});

const listStyle = () => css(theme().list);

const subHeadingStyle = () => css(pageTheme().section.heading, {
    fontSize: '1em',
    margin: '2em 0 1em 0',
	color: 'inherit',
	fontWeight: 700,
	textTransform: 'none'
});



export function BusinessHours(props)
{
	const daysList = (days: (keyof DayLabels)[]) => {
		let result = ''
		let sep;
		for (let i=0; i < days.length; i++) {
			if (i == days.length - 1)
				sep = ': ';			//XXX ????
			else if (i == days.length - 2)
				sep = ' and ';
			else
				sep = ', ';

			result += `${dayLabels[days[i]]}${sep}`;
		}
		return result;
	};

	return (
		<div class={businessHoursStyle()}>
			<h3 class={headingStyle()}>Business Hours</h3>

			<ul class={listStyle()}>
				<For each={props.venue.operatingHours}>{ j =>
					<li>
						<Switch>
							<Match when={j.createSectionHeading}>
								<h5 class={subHeadingStyle()}>{j.sectionHeading}</h5>
							</Match>
							<Match when={j.customLabel}>
								{j.customLabel}
							</Match>
							<Match when={true}>
								{j.daysopen=='other' ? j.otherDays : daysList(j.daysopen ?? []) }

								<Show when={j.opens} fallback='Closed'> 
									{formatDateTime(j.opens,'h:mma')}
									- {j.closes ? formatDateTime(j.closes,'h:mma') : 'Late'}
								</Show>
							</Match>
						</Switch>
					</li>
				}</For>
			</ul>
		</div>
	);
}

