import { css } from '@emotion/css';
import { anchorId } from 'Shared/frontend/staffResources/StaffResourcePage';
import { pageTheme } from 'Shared/frontend/ThemeProvider';
import { Show } from 'solid-js'
import { For, Match, Switch } from 'solid-js'

const contentStyle = () => css({
	padding: '2em 2em 4em 2em',
	background: 'white',
	color: 'black',

	maxWidth: '100%',
	/* This overflow has to sit inside the sticky headers :-( */
	overflowX: 'auto',
});

const embeddedStyle = () => css(pageTheme().embeddedHtml);

const sectionHeadingStyle = (isPreview:boolean) => css({
    width: '100%',
    position: 'sticky',
    top: isPreview ? 30 : 60,
    zIndex: 1,
    padding: '1em',
    fontSize: '1.2em',
    background: 'lightGrey',
    color: 'black',
	lineHeight: 1
});

//TODO in time use the standard BACKEND button styling
const buttonStyle = () => css({
	display: 'inline-block',
	minWidth: 'fit-content',
	maxWidth: '100%',
	border: 'none',
	margin: 0,
	borderWidth: 1,
	cursor: 'pointer',
	textAlign: 'center',
	appearance: 'none',
	borderRadius: '0.4em',
	padding: '0.44em 2.25em',
	backgroundColor: '#2e3234',
	color: '#e2e2e2',
	fontFamily: 'Questrial, sans-serif',   //FIXME only a Townie thing
	fontSize: 16
});

const headingStyle = () => css({
	background: '#444343',
	color: '#e5e6e7',
	fontSize: '2em',
	margin: 0,
	padding: '1em',
	textAlign: 'left',
	lineHeight: '1.3em',
	textTransform: 'uppercase',
	fontFamily: 'EB Garamond, sans-serif',
	fontWeight: 500,
	borderColor: '#DAB670'
});

const previewBarStyle = () => css({
	backgroundColor: 'black',
	position: 'sticky',
	width: '100%',
	top: 0,
	height: 30,
	zIndex: 10,
	textAlign: 'center'
});

const previewLinkStyle = () => css({
	display: 'block !important',
	textTransform: 'capitalize',
	textDecoration: 'underline',
	color: '#44C4A1 !important',
	paddingTop: 3
});


export function StaffResourceForPerson(props)
{
	const buttonLabel = section => {
		if (section.gDriveDownloadLink)
			return section.gDriveButton ?? 'Download file';
		else if (section.linkURL)
			return section.linkButton ?? 'Open';
	};

	const resourceLink = section => {
		if (section.gDriveDownloadLink)
			return section.gDriveDownloadAsPDF ?
				section.gDriveDownloadLink.replace('/edit?usp=sharing','/export?format=pdf') :
				section.gDriveDownloadLink;

		return section.linkURL;
	};

	return (
		<>		
			<Show when={props.isPreview}>
				<div class={previewBarStyle()}>
					<a class={previewLinkStyle()} href={`/staffResources#${anchorId(props.name)}`} target='_blank'>View on Staff Hub</a>
				</div>
			</Show>

			<div>
				<h3 class={headingStyle()}>{props.name}</h3> 
				
				<div id={anchorId(props.name)} />

				<For each={props.section}>{(section,index) => <>
					<div class={sectionHeadingStyle(props.isPreview)}>
						<Switch>
							<Match when={section.name}>
								<Show when={props.section.length > 1}>
									{index() + 1}{' '}
								</Show>
								{props.name} - {section.name}
							</Match>
							<Match when={true}>
								{props.name}
							</Match>
						</Switch>
					</div>

					<div class={contentStyle()} >
{/* XXX this && part doesnt look right */}						
						<Show when={section.contentType?.includes('richText') && section.richText}>
							<div innerHTML={section.richText} class={embeddedStyle()} />
						</Show>

{/* XXX this && part doesnt look right */}						
						<Show when={section.contentType?.includes('embed') && section.embed}>
							<div innerHTML={section.embed} class={embeddedStyle()} />
						</Show>

						<Show when={section.contentType?.includes('gDriveDownload') || section.contentType?.includes('link')}>
							<div>
								<a href={resourceLink(section)} target='_blank'>
									<button class={buttonStyle()}>
										{buttonLabel(section)}
									</button>
								</a>
							</div>
						</Show>
					</div>
				</>}</For>
			</div>
		</>		
	);
}

