import {DifferentDrummerRoutes} from 'Shared/routes/DifferentDrummerRoutes';
import {DifferentDrummerView} from 'Shared/view/DifferentDrummerView';
import {BuildBasics} from 'Common/BuildBasics';
import {Links} from 'Shared/config/DifferentDrummerLinks';
import {Nav as DifferentDrummerNav} from 'Shared/config/DifferentDrummerNav';
import {IVenueConfig} from 'Shared/config/IVenueConfig';
import {VenueBasics} from 'Common/VenueBasics';
import { DifferentDrummerTheme } from 'Shared/frontend/DifferentDrummerTheme';



export class DifferentDrummerConfig implements IVenueConfig
{
	theme() { return (new DifferentDrummerTheme()).settings(); }

	view() { return (new DifferentDrummerView()).view(); }

	link() { return Links; }

	nav() { return DifferentDrummerNav; }

	routes(inBrowser:boolean,build:BuildBasics,venue:VenueBasics)
	{
		return (new DifferentDrummerRoutes(inBrowser,build,venue)).routes();
	}
}
