import { css } from '@emotion/css';

const wrapStyle = () => css({
	display: 'flex',
	justifyContent: 'flex-end',
	margin: '2em 0'
});

const deleteButtonStyle = () => css({
	textTransform: 'uppercase',
	textDecoration: 'none',
	fontSize: '0.8em',
	border: 'solid 1px',
	padding: 2,
	color: 'rgb(0, 83, 148)',

	':hover': {
		background: 'red',
		color: 'white'
	}
});

export function DeleteButton(props)
{
	return (
		<div class={wrapStyle()}>
			<a href='' class={deleteButtonStyle()} onClick={props.delete}>{props.label}</a>
		</div>
	);
}
