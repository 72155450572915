import { ClockCircle } from 'Shared/backend/icons/ClockCircle';
import { ExclamationCircle } from 'Shared/backend/icons/ExclamationCircle';
import { IconColour } from 'Shared/backend/IconColour';
import { InfoCircle } from 'Shared/backend/icons/InfoCircle';
import { TickCircle } from 'Shared/backend/icons/TickCircle';
import { Time } from 'Shared/forms/DateTimeInputs';
import { Checkbox, SingleSelect, Text } from 'Shared/forms/Inputs';
import { Wrap } from 'Shared/forms/Wrap';
import { LineupSet } from 'Shared/model/lineup';
import {  createSignal, Match, Show, Switch } from 'solid-js';
import {DateTime} from 'luxon';
import { Booking } from 'Shared/backend/eventPage/artist/Booking';
import { Location } from 'Common/config/PageConfigTypes';
import { SetStoreFunction } from 'solid-js/store';
import { ArtistDetails } from 'Shared/backend/eventPage/artist/ArtistDetails';
import { css} from '@emotion/css';
import { IconPane } from 'Shared/forms/IconPane';

const headerStyle = () => css({
	position: 'sticky',
	top: 0,
	zIndex: 10,
	display: 'flex',
	flexDirection: 'row',
	alignItems: 'center',
	gap: '0 1em',
	height: '3.5em',
	marginBottom: '1em',
	padding: '0 6px',
	color: 'white',
	background: '#1b2126'
});	

const formStyle = () => css({
	display: 'flex',
	flexDirection: 'column',
	backgroundColor: 'white',
    padding: '0 2em 4em 2em'
});

const subHeaderStyle = () => css({
	position: 'sticky',
	zIndex: 10,
	display: 'flex',
	justifyContent: 'space-between',
	color: '#000000',
	fontWeight: 'bold',
	background: '#eee',
	top: '3.5em',
	alignItems: 'center',
	cursor: 'pointer',

	minHeight: '4em',
	padding: '0 1em 0 0.5em',

	'& > div': {
		display: 'flex',
		alignItems: 'center',
		gap: '1em'
	},

	i: {
		fontSize: '1.5em'
	},

	svg: {
		width: '2em'
	}
//
});

const layoutStyle = () => css({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'flex-start',
	flexWrap: 'wrap'
});

export const verticalStyle = () => css({
	display: 'flex',
	flexDirection: 'column',
	gap: '1em'
});

const topDetailsStyle = () => css({
	display: 'flex',
	flexDirection: 'column',
	gap: '2em',
	marginBottom: '1em'
});

const lowerContentStyle = () => css({
	display: 'flex',
	flexDirection: 'column',
	gap: '2em',
	marginBottom: '1em'
});

export const sectionStyle = () => css({
	width: '100%',
	margin: '1em 0'
});


export interface ISet extends LineupSet {
//	setStore: SetStoreFunction<EventDoc>;
//	setStore: SetStoreFunction<LineupSet>;
	store: LineupSet;
	setStore: SetStoreFunction<any>;
	location: Location;
	currentAct: number;
	setCurrentAct:(value:number|undefined)=>void,
	bookingStatus
}

//TODO replace classes...

export function ArtistForm(props:ISet)
{
	return (
		<div class={formStyle()}>
			<Header {...props} />

			<TopContent {...props} />

			<div class={lowerContentStyle()}>
				<Show when={props.useAgreement}>
					<BookingTask {...props} />

					<Show when={props.useAgreement}>
						<BookingStatus {...props} />
					</Show>
				</Show>

				<ArtistTasksAndDetails {...props} />
			</div>
		</div>
	);
}

function Header(props)
{
	return (
		<div class={headerStyle()}>
			{props.name?.toUpperCase()}

			<Show when={props.setTime}>
				{' '} ON STAGE {DateTime.fromISO(props.setTime).toFormat('hh:mm a')}
			</Show>
		</div>
	);
}

function TopContent(props)
{
	return (
		<div class={topDetailsStyle()}>
			<IconPane>
				<InfoCircle fill={props.name ? IconColour.active : IconColour.alert} />

				<Wrap label='Artist:'>
					<Text {...props} field='name' />
				</Wrap>
			</IconPane>

			<IconPane>
				<ClockCircle fill={props.setTime ? IconColour.active : IconColour.alert } />

				<Wrap label='Performance Start Time' notes='This will be advertised'>
					<Time {...props} field='setTime'/>
				</Wrap>
			</IconPane>

			<div>
				Use booking agreement? {' '} <Checkbox {...props} field='useAgreement' />
			</div>
		</div>
	);
}

function BookingTask(props:ISet)
{
	const [showBooking,setShowBooking] = createSignal(false);

	const active = () => props.name && props.setTime && props.performanceDuration && props.feeType && props.feeDetails && props.soundCheck; 


//		<div class='stickySubHeader toggleReveal' onClick={() => setShowBooking(!showBooking())}>
	return (<>
		<div class={subHeaderStyle()} onClick={() => setShowBooking(!showBooking())}>
			<div>
				<TickCircle fill={active() ? IconColour.active : IconColour.alert} />
				Agreement Details
			</div>
			<i class={`fas ${showBooking() ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
		</div>
		<Show when={showBooking()}>
			<div class={layoutStyle()}>
				<Booking {...props} />
			</div>
		</Show>
	</>);
}

function ArtistTasksAndDetails(props:ISet)
{
	const basicAssets = () => props.name && props.image?.hash && (props.state || props.city || props.country) && (props.mainGenre || props.extraGenre);
	const socialHandle = () => props.facebook || props.instagram;
	const biographyContent = () => props.tagline || props.biography;
	const mediaAssets = () => props.facebookVideoUrl || props.instagramVideoUrl || props.youtubeVideoUrl || 
		props.musicEmbedId || props.spotify || props.appleMusic || props.bandcamp || props.soundcloud;

	const basicAssetsFill = () => {
		if (!basicAssets()) return IconColour.alert;
		if (socialHandle() || mediaAssets()) return IconColour.active;
		if (!socialHandle() || !mediaAssets()) return IconColour.orange;
		return IconColour.alert;
	};

	const [showAssets,setShowAssets] = createSignal(false);

//		<div class='stickySubHeader toggleReveal' onClick={setShowAssets(!showAssets())}>

//TODO replace 'a' tag. Maybe move onClick up. etc. Disable V if not applicable

	return (<>
		<div class={subHeaderStyle()} onClick={() => setShowAssets(!showAssets())}>
			<div>
				<TickCircle fill={basicAssetsFill()} />
				Artist content
				{basicAssets() && (!socialHandle() || !mediaAssets()) ? ' | ✔ Basic info ' : ''}
				{socialHandle()     ? ' | ✔ social media '    : (basicAssets() ? ' | ✗ social media ' : '')}
				{mediaAssets()      ? ' | ✔ videos or music ' : (basicAssets() ? ' | ✗ videos or music ' : '')}
				{biographyContent() ? ' | ✔ biography '       : (basicAssets() ? ' | ✗ biography '  : '' )}
			</div>
			<Show when={props.useAgreement}>
				<i class={`fas ${showAssets() ? 'fa-chevron-up' : 'fa-chevron-down'}`} />
			</Show>
		</div>

		<Show when={!props.useAgreement || showAssets()}>
			<div class={layoutStyle()}>
				<ArtistDetails {...props} />
			</div>
		</Show>
	</>);
}

function BookingStatus(props:ISet) 
{
	const options = {
		draft: 'Draft Booking',
		offered: 'Booking Offered',
		confirmed: 'Booking Confirmed',
		completed: 'Booking Completed',
		cancelled: 'Booking Cancelled'
	};

	return (
		<IconPane>
			<Switch>
				<Match when={props.bookingStatus == 'draft' }>
					<ExclamationCircle />
				</Match>
				<Match when={props.bookingStatus == 'confirmed' }>
					<TickCircle fill={IconColour.active} />
				</Match>
				<Match when={props.bookingStatus == 'completed' }>
					<TickCircle fill={IconColour.active} />
				</Match>
				<Match when={props.bookingStatus == 'cancelled' }>
					<TickCircle fill={IconColour.inactive} />
				</Match>
				<Match when={props.bookingStatus == 'offered' }>
					<ClockCircle />
				</Match>
				<Match when={true}>
					<ExclamationCircle />
				</Match>
			</Switch>

			<Wrap label='Booking Status'>
				<SingleSelect {...props} field='bookingStatus' required={true} options={options} />
			</Wrap>
		</IconPane>
	);
}

