import {ArtistProps, verticalStyle,sectionHeadingStyle} from 'Shared/artists/ProfilePage';
import { sectionStyle } from 'Shared/artists/ProfilePage';
import {IconColour} from 'Shared/backend/IconColour';
import {PinCircle} from 'Shared/backend/icons/PinCircle';
import { IconPane } from 'Shared/forms/IconPane';
import {SingleSelect, Text} from 'Shared/forms/Inputs';
import {Wrap} from 'Shared/forms/Wrap';
import {Show} from 'solid-js';

export function Location(artist:ArtistProps)
{
	return (
		<section class={sectionStyle()}>
			<h4 class={sectionHeadingStyle()}>Location</h4>

			<IconPane>
				<PinCircle fill={artist.state || artist.city || artist.country ? IconColour.active : IconColour.inactive} />

				<div class={verticalStyle()}>
					{/*
						TODO ben, CJ tried adding international:'International', as state option so Country isnt displayed if venue is adding artist
									and selects Austraian State. but vm wasnt storing international in database, please add
					*/}

					<Wrap label='City/Town'>
						<Text {...artist} field='city' />
					</Wrap>

					<Wrap label='State'>
						<SingleSelect {...artist} field='state' 
							options={{
								act: 'ACT',
								nsw: 'NSW',
								nt: 'NT',
								qld: 'QLD',
								sa: 'SA',
								tas: 'TAS',
								vic: 'VIC',
								wa: 'WA'
							}}
						/>
					</Wrap>


					{/* TODO ben, pl note: add or 'international' to if statement when international option added to State */}
					<Show when={!artist.state}>
						<Wrap label='Country'>
							<Text {...artist} field='country' placeholder='Australia' />
						</Wrap>
					</Show>
				</div>
			</IconPane>
		</section>
	);
}	

