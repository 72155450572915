import { css } from '@emotion/css';

/*
	To work this assumes the container is centered I think.
	Other ideas here if necessary. https://css-tricks.com/full-width-containers-limited-width-parents/
	Also think I can center using flex, then use width:100vw. Or use JS if I have to.

	NOTE body has an overflowX:hidden on it. Without it Chrome has a small horizontal scrollbar.
*/
const fullWidthStyle = () => css({
	width: '100vw',
	position: 'relative',
	left: '50%',
	right: '50%',
	marginLeft: '-50vw',
	marginRight: '-50vw'
});

export function FullWidthStyle(props)
{
	/* The outer div is position:static by default - required for the position:relative */
	return (
		<div>
			{
				props.disable ? 
					props.children 
				:
					<div class={fullWidthStyle()}>
						{props.children}
					</div>
			}
		</div>
	);
}

