import {DocumentQueries} from 'Common/config/PageConfigTypes';
import { z } from 'zod';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {FrontendPageConfig, frontendData, frontendParams} from 'Common/FrontendPageConfig';
import { HirePage } from 'Shared/frontend/hirePage/HirePage';

export const hireParams = frontendParams.extend({ }).strict();	
export type HireParams = z.infer<typeof hireParams>;


export const hireData = frontendData.extend({
	pageDisplayName: z.string(),
	space: z.any()
}).strict();
export type HireData = z.infer<typeof hireData>;



export class Hire extends FrontendPageConfig<HireData,HireParams>
{
	static readonly pageName = 'frontend/hire';
	name() { return Hire.pageName; }

	settingsName() { return 'hire'; }

	access() { return 'venueFrontend' as AccessAreaName; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			pageDisplayName: 'Hire',
			template: HirePage
		};
	}

	documents(params:HireParams)
	{
		return <DocumentQueries> ({
			...super.documents(params),

			hire: {
				type: 'object',
				collection: 'hire',
				aggregate: () => [ ]
			},	  
			email: {
				type: 'object',
				collection: 'email',
				aggregate: () => [
					{$match:{type:'hire'}}
				]
			}
		});
	}
}

