import { BasePageThemeType, BaseThemeType,BaseSizesType } from 'Shared/frontend/BaseTheme';
//import { usePage } from 'Shared/frontend/PageProvider';
import {createContext,useContext} from 'solid-js';

/*
	See https://stackoverflow.com/questions/61132262/typescript-deep-partial for alternatives.
	type-fest looks very popular
*/
export type DeepPartial<T> = T extends object ? {
    [P in keyof T]?: DeepPartial<T[P]>;
} : T;


const ThemeContext = createContext();

export function ThemeProvider(props:{theme:BaseThemeType}) 
{
	return (
		<ThemeContext.Provider value={props.theme}>
			{props.children}
		</ThemeContext.Provider>
	);
}

export function theme():BaseThemeType
{
	return useContext(ThemeContext);
}

/* TODO? Note sure how useful on the backend... 

export function pageTheme():BasePageThemeType
{
	const t = theme();
	return t[usePage().data.settingsPageName] ?? t.page;
}
*/


//TODO keep? remove? delete? these functions?

export function palette()
{
	return useContext(ThemeContext).palette;
}

export function fonts()
{
	return useContext(ThemeContext).fonts;
}

export function sizes(): BaseSizesType
{
	return useContext(ThemeContext).sizes;
}

