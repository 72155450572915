import {locateSubDocument} from 'Common/ViewUtils';
import {EditComponent} from 'Common/components/EditComponent';
import {Location} from 'Common/config/PageConfigTypes';
import {PageProvider} from 'Shared/frontend/PageProvider';
import {BackendWrap} from 'Shared/backend/BackendWrap';
import {DetailsTab} from 'Shared/backend/menu/DetailsTab';
import {MenuDoc} from 'Shared/model/Menu';
import {MenuItem, MenuItemData} from 'Shared/view/backend/MenuItem';
import {MenuData} from 'Shared/view/backend/Menu';
import {Show, mergeProps} from 'solid-js';
import {SetStoreFunction, createStore} from 'solid-js/store';
import {menuSelector} from 'Shared/frontend/menuPage/MenuPage';
import { Id } from 'Common/Id';
import { css, cx, injectGlobal } from '@emotion/css';
import { inlineOpenLinkStyle, Theme } from 'Shared/backend/Theme';
import { ListMenu } from 'Shared/view/backend/ListMenu';
import { TabPanes } from 'Shared/backend/Tabs';
import { calculateProductType } from 'Shared/backend/menu/MenuDesignerPage';
import { Breadcrumbs } from 'Shared/backend/Breadcrumbs';
import { IconColour } from 'Shared/backend/IconColour';
import { theme, ThemeProvider } from 'Shared/backend/ThemeProvider';
import { standardRules } from 'Shared/common/Sanitise';
import {LinkOpen} from 'Shared/backend/icons/LinkOpen';
import {StatusIcons} from 'Shared/backend/menu/StatusIcons';
import {SingleSelect} from 'Shared/forms/Inputs';
import { bottomBarStyle, statusBarStyle } from 'Shared/forms/StatusBar';


const topLineStyle = () => css({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
	marginBottom: '1em'
});

const activeStyle = () => css({
	color: `${IconColour.green} !important`,
});


export interface IItemProps {
	docId: Id;
	store: MenuDoc;
	setStore: SetStoreFunction<MenuDoc>;
	location: Location;
	permission: string;
	component: EditComponent<MenuData,MenuDoc>;
	item: MenuItem;
}

export function MenuItemPage(props:MenuItemData)
{
	injectGlobal([standardRules,{body:Theme.body}]);

	const [store,setStore] = createStore(props.menu);
	const location = createLocation(props.params);

	const link = () => `/`+props.menu.slug+`#`+ menuSelector(store,location);

	const parentProductType = calculateProductType(props.menu,location.slice(0,-2));
	const productType = calculateProductType(props.menu,location);

	const item = () => locateSubDocument(store,location);

	const itemProps = mergeProps({page: props.page,
		store: store,
		setStore: setStore,
		location: location,
		permission: 'editMenu',
		docId: store._id,
		item: item(),
		link: link(),
		parentProductType: parentProductType,
		productType: productType
	});


	const tabs = {
		'details': {label:'Details',component:DetailsTab}
		//XXX + marketingTab.njk
	};

	return (
		<ThemeProvider theme={Theme}>
			<PageProvider page={props.page}>
				<BackendWrap>
					<div class={topLineStyle()}>
						<Breadcrumbs items={referringBreadcrumb(store,location,props.params.sectionIds,item().name)} />

						<Show when={item().status=='published'}>
							<a href={link()} class={cx(inlineOpenLinkStyle(),activeStyle())} target='_blank'>
								<LinkOpen class={css(theme().linkIcon)} />
							</a>
						</Show>
					</div>

					<TabPanes tabs={tabs} tabProps={itemProps} />

					<StatusBar {...itemProps} link={link()} />
				</BackendWrap>
			</PageProvider>
		</ThemeProvider>
	);
}

function StatusBar(props)
{
//TODO create a migrator... Replace with a boolean. 'published'==>true, others==>false

	return (
		<div class={css(bottomBarStyle)}>
			<div class={css(statusBarStyle)}> 
				<StatusIcons status={props.item.status} />

				<SingleSelect {...props} field='status' required={true}
					options={ {'unpublished':'Hide','published':'Show'} } 
				/>

				<Show when={props.item.status=='published'}>
					<a href={props.link} class='linkInline' target='_blank'><LinkOpen class={css(theme().linkIcon)} /></a>
				</Show>
			</div>
		</div>
	);
}		

function referringBreadcrumb(menu:MenuDoc,location:Location,sectionIds,name:string)
{
	const items = {Menus: '/admin/menus'};

	const page = typeof window == 'undefined' ? null : window?.context?.state?.lastPageName;
	if (page)
	{
		if (page == ListMenu.pageName) 
			items[menu.title+' (single list)'] = `/admin/listMenu/${menu.slug}`;
		else {
			let url = `/admin/menus/${menu.slug}`;
			for (const i of sectionIds) 
				url += '/' + i.name.replace(/\W/g,'').toLowerCase() +'-'+ i.index;

			const sectionTitle = locateSubDocument(menu,location.slice(0,-2)).title;

			items[sectionTitle] = url;
		}
	}

	items[name] = null;
	return items;
}


function createLocation(params:{sectionIds:{index:number,name:string}[],item:string})
{
	let loc:[string|number][] = [];
	for (const section of params.sectionIds)
		loc = [...loc,'sections',section.index];
	return [...loc,'items',params.item.index];
}

