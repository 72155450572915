
import { css } from '@emotion/css';
import { VenueUrls } from 'Common/VenueUrls';
import { IconColour } from 'Shared/backend/IconColour';
import { TickCircle } from 'Shared/backend/icons/TickCircle'
import { Text, Checkbox, Html, MultiSelect} from 'Shared/forms/Inputs'
import { Wrap } from 'Shared/forms/Wrap';
import { usePage } from 'Shared/frontend/PageProvider';
import { Show } from 'solid-js'
import { IconPane } from 'Shared/forms/IconPane';
import { theme } from 'Shared/backend/ThemeProvider';


const repeaterPaneStyle = () => css({
	display: 'flex',
	flexDirection: 'column',
	gap: '1.5em',
	padding: '1em 1em 0 2em',
    border: 'solid 1px lightGrey'
});

const previewButtonStyle = () => css(theme().button,{
	margin: '1em 0',
	alignSelf: 'flex-start'
});

const verticalStyle = () => css({
	display: 'flex',
	flexDirection: 'column',
	gap: '1em'
});

const headingStyle = () => css(theme().section.heading, {
	fontWeight: 700
});

export function StaffResourceSectionFields(props)
{
	const contentTypes = {
		richText: 'Text editor',
		gDriveDownload: 'Button to download a GDrive file',
		link: 'Button to open webpage or file',
		embed: 'Embed code from another site'
	};

	return (
		<div class={repeaterPaneStyle()}>
			<Wrap label='Section Heading'>
				<Text {...props} field='name' />
			</Wrap>

			<Wrap label='Content'>
				<MultiSelect {...props} field='contentType' value={props.contentType ?? []} options={contentTypes} />
			</Wrap>

			<Show when={props.contentType?.includes('richText')}>
				<RichText {...props} />
			</Show>

			<Show when={props.contentType?.includes('embed')}>
				<EmbedCode {...props} />
			</Show>

			<Show when={props.contentType?.includes('gDriveDownload')}>
				<FileDownload {...props} />
			</Show>

			<Show when={props.contentType?.includes('link')}>
				<ExternalLink {...props} />
			</Show>

			<button class={previewButtonStyle()} onClick={() => props.setOverlay(true)}>Preview Resource</button>
		</div>
	);
}

function RichText(props)
{
	const urls = new VenueUrls(usePage().build,usePage().site.key);

	return (
		<Html {...props} urls={urls} field='richText' options={{
			height: 600,
			menubar: false,
			plugins: ['link'],
			toolbar: 'undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent | link ',
			resize: true
		}} />
	);
}

function EmbedCode(props)
{
	return (
		<section>
			<h4 class={headingStyle()}>Embed code to display content</h4>

			<Wrap label='Embed Code'>
				<Text {...props} field='embed' />
			</Wrap>

			<Show when={props.embed}>
				<div innerHTML={props.embed} />
			</Show>
		</section>
	);
}

function FileDownload(props)
{
	const downloadUrl = () => {
		let link = props.gDriveDownloadLink;
		if (props.gDriveDownloadLink?.includes('/document/') && props.gDriveDownloadAsPDF)
			link = link?.replace('/edit?usp=sharing','/export?format=pdf');
		return link;
	};

	return (
		<section>
			<h4 class={headingStyle()}>Button to download a GDrive file</h4>

			<IconPane>
				<TickCircle fill={props.gDriveDownloadLink ? IconColour.active : IconColour.inactive} />

				<div class={verticalStyle()}>
					<Wrap label='GDrive file link'>
						<Text {...props} field='gDriveDownloadLink' />
					</Wrap>

					<Show when={props.gDriveDownloadLink?.includes('/document/') && !props.gDriveDownloadAsPDF}>
						<x-warning> Looks like this is a Google Document. Would you rather people download it as a PDF so they cannot change your text? </x-warning>
						<br />
					</Show>

					<Show when={props.gDriveDownloadLink?.includes('/document/')}>
						<Wrap label='Download document as a PDF'>
							<Checkbox {...props} field='gDriveDownloadAsPDF' />
						</Wrap>
					</Show>

					<Wrap label='Change button label'>
						<Text {...props} field='gDriveButton' options={{placeholder:'Download file'}} />
					</Wrap>

					<a href={downloadUrl()} target='_blank'>
						<button class={css(theme().button)}>
							{ props.gDriveButton ? props.gDriveButton : 'Download file' }
						</button>
					</a>
				</div>
			</IconPane>
		</section>
	);
}

function ExternalLink(props)
{
	return (
		<section>
			<h4 class={headingStyle()}>Button to open external webpage or file</h4>

			<IconPane>
				<TickCircle fill={props.linkURL ? IconColour.active : IconColour.inactive} />

				<div class={verticalStyle()}>
					<Wrap label='Link'>
						<Text {...props} field='linkURL' />
					</Wrap>

					<Wrap label='Link button label'>
						<Text {...props} field='linkButton' options={{placeholder:'Open'}} />
					</Wrap>

					{/* cant use button onclick to open a new window as pop up blockers catch it, so wrapped button in ahref */}
					<a href={props.linkURL} target='_blank'>
						<button class={css(theme().button)}>
							{props.linkButton ? props.linkButton: 'Open'}
						</button>
					</a>
				</div>
			</IconPane>
		</section>
	);
}

