import {css} from '@emotion/css';
import {Switch, Match, createSignal, Show} from 'solid-js';
import * as ImageSizes from 'Shared/model/ImageSizes';
import {Location} from 'Common/config/PageConfigTypes';
import {Id} from 'Common/Id';
import { Urls } from 'Common/Urls';
import { DeleteImageMessage2 } from 'Common/Messages';
import { VenueUrls } from 'Common/VenueUrls';
import Assert from 'Common/Assert';
import {ImageSchema, ImageSize, Url} from 'Shared/model/basic';
import { displayCropper } from 'Shared/forms/ImageCropper';
import { IPageData } from 'Common/PageConfig';
import { Page } from 'Common/pages/Page';
import { noteStyle } from 'Shared/forms/Wrap';
import { theme } from 'Shared/backend/ThemeProvider';



export const uploadStyle = () => css({
	div: {
		margin: '15px 0'
	}
});

export const previewStyle = () => css({
	position: 'relative',
	display: 'inline-block',
});

export const loadingStyle = () => css({
	position: 'absolute',
	display: 'flex',
	top: 0,
	bottom: 0,
	left: 0,
	right: 0,
	alignItems: 'center',
	justifyContent: 'center',

	img: {
		color: 'white',
		width: 60,
		height: 60,
		alignSelf: 'center'
	}
});

export const buttonsStyle = () => css({
	button: {
		marginRight: 5,
	},
});

export const removeImageStyle = () => css(theme().button, {
    backgroundColor: 'rgb(255, 115, 141)',
    borderColor: 'rgb(255, 90, 120)',

	'[disabled]': {
		filter: 'none !important', 	//XXX overriding forms.less
		backgroundColor: '#CCC',
	},

	':not([disabled])': {
		backgroundColor: '#ff738d',
		borderColor: '#ff5a78'
	}
});

export const dndStyle = () => css({
	cursor: 'pointer',
});


export interface IImageUpload {
	docId: Id,
	location: Location,
	fallbackUrl: Url;
	dimensions: keyof typeof ImageSizes;
	page: Page<IPageData>,
	permission: string,
	image: ImageSchema,
	setImage: (value:ImageSchema) => void,
	urls: Urls,
	previewSize:ImageSize
} 

/*
	Options include 'ratio', 'minWidth' and 'minHeight' which are all optional. 
	If 'ratio' is supplied only one of either 'minWidth' and 'minHeight' are required to 
	enforce minimum dimensions.
*/
export function ImageUpload(props: IImageUpload)
{
	const [buttonsNode,setButtonsNode] = createSignal();

	const assetFolder = Assert.have(props.page.config.permissions()[props.permission].assetFolder);

	return (
		<ImageUploadDragAndDrop {...props}>
			<div class={uploadStyle()}>
				<ImageUploadButtons {...props} node={buttonsNode} setNode={setButtonsNode} showDropMessage={false} />

				<Preview {...props}
					node={buttonsNode}
					setNode={setButtonsNode}
					assetFolder={assetFolder} 
					size={props.previewSize}
					format={ImageSizes[props.dimensions][props.previewSize].format}
				/>
			</div>
		</ImageUploadDragAndDrop>
	);
}


export function ImageUploadDragAndDrop(props:IImageUpload)
{
	const handleDrop = e => {
		e.stopPropagation();
		e.preventDefault();
		handleFiles(props,e.dataTransfer.files,true);
	};

	return (
		<div class={dndStyle()} onDragover={e => e.preventDefault()} onDrop={handleDrop}>
			{props.children}
		</div>
	);
}

//TODO share more between these interfaces
export interface IImageUploadButtons {
	docId: Id,
	location: Location,
	fallbackUrl: Url;
//	dimensions: keyof typeof ImageSizes;
	page: Page<IPageData>,
	permission: string,
	image: ImageSchema,
	setImage: (value:ImageSchema|undefined) => void,
	urls: Urls,
	node: ()=>HTMLElement,
	setNode: (e:HTMLElement)=>void,
	showDropMessage?: boolean
} 

export function ImageUploadButtons(props:IImageUploadButtons)
{
	const imageExists = () => props.image?.hash != undefined;

	const deleteImage = () => {
		if (!confirm('Are you sure you want do delete this image?'))
			return;

		props.page.server.sendOperationOptimistically(
			new DeleteImageMessage2(props.page.name(),props.permission,props.docId,props.location)
		);

		props.setImage(undefined);
	};

	return (
		<div class={buttonsStyle()}>
			<Show when={props.showDropMessage ?? true}>
				<div class={noteStyle()}>
					Drop image here <i>or</i> click to upload.
				</div>
			</Show>

			<input type='file' ref={props.setNode} 
				accept='.jpg,.jpeg,.png' autocomplete='off' name='files[]' style={{display:'none'}}
				onChange={() => handleFiles(props,props.node().files,true)}
			/>

			<button type='button' class={css(theme().button)} onClick={() => props.node().click()}>
				<i class='fa fa-upload'></i> {imageExists() ? 'Replace' : 'Upload' } image
			</button>

			<button type='button' class={removeImageStyle()} disabled={!imageExists()} onClick={deleteImage}>
				<i class='fa fa-times'></i> Remove image
			</button>
		</div>
	);
}

export interface IPreview {
	fallbackUrl:Url;
	assetFolder:string;
	size:ImageSize;
	format: string;
	image:ImageSchema,
	urls: Urls,
	node: ()=>HTMLElement,
	setNode: (e:HTMLElement)=>void
} 

export function Preview(props:IPreview)
{
	const loadingUrl = props.urls.resourceUrl('loading.gif');
	const previewUrl = () => props.urls.imageUrl(props.assetFolder,props.image.hash,props.size,props.image.formats[props.size].format,!props.image.dev,false); 

	// XXX suppose we could use <Suspense> here

	return (
		<div class={previewStyle()}>
			<Switch>
				<Match when={props.image?.dataUrl}>
					<img alt='Image preview' src={props.image.dataUrl} />
					<div class={loadingStyle()}>
						<img alt='Loading' src={loadingUrl} />
					</div>
				</Match>
				<Match when={props.image?.hash}>
					<img alt='Preview' src={previewUrl()} onClick={() => props.node().click()}/> 
				</Match>
				<Match when={props.fallbackUrl}>
					<img alt='Preview' src={props.urls.resourceUrl(props.fallbackUrl)} onClick={() => props.node().click()} /> 
				</Match>
			</Switch>
		</div>
	);
}

async function handleFiles(props:IImageUpload,files:FileList,crop:boolean)
{
	const dims = ImageSizes[props.dimensions]?.full;
	const ratio =  dims.ratio;
	let minWidth = dims.minWidth;
	let minHeight = dims.minHeight;

	if (minHeight==null && (ratio!=null && minWidth!=null))
		minHeight = Math.round(minWidth / ratio);
	if (minWidth==null && (ratio!=null && minHeight!=null))
		minWidth = Math.round(minHeight * ratio);
	if (minWidth==null) minWidth = 0;
	if (minHeight==null) minHeight = 0;

	let file = files[0];

	let dataUrl:string;
	if (crop) {
		const blob = await transformImage(file,ratio,minWidth,minHeight);
		if (blob==null) return;
		dataUrl = await fileOrBlobToDataUrl(blob);
		file = new File([blob],'trimmedImage');
	}
	else {
		dataUrl = await fileOrBlobToDataUrl(file);  //XXX NB returning Promise<string|ArrayBuffer|null> 
		if (!await checkImageSize(dataUrl,minWidth,minHeight)) {
			alert('The image is too small');
			return;
		}
	}

	const urls = new VenueUrls(window.build,window.site.key);
	const uploadUrl = urls.uploadImageUrl2(props.page.name(),props.permission,props.dimensions,props.docId,props.location);

	let loaded = false;
	setTimeout(() => {
		if (!loaded) props.setImage({dataUrl:dataUrl});
	},400);

	const fieldData = await uploadFile(file,uploadUrl);
	loaded = true;
	props.setImage(fieldData);
}

async function checkImageSize(image:string,minWidth:number,minHeight:number)
{
	const im = new Image();
	return await new Promise((resolve,reject) => {
		im.onload = () => {
			resolve(im.height >= minHeight && im.width >= minWidth);
		}
		im.src = image;
	});
}

async function fileOrBlobToDataUrl(fileOrBlob:File|Blob):Promise<string>
{
	/*
		For simplicity I'm aways returning a string. Might be better to return an ArrayBuffer instead or
		else pass string|ArrayBuffer back.

		Note on FF for cropping fileOrBlob=blob & FileReader returns a string.
	 */
//XXX when trimming getting a Blob

	return await new Promise((resolve,reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(fileOrBlob);
		reader.onloadend = () => {
			const out = reader.result instanceof ArrayBuffer ? reader.result.toString() : reader.result;
			resolve(Assert.toString(out));
		}
	});
}

async function uploadFile(file:File,uploadUrl:string)
{
	const formData = new FormData();

	formData.append('file',file);

	const ret = await fetch(uploadUrl,{method:'POST',body:formData});
	const body = await ret.json();
	if (body?.errorType!=null)
		throw new Error('Error on server');

//XXX    Could possibly move the image-related functions into this file
	return body;
}

async function transformImage(file:Blob,ratio:number|undefined,minWidth:number,minHeight:number):Promise<Blob|null>
{
	/* Create an image node for Cropper.js: */
	const image = new Image();

	return await new Promise((resolve,reject) => {
		image.onload = async () => 
			resolve(await displayCropper(image,file.type,ratio,minWidth,minHeight));
		image.addEventListener('error', err => {
			alert('Invalid image file type');
			reject(new Error('Invalid image file type'));
		});
		image.src = URL.createObjectURL(file);
	});
}

