
import { DateTime } from 'luxon';
import { IconColour } from 'Shared/backend/IconColour';
import { TickCircle } from 'Shared/backend/icons/TickCircle';
import { IconPane } from 'Shared/forms/IconPane';
import { Switch,Match,Show } from 'solid-js';

export function PosterAndPublish(props)
{
	return (<>
		<PosterStatus {...props} />
		<PublishStatus {...props} />
	</>);
}

function PosterStatus(props)
{
	const event = () => props.store;

	const posterStatus = () => {
		if (event().poster?.hash) 
			return event().poster.isCurrent ? 'ok' : 'stale';
		else 
			return 'missing';
	};

	const fill = () => {
		switch (posterStatus()) {
			case 'ok': return IconColour.active;
			case 'stale': return IconColour.alert;
			case 'missing': return IconColour.alert;
		}
	}

	return (
		<IconPane>
			<TickCircle fill={fill()} />

			<div class='sectionMargin'>
				<Show when={posterStatus()=='ok'}>
					Poster ready for social media
				</Show>
				<Show when={posterStatus()=='stale'}>
					{/* '/' is hacked onto details to force it to load if on marketing tab but url hasn't changed to marketing */}

					<a href='' onClick={() => window.pageJs(`/admin/event/${event().slug}/details`)}> 
						<x-warning>	
							Update poster on <u>Details Tab</u>, event info has changed!
						</x-warning>
					</a>
				</Show>
				<Show when={posterStatus()=='missing'}>
					<a href='' onClick={() => window.pageJs(`/admin/event/${event().slug}/details`)}>
						<x-warning>
							No Poster! Save it on <u>Details Tab</u>
						</x-warning>
					</a>
				</Show>
			</div>
		</IconPane>
	);
}

function PublishStatus(props)
{
	const event = () => props.store;

	const fill = () => {
		switch(event().status) {
			case 'draft': return IconColour.alert;
			case 'published': return IconColour.active;
			case 'unpublished': return IconColour.inactive;
			case 'scheduled': return IconColour.orange;
		}
	}

	return (
		<IconPane>
			<TickCircle fill={fill()} />

			<div class='sectionMargin'>
				<Switch>
					<Match when={event().status=='draft'}>
						<a href={`/admin/event/${event().slug}/details`} onClick={() => window.pageJs(`/admin/event/${event().slug}/details`)}>
							<x-warning>
								Draft event! Review then publish it on <u>Details Tab</u>
							</x-warning>
						</a>
					</Match>
					<Match when={event().status=='published'}>
						Event is published and visible on website here.
					</Match>
					<Match when={event().status=='unpublished'}>
						Event is no longer published on your website
					</Match>
					<Match when={event().status=='scheduled'}>
						<Switch>
							<Match when={event().scheduleDate}>
								Event is scheduled to auto-publish on your website 

								<Switch>
									<Match when={DateTime.fromISO(event().scheduleDate).toFormat('mm') == '00'}>
										{DateTime.fromISO(event().scheduleDate).toFormat('ha')}
									</Match>
									<Match when={true}>
										{DateTime.fromISO(event().scheduleDate).toFormat('h:mma')}
									</Match>
								</Switch>

								{DateTime.fromISO(event().scheduleDate).toFormat('ccc, dd/LL/yy')}
							</Match>
							<Match when={true}>
								Event is scheduled to auto-publish, but you need to set a date below:
							</Match>
						</Switch>
					</Match>
				</Switch>
			</div>
		</IconPane>
	);
}
