import { css } from '@emotion/css';
import { DateTime } from 'luxon';
import { otherFacebookHandles } from 'Shared/backend/eventPage/Handles';
import { musicExists, titleWithMarginStyle } from 'Shared/backend/eventPage/Plan';
import { MusicCircle } from 'Shared/backend/icons/MusicCircle'
import { FacebookCircle } from 'Shared/frontend/icons/FacebookCircle';
import { usePage } from 'Shared/frontend/PageProvider';
import { createSignal, For, Match, Show, Switch } from 'solid-js';
import { IconPane } from 'Shared/forms/IconPane';
import { theme } from 'Shared/backend/ThemeProvider';
import { overlayContentStyle, PostPane } from 'Shared/backend/eventPage/tasks/PostPane';
import { Overlay } from 'Shared/forms/Overlay';


export function WebsiteMusic(props)
{
	const lineup = () => props.store.lineup ?? [];
	const [overlay,setOverlay] = createSignal();

	return (<>
		<IconPane>
			<MusicCircle />
			<div>
				<div class={titleWithMarginStyle()}>Post artist music to Facebook</div>

				<Switch>
					<Match when={!musicExists(lineup())}>
						<div class={titleWithMarginStyle()}>
							No artist music
						</div>
						Perhaps ask them to add music to their artist profile at 
						<a class={css(theme().a)} href='https://profile.ontoitmedia.com'>profile.ontoitmedia.com</a>{' '} 
						so you can copy the artists content into your VOS.
						<br/>
					</Match>
					<Match when={true}>
						<For each={lineup()}>{ (artist,i) => <>
							<a class={css(theme().a)} onClick={() => setOverlay(i())}>
								<i class='fas fa-caret-right'/> Post {artist.name}'s music to Facebook
							</a>
							<br/>
						</>}</For>
					</Match>
				</Switch>
			</div>
		</IconPane>

		<Show when={overlay()!==undefined}>
			<Overlay close={() => setOverlay(undefined)}>
				<div class={overlayContentStyle()}>
					<ArtistItem {...props} artist={lineup()[overlay()]} />
				</div>
			</Overlay>
		</Show>
	</>);
}

function ArtistItem(props)
{
	const [postText,setPostText] = createSignal();

	return (
		<PostPane icon={FacebookCircle} title={props.artist.name+' song draft post'} postText={postText()}>
			<Post {...props} setPostText={setPostText} 
				instruction='Then paste into Facebook, where you can edit the text before posting.'
			/>
			<></>
		</PostPane>
	);
}

function Post(props)
{
	const lineup = () => props.store.lineup ?? [];
	const artist = () => props.artist;
	const page = usePage();

	/*
		Facebook strips line breaks and whitespaces so i've hacked in &#xFEFF; as recommended
		on stack exchange <p>.</p> draw users attention to events link
		particularly because we don't want them clicking video link by mistake
	*/
	/* <p>&#xFEFF;</p> */


/* TODO ben urgent check that #anchor for artistNameMusic is on frontend event page where music links are listed  */

	return (
		<div ref={props.setPostText}>
			<p>
				Click the poster to hear 
				{ artist().facebook ? '@'+artist().facebook : artist().name }.
				See live {DateTime.fromISO(props.store.times.startSingle).toFormat('ccc d LLL')+' '} 
				at {props.venue.name+' '}
				with {' '+ otherFacebookHandles(lineup,artist().position) }
			</p>
			
		  
			{/* Below will collapse to 1 line of text on draft post */}

			<p>
				&#128467;&#127926;
				INFO:
				{page.site.hostUrl}/event/{props.store.slug}#{artist().name.toLowerCase().replace(' ','')}
				&#127926;&#128467;
			</p>
		</div>
	);
}

