import { injectGlobal } from '@emotion/css';
import { StandardDefaultLayout } from 'Shared/frontend/layouts/StandardDefaultLayout';
import { PageProvider } from 'Shared/frontend/PageProvider';
import { standardRules } from 'Shared/common/Sanitise';
import { ThemeProvider } from 'Shared/frontend/ThemeProvider';
import { Widget } from 'Shared/frontend/showcaseWidgets/Widgets';
import { For } from 'solid-js';
import { createStore } from 'solid-js/store';


export function HomePage(props:HomeData)
{
	injectGlobal([standardRules,{body:props.theme.body}]);

	const [theme,setTheme] = createStore(props.theme);

	return (
		<ThemeProvider theme={theme}>
			<PageProvider page={props.page}>
				<StandardDefaultLayout {...props} expandDesktopNav={true}>
					<PageDesign {...props} />
				</StandardDefaultLayout>
			</PageProvider>
		</ThemeProvider>
	);
}

function PageDesign(props)
{
	/*
		Other possibilities could include: 
		eventGrid, grid, contactPage, menuPage, sections, widgetTree, eventGrid, grid
	*/

	//XXX cf alternatives... 'verticalList', 'verticalLayout', 'verticalWidgets'
	//Assert.check(props.design.type == 'widgetList');  //XXX Breaks Indie Island


	return (
		<For each={props.home.showcase}>{(section,index) =>
			<Widget {...section} location={[index()]} index={index()} 
				events={props.events} musicEvents={props.musicEvents} nonMusicEvents={props.nonMusicEvents} specials={props.specials}

				parallaxImageFolder='Showcase-image' parallaxImageSize='full'
				carouselImageFolder='Home-showcaseCarouselImage' carouselImageSize='large'
			/>
		}</For>
	);
}

