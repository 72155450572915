import {BandcampButton} from 'Shared/backend/icons/BandcampButton';
import {IconColour} from 'Shared/backend/IconColour';
import {ListenOnApple} from 'Shared/backend/icons/ListenOnApple';
import {ListenOnSpotify} from 'Shared/backend/icons/ListenOnSpotify';
import {MusicCircle} from 'Shared/backend/icons/MusicCircle';
import {SoundcloudButton} from 'Shared/backend/icons/SoundcloudButton';
import {Text} from 'Shared/forms/Inputs';
import {Wrap} from 'Shared/forms/Wrap';
import { css } from '@emotion/css'
import {Show} from 'solid-js';
import {appleMusic, bandcamp, soundcloud, spotify} from 'Browser/InputTypes';
import {activeStyle, ArtistProps, sectionHeadingStyle, searchLinkStyle, sectionNoteStyle, sectionStyle, verticalStyle} from 'Shared/artists/ProfilePage';
import { LinkOpen } from 'Shared/backend/icons/LinkOpen';
import { IconPane } from 'Shared/forms/IconPane';
import { theme } from 'Shared/backend/ThemeProvider';


const musicLinksStyle = () => css(verticalStyle(), {
	label: {
		a: {
			display: 'flex',
			flexDirection: 'row',
			alignItems: 'center'
		}
	},
});

const musicButtonStyle = () => css({
	svg: {
		width: '6em'
	}
});
 

export function MusicLinks(artist:ArtistProps)
{
	return (
		<section class={sectionStyle()}>
			<h4 class={sectionHeadingStyle()}> Music
				<div class={sectionNoteStyle()}>
					VOS displays links on venue website &amp; drafts social media posts so fans can listen on their platform of choice
				</div>
			</h4>
			<IconPane>
				<MusicCircle fill={artist.spotify || artist.appleMusic || artist.musicEmbedId ? IconColour.active : IconColour.inactive} />

				<div class={musicLinksStyle()}>
					<Spotify {...artist} />

					<AppleMusic {...artist} />

					<Bandcamp {...artist} />

					<Soundcloud {...artist} />
				</div>
			</IconPane> 
		</section>
	);
}

function Spotify(artist:ArtistProps)
{
//XXX the search here looks a bit odd

	const label = () => {
		if (exists(artist.spotify))
			return <a class={activeStyle()}>Spotify song link</a>;
		else 
			return <a class={searchLinkStyle()} target='_blank'
				href={`https://open.spotify.com/search/${artist.name}/artists`} 
			>
				Search Spotify for a song <LinkOpen class={css(theme().linkIcon)} fill={IconColour.inactive} />
			</a>;
	}

	return <>
		<Wrap label={label()}>
			<Text {...artist} field='spotify' processInput={spotify} placeholder='Paste song share link' />
		</Wrap>
			
		<Show when={exists(artist.spotify)}>
			<a href={artist.spotify} target='_blank' class={musicButtonStyle()}>
				<ListenOnSpotify />
			</a>
		</Show>
	</>;
}

function AppleMusic(artist:ArtistProps)
{ 
//XXX insert + or %20 between spaces in artist name search?
//XXX term, URL or JSON?  Inconsistent here...

	const label = () => {
		if (exists(artist.appleMusic))
			return <a class={activeStyle()}>Apple song link</a>;
		else 
			return <a class={searchLinkStyle()} target='_blank'
				href={`https://music.apple.com/us/search?term=${artist.name}`} 
			>
				Search Apple Music for a song
				<LinkOpen class={css(theme().linkIcon)} fill={IconColour.inactive} />
			</a>;
	}

	return <>
		<Wrap label={label()}>
			<Text {...artist} field='appleMusic' processInput={appleMusic} placeholder='Paste song share link' />
		</Wrap>

		<Show when={exists(artist.appleMusic)}>
			<a href={artist.appleMusic} target='_blank' class={musicButtonStyle()}>
				<ListenOnApple />
			</a>
		</Show>
	</>;
}

function Bandcamp(artist:ArtistProps)
{
	const label = () => {
		if (exists(artist.bandcamp))
			return <a class={activeStyle()}>Bandcamp page</a>;
		else
			return <a class={searchLinkStyle()} target='_blank'
				href={`https://bandcamp.com/search?q=${artist.name}&item_type`} 
			>
				Search Bandcamp
				<LinkOpen class={css(theme().linkIcon)} fill={IconColour.inactive} />
			</a>;
	};

	return <>
		<Wrap label={label()}>
			<Text {...artist} field='bandcamp' processInput={bandcamp} placeholder='Add link to music or merch page' />
		</Wrap>

		<Show when={exists(artist.bandcamp)}>
			<a href={artist.bandcamp} target='_blank' class={musicButtonStyle()}>
				<BandcampButton />
			</a>
		</Show>
	</>;
}

function Soundcloud(artist:ArtistProps)
{
	const label = () => {
		if (exists(artist.soundcloud))
			return <a class={activeStyle()}>Soundcloud page</a>;
		else 
			return <a class={searchLinkStyle()} target='_blank'
				href={`https://soundcloud.com/search?q=${artist.name}`} 
			>
				Search Soundcloud
				<LinkOpen class={css(theme().linkIcon)} fill={IconColour.inactive} />
			</a>;
	};

	return <>
		<Wrap label={label()}>
			<Text {...artist} field='soundcloud'  processInput={soundcloud} placeholder='Add link to artist/track/album' />
		</Wrap>

		<Show when={exists(artist.soundcloud)}>
			<a href={artist.soundcloud} target='_blank' class={musicButtonStyle()}>
				<SoundcloudButton />
			</a>
		</Show>
	</>;
}

function exists(value?:string)
{
	return value!=undefined && value.trim()!='';
}

