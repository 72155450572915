
export function StatusDraft(props)
{
	return (
		<svg id='status_draft' class={props.class} style={`fill:${props.fill ?? 'red'}; stroke:${props.stroke ?? 'inherit'};`} viewBox='0 0 64 64' enable-background='new 0 0 64 64' xmlns='http://www.w3.org/2000/svg'>
		  <defs></defs>
		  <path id="path-1" d="M 28.188 2.085 C 20.61 2.623 11.945 8.021 7.204 15.157 C -2.465 29.71 1.731 48.946 16.55 58.006 C 25.124 63.248 38.473 63.393 47.018 58.337 C 68.669 45.527 66.394 13.348 43.166 3.869 C 39.861 2.521 33.214 1.729 28.188 2.085 M 33.422 6.469 C 53.142 7.76 63.699 30.033 52.254 46.204 C 51.657 47.047 52.033 47.201 49.651 45.138 C 48.534 44.172 47.485 43.276 47.319 43.149 L 47.016 42.916 L 47.416 42.632 C 50.2 40.651 52.567 37.658 54.679 33.448 L 55.251 32.307 L 54.643 31.094 C 48.371 18.587 34.219 13.306 21.647 18.78 C 20.865 19.121 20.198 19.41 20.166 19.422 C 20.133 19.434 18.692 18.206 16.964 16.694 L 13.822 13.944 L 14.852 13.033 C 19.74 8.709 24.719 6.722 31.529 6.377 C 31.682 6.37 32.534 6.411 33.422 6.469 M 14.629 18.992 L 17.162 21.197 L 16.389 21.771 C 13.017 24.276 10.916 26.835 8.908 30.884 L 8.205 32.301 L 9.084 34.002 C 11.198 38.09 13.144 40.473 16.256 42.784 C 23.743 48.343 34.989 49.284 43.445 45.058 L 44.169 44.696 L 46.872 47.036 L 49.575 49.375 L 48.886 50.001 C 36.901 60.886 18.086 57.978 10.062 44 C 5.265 35.644 5.734 24.948 11.236 17.184 C 11.675 16.564 11.754 16.5 11.914 16.635 C 12.014 16.719 13.236 17.779 14.629 18.992 M 32.927 22.671 C 36.309 23.22 39.311 25.158 40.498 27.561 C 41.654 29.899 41.689 33.964 40.575 36.35 L 40.271 37.002 L 37.9 34.942 L 35.529 32.882 L 35.489 32.043 C 35.368 29.526 33.281 27.882 30.975 28.487 C 30.59 28.588 30.579 28.581 28.225 26.526 L 25.862 24.463 L 26.385 24.088 C 28.221 22.77 30.498 22.277 32.927 22.671 M 27.13 29.896 C 27.681 30.378 28.131 30.805 28.128 30.844 C 28.126 30.883 28.058 31.211 27.978 31.572 C 27.344 34.412 30.4 36.951 33.175 35.891 L 33.763 35.667 L 35.977 37.594 L 38.192 39.521 L 37.895 39.8 C 33.851 43.588 26.935 42.565 23.646 37.692 C 21.711 34.824 21.538 30.431 23.247 27.551 L 23.656 26.861 L 24.892 27.939 C 25.572 28.533 26.579 29.413 27.13 29.896" style="paint-order: fill;"></path>
		</svg>
	);
}

