import { HtmlCapturer } from 'Browser/HtmlCapturer';
import { UpdateMessage2 } from 'Common/Messages';
import { VenueUrls } from 'Common/VenueUrls';
import { usePage } from 'Shared/frontend/PageProvider';
import { IEventProps } from 'Shared/backend/eventPage/EventPage'
import { Overlay } from 'Shared/forms/Overlay';
import { EventPageContents } from 'Shared/frontend/eventPage/EventPage';
import { EventPoster } from 'Shared/frontend/eventPosters/EventPoster';
import { Match, Switch, Show, createSignal, createReaction } from 'solid-js';
import { reconcile } from 'solid-js/store';
import { css, cx } from '@emotion/css';
import { ThemeProvider } from 'Shared/frontend/ThemeProvider';
import { TabPanes } from 'Shared/backend/Tabs';
import { palette } from 'Shared/backend/Theme';
import { theme } from 'Shared/backend/ThemeProvider';


const fullScreenStyle = () => css({
	width: '100%',
	height: '100vh',
	position: 'fixed',
	top: 0,
	left: 0,
	border: 'none',
	zIndex: 5
});

const posterPaneStyle = () => css({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'start',
	gap: '0.5em',
    background: palette.dimBackground,
	width: '100%',
	border: '1px solid #fff',
	padding: '1em',
});

const pagePaneStyle = () => css({
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'start',
	gap: '0.5em',
    background: palette.dimBackground,
	padding: '1em',
	border: '1px solid #fff'
});

const pagePreviewStyle = () => css({
	width: '100%',
	/* Scale down for preview. Relies on the page elements using ems. */
	fontSize: '0.8em'
});


export function PreviewTab(props:IEventProps)
{
	const tabs = {
		'poster': {label:'Poster',component:PosterTab},
		'page': {label:'Page',component:PageTab}
	};

	return <TabPanes tabs={tabs} nested={true} initial='poster' tabProps={props} />;
}

function fieldsOnPoster(event)
{
	const lineup = [];
	for (const act of event.lineup ?? []) 
		lineup.push({
			name: act.name,
			mainGenre: act.mainGenre,
			otherGenre1: act.otherGenre1,
			extraGenre: act.extraGenre,
			imageHash: act.image?.hash
		});

	return {
		eventType: event.eventType,
		imageType: event.imageType,
		name: event.name,
		tagline: event.tagline,
		times: event.times,
		imageHash: event.image?.hash,
		imageDimensions: event.imageDimensions,
		lineup: lineup
	};
}

function PosterTab(props)
{
	let eventPosterPreview!: HTMLDivElement;

	const page = usePage();
	const settings = page.data.venue.settings;
	const event = () => props.store;

	const posterNode = () => eventPosterPreview.querySelector('.capture');
	const saveFromLink = () => (new HtmlCapturer()).saveFromLink(posterNode(),2.0); 

	const trackPosterChanges = createReaction(() => {
		if (!props.store.poster)
			return;
		props.setStore('poster','isCurrent',false);
		page.server.sendOperationOptimistically(new UpdateMessage2(page.name(),'edit',event()._id,['poster'],{isCurrent:false}));
	});
	const addTracking = () => trackPosterChanges(() => JSON.stringify(fieldsOnPoster(event())));
	addTracking();

	const uploadCapture = () => {
		const uploadUrl = (new VenueUrls(page.build,page.site.key)).uploadImageUrl2(page.name(),'poster','eventPoster',event()._id,['poster']);

		setTimeout(async () => {
			const ret = await (new HtmlCapturer()).upload(posterNode(),2.0,uploadUrl);
			props.setStore('poster',reconcile({...ret,isCurrent:true}));
			addTracking();
		});
	};

	return (
		<tab-pane class={posterPaneStyle()}>
			<Switch>
				<Match when={event().poster?.hash}>
					<Switch>
						<Match when={event().poster?.isCurrent === false}>
							<x-warning>
								Event information has changed. The web crawler poster may be out of date.
							</x-warning>

							<button class={css(theme().button)} onClick={uploadCapture}>
								Update poster for web crawlers
							</button>
						</Match>
						<Match when={true}>	
							<button class={css(theme().button)} onClick={uploadCapture}>
								Refresh poster for web crawlers
							</button>
						</Match>
					</Switch>
				</Match>
				<Match when={true}>
					<x-warning>
						The poster is currently NOT available to web crawlers.
					</x-warning>
					<button class={css(theme().button)} onClick={uploadCapture}>
						Save poster for web crawlers
					</button>
				</Match>
			</Switch>

			<div ref={eventPosterPreview} class={settings.frontend.venueClass}>
				<ThemeProvider theme={props.page.theme}>
					<EventPoster {...props.store} proxyProductionAssets={true} />
				</ThemeProvider>
			</div>

			<button class={css(theme().button)} onClick={saveFromLink}>Download poster for sharing</button>
		</tab-pane>
	);
}

function PageTab(props)
{
	const page = usePage();
	const settings = page.data.venue.settings;
	const event = () => props.store;
	const [overlay,setOverlay] = createSignal(false);

	return (
		<tab-pane class={pagePaneStyle()}>
			<Switch>
				<Match when={event().status != 'published'}>
					<button class={css(theme().button)} onClick={() => setOverlay(true)}>Fullscreen preview</button>

{/* TODO replace iframe */}	
					<Show when={overlay()}>
						<Overlay close={() => setOverlay(false)}>
							<iframe class={fullScreenStyle()} name='eventPage' id='preview_EventPageFullScreen' src={'/event/'+event().slug} />
						</Overlay>
					</Show>
				</Match>
				<Match when={true}>
					<a target='_blank' href={`/event/${event().slug}`}>
						<button class={css(theme().button)}>View on website</button>
					</a>
				</Match>
			</Switch>

			<div class={cx(settings.frontend.venueClass,pagePreviewStyle())}>
				<EventPagePreview {...props} />
			</div>
		</tab-pane>
	);
}

function EventPagePreview(props)
{
	const pageStyle = () => css(props.page.theme.body);

	const event = () => props.store;

	return (
		<ThemeProvider theme={props.page.theme}>
			<div class={`noReset ${pageStyle()}`}>
				<EventPageContents {...props} event={event()} isPreview={true} />
			</div>
		</ThemeProvider>
	);
}

