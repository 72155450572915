import { css, injectGlobal } from '@emotion/css';
import { PageFunctions } from 'Browser/PageFunctions';
import { CreateMessage2 } from 'Common/Messages';
import { VenueUrls } from 'Common/VenueUrls';
import { DateTime } from 'luxon';
import { BackendWrap } from 'Shared/backend/BackendWrap';
import { Breadcrumbs } from 'Shared/backend/Breadcrumbs';
import { EventTimeSummary,EventTimeNotes } from 'Shared/backend/EventTimeSummary';
import { IconColour } from 'Shared/backend/IconColour';
import { LinkOpen } from 'Shared/backend/icons/LinkOpen';
import { palette, Theme } from 'Shared/backend/Theme';
import { ThemeProvider } from 'Shared/backend/ThemeProvider';
import { standardRules } from 'Shared/common/Sanitise';
import { capitalise } from 'Shared/common/Capitalise';
import { PageProvider, usePage } from 'Shared/frontend/PageProvider';
import { Special } from 'Shared/view/backend/Special';
import { For, Show } from 'solid-js';

//XXX Ask Ben how should we select then rearrange the specials for the carousel? 
//    maybe just automatically add/remove based on period/status and sort by daily specials first then days of the week

//TODO cj split specials tables into current specials and past specials like events 

const sectionStyle = () => css({
	margin: '1em 0 3em 0'
});

const tableStyle = () => css({
	display: 'grid',
	gridTemplateColumns: '1fr 2fr 220px',
	columnGap: '1em',
	rowGap: '0.5em',
	alignItems: 'center',
	width: '100%',
	overflowX: 'auto',
	marginBottom: '2em'
});

const tableHeaderStyle = () => css({
	gridColumn: 'span 3',
	display: 'grid',
    gridTemplateColumns: 'subgrid',
	background: '#1b2126',
	color: 'white',
	padding: '1em 0.5em',
	textTransform: 'uppercase',
	fontWeight: 600

/*
	Not using sticky headers here as they don't work with overflow-x:auto which is required
	to work on narrow screen. The columns here are all self explanatory anyway.
	COULD re-enable just for wide screens I guess.
*/
//	position: 'sticky',
//	top: 70,
//	zIndex: 1,
});

const headingBarStyle = () => css({
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
});

const headingWrapStyle = () => css({
	display: 'flex',
	alignItems: 'center',
	gap: '0.5em',
});

const headingStyle = () => css({
	fontWeight: 600
});

const errorStyle = () => css({
	color: palette.error
});

const dateStyle = () => css({
	color: palette.darkText
});

const nameStyle = () => css({
	color: palette.darkText
});

const posterColumnStyle = () => css({
	justifySelf: 'center',
	textAlign: 'center'
});



export function SpecialsPage(props)
{
	injectGlobal([standardRules,{body:Theme.body}]);

	return (
		<ThemeProvider theme={Theme}>
			<PageProvider page={props.page}>
				<BackendWrap>
					<Breadcrumbs items={{
						Admin: '/admin',
						Specials: null
					}} />

					<section class={sectionStyle()}>
						<div class={headingBarStyle()}>
							<div class={headingWrapStyle()}>
								<h2 class={headingStyle()}>Specials</h2>

								<a href='/' target='_blank'>
									<LinkOpen class={css(Theme.linkIcon)} fill={IconColour.text} />
								</a>
							</div>

							<button class={css(Theme.button)} onClick={() => createSpecial(props.page)}>Create new special</button>
						</div>

						<SpecialsTable {...props} />

						<button class={css(Theme.button)} onClick={() => createSpecial(props.page)}>Create new special</button>
					</section>
				</BackendWrap>
			</PageProvider>
		</ThemeProvider>
	);
}

function SpecialsTable(props)
{
	//TODO ben special status uncomment once you've added special.status to special.json table 

	return (
		<div class={tableStyle()}>
			<div class={tableHeaderStyle()}>
				<div>Runs</div>
				<div>Name</div>
				<div class={posterColumnStyle()}>Image</div>
				{/* <div>Published</div> */}
			</div>

			<For each={props.specials}>{special =>
				<SpecialItem {...props} {...special} />
			}</For>
		</div>
	);
}

function SpecialItem(props)
{
	//TODO Ben high priority need the *rendered* event poster displayed here 
	//TODO  CJ SpecialsGroup for differentDrummer venue._id if no groups assigned give error

	const url = () => {
		if (!props.image?.hash) return '';
		return (new PageFunctions()).createImageUrl2(
			new VenueUrls(usePage().build,usePage().site.key),props.image.hash,props.image,'Special-image','tiny');
	};

	return (<>
		<a href={`/admin/special/${props._id}`} class={dateStyle()}>
			<EventTimeSummary {...props.times} />
			<EventTimeNotes {...props.times} />
		</a>

		<a href={`/admin/special/${props._id}`} class={nameStyle()}>
			<Show when={props.venue._id=='663830e9ce9dbf029e7151cf'}>
				<Show when={props.specialsGroup && props.specialsGroup != 'noGroups'}>
					{capitalise(props.specialsGroup)} - 
				</Show>
			</Show>

			<Show when={props.name} fallback={<div class={errorStyle()}>Add name (or delete if not needed)</div>}>
				{ props.name }
			</Show>
		</a>

		<a href={`/admin/special/${props._id}`} class={posterColumnStyle()}>
			<img src={url()} />
		</a>

		{/* <div class='status'/> */}
	</>);
}

async function createSpecial(page)
{
	const initial = {
		times:{
			frequency:'once',
			startSingle: DateTime.now().startOf('day').toISO({includeOffset:false,suppressMilliseconds:true})
		},
		imageDimensions:'portrait4x5'
	};

	const ret = await page.server.sendOperation(new CreateMessage2(Special.pageName,'edit',initial));
	window.pageJs(`/admin/special/${ret.id}`);
}

