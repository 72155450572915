import { VenueUrls } from 'Common/VenueUrls';
import { DateTime } from 'luxon';
import { usePage } from 'Shared/frontend/PageProvider';
import { copyToClipboard } from 'Shared/backend/CopyToClipboard';
import { otherInstagramHandles } from 'Shared/backend/eventPage/Handles';
import { ExclamationCircle } from 'Shared/backend/icons/ExclamationCircle';
import { IconColour } from 'Shared/backend/IconColour';
import { InstagramCircle } from 'Shared/backend/icons/InstagramCircle';
import { TickCircle } from 'Shared/backend/icons/TickCircle';
import { createSignal, Match, Show, Switch } from 'solid-js';
import { css } from '@emotion/css';
import { IconPane } from 'Shared/forms/IconPane';
import { announceStyle, linkedTitleStyle } from 'Shared/backend/eventPage/Plan';
import { noteStyle } from 'Shared/forms/Wrap';
import { DoneCheckbox } from 'Shared/backend/eventPage/DoneCheckbox';
import { theme } from 'Shared/backend/ThemeProvider';
import { Overlay } from 'Shared/forms/Overlay';
import { overlayContentStyle, PostPane } from 'Shared/backend/eventPage/tasks/PostPane';

const titleBeforeNoteStyle = () => css({
	marginBottom: 0
});


export function AnnounceInstagram(props)
{
	const [overlay,setOverlay] = createSignal(false);
	const [postText,setPostText] = createSignal();
	const [postLink,setPostLink] = createSignal();

	return (<>
		<IconPane>
			<Switch>
				<Match when={props.store.announceOnInstagram}>
					<TickCircle fill={IconColour.green} />
				</Match>
				<Match when={true}>
					<ExclamationCircle fill={IconColour.red} />
				</Match>
			</Switch>
			<div>
				<div class={announceStyle()}>
					<a class={linkedTitleStyle()} onClick={() => setOverlay(true)}><i class='fas fa-caret-right'/> Announce on Instagram</a>
					<DoneCheckbox {...props} field='announceOnInstagram' />
				</div>
			</div>
		</IconPane>

		<Show when={overlay()}>
			<Overlay close={() => setOverlay(false)}>
				<div class={overlayContentStyle()}>
					<PostPane icon={InstagramCircle} title='Draft Post' hideCopyButton={true} 
						instructions={<Instructions event={props.store} postText={postText()} postLink={postLink()} />}
					>
						<Post {...props} setPostText={setPostText} setPostLink={setPostLink} />
					</PostPane>
				</div>
			</Overlay>
		</Show>
	</>);
}

function Post(props)
{
	const event = () => props.store;
	const lineup = () => props.store.lineup ?? [];
	const firstAct = () => props.store.lineup?.[0];

	const posterUrl = () => (new VenueUrls(usePage().build,usePage().site.key)).imageUrl('Event-poster',event().poster.hash,'tiny','jpeg',!event().poster.dev,false);

	return (
		<div>
			<Show when={event().poster} fallback={<x-warning>Poster missing</x-warning>}>
				<img class='preview' src={posterUrl()}
					width={event().poster?.formats?.tiny?.width}
					height={event().poster?.formats?.tiny?.height}
				/>
			</Show>

			<div ref={props.setPostText}>
				Announcing 
				{ firstAct()?.instagram ? '@'+firstAct()?.instagram : firstAct()?.name }

				<Show when={lineup().length > 1}>	
					{' '}
					with 
					{ otherInstagramHandles(lineup(),0) }
					{' '}	
					{DateTime.fromISO(event().times.startSingle).toFormat('ccc d LLL')} 
					{' '}
					@{props.venue.instagram}
				</Show>

				{' '}
				<span ref={props.setPostLink}>{usePage().site.hostUrl}/event/{event().slug}</span>
			</div>
		</div>
	);
}

function Instructions(props)
{
	const page = usePage();
//TODO	
	const deviceIsDesktop = true;

	const url = () => (new VenueUrls(page.build,page.site.key)).imageUrl('Event-poster',props.event.poster?.hash,'medium','jpeg',!props.event.poster?.dev,true);

	return (
		<div>
			<h4 class={titleBeforeNoteStyle()}>Make an Instagram 'Story' or 'Post'</h4>
			<div class={noteStyle()}>Tip: if you make a Story Instagram lets you include a link to your event page!</div>

{/*
TODO saving an instagram image is handled differently by user on desktop vs mobile/tablet.
Please identify if deviceIsDesktop and add a download to step 1 below
*/}
			<ul>
				<Switch>
					<Match when={deviceIsDesktop}>
						<li><a class={css(theme().a)} href={url()} download>Click here to download the poster</a></li>
					</Match>
					<Match when={true}>
						 <li>Touch and hold the poster until your menu is displayed</li>
						<li>Select the Download/Save Image option.</li>
					</Match>
				</Switch>

				<li><a class={css(theme().a)} href='' onClick={() => copyToClipboard(props.postText)}>
					Click here to copy the Draft Post
				</a></li>
				<li>Open Instagram app and add your downloaded image</li>
				<li>On Instragram press the screen where you want the Post Text and select 'paste'.</li>
			</ul>

			<b>If making a 'Story' add a link to the event page</b>
			<ul>
				<li><a class={css(theme().a)} href='' onClick={() => copyToClipboard(props.postLink)}>Click here to copy link</a></li>
				<li>On your Instagram Story editor, select the sticker icon (smily face in a square)</li>
				<li>Select the 🔗 Link sticker</li>
				<li>Press and hold the URL box until 'Paste' appears, then paste in your link</li>
				<li>Press 'Customise sticker text' and write something like "Event Details Here"</li>
			</ul>
		</div>
	);
}

