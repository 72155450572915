import { css } from '@emotion/css';
import { HtmlCapturer } from 'Browser/HtmlCapturer';
import { ISpecial } from 'Shared/backend/specials/SpecialPage';
import { TabPanes } from 'Shared/backend/Tabs';
import { palette } from 'Shared/backend/Theme';
import { theme } from 'Shared/backend/ThemeProvider';
import { SpecialsPoster } from 'Shared/frontend/specialsPosters/SpecialsPoster';
import { ThemeProvider } from 'Shared/frontend/ThemeProvider';

const paneStyle = () => css({
	backgroundColor: palette.dimBackground,
	/* Don't want the poster margins to fold */
	border: `solid 1px ${palette.dimBackground}`
});

const downloadStyle = () => css(theme().button, {
	margin: '0 0 16px 24px'
});


export function PreviewTab(props:ISpecial)
{
	const tabs = {
		'poster': {label:'Poster',component:PosterTab}
	};

	return <TabPanes tabs={tabs} nested={true} tabProps={props} />;
}


function PosterTab(props:ISpecial)
{
	let posterPreview!: HTMLDivElement;
	const posterNode = () => posterPreview.querySelector('.capture');
	const saveFromLink = () => (new HtmlCapturer()).saveFromLink(posterNode(),2.0); 

	return (<>
		<div class={paneStyle()}>
			<div ref={posterPreview}>
				<ThemeProvider theme={props.page.theme}>
					<SpecialsPoster {...props.store} proxyProductionAssets={true} />
				</ThemeProvider>
			</div>

			<button class={downloadStyle()} onClick={saveFromLink}>Download poster for sharing</button>
		</div>
	</>);
}

