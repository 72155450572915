import { PageFunctions } from 'Browser/PageFunctions';
import { VenueUrls } from 'Common/VenueUrls';
import { IconColour } from 'Shared/backend/IconColour';
import { TickCircle } from 'Shared/backend/icons/TickCircle';
import { CalendarEntry } from 'Shared/forms/CalendarEntry';
import { Html, SingleSelect, Text} from 'Shared/forms/Inputs';
import { Wrap } from 'Shared/forms/Wrap';
import { usePage } from 'Shared/frontend/PageProvider';
import { Show } from 'solid-js';
import { ImageSelector } from 'Shared/backend/specials/ImageSelector';
import { PreviewTab } from 'Shared/backend/specials/PreviewTab';
import { css } from '@emotion/css';
import { DeleteButton } from 'Shared/forms/DeleteButton';
import { DeleteMessage2 } from 'Common/Messages';
import { Special } from 'Shared/view/backend/Special';
import { ISpecial } from 'Shared/backend/specials/SpecialPage';
//TODO extract out of eventPage
import { showTimesStatusIcon } from 'Shared/backend/eventPage/Utils';
import { ContentAndPreviewPanes } from 'Shared/backend/ContentAndPreviewPanes';
import { IconPane } from 'Shared/forms/IconPane';
import { theme } from 'Shared/backend/ThemeProvider';


//XXX can share with event page etc
const headerStyle = () => css({
	fontSize: '1.1em',
	padding: 6,
	margin: '1.5em 1em 1em 0',
	background: '#1b2126',
	color: 'white',
	display: 'flex',
	alignItems: 'center'
});

const verticalStyle = () => css({
	display: 'flex',
	flexDirection: 'column',
	gap: '1em'
});

const sectionStyle = () => css({
	margin: '2em 0'
});


export function DetailsTab(props:ISpecial)
{
	return (
		<ContentAndPreviewPanes {...props}>
			<EntryPane {...props} />

			<PreviewTab {...props} />
		</ContentAndPreviewPanes>
	);
}

export function EntryPane(props)
{
	const special = () => props.store;

	const urls = new VenueUrls(usePage().build,usePage().site.key);
	const imageUrl = () => special().image?.hash &&
		(new PageFunctions()).createImageUrl2(urls,special().image.hash,special().image,'Special-image','medium');


	return (
		<div>
			<div class={headerStyle()}>Info</div>

{/* FIXME support multiple specials groups for all sites. Could continue doing it this way, but probably better
          to have separate list pages for each group  
*/}
			<Show when={props.venue._id == '663830e9ce9dbf029e7151cf'}>
				<SingleSelect {...props} field='specialsGroup' required={true} placeholder='COMPULSORY: SELECT SPECIALS GROUP'
					options={{
						'group1':'Specials Group 1',
						'group2':'Specials Group 2',
						'group3':'Specials Group 3'
					}} 
				/>
			</Show>

			<Wrap label='Advertised name of special' instructionsAfterLabel='Keep this short (eg "Happy Hour")'>
				<Text {...props} field='name' />
			</Wrap>

			<section class={sectionStyle()}>
				<h4 class={css(theme().section.heading)}>Date</h4>

				<IconPane>
					{showTimesStatusIcon(special().times)}
					<CalendarEntry {...props} location={['times']} />
				</IconPane>
			</section>

			<section class={sectionStyle()}>
				<h4 class={css(theme().section.heading)}>Specials Poster</h4>

				<IconPane>
					<TickCircle fill={imageUrl() ? IconColour.active : IconColour.inactive} />
					<ImageSelector {...props} />
				</IconPane>
			</section>
	
			<section class={sectionStyle()}>
				<h4 class={css(theme().section.heading)}>Optional Info</h4>

				<div class={verticalStyle()}>
					<Wrap label={special().imageType == 'poster' ? 'Short description on Details Page' : 'Short description on Poster'}>
						<Text {...props} field='tagline' />
					</Wrap>

					<Wrap label='More Info on Details page'>
						<Html {...props} field='moreInfo' urls={urls}  options={{
							height: 200,
							menubar: false,
							plugins: ['link'],
							toolbar: 'undo redo | bold italic underline | alignleft aligncenter alignright alignjustify | outdent indent | link ',
							statusbar: true,
							resize: true 
						}} />
					</Wrap>
				</div>
			</section>

			<DeleteButton label='Delete special' delete={() => deleteItem(props.page,special()._id)} />
		</div>
	);
}


async function deleteItem(page,docId:Id)
{
	if (!confirm('Delete this special?'))
		return;

	const msg = new DeleteMessage2(Special.pageName,'edit',docId);
    await page.server.sendOperation(msg);

	window.pageJs('/admin/specials');
}

