import { css } from '@emotion/css';
import { DateTime } from 'luxon';
import { AnnounceDate } from 'Shared/backend/eventPage/tasks/AnnounceDate';
import { AnnounceFacebook } from 'Shared/backend/eventPage/tasks/AnnounceFacebook';
import { AnnounceInstagram } from 'Shared/backend/eventPage/tasks/AnnounceInstagram';
import { FacebookVideos } from 'Shared/backend/eventPage/tasks/FacebookVideos';
import { PosterAndPublish } from 'Shared/backend/eventPage/tasks/PosterAndPublish';
import { WebsiteMusic } from 'Shared/backend/eventPage/tasks/WebsiteMusic';
import { Calendar } from 'Shared/backend/icons/Calendar';
import { theme } from 'Shared/backend/ThemeProvider';
import { IconPane } from 'Shared/forms/IconPane';
import { Show, Switch,Match } from 'solid-js';

const planStyle = () => css({
	display: 'flex',
	flexDirection: 'column',
	gap: '2.5em'
});

const paneStyle = () => css({
	padding: '1em 1em 3em 1em'
});

export const titleStyle = () => css({
	fontWeight: 'bold',
	color: 'black'
});

export const linkedTitleStyle = () => css(theme().a,titleStyle());

export const titleWithMarginStyle = () => css(titleStyle(),{
	marginBottom: '0.5em'
});

export const announceStyle = () => css({
	display: 'flex',
	flexWrap: 'wrap',
	alignItems: 'center',
	gap: '1em'
});


export function Plan(props)
{
	const event = () => props.store;

	return (
		<div class={paneStyle()}>
			<div class={planStyle()}>
				<div>
					Complete tasks to promote event on your website and social media, and motivate performers to do the same!
				</div>
				<PosterAndPublish {...props} />
				<AnnounceDate {...props} />
				<AnnounceFacebook {...props} />
				<AnnounceInstagram {...props} />

				{/* Music Event Tasks to do between announce date and event  */}
				<Show when={event().eventType == 'music'}>
					<IconPane>
						<Calendar />
						<div class={titleStyle()}>
							<Switch>
								<Match when={event().announceDate}>
									Tasks to do between {' '}
									{DateTime.fromISO(event().announceDate).toFormat('ccc d/MM')} 
									{' & '}
									{DateTime.fromISO(event().times.startSingle).toFormat('ccc d/MM/yyyy')}
								</Match>
								<Match when={event().scheduleDate && event().status == 'scheduled'}>
									Tasks to do between {' '}
									{DateTime.fromISO(event().scheduleDate).toFormat('ccc d/MM')}
									{' & '}
									{DateTime.fromISO(event().times.startSingle).toFormat('ccc d/MM/yyyy')}
								</Match>
								<Match when={true}>
									Tasks to do between now and {' '}
									{DateTime.fromISO(event().times.startSingle).toFormat('ccc d/MM/yyyy')}
								</Match>
							</Switch>
						</div>
					</IconPane>

					<FacebookVideos {...props} />

					{/* Task: FB Post anchor link to jump to music player on website event page    */}
					<WebsiteMusic {...props} />
				</Show>
			</div>
		</div>
	);
}

export function musicExists(lineup)
{
	let ret = false;
	for (const a of lineup)
		ret = ret || a.spotify || a.appleMusic || a.bandcamp || a.soundcloud;
	return ret;
};

export function videoExists(lineup)
{
	let ret = false;
	for (const a of lineup)
		ret = ret || a.facebookVideoUrl || a.instagramVideoUrl || a.youtubeVideoUrl;
	return ret;
};

