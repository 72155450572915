import { css } from '@emotion/css';

/*
	XXX should this be attached to the theme somehow?
*/
export const posterGridStyle = () => css({
	display: 'flex !important',  //XXX !important required by menuPage until use of reset.less is removed
	padding: '0 10px',
	maxWidth: '100%',
	flexWrap: 'wrap',
	justifyContent: 'center'
});

