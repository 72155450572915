import {ISvgColours, IconColour} from 'Shared/backend/IconColour'

export function VosLogo(props:ISvgColours)
{
	return (
		<svg class='icon vosLogo'
			style={`fill:${props.fill ?? IconColour.text}; stroke:${props.stroke ?? 'inherit'};`} 
			viewBox="0 0 64 64" enable-background="new 0 0 64 64" xmlns="http://www.w3.org/2000/svg">
		  <defs></defs>
		  <path d="M 25.834 5.175 C 23.81 5.205 19.488 7.064 17.636 8.001 C 12.524 10.577 8.024 15.884 5.683 20.996 C 0.188 33.049 3.899 47.453 14.909 54.651 C 22.639 59.676 33.341 61.353 41.539 57.434 C 49.228 53.769 55.347 47.259 57.264 38.699 C 57.646 36.971 57.929 29.682 57.492 27.8 C 56.243 22.421 52.85 16.043 49.187 12.361 C 45.481 8.632 39.883 6.396 34.664 5.245 C 32.471 4.755 28.144 5.141 25.834 5.175 Z M 34.37 24.386 C 35.776 25.092 37.039 25.964 37.741 27.434 C 38.232 28.498 38.567 29.504 38.567 31.592 C 38.567 34.318 37.945 35.64 36.476 37.238 C 33.43 40.538 27.619 39.748 25.258 36.021 C 23.383 33.06 23.489 28.581 25.682 26.112 C 27.321 24.303 28.664 23.772 31.41 23.835 C 32.944 23.877 33.539 23.983 34.37 24.386 Z M 49.235 24.196 C 49.875 24.431 50.683 24.877 51.067 25.198 L 51.749 25.793 L 51.214 26.391 L 50.704 26.965 L 49.703 26.345 C 47.34 24.836 44.273 25.092 43.528 26.858 C 42.93 28.307 43.784 29.202 46.467 29.944 C 48.936 30.627 50.659 31.523 51.235 32.331 C 51.936 33.332 52.154 35.336 51.557 36.506 C 50.493 38.614 47.232 39.466 44.062 38.466 C 42.825 38.08 41.099 36.975 41.099 36.57 C 41.099 36.461 41.291 36.142 41.548 35.824 L 41.996 35.248 L 42.718 35.803 C 43.975 36.762 45.147 37.188 46.595 37.188 C 48.872 37.188 50.066 36.355 50.109 34.759 C 50.151 33.396 49.194 32.736 46.061 31.862 C 42.739 30.947 41.526 29.882 41.526 27.838 C 41.526 26.092 42.505 24.729 44.189 24.112 C 45.465 23.643 47.893 23.684 49.235 24.196 Z M 13.202 30.159 C 14.523 33.543 15.958 37.284 16.022 37.284 C 16.085 37.284 16.907 33.674 18.187 30.393 C 19.442 27.137 20.57 24.346 20.657 24.218 C 20.742 24.069 21.189 23.983 21.764 24.026 L 22.723 24.09 L 19.804 31.33 L 16.887 38.572 L 15.737 38.572 L 14.588 38.572 L 11.882 31.862 C 10.392 28.177 9.092 24.899 8.966 24.558 L 8.753 23.983 L 9.774 23.983 L 10.798 23.983 L 13.202 30.159 Z" style="stroke-miterlimit: 26.84; transform-origin: 63.909px 65.909px; stroke-width: 2px;"></path>
		  <path d="M 29.218 25.384 C 27.025 26.109 25.486 28.223 25.334 30.902 C 25.208 32.926 25.93 34.991 27.036 36.203 C 28.081 37.374 29.984 38.013 31.69 38.013 C 34.562 38.013 36.801 35.479 37.291 32.475 C 37.716 29.684 36.231 26.808 34.146 25.742 C 33.056 25.189 30.39 25.002 29.218 25.384 Z" style="stroke-miterlimit: 26.84; transform-origin: 64.909px 65.909px;"></path>
		</svg>
	);
}
