import { For } from 'solid-js';
import {ManagerData} from 'Common/pages/artist/Manager';
import {css, injectGlobal} from '@emotion/css';
import { CreateMessage2 } from 'Common/Messages';
import { theme, ThemeProvider } from 'Shared/backend/ThemeProvider';
import { Theme } from 'Shared/artists/Theme';
import { PageProvider } from 'Shared/frontend/PageProvider';
import { standardRules } from 'Shared/common/Sanitise';

const navStyle = () => css({
	display: 'flex',
	flexDirection: 'row-reverse !important',
	width: '100%'
});


export function ManagerPage(props:ManagerData) 
{
	injectGlobal([standardRules,{body:Theme.body}]);

	return (
		<ThemeProvider theme={Theme}>
			<PageProvider page={props.page}>
				<div class={css(theme().content)}>
					<nav class={navStyle()}>
						<a class={css(theme().a)} href='/user'>User details</a> 
					</nav>		

					<main>
						<ul>
							<For each={props.artists}>
								{artist => <li>
									<a class={css(theme().a)} href={`/profile/${artist._id}`}>{artist.name ?? '(Missing name)'}</a>
								</li>}
							</For>
						</ul>	

						<br/>

						<div>
							<a class={css(theme().a)} href="" onClick={e => createArtist(props.page)}>
								<button class={css(theme().button)}>Add artist</button>
							</a>
						</div>
					</main>
				</div>
			</PageProvider>
		</ThemeProvider>
	);
}

async function createArtist(page)
{
//TODO remove any *2 messages, operations and inputs (inc date time, html) as possible	

	const ret = await page.server.sendOperation(new CreateMessage2(page.name(),'create',{name:'New artist'}));

	window.pageJs(`/profile/${ret.id}`);
}



