import {SiteBasics} from 'Common/SiteBasics';
import {IPageData, IPageParams, PageConfig} from 'Common/PageConfig';
import {ComponentCreator} from 'Common/ComponentCreator';
import {PageStringRenderer} from 'Common/PageStringRenderer';
import {User} from 'Browser/User';
import {BuildBasics} from 'Common/BuildBasics';
import {IConnectionToServer} from 'Common/IConnectionToServer';
import {DocumentLoader} from 'Common/DocumentLoader';
import {IPage} from 'Common/pages/IPage';
import type {JsPageWidget} from 'Browser/widgets/JsPageWidget';
import {IPageWrapper} from 'Browser/pages/PageWrapper';
import Assert from 'Common/Assert';
import {Component} from 'Common/components/Component';

export type Params = {[name:string]:any}; 


export class Page<PD extends IPageData> implements IPage<PD>
{
	/* 
		A singleton mutable data structure that will be passed to the template and used 
		for resolving values in page.json.
	*/
	public data:PD = <PD>{params:{},htmlClasses:'',template:''};

	constructor(
		readonly config:PageConfig<IPageData,IPageParams>,  //XXX ==> PD
		readonly server:IConnectionToServer,
		readonly build:BuildBasics,
		protected params:Params,
		protected user:User,
		protected site:SiteBasics,
		protected theme,
		readonly pageStringRenderer:PageStringRenderer
	)
	{ }

	name() { return this.config.name(); }

	/* Page components will use this value except in cases where they override it */
	defaultAccess(): string { return this.config.access(); }

	*widgets(pageWrapper:IPageWrapper): Generator<JsPageWidget>
	{
		for (const widget of Object.values(this.config.widgets(pageWrapper)))
			yield widget;
	}

	widget(pageWrapper:IPageWrapper,name:string): JsPageWidget
	{
		return Assert.have(this.config.widgets(pageWrapper)[name]);
	}

	*components()
	{
		for (const [name,config] of Object.entries(this.config.components())) {
			const creator:any = new ComponentCreator(this.site,this.server,this);
			yield creator.createComponent(name,Assert.have(config));
		}
	}

	component(name:string):Component
	{
		const creator = new ComponentCreator(this.site,this.server,this);
		const config = (this.config.components())[name];
		return creator.createComponent(name,Assert.have(config));
	}

	protected initMiscData()
	{
		this.data = <PD><unknown>{
			...this.config.settings(),
			theme: this.theme,
			page: this,		//TODO after removing Nunjucks remove most of the properties below - use page instead
			params: this.params,
            build: this.build,
            buildKey: this.build.key, //TODO remove, use 'build'
			pageKey: Math.floor(Math.random() * 100000),
			pageName: this.name(),
			settingsPageName: this.config.settingsName?.(),
		};

//TODO maybe add 'artistAccess' here...
		if (this.user!=null) 
			this.data = {...this.data,
				user: {...this.user, 
//					access: this.user.roles.venueAccess(this.site.id)
				}
			};

//		const venueLinks = new VenueLinks(links);

//XXX this.data.params also exists but is undefined

		//TODO make this an absolute URL. Used as the canonical path
//		this.data.url = venueLinks.resolveLink(this.name(),this.params);
	}

	async load()
	{
		this.initMiscData();

		for (const [name,def] of Object.entries(this.config.documents(this.params))) {
			const loader = new DocumentLoader(this.server,this.name(),this.params);
			this.data[name as keyof PD] = await loader.load(name,def);
		}

		/* The components can contain non-DB data: */
		for (const [name,def] of Object.entries(this.config.components())) 
			this.data[name as keyof PD] = await this.component(name).load();
	}

	/* The parts to be rendered here are used to fill in the gaps in the wrap. */
//XXX in time ==> renderPartsForServer() + in time move to a ServerPage?
    async renderPartsToStrings()
    {
		return await Assert.have(this.pageStringRenderer).renderPageContentAsStrings(this.data);
	}
}

