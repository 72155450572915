import {DocumentQueries} from 'Common/config/PageConfigTypes';
import { z } from 'zod';
import {special as specialsModel} from 'Shared/model/Specials';
import * as t from 'Shared/model/basic';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {BackendPageConfig, backendData, backendParams} from 'Common/BackendPageConfig';
import { SpecialPage } from 'Shared/backend/specials/SpecialPage';


export const specialParams = backendParams.extend({
	id: t.id
}).strict();	
export type SpecialParams = z.infer<typeof specialParams>;


export const specialData = backendData.extend({
	params: specialParams,
	special: specialsModel,
}).strict();
export type SpecialData = z.infer<typeof specialData>;



export class Special extends BackendPageConfig<SpecialData,SpecialParams>
{
	static readonly pageName = 'backend/special';
	name() { return Special.pageName; }

	access() { return 'venueMenuEditor' as AccessAreaName; }

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			template: SpecialPage
		};
	}

	documents(params:SpecialParams): DocumentQueries
	{
		return ({
			...super.documents(params),
			special: {
				type:'object',
				collection: 'special',
				aggregate: () => [
					{$match: {_id: params.id}}
				]
			}
		});
	}

	permissions()
	{
		return ({
			edit: { collection:'special' },
			image: { collection:'special', assetFolder:'Special-image'},
		});
	}
}

