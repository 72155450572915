import { ShrinkingNavWithLogo} from 'Shared/frontend/navWidget/ShrinkingNavWithLogo';
import {OuterBasic} from 'Shared/frontend/skeleton/OuterBasic'
import {Url} from 'Shared/model/basic';
import {VenueDoc} from 'Shared/model/venue';


//TODO get types from model instead...
export type Links = {[key:string]:{target:string}}; 
export type Nav = {[key:string]: {link:Url}};

export interface IConfig {
	nav: {desktop: Nav};	//FIXME probably should put Nv up a level?
	link: Links;
}

interface IStandardDefaultLayout {
	params: XXX;
	config: IConfig;
	venue: VenueDoc;
	pageName: string;
	classes: string;
	expandDesktopNav:boolean;
}

export function StandardDefaultLayout(props:IStandardDefaultLayout)
{
//XXX maybe combine pageName and settingsPageName?

	return (
		<OuterBasic {...props} mainContent={props.children}>
			<ShrinkingNavWithLogo 
				currentPage={props.pageName}
				settingsPageName={props.settingsPageName}
				nav={props.config.nav.desktop} 
				links={props.config.link}
				expand={props.expandDesktopNav}
				logoSide='left' 
				logo={props.venue.logo}
			/>
		</OuterBasic>
	);
}
