import {Text} from 'Shared/forms/Inputs';
import { Wrap } from 'Shared/forms/Wrap';
import {FacebookAuthButton} from 'Shared/artists/FacebookAuthButton';
import {GoogleAuthButton} from 'Shared/artists/GoogleAuthButton';
import {Show, createSignal, mergeProps} from 'solid-js';
import {UserData} from 'Common/pages/artist/User';
import {css, cx, injectGlobal} from '@emotion/css';
import {PageProvider} from 'Shared/frontend/PageProvider';
import {PasswordFormContent} from 'Shared/users/SetPassword';
import { createStore } from 'solid-js/store';
import { verticalStyle } from 'Shared/artists/ProfilePage';
import { theme, ThemeProvider } from 'Shared/backend/ThemeProvider';
import { Theme } from 'Shared/artists/Theme';
import { standardRules } from 'Shared/common/Sanitise';

const userHeadingStyle = () => css({
	fontSize: '1.2em',
	color: 'rgb(66, 98, 123)',
	lineHeight: '1.5em',
	textTransform: 'uppercase',
	textAlign: 'left',
	fontWeight: 'initial'
});

const connectStyle = () => css({
	button: {
		display: 'flex',
		margin: '0 0 12px 0'
	}
});

const sectionStyle = () => css({
	margin: '1em 0 2em 0'
});


export function UserPage(props:UserData)
{
	injectGlobal([standardRules,{body:Theme.body}]);

	const [personStore,setPersonStore] = createStore(props.person);

//XXX or am I better passing through all props?
	const person = mergeProps({
		page: props.page,
		docId: personStore._id,
		permission: 'edit',
		collection: 'Person',
		location: [],
		store: personStore,
		setStore: setPersonStore,
	});

	return (
		<ThemeProvider theme={Theme}>
			<PageProvider page={props.page}>
				<div class={css(theme().content)}>
					<nav class={css(theme().nav)}>
						<a class={css(theme().a)} href='/manager'>Top</a>
						<a class={css(theme().a)} href='/logout'>Logout</a>
					</nav>		

					<h3 class={userHeadingStyle()}>Edit user</h3>

					<Details {...person} />

					{/* XXX Require at least one method to log in. If email: email + password */}

					<PasswordForm {...person} />

					<AuthenticationProviders {...person} />
				</div>
			</PageProvider>
		</ThemeProvider>
	);
}

function Details(person)
{
	return ( 
		<section class={cx(sectionStyle(),verticalStyle())}>
			<Wrap label='First name' required={true}>
				<Text {...person} field='firstName' />
			</Wrap>

			<Wrap label='Last name' required={true}>
				<Text {...person} field='lastName' />
			</Wrap>

			<Wrap label='Email' required={true}>
				<Text {...person} field='email' />
			</Wrap>
		</section>
	);
}

function PasswordForm(props:UserData)
{
	const [showPasswordForm,setShowPasswordForm] = createSignal(false);

	return (
		<section class={sectionStyle()}>
			<h3 class={userHeadingStyle()}>Set password</h3>

			<p>
				{ showPasswordForm() ?
					<a href='' class={css(theme().a)} onClick={() => setShowPasswordForm(false)}>Hide password form</a>
				:
					<a href='' class={css(theme().a)} onClick={() => setShowPasswordForm(true)}>Set password</a>
				}
			</p>

			<Show when={showPasswordForm()}>
				<PasswordFormContent {...props} setShowPasswordForm={setShowPasswordForm} done={passwordChanged} />
			</Show>
		</section>
	);
}


function passwordChanged(props,ret)
{
	if ('error' in ret) 
		alert(ret.error);
	else {
		alert('Password updated');
		props.setShowPasswordForm(false);
	}
}


function AuthenticationProviders(props:UserData)
{
	const person = () => props.store;

	return <>
		<section class={cx(sectionStyle(),connectStyle())}>
			<h3 class={userHeadingStyle()}>Connect to authentication providers</h3>
			{/*
				If the email exists and is different ignore the Google email.
				If the email doesnt yet exist then use it.
			 */}
			<Show when={!person().googleConnected}>
				<GoogleAuthButton label='Connect to Google' action={() => {location.href='/user/google/connect';}} />
			</Show>

			<Show when={!person().facebookConnected}>
				<FacebookAuthButton label='Connect to Facebook' action={() => location.href='/user/facebook/connect'} />
			</Show>

			<Show when={person().googleConnected && person().facebookConnected}>
				<p>
					All authentication providers are already connected.
				</p>
			</Show>
		</section>


		<section class={cx(sectionStyle(),connectStyle())}>
			<h3 class={userHeadingStyle()}>Disconnect from authentication providers</h3>

			<Show when={person().googleConnected}>
				<GoogleAuthButton label='Disconnect from Google' action={() => props.page.config.disconnectProvider(props.page.server,'google')} />
			</Show>

			<Show when={person().facebookConnected}>
				<FacebookAuthButton label='Disconnect from Facebook' action={() => props.page.config.disconnectProvider(props.page.server,'facebook')} />
			</Show>

			<Show when={!person().googleConnected && !person().facebookConnected}>
				No providers to disconnect.
			</Show>
		</section>
	</>;
}

