import {FacebookCircle} from 'Shared/backend/icons/FacebookCircle';
import {IconColour} from 'Shared/backend/IconColour';
import {InstagramCircle} from 'Shared/backend/icons/InstagramCircle';
import {LinkOpen} from 'Shared/backend/icons/LinkOpen';
import {WebsiteCircle} from 'Shared/backend/icons/WebsiteCircle';
import {Text} from 'Shared/forms/Inputs';
import {Wrap} from 'Shared/forms/Wrap';
import {activeStyle, ArtistProps, linkIconStyle, searchLinkStyle, sectionNoteStyle, sectionStyle, verticalStyle,sectionHeadingStyle} from 'Shared/artists/ProfilePage';
import {facebookPageUrl, instagramPageUrl, websiteUrl} from 'Browser/InputTypes';
import {createMemo} from 'solid-js';
import { cx } from '@emotion/css';
import { IconPane } from 'Shared/forms/IconPane';


export function SocialSites(props:ArtistProps)
{
	const searchTerms = createMemo(() => {
		//TODO fill in other performace types...
		let style;
		if (props.performanceType == 'comedy')
			style = 'Comedian';
		else if (props.performanceType =='dj')
			style = 'DJ';
		else 
			style = 'Music+Bands';

		let sep='';
		let location = '';
		if (props.city) {
			location += `${sep}${props.city}`;
			sep = '+';
		}
		if (props.state) {
			location += `${sep}${props.state}`;
			sep = '+';
		}
		if (props.country) {
			location += `${sep}${props.country}`;
			sep = '+';
		}

		return `${props.name}+${style}+${location}`;
	});

	return (
		<section class={sectionStyle()}>
			<h4 class={sectionHeadingStyle()}>
				Social media & sites
				<div class={sectionNoteStyle()}>Important so VOS can find artist content</div>
			</h4>

			<div class={verticalStyle()}>
				<FacebookUrl {...props} searchTerms={searchTerms} />

				<InstagramUrl {...props} searchTerms={searchTerms} />

				<WebsiteUrl {...props} searchTerms={searchTerms} />
			</div>
		</section>
	);
}	

function FacebookUrl(props:ArtistProps & {searchTerms:()=>string})
{
	const facebookLabel = () => exists(props.facebook) ?
			<a class={cx(searchLinkStyle(),activeStyle())} target='_blank' href={`https://www.facebook.com/${props.facebook}`}>
				Facebook <LinkOpen class={linkIconStyle()} fill={IconColour.active}/>
			</a>
		:
		   <a class={searchLinkStyle()} target='_blank'
					href={`https://www.google.com/search?q=+facebook+page+${props.searchTerms()}`}>
				Search Facebook for artist <LinkOpen class={linkIconStyle()} fill={IconColour.inactive} />
			</a>;

	return (
		<IconPane>
			<FacebookCircle fill={exists(props.facebook) ? IconColour.active : IconColour.inactive} />

			<Wrap label={facebookLabel()}>
				<Text {...props} field='facebook' processInput={facebookPageUrl} placeholder='Paste web link or handle here' />
			</Wrap>
		</IconPane>
	);
}

function InstagramUrl(props:ArtistProps & {searchTerms:()=>string})
{
	const instagramLabel = () => exists(props.instagram) ?
			<a class={cx(searchLinkStyle(),activeStyle())} target='_blank' href={`https://www.instagram.com/${props.instagram}`}>
				Instagram <LinkOpen class={linkIconStyle()} fill={IconColour.active}/> 
			</a>
		:
			<a class={searchLinkStyle()} target='_blank'
					href={`https://www.google.com/search?q=instagram+profile+${props.searchTerms()}`}>
				Search Instagram for artist <LinkOpen class={linkIconStyle()} fill={IconColour.inactive} />
			</a>;

	return (
		<IconPane>
			<InstagramCircle fill={exists(props.instagram) ? IconColour.active : IconColour.inactive} />

			<Wrap label={instagramLabel()}>
				<Text {...props} field='instagram' processInput={instagramPageUrl} placeholder='Paste web link or handle here' />
			</Wrap>
		</IconPane>
	);
}

function WebsiteUrl(props:ArtistProps & {searchTerms:()=>string})
{
	const websiteLabel = () => exists(props.website) ?
			<a class={cx(searchLinkStyle(),activeStyle())} target='_blank' href={props.website}>
				Website <LinkOpen class={linkIconStyle()} fill={IconColour.active}/> 
			</a>
		:
			<a class={searchLinkStyle()} target='_blank'
					href={`https://www.google.com/search?q=website+${props.searchTerms()}`}>
				Search for artist website <LinkOpen class={linkIconStyle()} fill={IconColour.inactive} />
			</a>;

	return (
		<IconPane>
			<WebsiteCircle fill={exists(props.website) ? IconColour.active : IconColour.inactive} />

			<Wrap label={websiteLabel()}>
				<Text {...props} field='website' processInput={websiteUrl} placeholder='Paste website address here starting with http' />
			</Wrap>
		</IconPane>
	);
}

function exists(value?:string)
{
	return value!=undefined && value.trim()!='';
}

