import {CSSInterpolation} from '@emotion/css';
import {palette} from 'Shared/artists/Palette';

export const globalForms:CSSInterpolation = {
	'x-field': {
		display: 'block',
		marginBottom: 15,
		width: '100%',
		padding: 6
	},

	label: {
		margin: '6px 0',
		fontWeight: 'bold',
		fontSize: 12,
		color: '#666'
	},

	'select,input,textarea': {
		font: '15px Arial,Helvetica,sans-serif',
		backgroundColor: 'inherit',
		border: 'none',
		borderBottom: 'solid #efefef 1px',
		padding: '5px 10px 5px 6px',

		width: '100%',
		color: '#555',

		'&:hover': {
			borderColor: 'rgba(34,36,38,.35)'
		},

		'&:focus': {
			borderColor: '#66afe9',
			outline: 0,
			boxShadow: 'inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6)'
		}

		//FIXME focusing select gives orange border on FF
	},

	'input::placeholder, textarea::placeholder': {
		fontsize: 14,
		fontstyle: 'italic'
	},

	'input, textarea': {
		transition: 'border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s'
	},

//TODO putting in a buttons.tsx file or function? 
	'button, input[type="submit"], input[type="button"]': {
		textAlign: 'center',
		verticalAlign: 'middle',
		backgroundColor: palette.button,
		borderRadius: 3,
		border: '1px solid #728a9d',
		color: '#FFF',
		padding: '8px 12px',
		fontSize: 13,
		fontWeight: 400,
		lineHeight: '16px',	//XXX Emotion bug requires px

		'&:hover': {
			cursor: 'pointer'
		},

		'&[disabled]': {
			filter: 'brightness(125%)',

			'&:hover': {
				cursor: 'not-allowed'
			}
		},

		'&:focus': {
			border: `solid 1px brightness(70%)`
		}
	},

	'x-error': {
		display: 'block',
		color: palette.error
	},

	'x-notes': {
		display: 'block',
		fontSize: 10,
		lineHeight: '1.2em',
		margin: '2px 0px',
		color: '#666',
		textTransform: 'initial'
	},

	//XXX CUSTOM, not centering,  NB its a button really
	'a.delete': {
		float: 'right',
		marginTop: '0.5em',
		textTransform: 'uppercase',
		fontSize: '0.8em',
		border: 'solid 1px',
		padding: 2
	},

	'a.delete:hover': {
		background: 'red',
		color: 'white'
	}
};

