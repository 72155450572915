import {BuildBasics} from 'Common/BuildBasics';
import {Id} from 'Common/Id';
import { SiteBasics } from 'Common/SiteBasics';
import {Location} from 'Common/config/PageConfigTypes';
import * as imageSize from 'Shared/model/ImageSizes';

export interface FullLink
{
	url:string;
	id?:string;
}

export interface PartialLink
{
	partial:string;
	id?:string;
}

export interface ILinks
{
	js: FullLink[];
	css: FullLink[];
	prefixes: {resources?:string};
}


export abstract class Urls
{
	constructor(
		protected build:BuildBasics
	) { }

	abstract imageUrl(assetFolder:string,hash:string,size:string,fileType:string,useProduction:boolean,proxyProductionAssets:boolean):string;

	uploadImageUrl(page:string,component:string,docId:Id,location:Location):string
	{
		return `${hostUrl()}/admin/imageUpload/`+page+'/'+component+'/'+docId.toString() + (location==undefined ? '' : '/'+encodeURIComponent(JSON.stringify(location)));
	}

	uploadImageUrl2(page:string,permission:string,formats:keyof typeof imageSize,docId:Id,location:Location):string
	{
		const encPage = encodeURIComponent(page);
		const encLocation = location==undefined ? '' : '/'+encodeURIComponent(JSON.stringify(location));

		return `${hostUrl()}/admin/imageUpload2/${encPage}/${permission}/${formats}/${docId}${encLocation}`;
	}

	resourceUrl(relativeUrl:string):string
	{
		return this.buildUrl(`resources/${relativeUrl}`);
	}

	buildUrl(relativeUrl:string):string
	{
		const buildBit = this.build.storeForBuiltAssets=='production' ? '_build' : '_devBuild';
		return `${hostUrl()}/${buildBit}/`+this.build.key+'/'+relativeUrl;
	}
}

export function	hostUrl()
{
	const useVite = typeof window!='undefined' ? window.useVite : global.useVite;
	return useVite ? document.location.protocol+'//'+document.location.hostname+':8081' : '';
}

export function entryUrl(site:SiteBasics)
{
	switch(site.type)
	{
		case 'artist':	return '/manager';
		default:		return '/admin';
	}
}

