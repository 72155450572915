import { z } from 'zod';
import {staffResource as staffResourceModel} from 'Shared/model/StaffResource';
import {DocumentQueries} from 'Common/config/PageConfigTypes';
import {HtmlEditorWidget} from 'Browser/widgets/HtmlEditorWidget';
import * as t from 'Shared/model/basic';
import {MultiSelectWidget} from 'Browser/widgets/MultiSelectWidget';
import {DateTimeWidget} from 'Browser/widgets/DateTimeWidget';
import {IPageWrapper} from 'Browser/pages/PageWrapper';
import {AccessAreaName} from 'Common/permissions/AccessArea';
import {BackendPageConfig, backendData} from 'Common/BackendPageConfig';
import {VenueUrls} from 'Common/VenueUrls';
import { StaffResourcePage } from 'Shared/backend/staffResources/StaffResourcePage';


export const staffResourceParams = z.object({
	id: t.id
}).strict();	
export type StaffResourceParams = z.infer<typeof staffResourceParams>;

export const staffResourceData = backendData.extend({
	staffResource: staffResourceModel,

	/* Component data types: */
	createStaffResource: z.object({
		id: z.string()
	})
}).strict();
export type StaffResourceData = z.infer<typeof staffResourceData>;


export class StaffResource extends BackendPageConfig<StaffResourceData,StaffResourceParams>
{
	static readonly pageName = 'backend/staffResource';
	name() { return StaffResource.pageName; }

	wrap() { return 'backend'; }

	access() { return 'venueFullEditor' as AccessAreaName; }

//TODO DELETE
	widgets(pageWrapper:IPageWrapper)
	{
		const urls = new VenueUrls(this.build,this.venue.key);

		return {
			[HtmlEditorWidget.key()]: new HtmlEditorWidget(urls),
			[MultiSelectWidget.key()]: new MultiSelectWidget(),
			[DateTimeWidget.key()]: new DateTimeWidget(),
		};
	}

	defaultSettings()
	{
		return {
			...super.defaultSettings(),
			template: StaffResourcePage
		};
	}

	permissions()
	{
		return ({
			editStaffResource: {collection:'staffResource'},
		});
	}

	documents(params:StaffResourceParams):DocumentQueries
	{
		return ({
			...super.documents(params),

			staffResource: {
				type:'object',
				collection: 'staffResource',
				aggregate: () => [
//					{$match: {slug:params.slug}}
					{$match: {_id:params.id}}
//						id: { path: 'params.id', type: 'number' }
				],
			}
		});
	}

	/*
	components():Components<StaffResourceData>
	{
		return ({
			editResource: {
				type: 'edit',
				collection: 'staffResource',
				locate: (doc:StaffResourceDoc,) => doc,
			},

			deleteStaffResource: {
				type: 'delete',
				collection: 'staffResource',
				redirect: () => '/admin/staffResources' 
			},

			sections: {
				type: 'repeater',
				selector: '#sections repeater-items',
				collection: 'staffResource',
				locate:				(doc:StaffResourceDoc,location:Location) => doc.section[Assert.toNumber(location[0])],
				locateListParent:	(doc:StaffResourceDoc) => doc,
				fieldName:			() => 'section',
				initialValues: () => ({}),
				processInput: (location:Location,field:string,value:string) => value
			},

			overlays: <OverlayComponent>{
				type: 'overlay',
				panes: {
					resourceOverlay: false
				}
			},
		})
	}
	*/
}

