import { css } from '@emotion/css';
import { Phone } from 'Shared/frontend/icons/Phone';
import { Pin } from 'Shared/frontend/icons/Pin';
import { leadingIconStyle } from 'Shared/frontend/leadingIconStyle';
import { palette, theme } from 'Shared/frontend/ThemeProvider';
import { Show } from 'solid-js';


const phoneAndAddressStyle = () => css(theme().list);

const aStyle = () => css(theme().a, {
	color: palette().text
});

const addressStyle = () => css(theme().address, {
	color: palette().text
});


export function PhoneAndAddress(props)
{
	return (<>
		<ul class={phoneAndAddressStyle()}>
			<li>
				<a class={aStyle()} href={`tel:${props.venue.phoneNumber}`}>
					<div class={leadingIconStyle()}>
						<Phone />
					</div>
					{props.venue.phoneNumber}
				</a>
			</li>
			<li>
				 <a class={aStyle()} href={props.venue.mapUrl} target='_blank'>
					<div class={leadingIconStyle()}>
						<Pin />
					</div>
					<address class={addressStyle()}>
						{props.venue.streetAddress}
						<br/>
						{props.venue.suburb}{' '}
						{props.venue.state}{' '}
						{props.venue.postcode}
					</address>
				</a> 
			</li>
		</ul>

		<Show when={props.venue.postalAddress}>
			<div>Postal Address: {props.venue.postalAddress}</div>
		</Show>
	</>);
}

