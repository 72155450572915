import { VenueUrls } from 'Common/VenueUrls';
import { usePage } from 'Shared/frontend/PageProvider';
import { ISet, sectionStyle, verticalStyle } from 'Shared/backend/eventPage/artist/ArtistForm'
import { DocumentCircle } from 'Shared/backend/icons/DocumentCircle';
import { IconColour } from 'Shared/backend/IconColour';
import { LinkOpen } from 'Shared/backend/icons/LinkOpen';
import { Html, Text } from 'Shared/forms/Inputs';
import { labelStyle, Wrap } from 'Shared/forms/Wrap';
import { createSignal, Match, Show, Switch } from 'solid-js';
import { inlineOpenLinkStyle, palette } from 'Shared/backend/Theme';
import { css, cx } from '@emotion/css';
import { IconPane } from 'Shared/forms/IconPane';
import { theme } from 'Shared/backend/ThemeProvider';

const warningStyle = () => css({
	color: palette.error
});

const notesStyle = () => css({
    fontSize: 10,
    lineHeight: '1.2em',
    margin: '0 0',
    color: 'rgb(102, 102, 102)',
	padding: '0 0'
});

const activeStyle = () => css({
	color: palette.active
});


export function Biography(props:ISet)
{
	const page = usePage();
	const urls = new VenueUrls(page.build,page.site.key);

	let taglineNode;
	const [taglineLength,setTaglineLength] = createSignal(0);
	const taglineChange = () => setTaglineLength(taglineNode.value.length);

	return (
		<section class={sectionStyle()}>
			<h4 class={css(theme().section.heading)}>Biography</h4>

			<IconPane>
				<DocumentCircle fill={props.tagline || props.biography ? IconColour.active : IconColour.inactive} />

				<div class={verticalStyle()}>
					<Show when={props.facebook || props.website}> 
						<label class={labelStyle()}>
							Search for info on artists 

							<Show when={props.facebook}>
								<a class={css(theme().a)} href={`https://www.facebook.com/${props.facebook}`} target='_blank'>
									<span class={cx(inlineOpenLinkStyle(),activeStyle())}>
										FACEBOOK 
										<LinkOpen class={css(theme().linkIcon)} fill={IconColour.active} />
									</span>
								</a>
							</Show>

							<Show when={props.facebook && props.website}>
								or 
							</Show>

							<Show when={props.website}>
								<a class={css(theme().a)} href={props.website} target='_blank'>	
									<span class={cx(inlineOpenLinkStyle(),activeStyle())}>
										WEBSITE HERE
										<LinkOpen class={css(theme().linkIcon)} fill={IconColour.active} />
									</span>
								</a>
							</Show>
						</label>
					</Show>
			
					<div>
						<Wrap 
							label='Short description' 
							instructionsAfterLabel='Imagine this is the only thing people will read about the artist'
						>
							<Text ref={taglineNode} {...props} field='tagline' onInput={taglineChange} />
						</Wrap>

						<Switch>
							<Match when={taglineLength() > 100}>
								<div class={warningStyle()}>
									Remove {taglineLength() - 100} characters (it won't save if over 100 characters)
								</div>
							</Match>
							<Match when={true}>
								<div class={notesStyle()}>
									{100 - (taglineLength() ?? 0)}/100 characters left
								</div>
							</Match>
						</Switch>
					</div>

					<Wrap label='More Artist Info' instructionsAfterLabel='Displayed after "Short description" so do not repeat that'>
						<Html {...props} urls={urls} field='biography' options={{
							height: 200,
							menubar: false,
							plugins: ['link'],
							toolbar: 'undo redo | link',
							statusbar: true,
							resize: true
						}} />
					</Wrap>
				</div>
			</IconPane>
		</section>
	);
}

