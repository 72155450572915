import {genres, performanceType} from 'Shared/ArtistTypes';
import {ArtistProps, verticalStyle} from 'Shared/artists/ProfilePage';
import {IconColour} from 'Shared/backend/IconColour';
import { sectionStyle } from 'Shared/backend/eventPage/artist/ArtistForm';
import {InfoCircle} from 'Shared/backend/icons/InfoCircle';
import { IconPane } from 'Shared/forms/IconPane';
import {SingleSelect, Text} from 'Shared/forms/Inputs';
import {Wrap} from 'Shared/forms/Wrap';

export function Style(artist:ArtistProps)
{
	return (
		<section class={sectionStyle()}>
			<h4>Style</h4>

			<IconPane>
				<InfoCircle fill={artist.mainGenre || artist.extraGenre ? IconColour.active : IconColour.inactive} />

				<div class={verticalStyle()}>
					{/*
						TODO add 'performanceType' in lineup.json and artist profile, then uncomment below, change .location class from .threeColumn() to .fourColumn()
									once in artist profile  change 'set required' below to 'true'
					*/}
					
					<Wrap label='Performance type' required={true}>
						<SingleSelect {...artist} field='performanceType' options={performanceType} required={true} placeholder='Original Band' />
					</Wrap>

					<Wrap label='Main genre' required={true}>
						<SingleSelect {...artist} field='mainGenre' options={genres} required={true} />
					</Wrap>

					<Wrap label='2nd genre' required={false}>
						<SingleSelect {...artist} field='otherGenre1' options={genres} required={false} />
					</Wrap>

					<Wrap label='Custom genre' required={false} instructionsAfterLabel='Type if not in the above genre list'>
						<Text {...artist} field='extraGenre' />
					</Wrap>
				</div>
			</IconPane>
		</section>
	);
}
